import React from "react";
import { Body, Header } from "@sweeten/oreo";
import thankYouSvgPath from "../../../assets/images/hubspot_applicant_form/thank_you.svg";

const SuccessConfirmation = () => {
  return (
    <div style={{ textAlign: "center", padding: "0 24px" }}>
      <img src={thankYouSvgPath} alt="thank_you" />
      <Header tag="h6">Thank you for applying!</Header>
      <Body tag="p">
        Next, one of our team members will call you. They’ll want to learn more
        about your business, and will answer any questions about Sweeten.
      </Body>
    </div>
  );
};

export default SuccessConfirmation;
