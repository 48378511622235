// eslint-disable-next-line import/prefer-default-export
export const propertyTypeOptions = {
  apartment: "Apartment/Condo/Co-op",
  house: "Home/Single Family Home",
  multi_family: "Multi-Family Home",
  townhouse: "Townhome/Patio Home",
};

export const finishLevelOptions = {
  budget: "Budget to mid-level ($$)",
  mid_grade: "Mid-level to high-end ($$$)",
  high_end: "High-end to luxury ($$$$)",
};

export const ownershipTypeOptions = {
  "Yes, I own": "Yes, I own the property",
  "In contract": "Almost, I'm in contract",
  "Made an offer": "No, but I made an offer",
  "Representing owner": "I represent the owner",
};

export const ownerRelationOptions = [
  "Architect",
  "Interior designer",
  "Real estate agent",
  "Developer",
  "Building manager",
  "Relative",
  "Engineer",
];

export const umbrellaInsuranceOptions = [
  "$1 Million",
  "$2 - $4 Million",
  "$5 Million",
  "$6 Million+",
  "No insurance required",
  "I'll find out later",
];

export const startDateOptions = [
  "Within a month",
  "1-3 months",
  "3-6 months",
  "Later than 6 months",
];
