import { gql } from "apollo-boost";

export const calculateStartDates = (dateOption, currentDate) => {
  let startDate;
  let endDate;
  switch (dateOption) {
    case "Lifetime":
      return null;
    case "This month":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      break;
    case "Last month":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      startDate.setMonth(startDate.getMonth() - 1);
      break;
    case "Year to date":
      startDate = new Date(currentDate.getFullYear(), 0, 1);
      break;
    case "Last year":
      startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
      endDate = new Date(currentDate.getFullYear(), 0, 1);
      break;
    default:
      // year x to year x + 1
      startDate = new Date(Number(dateOption.split(" - ")[0]), 0, 1);
      endDate = new Date(Number(dateOption.split(" - ")[1]), 0, 1);
  }
  return endDate
    ? {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      }
    : {
        startDate: startDate.toISOString(),
      };
};

export const translateToHours = timeMinutes => {
  if (!timeMinutes) {
    return `0min`;
  }
  const timeRounded = Math.round(timeMinutes);
  if (timeRounded < 60 && timeRounded >= 0) {
    return `${timeRounded}min`;
  }
  if (timeRounded >= 1440) {
    return "24h+";
  }
  if (timeRounded <= -1440) {
    return "-24h+";
  }
  const hours = timeRounded / 60;
  const hoursRounded = Math.floor(hours);
  const minutes = (hours - hoursRounded) * 60;
  const minutesRounded = Math.round(minutes);
  return `${hoursRounded}h ${minutesRounded}min`;
};

export const GET_COMPANY_CREATED_AT = gql`
  query getCreatedAtData {
    currentUser {
      id
      company {
        id
        createdAt
        contact {
          location {
            state
          }
        }
      }
    }
  }
`;
