import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite/no-important";
import { Header, Body, styles, Accordion, Icon } from "@sweeten/oreo";
import VerifiedSvgPath from "../../assets/images/sweeten-verified.svg";
import Checkmark from "../components/checkmark";

const ss = StyleSheet.create({
  titleAndProtection: {
    backgroundColor: `rgba(219, 241, 254, 0.5)`,
    padding: 24,
  },
  headerAndBody: {
    cursor: "pointer",
    ...styles.center("vertical"),
    padding: "18px 24px",
    backgroundColor: `rgba(219, 241, 254, 0.25)`,
  },
  criteria: {
    color: styles.colors.grey40,
  },
  black: {
    color: styles.colors.black,
  },
  removeBottomBorder: {
    borderBottom: "none",
  },
  noBorderTop: {
    borderTop: "none",
    paddingTop: 0,
  },
  blueLight: {
    backgroundColor: `rgba(219, 241, 254, 0.5)`,
  },
  flexCentered: {
    ...styles.center("vertical"),
  },
  container: {
    marginBottom: 64,
    border: `1px solid ${styles.colors.grey20}`,
    borderRadius: 8,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        margin: "0px -16px 40px",
        border: "none",
      },
    }),
  },
  verifiedIcon: {
    marginLeft: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginLeft: 16,
      },
    }),
  },
});

const VettingHeader = ({ contentIsHidden, criteria }) => {
  const arrowName = contentIsHidden ? "chevron-down" : "chevron-up";
  return (
    <div
      className={css([
        ss.headerAndBody,
        !contentIsHidden && ss.removeBottomBorder,
      ])}
      style={{ borderTop: `1px solid ${styles.colors.grey20}` }}
    >
      <Checkmark notChecked={!criteria.isFulfilled} />
      <Body
        tag="div"
        variant="bold"
        aphStyle={[ss.criteria, criteria.isFulfilled && ss.black]}
      >
        {criteria.name}
      </Body>
      <Icon name={arrowName} size={24} style={{ marginLeft: "auto" }} />
    </div>
  );
};

VettingHeader.propTypes = {
  contentIsHidden: PropTypes.bool,
  criteria: PropTypes.object,
};

const Vetting = ({ company }) => {
  const {
    registeredBusiness,
    verifiedBusiness,
    validLicenses,
    insuranceCoverage,
    referencesConfirmed,
    portfolioReviewed,
    onlineReputationChecked,
    requiresLicense,
  } = company || {};
  const criteriaAll = [
    {
      name: "Legally registered business",
      isFulfilled: registeredBusiness,
      description: "Their business is registered and active with the state.",
    },
    ...(requiresLicense
      ? [
          {
            name: "Valid and active license",
            isFulfilled: validLicenses,
            description: `They have a valid and active license to perform work
            (as required within their service area).`,
          },
        ]
      : []),
    {
      name: "Full insurance coverage",
      isFulfilled: insuranceCoverage,
      description: `Their insurance is active, and their coverage
      meets or exceeds Sweeten’s standards.`,
    },
    {
      name: "References",
      isFulfilled: referencesConfirmed,
      description: `Their past clients give consistently positive
      feedback—verified by phone or email.`,
    },
    {
      name: "Work portfolio",
      isFulfilled: portfolioReviewed,
      description: `Their portfolio projects meet or exceed Sweeten’s standards,
      and are aligned with the contractor’s online claims and stated price range.`,
    },
    {
      name: "Online reputation",
      isFulfilled: onlineReputationChecked,
      description: `A deep online search for client reviews reveals
      consistently positive feedback.`,
    },
    {
      name: "Live interview",
      isFulfilled: verifiedBusiness,
      description: `During a live, 30-minute interview they demonstrate a high level
      of professionalism and strong communication skills.`,
    },
  ];

  const isOpenForMatching =
    company.status === "active" &&
    company.priority > 0 &&
    !company.paused &&
    company.services.includes("General Contracting");

  return (
    <div className={css(ss.container)}>
      <div className={css(ss.titleAndProtection, ss.radius)}>
        <Header tag="h6">How we vet our general contractors</Header>
        <Body tag="div" style={{ marginTop: 8 }}>
          Before accepting a contractor into our network, we confirm the
          following—so you don&apos;t have to:
        </Body>
      </div>
      {criteriaAll.map(criteria => (
        <Accordion shouldHideContent>
          <VettingHeader contentIsHidden criteria={criteria} />
          <div className={css([ss.headerAndBody, ss.noBorderTop])}>
            {criteria.description}
          </div>
        </Accordion>
      ))}
      <div
        className={css(ss.flexCentered, ss.titleAndProtection)}
        style={{ borderTop: `1px solid ${styles.colors.grey20}` }}
      >
        <Body tag="div">
          You can give yourself peace of mind by hiring this contractor through
          Sweeten using Sweeten Secure Pay.
        </Body>
        <div className={css(ss.verifiedIcon)}>
          <img src={VerifiedSvgPath} alt="verified" />
        </div>
      </div>
    </div>
  );
};

Vetting.propTypes = {
  company: PropTypes.object,
};

export default Vetting;
