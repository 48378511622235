import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Header } from "@sweeten/oreo";
import { isEmpty } from "lodash";
import ProjectRooms from "../../project_rooms";
import { trackViewStepPrimeForm } from "../tracking_events";

const handleSubmit = ({ addRoomLoading, rooms, setErrors, update }) => {
  if (addRoomLoading) {
    return null;
  }
  if (isEmpty(rooms)) {
    setErrors("You must choose at least one area and its square footage");
  } else {
    update({});
  }
  return null;
};

const Rooms = ({ isActive, setHandleSubmit, subject: project, update }) => {
  const { rooms } = project;
  const [error, setErrors] = useState("");
  const [addRoomLoading, setAddRoomLoading] = useState(false);

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => () =>
        handleSubmit({ addRoomLoading, rooms, setErrors, update })
      );
    }
  }, [isActive, rooms, addRoomLoading]);

  useEffect(() => {
    trackViewStepPrimeForm("Rooms");
  }, []);

  return (
    <>
      <Header tag="h5" style={{ marginBottom: 16 }}>
        Please tell us which areas you plan to renovate
      </Header>
      <ProjectRooms
        id={project.id}
        error={error}
        setLoadingState={setAddRoomLoading}
        withSuggestions
      />
    </>
  );
};

Rooms.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default Rooms;
