import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button, FormLabel, Input, styles } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useForm, useField } from "react-final-form-hooks";
import { AppDispatch } from "../app_provider";
import PasswordRequirements, {
  shouldShowPasswordReqs,
} from "./password_requirements";
import {
  validate,
  required,
  securePassword as passwordValidator,
  test,
} from "../../validators";
import { goToPage } from "../../utils";

/* ======= Styles ======= */

const ss = StyleSheet.create({
  container: {
    width: "100%",
  },
  inputField: {
    width: "100%",
    marginBottom: 24,
  },
  saveCtaStyle: {
    display: "flex",
    alignItems: "flex-start",
    flexFlow: "column nowrap",
    marginTop: 16,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        alignItems: "stretch",
        textAlign: "center",
      },
    }),
  },
});

/* ======= Mutations ======= */

const USER_UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!, $resetToken: String) {
    userUpdatePassword(password: $password, resetToken: $resetToken) {
      user {
        id
      }
      error
    }
  }
`;

/* ======= Functions ======= */

const validateForm = values => {
  const { password, passwordConfirmation } = values;
  const passwordValidators = [required, passwordValidator];
  const match = () => password === passwordConfirmation;
  const passConfirmationValidators = [
    required,
    () => test(password, match, "Passwords do not match"),
  ];

  return {
    password: validate(passwordValidators, password),
    passwordConfirmation: validate(
      passConfirmationValidators,
      passwordConfirmation
    ),
  };
};

/* ======= Components ======= */

const UpdatePasswordForm = ({ aphStyle, resetToken }) => {
  const [updatePassword, { loading: mutationLoading }] = useMutation(
    USER_UPDATE_PASSWORD
  );
  const dispatch = useContext(AppDispatch);

  const showSuccessAlert = () => {
    dispatch({
      type: "alert:show",
      payload: {
        variant: "success",
        text: "Your password has been successfully updated!",
      },
    });
  };

  const showErrorAlert = () => {
    dispatch({
      type: "alert:show",
      payload: {
        variant: "error",
        text: "Password reset token is invalid.",
      },
    });
  };

  const { dirty, form, hasValidationErrors, handleSubmit } = useForm({
    onSubmit: values => {
      const { password } = values;

      updatePassword({
        variables: { password, resetToken },
      }).then(({ data }) => {
        const { userUpdatePassword } = data;
        const { error } = userUpdatePassword;

        if (error) {
          showErrorAlert();
        } else if (resetToken) {
          goToPage("/login?password-reset=true");
        } else {
          showSuccessAlert();
          form.reset();
        }
      });
    },
    validate: validateForm,
  });

  const password = useField("password", form);
  const passwordConfirmation = useField("passwordConfirmation", form);

  const shouldDisableSubmit = !dirty || hasValidationErrors;

  const passwordOnChange = (field, val) => {
    const newVal = val.replace(/\s/g, "");
    field.input.onChange({
      target: {
        value: newVal,
      },
    });
  };

  return (
    <div className={css(ss.container)}>
      <div className={css(ss.inputField)}>
        <FormLabel>Password</FormLabel>
        <Input
          {...password.input}
          error={
            password.meta.dirty && password.meta.touched && password.meta.error
          }
          onChange={val => passwordOnChange(password, val)}
          type="password"
        />
      </div>
      {shouldShowPasswordReqs(password) && (
        <PasswordRequirements passwordField={password} />
      )}
      <div className={css(ss.inputField)} style={{ marginTop: 16 }}>
        <FormLabel>Password Confirmation</FormLabel>
        <Input
          {...passwordConfirmation.input}
          error={
            passwordConfirmation.meta.dirty &&
            password.meta.touched &&
            passwordConfirmation.meta.error
          }
          onChange={val => passwordOnChange(passwordConfirmation, val)}
          type="password"
        />
      </div>
      <div className={css([ss.saveCtaStyle, aphStyle])}>
        <Button
          disabled={shouldDisableSubmit}
          loading={mutationLoading}
          onClick={handleSubmit}
          style={{ margin: "16px 0" }}
        >
          {resetToken ? "Update Password" : "Save"}
        </Button>
      </div>
    </div>
  );
};

UpdatePasswordForm.propTypes = {
  aphStyle: PropTypes.object,
  resetToken: PropTypes.string,
};

export default UpdatePasswordForm;
