import React from "react";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite";
import { Body, styles } from "@sweeten/oreo";

const PROGRESS_MESSAGES = [
  "We suggest discussing your project with 3 general contractors.",
  `Select two more contractors to chat with so you
  have more options available while comparing estimates.`,
  `Almost there! Select one more contractor and you
  would be in a good position to make a hiring decision.`,
  `Feel free to select more contractors if you’d like
  more points of view for your project.`,
];

const ss = StyleSheet.create({
  container: {
    width: 312,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        backgroundColor: styles.colors.orange7,
        width: "100%",
        padding: 16,
        boxSizing: "border-box",
      },
    }),
  },
  progressContainer: {
    position: "relative",
    display: "flex",
    marginBottom: 12,
  },
  progressBlock: {
    height: 12,
  },
  progressLeftEnd: {
    borderRadius: "6px 0 0 6px",
    marginRight: 3,
    width: 102,
  },
  progressMiddle: {
    marginRight: 3,
    width: 98,
  },
  progressRightEnd: {
    position: "relative",
    borderRadius: "0 6px 6px 0",
    width: 102,
  },
  filledIn: {
    backgroundColor: "#C8E4BF",
  },
  empty: {
    backgroundColor: "#E4E4E4",
  },
  messageText: {
    color: styles.colors.grey40,
    fontSize: 14,
  },
  starCircle: {
    position: "absolute",
    right: 0,
    top: -6,
    height: 24,
    width: 24,
    borderRadius: 12,
    backgroundColor: styles.colors.brandPrimary,
    textAlign: "center",
    color: "white",
    fontSize: 16,
    fontWeight: 900,
  },
  progressDotOuter: {
    marginLeft: -3,
    marginTop: -2,
    borderRadius: "50%",
    width: 16,
    height: 16,
    backgroundColor: styles.colors.white,
    ...styles.center(),
    boxShadow: `0 0 8px 0 ${styles.colors.orange5}`,
  },
  progressDotInner: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: styles.colors.orange2,
  },
  orange: {
    backgroundColor: styles.colors.orange7,
    padding: 24,
  },
});

const NumIntrosProgress = ({ introCount, fromSuccessModal }) => (
  <div className={css(ss.container, fromSuccessModal && ss.orange)}>
    <Body tag="div" style={{ marginBottom: 12 }} variant="bold">
      Contractors you&apos;ve selected
    </Body>
    <div className={css(ss.progressContainer)}>
      <div
        className={css([
          ss.progressBlock,
          ss.progressLeftEnd,
          introCount > 0 ? ss.filledIn : ss.empty,
        ])}
      />
      {introCount === 1 && (
        <div className={css(ss.progressDotOuter)}>
          <div className={css(ss.progressDotInner)} />
        </div>
      )}
      <div
        className={css([
          ss.progressBlock,
          ss.progressMiddle,
          introCount > 1 ? ss.filledIn : ss.empty,
        ])}
      />
      {introCount === 2 && (
        <div className={css(ss.progressDotOuter)}>
          <div className={css(ss.progressDotInner)} />
        </div>
      )}
      <div
        className={css([
          ss.progressBlock,
          ss.progressRightEnd,
          introCount > 2 ? ss.filledIn : ss.empty,
        ])}
      >
        <div className={css(ss.starCircle)}>&#9734;</div>
      </div>
    </div>
    <Body aphStyle={ss.messageText} tag="div">
      {introCount < 3 ? PROGRESS_MESSAGES[introCount] : PROGRESS_MESSAGES[3]}
    </Body>
  </div>
);

NumIntrosProgress.propTypes = {
  introCount: PropTypes.number,
  fromSuccessModal: PropTypes.bool,
};

export default NumIntrosProgress;
