import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { startsWith } from "lodash";
import { StyleSheet, css } from "aphrodite";
import { Button, TextLink, TopNav, styles } from "@sweeten/oreo";
import {
  goToPage,
  hubspotNotifyProjectUpdate,
  isRequestFromRenApp,
  postMessageToRNWebView,
  trackMixpanelWithCallback,
} from "../../utils";
import BackgroundImage from "../../../assets/images/new-project-posting/background.jpg";

import AlbusWizard from "../../components/albus_wizard";
import FinishLaterModal from "../../components/finish_later_modal";
import ProgressBar from "../../components/progress_bar";

import RoomScope from "./steps/room_scope";
import Rooms from "./steps/rooms";
import PropertyType from "./steps/property_type";
import OwnershipType from "./steps/ownership_type";
import OwnershipClosingDate from "./steps/ownership_closing_date";
import StartDate from "./steps/start_date";
import OwnerRelation from "./steps/owner_relation";
import Budget from "./steps/budget";
import AdditionalService from "./steps/additional_services";
import UmbrellaInsurance from "./steps/umbrella_insurance";
import Address from "./steps/address";
import ZipCode from "./steps/zip_code";
import ContactDetails from "./steps/contact_details";
import OutOfService from "./steps/out_of_service";
import NoOwnership from "./steps/no_ownership";
import AdditionalInfo from "./steps/additional_info";
import ArchitectDesigner from "./steps/architect_designer";
import SquareFootage from "./steps/square_footage";
import Intention from "./steps/intention";
import FinishLevel from "./steps/finish_level";
import BudgetFlexibility from "./steps/budget_flexibility";
import SofiInterest from "./steps/sofi_interest";

import {
  trackClickNextPrimeForm,
  trackClickBackPrimeForm,
} from "./tracking_events";

import {
  USER_UPDATE,
  PROJECT_UPDATE,
  PROJECT_POST,
  GET_PROJECT_AND_EXPERIMENT_USER,
} from "./graphql_constants";

/* ======= Styles ======= */

const sharedStyles = {
  position: "fixed",
  top: TopNav.style.desktop.height,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: styles.zIndexes.topNav - 1,
};

const ss = StyleSheet.create({
  backdrop: {
    ...sharedStyles,
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        backgroundImage: "none",
        top: 0,
      },
    }),
  },
  container: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    padding: "0 24px",
    ...sharedStyles,
    ...styles.center(),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        position: "relative",
        backgroundColor: styles.colors.white,
        top: TopNav.style.mobile.height,
        padding: 0,
        alignItems: "stretch",
        height: "calc(100% - 48px)",
      },
    }),
  },
  mainContent: {
    margin: "24px 0px 16px",
    display: "flex",
    flexDirection: "column",
    height: "80%",
    maxHeight: 560,
    padding: 24,
    width: 584,
    borderRadius: 16,
    backgroundColor: styles.colors.white,
    overflow: "hidden",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        margin: 0,
        height: "auto",
        maxHeight: "none",
      },
    }),
  },
  body: {
    height: "100%",
    padding: 24,
    overflow: "scroll",
    // Chrome, Opera, Safari
    "::-webkit-scrollbar": {
      display: "none",
    },
    // IE, Edge
    "-ms-overflow-style": "none",
    // Firefox
    scrollbarWidth: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: { padding: "24px 0px" },
    }),
  },
  footer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  progressBar: {
    position: "fixed",
    top: TopNav.style.desktop.height,
    left: 0,
    right: 0,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        top: TopNav.style.mobile.height,
      },
    }),
  },
});

export const inFiveNYBoroughs = county => {
  return (
    county === "New York County" ||
    county === "Kings County" ||
    county === "Bronx County" ||
    county === "Richmond County" ||
    county === "Queens County"
  );
};

/* ======= isComplete Functions ======= */

const additionalInfoComplete = values => {
  const { additionalInfo } = values;
  return additionalInfo !== null;
};

const locationComplete = values => {
  const { location } = values;
  const { zip, address } = location;
  return zip && address;
};

const roomsComplete = values => {
  const { rooms } = values;
  return rooms.length;
};

/* ======= Constants ======= */

const STEPS = {
  zipCode: {
    component: ZipCode,
    hashKey: "zipcode",
    fields: ["location.zip"],
  },
  contactDetailsOOS: {
    component: ContactDetails,
    hashKey: "contact-details",
    fields: ["user.email", "user.firstName", "user.lastName"],
    conditionsHandler: values =>
      (!values.isInServiceArea || values.useType === "commercial") &&
      !values.user,
  },
  contactDetailsNoOwnership: {
    component: ContactDetails,
    hashKey: "contact-details",
    fields: ["user.email", "user.firstName", "user.lastName"],
    conditionsHandler: ({ ownershipType, user }) =>
      !user && ownershipType === "Made an offer",
  },
  outOfService: {
    preventNavigateBack: true,
    component: OutOfService,
    hashKey: "out-of-service",
    conditions: [["isInServiceArea", false]],
    fields: [],
    isComplete: ({ isInServiceArea }) => isInServiceArea,
  },
  noOwnership: {
    preventNavigateBack: true,
    component: NoOwnership,
    hashKey: "message",
    conditions: [["ownershipType", "Made an offer"]],
    fields: [],
    isComplete: ({ ownershipType }) => ownershipType !== "Made an offer",
  },
  roomScope: {
    component: RoomScope,
    hashKey: "room-scope",
    fields: ["roomScope"],
  },
  rooms: {
    component: Rooms,
    hashKey: "rooms",
    fields: ["rooms"],
    isComplete: roomsObj => roomsComplete(roomsObj),
  },
  propertyType: {
    component: PropertyType,
    hashKey: "property-type",
    fields: ["propertyType"],
  },
  budget: {
    component: Budget,
    hashKey: "budget",
    fields: ["budget.budgetMin", "budget.budgetMax"],
  },
  location: {
    component: Address,
    stepName: "Address",
    hashKey: "address",
    fields: ["location.address", "location.address2", "location.zip"],
    isComplete: locationObj => locationComplete(locationObj),
    hasSkipOption: ({ shouldAutoOptInToMatches }) => !shouldAutoOptInToMatches,
  },
  umbrellaInsurance: {
    component: UmbrellaInsurance,
    hashKey: "umbrella-insurance",
    conditionsHandler: ({ propertyType, location }) =>
      propertyType === "apartment" && inFiveNYBoroughs(location.county),
    fields: ["umbrellaInsurance"],
  },
  ownershipType: {
    component: OwnershipType,
    hashKey: "ownership-type",
    fields: ["ownershipType"],
  },
  ownerRelation: {
    component: OwnerRelation,
    hashKey: "owner-relation",
    conditions: [["ownershipType", "Representing owner"]],
    fields: ["ownerRelation"],
  },
  desiredStartDate: {
    component: StartDate,
    hashKey: "start-date",
    fields: ["desiredStartDate"],
  },
  additionalService: {
    component: AdditionalService,
    hashKey: "additional-services",
    fields: ["additionalService", "additionalInfo"],
    isComplete: values => {
      const { additionalService, additionalInfo } = values;

      if (additionalService === "not_sure") {
        return !!(additionalService && additionalInfo);
      }
      return !!additionalService;
    },
  },
  additionalInfo: {
    component: AdditionalInfo,
    stepName: "Additional Info",
    hashKey: "additional-info",
    fields: ["additionalInfo"],
    isComplete: additionalInfoObj => additionalInfoComplete(additionalInfoObj),
    hasSkipOption: () => true,
  },
  architectDesigner: {
    component: ArchitectDesigner,
    hashKey: "architect-designer",
    fields: ["workingWithArchitectDesigner"],
    conditions: [["additionalService", "just_a_general_contractor"]],
  },
  squareFootage: {
    component: SquareFootage,
    hashKey: "square-footage",
    fields: ["squareFootage"],
    conditions: [["roomScope", "Entire Home"]],
  },
  intention: {
    component: Intention,
    hashKey: "intention",
    fields: ["intention"],
  },
  finishLevel: {
    component: FinishLevel,
    hashKey: "finish-level",
    fields: ["finishLevel"],
  },
  ownershipClosingDate: {
    component: OwnershipClosingDate,
    hashKey: "close-date",
    fields: ["ownershipClosingDate"],
    conditions: [["ownershipType", "Made an offer"]],
  },
  budgetFlexibility: {
    component: BudgetFlexibility,
    hashKey: "budget-flexibility",
    fields: ["budgetFlexibility"],
  },
  sofiInterest: {
    component: SofiInterest,
    hashKey: "sofi-interest",
    fields: ["sofiInterest"],
  },
};

const DEFAULT_STEP_LIST = [
  "zipCode",
  "contactDetailsOOS",
  "outOfService",
  "propertyType",
  "ownershipType",
  "ownershipClosingDate",
  "contactDetailsNoOwnership",
  "noOwnership",
  "ownerRelation",
  "desiredStartDate",
  "roomScope",
  "rooms",
  "squareFootage",
  "additionalService",
  "architectDesigner",
  "finishLevel",
  "budget",
  "budgetFlexibility",
  "umbrellaInsurance",
  "location",
  "additionalInfo",
  "intention",
];

/* ======= Functions ======= */

const onClickBackFirstStep = user => {
  trackClickBackPrimeForm();
  if (user) {
    goToPage("/");
  } else {
    goToPage("https://sweeten.com");
  }
};

const onComplete = async ({ project, projectPost, markFormCompletedAt }) => {
  const { user, formCompletedAt, postedAt, isAngiLead } = project;
  const { phone } = user || {};

  if (!formCompletedAt) {
    await markFormCompletedAt();
  }
  trackClickNextPrimeForm();

  // Sends event to HS proxy for the final step in the form
  hubspotNotifyProjectUpdate({
    projectId: project.id,
    eventName: "journey-additional-info",
  });

  if (!user || !phone || isAngiLead) {
    goToPage("/contact-info");
  } else if (postedAt) {
    if (isRequestFromRenApp) {
      postMessageToRNWebView("projectUpdated");
    } else {
      goToPage("/overview");
    }
  } else {
    await projectPost();
    goToPage(`/projects/${project.id}/matching`);
  }
};

const onSetInitialStep = (step, setMutation) => {
  if (startsWith(step.id, "contactDetails")) {
    setMutation(USER_UPDATE);
  }
};

const onStepChange = (previousStep, nextStep, project, setMutation) => {
  if (startsWith(nextStep.id, "contactDetails")) {
    setMutation(USER_UPDATE);
  } else if (startsWith(previousStep.id, "contactDetails")) {
    setMutation(PROJECT_UPDATE);
  }
  // checks whether to fire Next or Back button click mixpanel event
  if (nextStep.index < previousStep.index) {
    trackClickBackPrimeForm();
  } else {
    trackClickNextPrimeForm();
    hubspotNotifyProjectUpdate({
      projectId: project.id,
      eventName: `journey-${previousStep.hashKey}`,
    });
  }
};

/* ======= Components ======= */

const Footer = ({
  currentStep,
  handleBack,
  handleSkip,
  handleSubmit,
  isLastStep,
  loading,
  shouldHideBack,
  shouldShowSkip,
}) => {
  return (
    <div className={css(ss.footer)}>
      {currentStep.id && !shouldHideBack && (
        <TextLink
          onClick={handleBack}
          style={{ marginRight: "auto", marginLeft: 24 }}
          variant="black"
        >
          Back
        </TextLink>
      )}
      {shouldShowSkip && (
        <Button
          label="Skip"
          onClick={() =>
            trackMixpanelWithCallback(
              `Clicked Skip on ${currentStep.stepName}`,
              {},
              handleSkip
            )
          }
          style={{ marginRight: 16 }}
          variant="outlineDark"
        />
      )}
      <Button
        data-test="albus_wizard_next_button"
        onClick={handleSubmit}
        label={isLastStep ? "Finish" : "Next"}
        loading={loading}
      />
    </div>
  );
};

Footer.propTypes = {
  currentStep: PropTypes.object,
  handleBack: PropTypes.func,
  handleSkip: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLastStep: PropTypes.bool,
  loading: PropTypes.bool,
  shouldHideBack: PropTypes.bool,
  shouldShowSkip: PropTypes.bool,
};

const WizardWrapper = ({ data }) => {
  const { project, additionalServicesCopyExperimentUser } = data;
  const { id, user, isInServiceArea, location, ownershipType } = project;
  const { zip } = location || {};

  const [showFinishLaterModal, toggleFinishLaterModal] = useState(false);
  const [progressData, setProgressData] = useState({
    stepsCompleted: 0,
    totalSteps: 0,
  });
  const [mutation, setMutation] = useState(PROJECT_UPDATE);
  const [projectPost] = useMutation(PROJECT_POST, {
    variables: {
      id,
    },
  });
  const [markFormCompletedAt] = useMutation(PROJECT_UPDATE, {
    variables: {
      id,
      attributes: {
        formCompletedAt: new Date(),
      },
    },
  });

  let onFinishLater;
  if (user) {
    onFinishLater = () => goToPage("/overview");
  } else {
    onFinishLater = () => toggleFinishLaterModal(true);
  }

  useEffect(() => {
    if (zip && !isInServiceArea) {
      window.location.hash = "zipcode";
    } else if (ownershipType === "Made an offer") {
      window.location.hash = "ownership-type";
    }
  }, []);

  const stepList = DEFAULT_STEP_LIST;

  return (
    <>
      <div className={css(ss.backdrop)}>
        <div className={css(ss.container)}>
          <div className={css(ss.mainContent)}>
            <div className={css(ss.progressBar)}>
              <ProgressBar onFinishLater={onFinishLater} {...progressData} />
            </div>
            <AlbusWizard
              bodyAphStyle={ss.body}
              experimentUsers={{
                additionalServicesCopyExperimentUser,
              }}
              FooterComponent={Footer}
              onSetInitialStep={step => onSetInitialStep(step, setMutation)}
              mutation={mutation}
              onClickBackFirstStep={() => onClickBackFirstStep(user)}
              onComplete={() =>
                onComplete({ project, projectPost, markFormCompletedAt })
              }
              onStepChange={(previousStep, nextStep) =>
                onStepChange(previousStep, nextStep, project, setMutation)
              }
              setProgressData={setProgressData}
              stepConfig={STEPS}
              stepList={stepList}
              subject={project}
              shouldAllowBackFromFirstStep={!isRequestFromRenApp}
            />
          </div>
          {showFinishLaterModal && (
            <FinishLaterModal
              closeModal={() => toggleFinishLaterModal(false)}
              projectId={id}
            />
          )}
        </div>
      </div>
    </>
  );
};

WizardWrapper.propTypes = {
  data: PropTypes.object,
  stepList: PropTypes.array,
};

const WizardWrapperQuery = props => {
  const { projectId } = props;
  const { data, loading } = useQuery(GET_PROJECT_AND_EXPERIMENT_USER, {
    variables: { id: projectId },
  });

  return loading ? null : <WizardWrapper data={data} {...props} />;
};

WizardWrapperQuery.propTypes = {
  projectId: PropTypes.number,
  stepList: PropTypes.array,
};

WizardWrapperQuery.defaultProps = {
  stepList: DEFAULT_STEP_LIST,
};

export default WizardWrapperQuery;
