import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Header, Textarea } from "@sweeten/oreo";
import { useField, useForm } from "react-final-form-hooks";
import StepInfoValue from "../step_info_value";
import AttachPhotos from "../../../components/attach_photos";
import { trackViewStepPrimeForm } from "../tracking_events";

const AdditionalInfo = ({
  isActive,
  subject: project,
  setHandleSubmit,
  update,
}) => {
  const { additionalInfo } = project || {};
  const { form, handleSubmit } = useForm({
    initialValues: { additionalInfo },
    onSubmit: values => {
      if (values.additionalInfo) {
        update({ additionalInfo: values.additionalInfo });
      } else {
        update({ additionalInfo: "" });
      }
    },
  });
  const textAreaField = useField("additionalInfo", form);

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Additional Info");
  }, []);

  return (
    <div>
      <Header tag="h5">
        Can you share any details that will help us understand your project?
      </Header>
      <StepInfoValue
        stepValue="Tell us more about your project, including any
        important circumstances. This helps us find the best matches for you."
      />
      <Textarea
        {...textAreaField.input}
        data-test="additional_info"
        error={textAreaField.meta.touched && textAreaField.meta.error}
        placeholder="For example: We've purchased a home and need to gut renovate
        the kitchen, update 2 bathrooms, and re-paint everything before moving in.
        Our target move-in date is in 4 months."
        widthVariant="large"
        heightVariant="medium"
      />
      <AttachPhotos projectId={project.id} />
    </div>
  );
};

AdditionalInfo.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default AdditionalInfo;
