/* eslint-disable max-len */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { StarRating, Button, Textarea, Form, Header } from "@sweeten/domo";
import withFlash from "../with_flash";

const RatingQuestion = props => {
  const { companyName, fieldName, isRequired, form, question } = props;

  return (
    <div className="grid-container">
      <div className="cols-8-of-12">
        <p className="bold-text mt-none mb-none">
          {question.replace(/\[GC Company]/gi, companyName)}
        </p>

        <div className="flex-row-nowrap" style={{ alignItems: "flexStart" }}>
          <div className="mb-none mt-4">
            <StarRating
              {...form.generateInputProps(fieldName)}
              hasNotAvailable
              required={isRequired}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

RatingQuestion.propTypes = {
  companyName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  question: PropTypes.string.isRequired,
};

RatingQuestion.defaultProps = {
  isRequired: true,
};

const CommentQuestion = props => {
  const {
    companyName,
    description,
    fieldName,
    form,
    maxCharacterCount,
    placeholder,
    question,
  } = props;

  return (
    <div>
      <div className="grid-container">
        <div className="cols-8-of-12">
          <p className="bold-text">
            {question.replace(/\[GC Company]/gi, companyName)}
          </p>
          <p className="caption-text">
            <em>{description}</em>
          </p>
        </div>
      </div>
      <div className="grid-container">
        <div className="cols-8-of-12">
          <Textarea
            {...form.generateInputProps(fieldName)}
            maxCharacterCount={maxCharacterCount}
            placeholder={placeholder}
            widthVariant="large"
          />
        </div>
      </div>
    </div>
  );
};

CommentQuestion.propTypes = {
  companyName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  maxCharacterCount: PropTypes.number,
  placeholder: PropTypes.string,
  question: PropTypes.string.isRequired,
};

CommentQuestion.defaultProps = {
  maxCharacterCount: 1000,
  placeholder: "Write here...",
};

class ReviewForm extends Component {
  constructor() {
    super();
    this.renderForm = this.renderForm.bind(this);
  }

  renderForm(form) {
    const { sig, companyName, reviewType } = this.props;
    const questions = Object.entries(this.constructor.ListOfQuestionProps).map(
      section => {
        const [header, sectionQuestions] = section;

        return (
          <div key={header}>
            <div className="grid-container">
              <div className="cols-12-of-12">
                <Header tag="h5">{header}</Header>
              </div>
            </div>
            {sectionQuestions.map(question => {
              if (reviewType === "reference" && question.hideFromReference) {
                return null;
              }

              const QuestionComponent = question.component;

              return (
                <QuestionComponent
                  {...question}
                  companyName={companyName}
                  form={form}
                  key={question.fieldName}
                />
              );
            })}
            <input type="hidden" name="sig" value={sig} />
          </div>
        );
      }
    );

    return (
      <div>
        {questions}

        <div className="grid-container">
          <div className="cols-8-of-12">
            <Button
              label="Submit Review"
              onClick={form.handleSubmit}
              style={form.isDirty ? "primary" : "outline-dark"}
              type="submit"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      authToken,
      companyName,
      errors,
      formAction,
      formMethod,
      initialValues,
      model,
      projectTitle,
    } = this.props;

    return (
      <div className="grid-wrapper">
        <div className="grid-container">
          <div className="cols-12-of-12">
            <Header tag="h3">Post your Review</Header>
            <p>
              Your privacy is important to us; we will not share your personally
              identifiable information.
            </p>
            <p className="mb-16">
              <strong>
                Evaluate your experience working with {companyName} on your{" "}
                {projectTitle} project.
              </strong>
              <br />
              For each category, select between 1 and 5 stars where 1 = Poor and
              5 = Excellent. Select &quot;N/A&quot; if this did not apply to
              your project.
            </p>
          </div>
        </div>

        <Form
          authToken={authToken}
          errors={errors}
          formAction={formAction}
          formMethod={formMethod}
          id="reviews-form"
          initialValues={initialValues}
          isAjax={false}
          model={model}
          renderForm={this.renderForm}
        />
      </div>
    );
  }
}

ReviewForm.propTypes = {
  authToken: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  formAction: PropTypes.string.isRequired,
  formMethod: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  model: PropTypes.string.isRequired,
  projectTitle: PropTypes.string.isRequired,
  sig: PropTypes.string.isRequired,
  reviewType: PropTypes.string,
};

ReviewForm.defaultProps = {
  errors: {},
  initialValues: {},
};

ReviewForm.ListOfQuestionProps = {
  "Overall Renovation Experience": [
    {
      component: RatingQuestion,
      fieldName: "expert_nps_star_rating",
      isRequired: true,
      question: "How likely are you to recommend [GC Company]?",
    },
    {
      component: CommentQuestion,
      fieldName: "expert_public_feedback",
      question: "Leave your review of [GC Company]",
      description:
        "Feel free to call out anything they did especially well, anything that could be improved upon, or anything you think other renovators should know (optional)",
    },
    {
      component: RatingQuestion,
      fieldName: "sweeten_nps_star_rating",
      isRequired: true,
      question: "How likely are you to recommend working with Sweeten?",
      hideFromReference: true,
    },
    {
      component: CommentQuestion,
      fieldName: "sweeten_public_feedback",
      question:
        "Review your experience with Sweeten; we’re always trying to be better!",
      description:
        "Feel free to call-out anything we did especially well, anything that could be improved upon, or anything you think other renovators should know (optional)",
      hideFromReference: true,
    },
    {
      component: CommentQuestion,
      fieldName: "sweeten_additional_feedback",
      question: "Do you have any additional feedback or comment for Sweeten?",
      description: "This won’t be shared on your review (optional)",
      hideFromReference: true,
    },
  ],
};

export default withFlash(ReviewForm);
