export const dropdownNavLinks = [
  {
    label: "Home Renovations",
    href: "https://sweeten.com/how-we-work/",
  },
  {
    label: "Commercial Renovations",
    href: "https://sweeten.com/commercial-renovations/",
  },
  {
    label: "For Architects/Designers",
    href: "https://sweeten.com/home-architects-interior-designers/",
  },
  {
    label: "Contractor Vetting",
    href: "https://sweeten.com/vetted-general-contractors/",
  },
];

export const mobileNavLinks = [
  {
    label: "Home",
    href: "https://sweeten.com/",
  },
  {
    label: "Start Your Renovation",
    href: "/start",
  },
  ...dropdownNavLinks,
  {
    label: "For Contractors",
    href: "https://sweeten.com/general-contractor-network/",
  },
  {
    label: "Blog",
    href: "https://sweeten.com/blog/",
  },
  {
    label: "Log In",
    href: "/login/renovator",
  },
  {
    label: "Sign Up",
    href: "/signup/renovator",
  },
];
