import React from "react";
import { css, StyleSheet } from "aphrodite";
import PropTypes from "prop-types";
import { Header } from "@sweeten/oreo";

const ss = StyleSheet.create({
  toggleItem: {
    boxSizing: "border-box",
    background: "#fafafb",
    marginBottom: "20px",
    padding: "30px 30px 15px",
  },
  toggleTitle: {
    color: "#78808e",
    borderBottom: "0 solid #d4d4d4",
    fontWeight: 700,
    padding: "0 0 15px",
    cursor: "pointer",
    lineHeight: "30px",
    fontSize: "18px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    textAlign: "left",
    ":after": {
      fontSize: "40px",
      fontWeight: 300,
    },
  },
  titleOpen: {
    ":after": {
      content: "'-'",
    },
  },
  titleClosed: {
    ":after": {
      content: "'+'",
    },
  },
});

const Faq = ({ question }) => {
  const [open, setOpen] = React.useState(true);
  const toggle = () => setOpen(!open);

  return (
    <div className={css(ss.toggleItem)}>
      <div
        onClick={toggle}
        className={css([ss.toggleTitle, open ? ss.titleOpen : ss.titleClosed])}
      >
        {question[0]}
      </div>
      {open && question[1]}
    </div>
  );
};

Faq.propTypes = {
  question: PropTypes.array.isRequired,
};

const Faqs = ({ project }) => {
  const neighborhood =
    project?.location?.neighborhood || project?.location?.city;
  if (!neighborhood) {
    return null;
  }

  const questions = [
    [
      `What factors influence the cost of a home remodel in ${neighborhood}?`,
      <>
        Kitchens and bathrooms are the meat and potatoes of home renovations.
        They are the most common spaces that people renovate whether they update
        them one room at a time or as part of a whole home remodel. Therefore to
        understand renovation pricing, a great place to start is by
        familiarizing yourself with the costs of updating these key spaces.{" "}
        <a href="https://sweeten.com/blog/home-renovation-cost-guides/bathroom-remodeling-us/">
          Bathroom renovations costs
        </a>{" "}
        start between $16,000 and $31,000 and{" "}
        <a href="https://sweeten.com/blog/home-renovation-cost-guides/kitchen-remodel-guide/">
          kitchen renovations costs
        </a>{" "}
        start between $21,000 and $60,000, depending on the room size, project
        location, project complexity, labor required, and quality of finish
        materials.
      </>,
    ],
    [
      `How can I save money on my home renovation in ${neighborhood}?`,
      <>
        Write up a home renovation wish list and categorize the items into
        &quot;must haves&quot; and &quot;nice-to-haves&quot;. Write a sentence
        or two about your how you envision living and thriving in your remodeled
        space. Set a budget range with room for contigency. Share this
        information with your contractor and collaborate to determine what your
        budget allows and how to get the most out of your home transformation!
      </>,
    ],
    [
      `How do I find a reliable contractor for my home renovation in 
      ${neighborhood}?`,
      <>
        Sweeten can help you find a reliable contractor for your home renovation
        in your neighborhood. We work with a network of pre-vetted contractors
        in various locations, so you can be confident you&apos;re hiring
        qualified professionals. We&apos;ll help you with finding the right
        contractor for the job based on your project requirements, budget, and
        time frame.
      </>,
    ],
    [
      `What factors affect the cost of a home renovation in ${neighborhood}?`,
      <>
        Complexity of the project, labor costs, quality of materials, permits
        and fees, and unexpected issues like structural damage, plumbing
        problems or outdated infrastructure that needs to be brought up to code.
      </>,
    ],
    [
      `How can I find inspiration for my ${neighborhood} home renovation project?`,
      <>
        Sweeten has been helping homeowners, architects, and designers build
        beautiful spaces since 2011. Check out our{" "}
        <a href="https://www.sweeten.com/blog/">blog</a> for inspiration and
        education about renovating your home with talented architects,
        designers, and general contractors. Browse through our extensive library
        to find ideas that match your style and budget.
      </>,
    ],
  ];
  return (
    <>
      <Header
        tag="h2"
        style={{ fontSize: 16, marginBottom: 24, lineHeight: "28px" }}
      >
        FAQs
      </Header>
      {questions.map(question => (
        <Faq project={project} question={question} />
      ))}
    </>
  );
};

Faqs.propTypes = {
  project: PropTypes.object.isRequired,
};

export default Faqs;
