import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Header, RadioGroup, FormLabel } from "@sweeten/oreo";
import { useField, useForm } from "react-final-form-hooks";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";
import { required } from "../../../validators";
import { finishLevelOptions } from "../../../project_show/shared";

const FinishLevel = ({
  isActive,
  subject: project,
  setHandleSubmit,
  update,
}) => {
  const { finishLevel } = project;
  const { form, handleSubmit } = useForm({
    initialValues: { finishLevel },
    onSubmit: update,
  });
  const field = useField("finishLevel", form, required);
  const error = field.meta.touched && field.meta.error;
  const onChange = val => {
    field.input.onChange(val);
    handleSubmit();
  };

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Finish Level");
  }, []);

  useEffect(() => {
    if (error) {
      trackViewError("finish level", field, error);
    }
  }, [error]);

  return (
    <>
      <Header tag="h5">
        What level of materials and finishes are you considering?
      </Header>
      <RadioGroup
        error={error}
        {...field.input}
        onChange={onChange}
        style={{ marginTop: 32 }}
      >
        <RadioGroup.Radio
          value="budget"
          data-test="finish-level-first-choice"
        />
        <FormLabel style={{ marginBottom: 0 }}>
          {finishLevelOptions.budget}
        </FormLabel>
        <RadioGroup.Radio value="mid_grade" />
        <FormLabel style={{ marginBottom: 0 }}>
          {finishLevelOptions.mid_grade}
        </FormLabel>
        <RadioGroup.Radio value="high_end" />
        <FormLabel style={{ marginBottom: 0 }}>
          {finishLevelOptions.high_end}
        </FormLabel>
      </RadioGroup>
    </>
  );
};

FinishLevel.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default FinishLevel;
