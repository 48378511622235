import { Body } from "@sweeten/oreo";
import { Textarea } from "@sweeten/domo";
import PropTypes from "prop-types";
import React from "react";

const OtherFeedback = ({ reason, reasonOtherField }) => {
  const headerText = () => {
    if (reason === "information") {
      return "What else would you like to know?";
    }
    if (reason === "expertise") {
      return (
        "What part of the project scope is outside your expertise? " +
        "We’ll stop sending you projects with this type of work."
      );
    }
    return "Tell us anything else you’d like us to know.";
  };

  return (
    <>
      <Body>{headerText()}</Body>
      <Textarea {...reasonOtherField.input} />
    </>
  );
};

OtherFeedback.propTypes = {
  reason: PropTypes.string,
  reasonOtherField: PropTypes.object,
};

export default OtherFeedback;
