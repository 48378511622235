import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { Body, Header, Icon, styles } from "@sweeten/oreo";

const ss = StyleSheet.create({
  item: {
    display: "flex",
    marginBottom: 24,
  },
  flexColumn: {
    display: "flex",
    flexFlow: "column nowrap",
  },
  header: {
    fontWeight: 700,
    fontSize: 14,
  },
  itemBody: {
    color: styles.colors.grey40,
    fontSize: 14,
  },
  container: {
    marginBottom: 64,
    lineHeight: "24px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginTop: 40,
        marginBottom: 40,
      },
    }),
  },
});

const InfoItem = ({ icon, header, value }) => (
  <div className={css(ss.item)}>
    <Icon
      name={icon}
      backgroundColor="#FFFAF6"
      borderRadius={4}
      style={{ minWidth: 48 }}
    />
    <div className={css(ss.flexColumn)} style={{ marginLeft: 16 }}>
      <Header tag="h6" aphStyle={ss.header}>
        {header}
      </Header>
      <Body tag="div" aphStyle={ss.itemBody}>
        {value}
      </Body>
    </div>
  </div>
);

InfoItem.propTypes = {
  icon: PropTypes.string,
  header: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

const Info = ({
  doesBudgetFinish,
  doesMidGradeFinish,
  doesHighEndFinish,
  doesLuxuryFinish,
  epaCertified,
  hasCompletedRehabMortgageProject,
  services,
  serviceAreas,
  supportDuringPermitProcess,
  worksWithIkeaProducts,
  typicalProjectTypes,
}) => {
  const { residential, commercial } = typicalProjectTypes || [];

  let typicalResidentialProjectTypes;
  let typicalCommercialProjectTypes;
  if (residential.length) {
    const typicalProjectTypesText = typicalProjectTypes.residential.join(", ");
    typicalResidentialProjectTypes = typicalProjectTypesText && (
      <div>
        <Body tag="span" variant="bold">
          Residential -{" "}
        </Body>
        <Body tag="span" style={{ color: styles.colors.grey40 }}>
          {typicalProjectTypesText}
        </Body>
      </div>
    );
  }
  if (commercial.length) {
    const typicalProjectTypesText = typicalProjectTypes.commercial.join(", ");
    typicalCommercialProjectTypes = typicalProjectTypesText && (
      <div>
        <Body tag="span" variant="bold">
          Commercial -{" "}
        </Body>
        <Body tag="span" style={{ color: styles.colors.grey40 }}>
          {typicalProjectTypesText}
        </Body>
      </div>
    );
  }

  const shouldShowFinishLevel =
    doesBudgetFinish ||
    doesMidGradeFinish ||
    doesHighEndFinish ||
    doesLuxuryFinish;
  const finishLevel = [];
  if (doesBudgetFinish) {
    finishLevel.push("Budget");
  }
  if (doesMidGradeFinish) {
    finishLevel.push("Mid-grade");
  }
  if (doesHighEndFinish) {
    finishLevel.push("High-end");
  }
  if (doesLuxuryFinish) {
    finishLevel.push("Luxury");
  }

  const shouldShowAdditionalInfo =
    epaCertified ||
    hasCompletedRehabMortgageProject ||
    supportDuringPermitProcess ||
    worksWithIkeaProducts;
  const additionalInfo = [];
  if (epaCertified) {
    additionalInfo.push("EPA certified for painting");
  }
  if (hasCompletedRehabMortgageProject) {
    additionalInfo.push("Works on 203K loan projects");
  }
  if (
    supportDuringPermitProcess !== null &&
    supportDuringPermitProcess !== "zero"
  ) {
    additionalInfo.push("Helps with permits / board approval");
  }
  if (worksWithIkeaProducts) {
    additionalInfo.push("Works with IKEA cabinets");
  }

  return (
    <div className={css(ss.container)}>
      {services && (
        <InfoItem icon="wand" header="Services" value={services.join(", ")} />
      )}
      {serviceAreas && (
        <InfoItem
          icon="map"
          header="Service areas"
          value={serviceAreas.join(" | ")}
        />
      )}
      {shouldShowFinishLevel && (
        <InfoItem
          icon="bar-chart"
          header="Finish level"
          value={finishLevel.join(", ")}
        />
      )}
      {typicalProjectTypes && (
        <InfoItem
          icon="shapes"
          header="Typical projects"
          value={[
            typicalResidentialProjectTypes,
            typicalCommercialProjectTypes,
          ]}
        />
      )}
      {shouldShowAdditionalInfo && (
        <InfoItem
          icon="check-square"
          header="Additional services"
          value={additionalInfo.join(" | ")}
        />
      )}
    </div>
  );
};

Info.propTypes = {
  doesBudgetFinish: PropTypes.bool,
  doesMidGradeFinish: PropTypes.bool,
  doesHighEndFinish: PropTypes.bool,
  doesLuxuryFinish: PropTypes.bool,
  epaCertified: PropTypes.bool,
  hasCompletedRehabMortgageProject: PropTypes.bool,
  services: PropTypes.array,
  serviceAreas: PropTypes.array,
  supportDuringPermitProcess: PropTypes.bool,
  typicalProjectTypes: PropTypes.object,
  worksWithIkeaProducts: PropTypes.bool,
};

export default Info;
