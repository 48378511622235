import React from "react";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite";
import {
  Body,
  FormLabel,
  Icon,
  Loader,
  RadioGroup,
  styles,
  EllipsisMenu,
  Header,
} from "@sweeten/oreo";

// == CONSTANTS ========================================================

export const INTRO_CATEGORIES = {
  email: "email",
  phoneCall: "phone_call",
  siteVisit: "site_visit",
};

export const CATEGORY_TO_READABLE_STRING = {
  phone_call: "Phone",
  site_visit: "In-person site visit",
  email: "Email",
};

export const CATEGORY_TO_SELECTED_READABLE_STRING = {
  phone_call: "Suggest phone call",
  site_visit: "Suggest in-person site visit",
  email: "Suggest email",
};

export const determineModalHeader = (step, name = "") => {
  if (step === "selectIntroCategory") {
    return `How would you like to chat with ${name}?`;
  }
  if (step === "confirm") {
    return `Review and confirm`;
  }
  return (
    <>
      <Icon
        name="checkmark"
        color={styles.colors.greenSuccess}
        size={36}
        style={{
          borderRadius: "50%",
          border: `6px solid ${styles.colors.green4}`,
          padding: 12,
          marginBottom: 16,
        }}
      />
      <Header tag="h6">
        Great! We’ve shared your project details and invitation to chat with{" "}
        {name}
      </Header>
    </>
  );
};

export const MODAL_STEPS = {
  selectIntroCategory: "selectIntroCategory",
  confirm: "confirm",
  success: "success",
};

// == COMPONENTS ===================================
const SelectionSS = StyleSheet.create({
  grayBox: {
    backgroundColor: styles.colors.grey10,
    borderRadius: 2,
    padding: "8px 16px",
    display: "flex",
    ...styles.center("vertical"),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        alignItems: "flex-start",
        padding: "16px 8px",
      },
    }),
  },
  iconContainer: {
    position: "relative",
    lineHeight: 1,
  },
  accentDot: {
    position: "absolute",
    top: -1,
    right: -1,
    width: 8,
    height: 8,
    borderRadius: 50,
    backgroundColor: styles.colors.brandAccent,
  },
  innerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 16,
    ...styles.center("vertical"),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        alignItems: "flex-start",
      },
    }),
  },
  outerContainer: {
    margin: "16px 0",
  },
  selection: {
    display: "flex",
    flexFlow: "column",
    flexGrow: 2,
  },
  tinyText: {
    fontSize: 12,
    lineHeight: "18px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        lineHeight: 1,
      },
    }),
  },
  tinyTextBlack: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginBottom: 4,
        lineHeight: "18px",
      },
    }),
  },
  greyText: {
    color: styles.colors.grey40,
  },
  caption: {
    color: styles.colors.grey40,
    fontSize: 12,
  },
});

export const ModalStepLoader = () => (
  <div style={{ paddingLeft: 32, paddingRight: 32 }}>
    <Loader.Body tag="p" />
    <Loader.Body tag="p" />
    <Loader.Body tag="p" />
  </div>
);

export const Selection = ({ icon, selectionText, onEditClick, onDelete }) => (
  <div className={css(SelectionSS.outerContainer)}>
    <div className={css(SelectionSS.grayBox)}>
      <div className={css(SelectionSS.iconContainer)}>
        <Icon name={icon} size={24} color={styles.colors.black} />
        <div className={css(SelectionSS.accentDot)} />
      </div>
      <div className={css(SelectionSS.innerContainer)}>
        <div className={css(SelectionSS.selection)}>
          <Body
            aphStyle={[SelectionSS.tinyText, SelectionSS.tinyTextBlack]}
            tag="div"
          >
            {selectionText}
          </Body>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <EllipsisMenu>
            {onEditClick && (
              <EllipsisMenu.Item onClick={onEditClick}>Edit</EllipsisMenu.Item>
            )}
            {onDelete && (
              <EllipsisMenu.Item onClick={onDelete}>
                Cancel Meeting
              </EllipsisMenu.Item>
            )}
          </EllipsisMenu>
        </div>
      </div>
    </div>
  </div>
);

Selection.propTypes = {
  icon: PropTypes.string.isRequired,
  selectionText: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export const CategoryStep = ({ category, hasEmailOption, onCategoryClick }) => {
  return (
    <RadioGroup
      numCols={{ desktopLarge: 1 }}
      onChange={onCategoryClick}
      value={category}
    >
      {hasEmailOption && <RadioGroup.Radio value={INTRO_CATEGORIES.email} />}
      {hasEmailOption && (
        <FormLabel style={{ marginBottom: 0 }}>
          {CATEGORY_TO_READABLE_STRING.email}
        </FormLabel>
      )}
      <RadioGroup.Radio value={INTRO_CATEGORIES.phoneCall} />
      <FormLabel style={{ marginBottom: 0 }}>
        {CATEGORY_TO_READABLE_STRING.phone_call}
      </FormLabel>
      <RadioGroup.Radio value={INTRO_CATEGORIES.siteVisit} />
      <FormLabel style={{ marginBottom: 0 }}>
        {CATEGORY_TO_READABLE_STRING.site_visit}
      </FormLabel>
    </RadioGroup>
  );
};

CategoryStep.propTypes = {
  category: PropTypes.string,
  hasEmailOption: PropTypes.bool,
  onCategoryClick: PropTypes.func.isRequired,
};

export const ConfirmationStep = ({ category, onEditClick }) => {
  let text = "";
  let icon = "calendar";

  if (category === INTRO_CATEGORIES.email) {
    text = `If the contractor is interested in your project, we'll email you to confirm
    your project. You'll be able to continue communicating by email.`;
    icon = "profile";
  } else if (category === INTRO_CATEGORIES.phoneCall) {
    text = `If the contractor is interested in your project, we'll email you with their details.
    You can then message the contractor and suggest a time to call.`;
  } else {
    text = `If the contractor is interested in your project, we’ll email you with their details.
    You can then message the contractor and fix a time for the visit.`;
  }
  return (
    <>
      <Body aphStyle={SelectionSS.greyText}>{text}</Body>
      <Selection
        icon={icon}
        selectionText={
          <Body tag="div" variant="bold">
            {CATEGORY_TO_SELECTED_READABLE_STRING[category]}
          </Body>
        }
        onEditClick={onEditClick}
      />
    </>
  );
};

ConfirmationStep.propTypes = {
  category: PropTypes.string.isRequired,
  onEditClick: PropTypes.func,
};

export const SuccessScreen = ({ name }) => (
  <>
    <Body tag="p" style={{ marginTop: 24 }}>
      What&apos;s next?
    </Body>
    <ul>
      <li>
        <Body tag="div" variant="bold">
          {name} will reply. Keep an eye on your inbox.
        </Body>
      </li>
    </ul>
  </>
);

SuccessScreen.propTypes = {
  name: PropTypes.string,
};
