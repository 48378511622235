import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormLabel, Header, RadioGroup } from "@sweeten/oreo";
import { useField, useForm } from "react-final-form-hooks";
import StepInfoValue from "../step_info_value";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";
import { propertyTypeOptions } from "../../../project_show/shared";

const propertyTypeValidator = val => {
  if (!val) {
    return "Select a property type";
  }
  return "";
};

const PropertyType = ({
  isActive,
  subject: project,
  setHandleSubmit,
  update,
}) => {
  const { propertyType } = project;
  const { form, handleSubmit } = useForm({
    initialValues: { propertyType },
    onSubmit: update,
  });
  const field = useField("propertyType", form, val =>
    propertyTypeValidator(val)
  );
  const error = field.meta.touched && field.meta.error;
  const onChange = val => {
    field.input.onChange(val);
    handleSubmit();
  };

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Property Type");
  }, []);

  useEffect(() => {
    if (error) {
      trackViewError("property type", field, error);
    }
  }, [error]);

  return (
    <>
      <Header tag="h5">What type of property is this?</Header>
      <StepInfoValue
        stepValue="We'll match you with contractors who have experience with your
        property type."
      />
      <RadioGroup error={error} {...field.input} onChange={onChange}>
        <RadioGroup.Radio data-test="apartment" value="apartment" />
        <FormLabel style={{ marginBottom: 0 }}>
          {propertyTypeOptions.apartment}
        </FormLabel>
        <RadioGroup.Radio value="house" />
        <FormLabel style={{ marginBottom: 0 }}>
          {propertyTypeOptions.house}
        </FormLabel>
        <RadioGroup.Radio value="multi_family" />
        <FormLabel style={{ marginBottom: 0 }}>
          {propertyTypeOptions.multi_family}
        </FormLabel>
        <RadioGroup.Radio value="townhouse" />
        <FormLabel style={{ marginBottom: 0 }}>
          {propertyTypeOptions.townhouse}
        </FormLabel>
      </RadioGroup>
    </>
  );
};

PropertyType.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default PropertyType;
