import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormLabel, Header, Input } from "@sweeten/oreo";
import { useForm, useField } from "react-final-form-hooks";
import {
  postalCode as postalCodeValidation,
  required,
  validate,
} from "../../../validators";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";
import { hubspotNotifyProjectUpdate } from "../../../utils";

const ZipCode = ({ isActive, subject: project, setHandleSubmit, update }) => {
  const {
    location: { zip },
    useType,
  } = project;

  const { form, handleSubmit } = useForm({
    initialValues: { useType, zip },
    onSubmit: values => {
      update(
        { ...values, useType: "residential" },
        {
          customCallback: ({ subject }) => {
            if (!subject.isInServiceArea) {
              hubspotNotifyProjectUpdate({
                projectId: project.id,
                eventName: "journey-out-of-service",
              });
            }
            return {};
          },
        }
      );
    },
  });

  const zipCode = useField("zip", form, val =>
    validate([required, postalCodeValidation], val)
  );
  const zipCodeError = zipCode.meta.touched && zipCode.meta.error;

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Zip Code");
  }, []);

  useEffect(() => {
    if (zipCodeError) {
      trackViewError("zip code", zipCode, zipCodeError);
    }
  }, [zipCodeError]);

  return (
    <>
      <Header tag="h5" style={{ marginBottom: 16 }}>
        Please answer a few quick questions to help us find the best contractor
        for your project.
      </Header>
      <FormLabel>Zip code of the property</FormLabel>
      <Input
        {...zipCode.input}
        autofocus
        data-test="zip_code"
        error={zipCodeError}
        pattern="[0-9]*"
        placeholder="Enter 5-digit ZIP code"
        style={{ lineHeight: "inherit" }}
      />
    </>
  );
};

ZipCode.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default ZipCode;
