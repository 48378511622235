import React from "react";
import { Icon, styles } from "@sweeten/domo";
import { StyleSheet, css } from "aphrodite";

const ss = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    flexRow: "no-wrap",
  },
  text: { marginLeft: 8 },
});

const SweetenProjectLabel = () => (
  <div className={css(ss.container)}>
    <Icon color={styles.colors.grey50} name="sweeten-house" size={20} />
    <div className={css(ss.text)}>Sweeten Project</div>
  </div>
);

export default SweetenProjectLabel;
