import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormLabel, Header, RadioGroup } from "@sweeten/oreo";
import { useField, useForm } from "react-final-form-hooks";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";

const ownershipClosingDateValidator = val => {
  if (!val) {
    return "Select an option";
  }
  return "";
};

export const ownershipClosingDateOptions = {
  shopping_around_for_a_property:
    "I’m shopping for a property, and want to know how much to budget for renovation",
  need_cost_guidance:
    "I need a renovation cost estimate before I make an offer",
  buy_property_soon:
    "I’ve made an offer and hope to close on the property soon",
};

const OwnershipClosingDate = ({
  isActive,
  subject: project,
  setHandleSubmit,
  update,
}) => {
  const { ownershipClosingDate } = project;
  const { form, handleSubmit } = useForm({
    initialValues: { ownershipClosingDate },
    onSubmit: update,
  });
  const field = useField("ownershipClosingDate", form, val =>
    ownershipClosingDateValidator(val)
  );
  const error = field.meta.touched && field.meta.error;
  const onChange = val => {
    field.input.onChange(val);
    handleSubmit();
  };

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Ownership Closing Date");
  }, []);

  useEffect(() => {
    if (error) {
      trackViewError("ownership closing date", field, error);
    }
  }, [error]);

  return (
    <>
      <Header style={{ marginBottom: 16 }} tag="h5">
        Where are you in the buying process?
      </Header>
      <RadioGroup error={error} {...field.input} onChange={onChange}>
        <RadioGroup.Radio value="shopping_around_for_a_property" />
        <FormLabel style={{ marginBottom: 0 }}>
          {ownershipClosingDateOptions.shopping_around_for_a_property}
        </FormLabel>
        <RadioGroup.Radio value="need_cost_guidance" />
        <FormLabel style={{ marginBottom: 0 }}>
          {ownershipClosingDateOptions.need_cost_guidance}
        </FormLabel>
        <RadioGroup.Radio value="buy_property_soon" />
        <FormLabel style={{ marginBottom: 0 }}>
          {ownershipClosingDateOptions.buy_property_soon}
        </FormLabel>
      </RadioGroup>
    </>
  );
};

OwnershipClosingDate.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default OwnershipClosingDate;
