import React from "react";
import PropTypes from "prop-types";
import { Body, Header, Icon, TextLink, styles } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import { isString } from "lodash";
import { goToPage, prependUrlWithHttp } from "../utils";
import Map from "../project_show/map";

const ss = StyleSheet.create({
  outerContainer: {
    marginBottom: 64,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginBottom: 40,
      },
    }),
  },
  header: {
    fontSize: 22,
    marginBottom: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 18,
      },
    }),
  },
  contactItem: {
    ...styles.center("vertical"),
    marginBottom: 24,
  },
  contactBody: {
    marginLeft: 16,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: { fontSize: 14 },
    }),
  },
  iconsContainer: {
    ...styles.center("vertical"),
    marginTop: 24,
  },
  icon: {
    ...styles.center(),
    marginRight: 8,
    cursor: "pointer",
    width: 48,
    height: 48,
    border: `1px solid ${styles.colors.grey20}`,
    borderRadius: 4,
  },
});

const ContactInfoItem = ({ icon, value }) => (
  <div className={css(ss.contactItem)}>
    <Icon
      name={icon}
      borderRadius={4}
      backgroundColor="#FFFAF6"
      style={{ minWidth: 48 }}
    />
    {isString(value) ? (
      <Body tag="div" variant="large" aphStyle={ss.contactBody}>
        {value}
      </Body>
    ) : (
      value
    )}
  </div>
);

ContactInfoItem.propTypes = {
  icon: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

const Contact = ({
  contact,
  showExactLocation,
  gcFirstContactedAt,
  enabledForTwilioProxy,
  proxyPhoneNumberForRen,
}) => {
  const {
    email,
    facebookUrl,
    instagramUrl,
    pinterestUrl,
    location,
    number,
    webUrl,
  } = contact || {};
  const { address, lat, lng } = location || {};

  const showEmail = enabledForTwilioProxy ? gcFirstContactedAt : true;

  const phoneNumber = proxyPhoneNumberForRen || number;

  return (
    <div className={css(ss.outerContainer)}>
      <Header tag="h5" aphStyle={ss.header}>
        Contact information
      </Header>
      <div>
        <div>
          {phoneNumber && <ContactInfoItem icon="phone" value={phoneNumber} />}
          {webUrl && (
            <ContactInfoItem
              icon="website"
              value={
                <TextLink
                  href={prependUrlWithHttp(webUrl)}
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ wordBreak: "break-word" }}
                  aphStyle={ss.contactBody}
                >
                  Website
                </TextLink>
              }
            />
          )}
          {email && showEmail && (
            <ContactInfoItem
              icon="mail"
              value={
                <TextLink
                  href={`mailto:${email}`}
                  style={{ wordBreak: "break-word" }}
                  aphStyle={ss.contactBody}
                >
                  {email}
                </TextLink>
              }
            />
          )}
          {address && <ContactInfoItem icon="pin" value={address} />}
        </div>
        <div>
          <Map lat={lat} lng={lng} showExactLocation={showExactLocation} />
          <div className={css(ss.iconsContainer)}>
            {facebookUrl && (
              <div
                className={css(ss.icon)}
                onClick={() =>
                  goToPage(prependUrlWithHttp(facebookUrl), {
                    shouldOpenNewTab: true,
                  })
                }
              >
                <Icon name="facebook" color="#908E8E" size={16} />
              </div>
            )}
            {instagramUrl && (
              <div
                className={css(ss.icon)}
                onClick={() =>
                  goToPage(prependUrlWithHttp(instagramUrl), {
                    shouldOpenNewTab: true,
                  })
                }
              >
                <Icon name="instagram" color="#908E8E" size={16} />
              </div>
            )}
            {pinterestUrl && (
              <div
                className={css(ss.icon)}
                onClick={() =>
                  goToPage(prependUrlWithHttp(pinterestUrl), {
                    shouldOpenNewTab: true,
                  })
                }
              >
                <Icon name="pinterest" color="#908E8E" size={16} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {
  contact: PropTypes.object,
  showExactLocation: PropTypes.bool,
  gcFirstContactedAt: PropTypes.string,
  enabledForTwilioProxy: PropTypes.bool,
  proxyPhoneNumberForRen: PropTypes.string,
};

export default Contact;
