import React from "react";
import PropTypes from "prop-types";
import fetch from "unfetch";
import GraphqlClient from "./graphql_client";

const linkOptions = {
  uri: "/graphql",
  fetch,
};

const PlatformGraphqlClient = ({ authToken, children }) => (
  <GraphqlClient authToken={authToken} linkOptions={linkOptions}>
    {children}
  </GraphqlClient>
);

PlatformGraphqlClient.propTypes = {
  authToken: PropTypes.string,
};

export default PlatformGraphqlClient;
