/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Button, Avatar, TextLink, Modal, styles } from "@sweeten/oreo";
import { useMutation } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import { goToPage } from "../utils";
import { GC_RESPONSE } from "../components/match_banner";

const ss = StyleSheet.create({
  leftButton: {
    marginRight: 16,
  },
  messageBox: {
    padding: 24,
    width: "100%",
    borderRadius: 10,
    border: `1px solid ${styles.colors.grey20}`,
    minHeight: 100,
    marginLeft: 16,
    whiteSpace: "pre-line",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  info: {
    backgroundColor: styles.colors.blueLight,
    marginTop: 16,
    display: "flex",
    padding: 12,
  },
  decline: {
    marginTop: 16,
    color: styles.colors.grey40,
  },
});

const ReadRENMessageModal = ({ onClose, intro }) => {
  const [respondToMatch, { loading: mutationLoading }] = useMutation(
    GC_RESPONSE
  );

  const { project, renIntroMessage } = intro;
  const { user } = project;
  const { firstName } = user;

  const onAccept = () => {
    const { id } = intro;

    respondToMatch({
      variables: {
        id,
        isAccepting: true,
        company: {},
      },
    }).then(({ data }) => {
      const { gcAskResponse } = data;
      const { intro: mutationIntroData } = gcAskResponse;
      const { conversation } = mutationIntroData;
      if (conversation) {
        goToPage(`/conversations/${conversation.id}`);
      } else {
        goToPage(`/feedback/expert-ask?intro_id=${id}&response=yes`);
      }
    });
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Header>Message from {firstName}</Modal.Header>
      <Modal.Body>
        <div style={{ display: "flex" }}>
          <Avatar firstName={firstName} size={64} />
          <div className={css(ss.messageBox)}>{renIntroMessage}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <div className={css(ss.flexEnd)}>
            <TextLink onClick={onClose} style={{ marginRight: 16 }}>
              Cancel
            </TextLink>
            <Button
              label="Accept Project"
              onClick={onAccept}
              loading={mutationLoading}
              type="submit"
              variant="dark"
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ReadRENMessageModal.propTypes = {
  onClose: PropTypes.func,
  intro: PropTypes.object,
};

export default ReadRENMessageModal;
