import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Body,
  Checkbox,
  FormLabel,
  Icon,
  Input,
  TextLink,
  styles,
} from "@sweeten/oreo";
import { css, StyleSheet } from "aphrodite";
import { useField } from "react-final-form-hooks";
import { withApollo } from "react-apollo";
import { formatPhoneNumber } from "../../utils";
import {
  required,
  phoneNumber as validPhone,
  validate,
} from "../../validators";

const ss = StyleSheet.create({
  accentText: {
    color: styles.colors.brandAccent,
  },
  checkboxAndText: {
    display: "flex",
    flexFlow: "row nowrap",
    marginTop: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginTop: 16,
      },
    }),
  },
  optOutContainer: {
    border: styles.border,
    borderRadius: 4,
    marginTop: 24,
    padding: 24,
  },
  tosLink: {
    fontSize: 12,
    lineHeight: "18px",
  },
  caption: {
    fontSize: 12,
    lineHeight: "18px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 11,
        lineHeight: "16px",
      },
    }),
  },
});

const phoneRequiredForSMS = val => {
  if (val === false) {
    return "";
  }
  return !val && "Phone is required to receive SMS";
};

const phoneNumberValidation = (phoneValue, allValues, isOptional, client) => {
  const validPhoneWithClient = val => validPhone(val, client);

  if (!isOptional) {
    return validate([required, validPhoneWithClient], phoneValue);
  }
  if (allValues.smsOptIn) {
    return validate([phoneRequiredForSMS, validPhoneWithClient], phoneValue);
  }
  return validPhoneWithClient(phoneValue);
};

const AccentText = ({ children }) => (
  <Body aphStyle={ss.accentText} tag="span" variant="bold">
    {children}
  </Body>
);

AccentText.propTypes = {
  children: PropTypes.string,
};

const OptOutWarning = () => (
  <div className={css(ss.optOutContainer)}>
    <Icon
      name="chat"
      color={styles.colors.black}
      backgroundColor={styles.colors.grey20}
    />
    <Body tag="div" style={{ marginTop: 16 }}>
      You have opted out of Sweeten text messages. To opt in again at any time,
      text <AccentText>START</AccentText> to{" "}
      <AccentText>(917) 512-2332</AccentText>.
    </Body>
  </div>
);

const PhoneFieldGroup = ({
  aphStyle,
  client,
  isOptional,
  form,
  hideSmsSection,
  onTwilioDenyList,
  showTos,
  style,
  label,
}) => {
  const [showOptOutWarning, updateOptOutWarningVisibility] = useState(
    onTwilioDenyList
  );
  const phone = useField("phone", form, (newVal, allVals) =>
    phoneNumberValidation(newVal, allVals, isOptional, client)
  );
  const smsOptIn = useField("smsOptIn", form);
  const phoneOnChange = val => {
    const newVal = formatPhoneNumber(val);
    phone.input.onChange(newVal);
  };
  const smsOnChange = () => smsOptIn.input.onChange(!smsOptIn.input.value);

  useEffect(() => {
    // If the initial phone was on deny list, check if new valid number is different
    if (onTwilioDenyList && phone.meta.valid) {
      const strippedNew = phone.input.value.replace(/D/g, "");
      const strippedInitial = phone.meta.initial.replace(/D/g, "");

      updateOptOutWarningVisibility(strippedInitial === strippedNew);
    }
  });
  return (
    <div className={css(aphStyle)} style={style}>
      <FormLabel>
        {label}
        {isOptional ? " (optional)" : ""}
      </FormLabel>
      <Input
        {...phone.input}
        data-test="phone_number"
        error={phone.meta.touched && phone.meta.error}
        onChange={phoneOnChange}
        pattern="[0-9]*"
      />
      {!hideSmsSection && (
        <>
          {showOptOutWarning ? (
            <OptOutWarning />
          ) : (
            <div className={css(ss.checkboxAndText)}>
              <div>
                <Checkbox
                  {...smsOptIn.input}
                  onClick={smsOnChange}
                  style={{ marginTop: 4 }}
                />
              </div>
              <Body aphStyle={ss.caption}>
                Please check in order to receive text messages with important
                project updates and meeting reminders. We will NEVER spam you or
                share your number without your permission.
                {showTos && (
                  <>
                    {" "}
                    See our{" "}
                    <TextLink
                      aphStyle={ss.tosLink}
                      href="/terms-of-use"
                      target="_blank"
                    >
                      terms of service
                    </TextLink>
                    .
                  </>
                )}
              </Body>
            </div>
          )}
        </>
      )}
    </div>
  );
};

PhoneFieldGroup.propTypes = {
  aphStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Phone field is only required when SMS is checked, otherwise can be blank. */
  client: PropTypes.object,
  hideSmsSection: PropTypes.bool,
  isOptional: PropTypes.bool,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  onTwilioDenyList: PropTypes.bool,
  showTos: PropTypes.bool,
  style: PropTypes.object,
};

PhoneFieldGroup.defaultProps = {
  label: "Phone",
  showTos: true,
  hideSmsSection: false,
};

export default withApollo(PhoneFieldGroup);
