import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import fetch from "unfetch";
import { Col, Header, Input, Row, Icon, styles } from "@sweeten/oreo";
import { useForm, useField } from "react-final-form-hooks";
import {
  postalCode as postalCodeValidation,
  required,
  validate,
} from "../../../validators";
import StepInfoValue from "../step_info_value";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";
import { trackMixpanel } from "../../../utils";

export const geolocate = (
  addressField,
  setGeolocateLoading,
  setGeolocateError
) => {
  trackMixpanel("Clicked Location Pin");
  setGeolocateLoading(true);
  const success = position => {
    const { latitude, longitude } = position.coords;

    fetch(
      // eslint-disable-next-line max-len
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.GOOGLE_MAPS_KEY_BACKEND}`
    )
      .then(response => response.json())
      .then(data => {
        const { results } = data || [];
        if (results.length) {
          const addressComponents = results[0].address_components;
          addressField.input.onChange(
            `${addressComponents[0].long_name} ${addressComponents[1].long_name}`
          );
          trackMixpanel("Address Populated");
        } else {
          setGeolocateError("Could not determine current location");
        }
        setGeolocateLoading(false);
      })
      .catch(() => {
        setGeolocateError("Could not determine current location");
        setGeolocateLoading(false);
      });
  };

  const error = () => {
    setGeolocateError(
      "Please enable permission to access your location or type your address"
    );
    setGeolocateLoading(false);
  };

  if (!navigator.geolocation) {
    setGeolocateError("This feature is not supported by your browser");
    setGeolocateLoading(false);
  } else {
    setGeolocateError("");
    navigator.geolocation.getCurrentPosition(success, error);
  }
};

const Address = ({ isActive, subject: project, setHandleSubmit, update }) => {
  const { location, shouldAutoOptInToMatches } = project;

  const { form, handleSubmit } = useForm({
    initialValues: {
      address: location.address,
      address2: location.address2,
      zip: location.zip,
    },
    onSubmit: update,
  });
  const address = useField(
    "address",
    form,
    shouldAutoOptInToMatches && required
  );
  const addressError = address.meta.touched && address.meta.error;
  const address2 = useField("address2", form);
  const zipCode = useField("zip", form, val =>
    validate([required, postalCodeValidation], val)
  );
  const zipCodeError = zipCode.meta.touched && zipCode.meta.error;
  const [geolocateError, setGeolocateError] = useState("");
  const [geolocateLoading, setGeolocateLoading] = useState(false);

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Address");
  }, []);

  useEffect(() => {
    setGeolocateError("");
    if (addressError) {
      trackViewError("address", address, addressError);
    } else if (zipCodeError) {
      trackViewError("zip code", zipCode, zipCodeError);
    }
  }, [addressError, zipCodeError]);

  return (
    <>
      <Header tag="h5">What&apos;s the property&apos;s street address?</Header>
      <StepInfoValue
        stepValue="Your address lets us find contractors who work in your area.
        We only share it with contractors you ask to connect with."
      />
      <Row>
        <Col width={12}>
          <Input
            {...address.input}
            data-test="street_address"
            error={
              geolocateError || (address.meta.touched && address.meta.error)
            }
            placeholder="Street address"
            style={{ lineHeight: "inherit" }}
            disabled={geolocateLoading}
            afterElement={
              <div
                onClick={() =>
                  geolocate(address, setGeolocateLoading, setGeolocateError)
                }
              >
                <Icon
                  name="pin"
                  size={16}
                  color={styles.colors.brandPrimary}
                  style={{ cursor: "pointer" }}
                />
              </div>
            }
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <Col width={{ desktopStandard: 6, phoneStandard: 12 }}>
          <Input
            {...address2.input}
            placeholder="Apt. No. (optional)"
            style={{ lineHeight: "inherit" }}
          />
        </Col>
        <Col width={{ desktopStandard: 6, phoneStandard: 12 }}>
          <Input
            {...zipCode.input}
            error={zipCode.meta.touched && zipCode.meta.error}
            pattern="[0-9]*"
            placeholder="ZIP Code"
            style={{ lineHeight: "inherit" }}
          />
        </Col>
      </Row>
    </>
  );
};

Address.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default Address;
