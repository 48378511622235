import React from "react";
import PropTypes from "prop-types";
import { Col, Header, Row } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import GalleryImage from "../gallery_image";
import UpdatePasswordForm from "../../components/account_forms/update_password_form";

const ss = StyleSheet.create({
  container: {
    display: "flex",
    flexFlow: "nowrap",
  },
  ctaStyle: {
    alignItems: "flex-end",
  },
  form: {
    flex: 1,
    flexFlow: "column nowrap",
  },
});

const PasswordReset = ({ resetToken }) => {
  return (
    <div className={css(ss.container)}>
      <GalleryImage />
      <div className={css(ss.form)}>
        <Row style={{ justifyContent: "center" }}>
          <Col width={5}>
            <Header tag="h3" style={{ textAlign: "center", margin: "32px 0" }}>
              Reset Password
            </Header>
            <UpdatePasswordForm
              aphStyle={ss.ctaStyle}
              resetToken={resetToken}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

PasswordReset.propTypes = {
  resetToken: PropTypes.string,
};

export default PasswordReset;
