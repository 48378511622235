import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import { Textarea } from "@sweeten/domo";
import {
  Modal,
  Button,
  FormLabel,
  RadioGroup,
  TextLink,
  Body,
  styles,
} from "@sweeten/oreo";
import { StyleSheet } from "aphrodite/no-important";
import { trackMixpanelWithCallback, goToPage } from "../../../utils";
import { required } from "../../../validators";
import { ACTIVE_MATCH_FRAGMENT, IntroPropType } from "../shared";

const REN_REJECT_ASK = gql`
  mutation renRejectMatch($matchId: ID!, $feedbackBody: String!) {
    renRejectMatch(matchId: $matchId, feedbackBody: $feedbackBody) {
      intro {
        ...ActiveMatchFields
      }
    }
  }
  ${ACTIVE_MATCH_FRAGMENT}
`;

const modalStyles = StyleSheet.create({
  container: {
    width: 580,
  },
  cancelFeedback: {
    color: styles.colors.grey50,
    ":hover": {
      color: styles.colors.grey30,
    },
  },
  greyText: {
    color: styles.colors.grey40,
  },
});

const SCREENS = {
  responseCategory: "responseCategory",
  responseSubcategory: "responseSubcategory",
};

// If you add a new category here, make sure to add it to Companies::Stats::REJECTION_REASONS
const RESPONSE_CATEGORIES = {
  Photos: {
    subcategories: ["Not enough portfolio photos", "Photos are poor quality"],
  },
  Reviews: {
    subcategories: [
      "Reviews are too old",
      "Not enough reviews",
      "Reviews are unimpressive",
    ],
  },
  "Renovation quality / style": {
    subcategories: [
      "I don’t like the style of the work",
      "Work looks too expensive",
    ],
  },
  Location: {
    subcategories: [
      "Does not look like a local",
      "Has not done projects in my area",
    ],
  },
  "Not enough information in profile": {},
  "I know this contractor and would rather work with someone else": {},
  Other: {},
};

const commonProps = {
  field: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

const ResponseCategoryOptions = ({ field, onChange }) => {
  const radioButtons = Object.keys(RESPONSE_CATEGORIES).reduce(
    (arr, option) => {
      arr.push(<RadioGroup.Radio value={option} key={`${option}-radio`} />);
      arr.push(
        <FormLabel style={{ marginBottom: 0 }} key={`${option}-label`}>
          {option}
        </FormLabel>
      );

      return arr;
    },
    []
  );
  return (
    <RadioGroup {...field} onChange={onChange}>
      {radioButtons}
    </RadioGroup>
  );
};

ResponseCategoryOptions.propTypes = commonProps;

const ResponseSubcategoryOptions = ({ responseCategory, onChange, field }) => {
  const options = RESPONSE_CATEGORIES[responseCategory].subcategories;
  const radioButtons = options.reduce((arr, option) => {
    arr.push(<RadioGroup.Radio value={option} key={`${option}-radio`} />);
    arr.push(
      <FormLabel style={{ marginBottom: 0 }} key={`${option}-label`}>
        {option}
      </FormLabel>
    );

    return arr;
  }, []);

  return (
    <div>
      <Body
        aphStyle={modalStyles.greyText}
        tag="p"
        variant="large"
        style={{ marginBottom: 0 }}
      >
        {responseCategory}
      </Body>
      <RadioGroup {...field} onChange={onChange}>
        {radioButtons}
      </RadioGroup>
    </div>
  );
};

ResponseSubcategoryOptions.propTypes = {
  ...commonProps,
  responseCategory: PropTypes.string,
};

const defaultField = {
  value: "",
  error: false,
};

const RenRejectMatchModal = props => {
  const { onClose, onSuccessCb, triggerLocation, intro } = props;
  const { id: matchId, company } = intro;
  const [currentScreen, updateScreen] = useState(SCREENS.responseCategory);
  const [responseCategory, updateResponseCategory] = useState(defaultField);
  const [responseSubcategory, updateResponseSubcategory] = useState(
    defaultField
  );
  const isOnCategoryScreen = currentScreen === SCREENS.responseCategory;
  const isOnSubcategoryScreen = currentScreen === SCREENS.responseSubcategory;
  const [rejectMatch, { loading: mutationLoading }] = useMutation(
    REN_REJECT_ASK
  );

  const [submitDirty, setSubmitDirty] = useState(false);
  const checkFormValidity = () => {
    setSubmitDirty(true);
    if (isOnCategoryScreen) {
      if (responseCategory.value === "Other") {
        return required(responseSubcategory.value);
      }
      const responseCategoryError = required(responseCategory.value);
      updateResponseCategory({
        ...responseCategory,
        error: responseCategoryError,
      });

      return responseCategoryError;
    }

    const responseSubcategoryError = required(responseSubcategory.value);
    updateResponseSubcategory({
      ...responseSubcategory,
      error: responseSubcategoryError,
    });

    return responseSubcategoryError;
  };
  const onCategoryChange = value => {
    setSubmitDirty(false);
    updateResponseCategory({ value });
    if (value === "Other") {
      const textarea = document.getElementById("bottom_of_body");
      setTimeout(() => textarea.scrollIntoView(), 1);
    } else if (value && RESPONSE_CATEGORIES[value].subcategories) {
      updateScreen(SCREENS.responseSubcategory);
    }
  };
  const onSubcategoryChange = value => updateResponseSubcategory({ value });
  const onGoBack = () => {
    if (isOnSubcategoryScreen) {
      updateResponseCategory(defaultField);
      updateResponseSubcategory(defaultField);
      updateScreen(SCREENS.responseCategory);
    } else {
      onClose();
    }
  };
  const onSubmit = () => {
    const error = checkFormValidity();
    if (error) {
      return;
    }
    const feedbackBody = responseSubcategory.value || responseCategory.value;
    rejectMatch({
      variables: {
        matchId,
        feedbackBody,
      },
    }).then(() => {
      trackMixpanelWithCallback(
        "Rejected GC",
        {
          "Trigger Location": triggerLocation,
          "GC ID": company.id,
          "Reason for Rejecting GC": feedbackBody,
        },
        () => {
          if (onSuccessCb) {
            onSuccessCb();
          }
          if (triggerLocation === "gc_profile") {
            goToPage("/my-contractors");
          }
          onClose();
        }
      );
    });
  };

  return (
    <Modal
      aphStyle={modalStyles.container}
      onClose={onClose}
      disableOutsideClick
    >
      <Modal.Header style={{ marginBottom: 8 }}>
        Please tell us what you don&apos;t like
      </Modal.Header>
      <Modal.Body>
        <Body
          aphStyle={modalStyles.greyText}
          tag="p"
          variant="large"
          style={{ marginTop: 0, marginBottom: 24 }}
        >
          You&apos;ll help us do a better job when you request additional
          matches.
        </Body>
        {isOnCategoryScreen && (
          <ResponseCategoryOptions
            field={responseCategory}
            onChange={onCategoryChange}
          />
        )}
        {responseCategory.value === "Other" && (
          <>
            <Body aphStyle={modalStyles.greyText} variant="caption">
              We will share this information with the contractor.
            </Body>
            <Textarea
              handleChange={event =>
                updateResponseSubcategory({ value: event.target.value })
              }
              error={submitDirty && required(responseSubcategory.value)}
              heightVariant="small"
            />
          </>
        )}
        {isOnSubcategoryScreen && (
          <ResponseSubcategoryOptions
            responseCategory={responseCategory.value}
            field={responseSubcategory}
            onChange={onSubcategoryChange}
          />
        )}
        <div style={{ paddingBottom: 24 }} id="bottom_of_body" />
      </Modal.Body>
      <Modal.Footer>
        <TextLink aphStyle={modalStyles.cancelFeedback} onClick={onGoBack}>
          Go Back
        </TextLink>
        <Button
          loading={mutationLoading}
          onClick={onSubmit}
          style={{ marginLeft: 24 }}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RenRejectMatchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccessCb: PropTypes.func,
  intro: IntroPropType,
  triggerLocation: PropTypes.string.isRequired,
};

export default RenRejectMatchModal;
