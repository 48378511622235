import { Body, Header, styles } from "@sweeten/oreo";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet } from "aphrodite";

const ss = StyleSheet.create({
  header: {
    fontSize: 22,
    marginBottom: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 18,
      },
    }),
  },
  body: {
    whiteSpace: "pre-wrap",
    color: styles.colors.grey40,
  },
});

const Description = ({ description }) => {
  return (
    <>
      <Header tag="h5" aphStyle={ss.header}>
        Introduction
      </Header>
      <Body aphStyle={ss.body} tag="div" variant="large">
        {description}
      </Body>
    </>
  );
};

Description.propTypes = {
  description: PropTypes.string,
};

export default Description;
