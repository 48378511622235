import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Header, RadioGroup, FormLabel } from "@sweeten/oreo";
import { useForm, useField } from "react-final-form-hooks";
import { required } from "../../../validators";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";

export const budgetFlexibilityOptions = {
  no: "No - My budget is firm.",
  maybe: `Yes - I'm not sure how much my project should cost. I'm open to discussing both the scope
    and the budget.`,
  yes: "Yes - My project scope is firm but I’m willing to discuss the budget.",
};

const BudgetFlexibility = ({
  isActive,
  subject: project,
  setHandleSubmit,
  update,
}) => {
  const { budgetFlexibility } = project;

  const { form, handleSubmit } = useForm({
    initialValues: {
      budgetFlexibility,
    },
    onSubmit: update,
  });
  const field = useField("budgetFlexibility", form, required);
  const error = field.meta.touched && field.meta.error;
  const onChange = val => {
    field.input.onChange(val);
    handleSubmit();
  };

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Budget Flexibility");
  }, []);

  useEffect(() => {
    if (error) {
      trackViewError("budget flexibility", field, error);
    }
  }, [error]);

  return (
    <>
      <Header style={{ marginBottom: 24 }} tag="h5">
        Is your budget flexible?
      </Header>
      <RadioGroup {...field.input} error={error} onChange={onChange}>
        <RadioGroup.Radio value="no" data-test="budget_flexibility_no" />
        <FormLabel style={{ marginBottom: 0, lineHeight: "18px" }}>
          {budgetFlexibilityOptions.no}
        </FormLabel>
        <RadioGroup.Radio value="maybe" data-test="budget_flexibility_maybe" />
        <FormLabel style={{ marginBottom: 0, lineHeight: "18px" }}>
          {budgetFlexibilityOptions.maybe}
        </FormLabel>
        <RadioGroup.Radio value="yes" data-test="budget_flexibility_yes" />
        <FormLabel style={{ marginBottom: 0, lineHeight: "18px" }}>
          {budgetFlexibilityOptions.yes}
        </FormLabel>
      </RadioGroup>
    </>
  );
};

BudgetFlexibility.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default BudgetFlexibility;
