import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Header, Input } from "@sweeten/oreo";
import { useField, useForm } from "react-final-form-hooks";
import { required } from "../../../validators";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";

export const sqFtOnChange = (field, val) => {
  const newVal = val.replace(/[^\d.]/g, "");
  if (newVal !== "0") {
    field.input.onChange({
      target: {
        value: newVal,
      },
    });
  }
};

const SquareFootage = ({
  isActive,
  subject: project,
  setHandleSubmit,
  update,
}) => {
  const { squareFootage } = project;
  const { form, handleSubmit } = useForm({
    initialValues: { squareFootage },
    onSubmit: update,
  });
  const field = useField("squareFootage", form, required);
  const error = field.meta.touched && field.meta.error;

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Square Footage");
  }, []);

  useEffect(() => {
    if (error) {
      trackViewError("square footage", field, error);
    }
  }, [error]);

  return (
    <div>
      <Header tag="h5">
        What&apos;s the approximate size of your home (in square feet)?
      </Header>
      <Input
        data-test="square_footage"
        error={error}
        {...field.input}
        onChange={val => sqFtOnChange(field, val)}
        style={{ marginTop: 40 }}
        pattern="[0-9]*"
        placeholder="Square Feet"
      />
    </div>
  );
};

SquareFootage.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default SquareFootage;
