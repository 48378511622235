/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite/no-important";
import {
  Header,
  styles,
  Modal,
  Card,
  Lightbox,
  Body,
  Button,
  Icon,
  Loader,
  ArrowLink,
  Checkbox,
} from "@sweeten/oreo";
import { get, capitalize, forEach, isEmpty } from "lodash";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import CloseIcon from "../../../vendor/muze_bootstrap/assets/svg/icons/close@20.svg";
import CloseWhite from "../../../vendor/muze_bootstrap/assets/svg/icons/close-white.svg";
import WhiteArrow from "../../assets/images/white_arrow.svg";
import ADUSvg from "../../assets/images/gc-profile-photo-experience/ADU.svg";
import ApartmentComboSvg from "../../assets/images/gc-profile-photo-experience/ApartmentCombo.svg";
import AtticSvg from "../../assets/images/gc-profile-photo-experience/Attic.svg";
// import BarSvg from "../../assets/images/gc-profile-photo-experience/Bar.svg";
import BasementSvg from "../../assets/images/gc-profile-photo-experience/Basement.svg";
import BathroomSvg from "../../assets/images/gc-profile-photo-experience/Bathroom.svg";
import BedroomSvg from "../../assets/images/gc-profile-photo-experience/Bedroom.svg";
// import CafeSvg from "../../assets/images/gc-profile-photo-experience/Cafe.svg";
import ClosetSvg from "../../assets/images/gc-profile-photo-experience/Closet.svg";
import DiningSvg from "../../assets/images/gc-profile-photo-experience/Dining.svg";
// import EntireHomeSvg from "../../assets/images/gc-profile-photo-experience/EntireHome.svg";
import ExteriorSvg from "../../assets/images/gc-profile-photo-experience/Exterior.svg";
import FoyerSvg from "../../assets/images/gc-profile-photo-experience/Foyer.svg";
import GarageSvg from "../../assets/images/gc-profile-photo-experience/Garage.svg";
// import GymSvg from "../../assets/images/gc-profile-photo-experience/Gym.svg";
// import HotelSvg from "../../assets/images/gc-profile-photo-experience/Hotel.svg";
import KitchenSvg from "../../assets/images/gc-profile-photo-experience/Kitchen.svg";
import LaundrySvg from "../../assets/images/gc-profile-photo-experience/Laundry.svg";
import LivingSvg from "../../assets/images/gc-profile-photo-experience/Living.svg";
import NewConstructionSvg from "../../assets/images/gc-profile-photo-experience/NewConstruction.svg";
import NurserySvg from "../../assets/images/gc-profile-photo-experience/Nursery.svg";
// import OfficeCommSvg from "../../assets/images/gc-profile-photo-experience/Office(Commercial).svg";
import OfficeResSvg from "../../assets/images/gc-profile-photo-experience/Office(Residential).svg";
import OtherSvg from "../../assets/images/gc-profile-photo-experience/Other.svg";
import PatioSvg from "../../assets/images/gc-profile-photo-experience/Patio.svg";
// import RestaurantSvg from "../../assets/images/gc-profile-photo-experience/Restaurant.svg";
// import RetailSvg from "../../assets/images/gc-profile-photo-experience/Retail.svg";
import RoomAdditionSvg from "../../assets/images/gc-profile-photo-experience/RoomAddition.svg";
import YardSvg from "../../assets/images/gc-profile-photo-experience/Yard.svg";
import { goToPage } from "../utils";
import ItemCarousel from "./item_carousel";
import "./lightbox.css";

const GET_PHOTO_WALL_DATA = gql`
  query getPhotoWallInfo($id: ID!, $category: String!) {
    company(id: $id) {
      id
      albums(category: [$category]) {
        id
        category
        isHidden
        images {
          id
          roomTypeConstant
          sortIndex
          url {
            large
          }
          type
          isHidden
          project {
            id
            title
            awardedAt
            completedAt
            createdAt
            isSweeten
            location {
              neighborhood
            }
            album {
              id
            }
          }
        }
      }
    }
    roomTypes {
      id
      displayName
      constant
    }
  }
`;

const GET_ROOM_TYPES_INFO = gql`
  query getRoomTypesInfo($id: ID!) {
    company(id: $id) {
      id
      albums(category: ["rooms"]) {
        id
        category
        isHidden
      }
    }
    roomTypes {
      id
      displayName
      constant
    }
  }
`;

const GET_PROFILE_PROJECTS_INFO = gql`
  query getProfileProjectsInfo($id: ID!) {
    currentUser {
      id
    }
    company(id: $id) {
      id
      portfolioProjects {
        id
        sortIndexPortfolioPage
        title
        awardedAt
        completedAt
        createdAt
        isSweeten
        location {
          neighborhood
        }
        album {
          id
          isHidden
          images {
            id
            url {
              large
            }
            sortIndex
            category
            isHidden
          }
        }
        rooms {
          id
          roomTypeConstant
        }
        defaultImage {
          id
          url {
            large
          }
        }
        review {
          id
          rating
        }
      }
    }
    roomTypes {
      id
      displayName
      constant
    }
  }
`;

const ss = StyleSheet.create({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "white",
    zIndex: 1000,
    padding: "64px 160px",
    overflowY: "scroll",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        padding: 16,
      },
    }),
  },
  fixedHeader: {
    position: "fixed",
    top: 0,
    right: 0,
    width: "100%",
    backgroundColor: "white",
    padding: "24px 0px 0px",
    zIndex: 100,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        padding: "16px 0px 0px",
        height: 24,
      },
    }),
  },
  closeContainer: {
    cursor: "pointer",
    marginBottom: 24,
    float: "right",
    marginRight: 160,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginRight: 16,
      },
    }),
  },
  thumbnailImage: {
    width: "calc(33.33% - 16px)",
    height: 240,
    borderRadius: 8,
    marginBottom: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
      },
    }),
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 8,
    cursor: "pointer",
  },
  quarterContainer: {
    height: 336,
    gridColumnStart: "span 2",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        gridRowStart: "span 4",
        gridColumnStart: "span 4",
      },
    }),
  },
  fullContainer: {
    width: "100%",
    height: 700,
    margin: "16px 0px",
    gridRowStart: "span 4",
    gridColumnStart: "span 4",
  },
  halfContainer: {
    height: 700,
    gridRowStart: "span 2",
    gridColumnStart: "span 2",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        gridRowStart: "span 4",
        gridColumnStart: "span 4",
      },
    }),
  },
  col: {
    flexDirection: "column",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginTop: 32,
      },
    }),
  },
  photosContainer: {
    marginTop: 24,
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: 28,
  },
  marginProjects: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginTop: 16,
      },
    }),
  },
  exploreCard: {
    width: "calc(16.66% - 24px)",
    height: 150,
    borderRadius: 8,
    backgroundColor: "#FAFAFB",
    ...styles.center(),
    flexDirection: "column",
    cursor: "pointer",
    margin: "0px 24px 24px 0px",
    padding: "0px 12px",
    textAlign: "center",
    boxSizing: "border-box",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "calc(50% - 12px)",
        height: 72,
        flexDirection: "row",
        padding: "20px 12px",
        justifyContent: "flex-start",
        margin: 0,
        marginBottom: 12,
      },
    }),
  },
  mobileMargin: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginRight: 24,
      },
    }),
  },
  desktopMargin: {
    marginRight: 24,
  },
  exploreMargin: {
    margin: "64px 0px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        margin: "24px 0px",
      },
    }),
  },
  title: {
    fontSize: 18,
    cursor: "pointer",
    ":hover": {
      color: styles.colors.grey40,
    },
  },
  flexCentered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  absoluteCentered: {
    position: "absolute",
    color: styles.colors.black,
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        top: 4,
      },
    }),
  },
  projectDetails: {
    zIndex: 1,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  neighborhood: {
    margin: "8px 0px 20px",
    color: "#76808F",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "32px",
  },
  thumbnailPhotos: {
    marginTop: 24,
    display: "flex",
    flexWrap: "wrap",
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    boxSizing: "border-box",
    position: "relative",
    padding: 0,
    width: "calc(50% - 8px)",
    borderRadius: 8,
    cursor: "pointer",
    marginBottom: 16,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
      },
    }),
  },
  cardCarousel: {
    boxSizing: "border-box",
    position: "relative",
    padding: 0,
    width: "calc(33.33% - 8px)",
    borderRadius: 8,
    cursor: "pointer",
    marginBottom: 16,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
      },
    }),
  },
  marginRight: {
    marginRight: 16,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginRight: 0,
      },
    }),
  },
  marginRightCarousel: {
    marginRight: 8,
  },
  photo: {
    width: "100%",
    height: 240,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    objectFit: "cover",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        height: 200,
      },
    }),
  },
  marginRightThumbanil: {
    marginRight: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginRight: 0,
      },
    }),
  },
  sweetenProject: {
    position: "absolute",
    top: 16,
    left: 16,
    borderRadius: 24,
    backgroundColor: styles.colors.grey50,
    fontWeight: "bold",
    color: styles.colors.white,
    padding: "4px 12px",
    fontSize: 12,
    lineHeight: "20px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        top: 8,
        left: 8,
      },
    }),
  },
  textContainer: {
    margin: "8px 16px 16px",
  },
  neighborhoodYearNumPhotos: {
    color: styles.colors.grey40,
  },
  neighborhoodYearRating: {
    ...styles.center("vertical"),
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  container: {
    marginBottom: 64,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginBottom: 40,
        width: "100%",
      },
    }),
  },
  projectsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  bold: {
    fontWeight: "bold",
  },
  customArrowLink: {
    fontSize: 14,
    cursor: "pointer",
    ...styles.center("vertical"),
  },
  profileContainer: {
    width: 736,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopLarge,
      style: {
        marginBottom: 40,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
      },
    }),
  },
  mobileButton: {
    marginTop: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginTop: 8,
        width: 280,
      },
    }),
  },
  dropdown: {
    backgroundColor: styles.colors.white,
    width: 200,
    marginBottom: 24,
  },
  white: {
    color: styles.colors.white,
  },
  whiteButton: {
    border: "1px solid white",
    color: styles.colors.white,
    ":hover": {
      backgroundColor: styles.colors.grey40,
    },
  },
  arrowLeft: {
    position: "fixed",
    left: 20,
    top: 0,
    bottom: 0,
    margin: "auto",
    borderRadius: "50%",
    cursor: "pointer",
    opacity: 1,
    backgroundColor: "#0D1522",
    ":hover": {
      backgroundColor: styles.colors.grey40,
    },
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  arrowRight: {
    position: "fixed",
    right: 20,
    top: 0,
    bottom: 0,
    margin: "auto",
    borderRadius: "50%",
    cursor: "pointer",
    opacity: 1,
    backgroundColor: "#0D1522",
    transform: "rotate(180deg)",
    ":hover": {
      backgroundColor: styles.colors.grey40,
    },
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  black: {
    backgroundColor: "#0D1522",
  },
  darkCard: {
    backgroundColor: "#2A2C34",
    color: "white",
    border: "none",
  },
  mobile: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
      },
    }),
  },
  flexBetween: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      },
    }),
  },
  arrowLinkHeader: {
    marginLeft: 16,
    cursor: "pointer",
  },
  loader: {
    height: 600,
  },
  topHeight: {
    height: 88,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginTop: 44,
      },
    }),
  },
  roomTypeIcon: {
    width: 48,
    height: 48,
    marginBottom: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: 36,
        height: 36,
        marginBottom: 0,
        marginRight: 16,
      },
    }),
  },
  smallHeader: {
    width: "100%",
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 24,
    ...styles.center("vertical"),
    justifyContent: "space-between",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        fontSize: 18,
      },
    }),
  },
  filterButton: {
    cursor: "pointer",
    width: 128,
    marginBottom: 24,
    ...styles.center(),
    borderRadius: 20,
    height: 40,
    backgroundColor: "#FAFAFB",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: "bold",
    color: "#76808F",
  },
  filterSubheader: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "40px",
    marginBottom: 24,
  },
  modal: {
    width: 640,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        width: "100%",
      },
    }),
  },
  modalHeader: {
    textAlign: "center",
  },
  filterOption: {
    cursor: "pointer",
    width: 240,
    display: "flex",
    alignItems: "center",
  },
  filterFooter: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        ...styles.center(),
      },
    }),
  },
});

const photosSortAndSlice = (photos, sliceAmount = photos.length) => {
  return photos
    .filter(photo => !photo.isHidden)
    .sort((a, b) => a.sortIndex - b.sortIndex)
    .slice(0, sliceAmount);
};

const ProjectCard = ({
  project,
  idx,
  inCarousel,
  forPortfolio,
  setOverlayType,
  setSelectedProject,
  setSelectedRoomType,
  profileClick,
  darkTheme,
}) => {
  const { defaultImage, title, location, isSweeten, album } = project || {};
  const { neighborhood } = location || {};
  const { images } = album || {};

  const style = [darkTheme && ss.darkCard];
  if (inCarousel) {
    style.push([ss.cardCarousel, ss.marginRightCarousel]);
  } else if (forPortfolio) {
    style.push([ss.cardCarousel, ss.marginRightCarousel]);
  } else {
    style.push([ss.card, idx % 2 === 0 && ss.marginRight]);
  }

  const coverImage = images ? images[0] : defaultImage;
  const largeUrl = get(coverImage, "url.large");

  return (
    <Card
      aphStyle={style}
      onClick={() => {
        if (setOverlayType && setSelectedProject) {
          setOverlayType("thumbnail");
          setSelectedProject(project);
        } else if (profileClick) {
          profileClick(project);
        }
        setSelectedRoomType(null);
      }}
    >
      {isSweeten && (
        <div className={css(ss.sweetenProject)}>Sweeten project</div>
      )}
      {largeUrl ? (
        <div className={css(ss.photo)}>
          <img src={largeUrl} alt={title} className={css(ss.photo)} />
        </div>
      ) : (
        <div className={css(ss.photo)} />
      )}
      <div className={css(ss.textContainer)}>
        <Body
          tag="div"
          variant="bold"
          aphStyle={darkTheme && ss.white}
          style={{ fontSize: 16, lineHeight: "20px" }}
        >
          {title}
        </Body>
        <div className={css(ss.neighborhoodYearRating)}>
          <Body
            tag="div"
            aphStyle={[ss.neighborhoodYearNumPhotos, darkTheme && ss.white]}
          >
            {neighborhood}
          </Body>
          <div
            className={css(ss.neighborhoodYearNumPhotos, darkTheme && ss.white)}
          >
            {images.length} photos
          </div>
        </div>
      </div>
    </Card>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.object,
  idx: PropTypes.number,
  inCarousel: PropTypes.bool,
  forPortfolio: PropTypes.bool,
  setOverlayType: PropTypes.func,
  setSelectedProject: PropTypes.func,
  setSelectedRoomType: PropTypes.func,
  profileClick: PropTypes.func,
  darkTheme: PropTypes.func,
};

const sortForProjects = projects => {
  if (!projects) {
    return [];
  }

  return projects.sort((a, b) => {
    if (
      a.sortIndexPortfolioPage !== null &&
      b.sortIndexPortfolioPage !== null
    ) {
      return a.sortIndexPortfolioPage - b.sortIndexPortfolioPage;
    }
    return b.album.images.length - a.album.images.length;
  });
};

const FilterSelection = ({ items, onApply, onClose, selectedRoomTypes }) => {
  const [selectedRoomTypesArr, setSelectedRoomTypes] = useState(
    selectedRoomTypes
  );

  const onClickOption = item => {
    if (selectedRoomTypesArr[item.value]) {
      if (item.value === "all") {
        setSelectedRoomTypes({});
      } else {
        setSelectedRoomTypes(prevState => ({
          ...prevState,
          [item.value]: false,
        }));
      }
    } else if (item.value === "all") {
      setSelectedRoomTypes({
        all: true,
      });
    } else {
      setSelectedRoomTypes(prevState => ({
        ...prevState,
        all: false,
        [item.value]: true,
      }));
    }
  };

  return (
    <Modal aphStyle={ss.modal} onClose={onClose}>
      <Modal.Header aphStyle={ss.modalHeader}>Filters</Modal.Header>
      <Modal.Body>
        <div className={css(ss.filterSubheader)}>Project types</div>
        <div
          className={css(ss.flexWrap)}
          style={{ justifyContent: "space-between" }}
        >
          {items.map(item => (
            <div
              className={css(ss.filterOption)}
              onClick={() => onClickOption(item)}
            >
              <Checkbox
                value={selectedRoomTypesArr[item.value]}
                style={{ marginRight: 8 }}
              />
              {item.label}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer aphStyle={ss.filterFooter}>
        <Button
          onClick={() => {
            onApply(selectedRoomTypesArr);
            onClose();
          }}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

FilterSelection.propTypes = {
  items: PropTypes.object,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  selectedRoomTypes: PropTypes.array,
};

export const GcProfileProjects = ({
  companyId,
  showCarousel,
  forPortfolio,
  setOverlayType,
  selectedProject,
  setSelectedProject,
  setSelectedRoomType,
  profileClick,
  darkTheme,
}) => {
  const [filterRoomTypes, setFilterRoomTypes] = useState({});
  const [filterOpen, toggleFilterOpen] = useState(false);
  const [numShown, setNumShown] = useState(() => {
    if (showCarousel) {
      return 3;
    }
    if (forPortfolio) {
      return 12;
    }
    return 10;
  });

  const { data, loading } = useQuery(GET_PROFILE_PROJECTS_INFO, {
    variables: {
      id: companyId,
    },
  });

  if (loading) {
    return <Loader.Body aphStyle={ss.loader} style={{ marginBottom: 24 }} />;
  }

  const roomTypes = get(data, "roomTypes");
  const projects = get(data, "company.portfolioProjects");

  const sortedProjects = sortForProjects(projects);
  const filteredProjects = !isEmpty(filterRoomTypes)
    ? sortedProjects.filter(
        project =>
          project.rooms.some(
            room => filterRoomTypes[room.roomTypeConstant] === true
          ) && (selectedProject ? project.id !== selectedProject.id : true)
      )
    : sortedProjects.filter(project =>
        selectedProject ? project.id !== selectedProject.id : true
      );
  const hiddenFilteredProjects = filteredProjects.filter(project => {
    if (!project.album) {
      return false;
    }

    return !project.album.isHidden;
  });

  const onClickViewAll = () => {
    window.location.hash = "#portfolio";
    setOverlayType("portfolio");
    setFilterRoomTypes({});
  };

  if (hiddenFilteredProjects) {
    if (showCarousel) {
      return (
        <div style={{ marginTop: 24 }}>
          <div className={css(ss.smallHeader, darkTheme && ss.white)}>
            Other projects by this contractor
            <div
              onClick={onClickViewAll}
              className={css(
                ss.customArrowLink,
                ss.bold,
                darkTheme && ss.white
              )}
            >
              <div style={{ whiteSpace: "nowrap" }}>View all</div>
              <Icon
                name="right"
                size={16}
                color={darkTheme ? styles.colors.white : styles.colors.black}
                style={{ marginLeft: 8 }}
              />
            </div>
          </div>
          <ItemCarousel numShown={3}>
            {hiddenFilteredProjects.map((project, idx) => (
              <ProjectCard
                project={project}
                idx={idx}
                inCarousel
                setOverlayType={setOverlayType}
                setSelectedProject={setSelectedProject}
                setSelectedRoomType={setSelectedRoomType}
                darkTheme={darkTheme}
              />
            ))}
          </ItemCarousel>
        </div>
      );
    }
    if (forPortfolio) {
      const roomsObj = {};
      for (let i = 0; i < hiddenFilteredProjects.length; i += 1) {
        const { rooms } = hiddenFilteredProjects[i];
        for (let j = 0; j < rooms.length; j += 1) {
          const room = rooms[j];
          if (!roomsObj[room.roomTypeConstant]) {
            roomsObj[room.roomTypeConstant] = true;
          }
        }
      }
      let dropdownOptions = [{ value: "all", label: "View all" }];
      dropdownOptions = dropdownOptions.concat(
        Object.keys(roomsObj).map(room => ({
          value: room,
          label: roomTypes.find(roomType => roomType.constant === room)
            .displayName,
        }))
      );

      return (
        <div className={css(ss.container)}>
          {filterOpen && (
            <FilterSelection
              items={dropdownOptions}
              onClose={() => toggleFilterOpen(false)}
              onApply={val => setFilterRoomTypes(val)}
              selectedRoomTypes={filterRoomTypes}
            />
          )}
          <div className={css(ss.smallHeader)}>Explore by projects</div>
          <div
            onClick={() => toggleFilterOpen(true)}
            className={css(ss.filterButton)}
          >
            Filter by
          </div>
          <div className={css(ss.projectsContainer)}>
            {hiddenFilteredProjects.map((project, idx) => (
              <ProjectCard
                project={project}
                index={idx}
                forPortfolio
                setOverlayType={setOverlayType}
                setSelectedProject={setSelectedProject}
                setSelectedRoomType={setSelectedRoomType}
              />
            ))}
          </div>
          <div style={{ marginTop: 16, textAlign: "center" }}>
            {numShown < hiddenFilteredProjects.length && (
              <Button
                variant="outlineDark"
                onClick={() => setNumShown(numShown + 12)}
                aphStyle={ss.mobileButton}
              >
                Load more projects
              </Button>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className={css(ss.profileContainer, ss.container)}>
        <div className={css(ss.smallHeader)}>
          Projects ({projects.length})
          <ArrowLink onClick={onClickViewAll} linkAphStyle={ss.bold}>
            View all projects
          </ArrowLink>
        </div>
        <div className={css(ss.projectsContainer)}>
          {hiddenFilteredProjects.map((project, idx) => (
            <ProjectCard
              project={project}
              idx={idx}
              forPortfolio={forPortfolio}
              setOverlayType={setOverlayType}
              setSelectedProject={setSelectedProject}
              setSelectedRoomType={setSelectedRoomType}
              profileClick={profileClick}
            />
          ))}
        </div>
      </div>
    );
  }
  return null;
};

GcProfileProjects.propTypes = {
  companyId: PropTypes.number,
  showCarousel: PropTypes.bool,
  forPortfolio: PropTypes.bool,
  setOverlayType: PropTypes.func,
  selectedProject: PropTypes.object,
  setSelectedProject: PropTypes.func,
  setSelectedRoomType: PropTypes.func,
  profileClick: PropTypes.func,
  darkTheme: PropTypes.bool,
};

const roomTypeSvgMap = {
  adu: ADUSvg,
  apartment_combo: ApartmentComboSvg,
  attic: AtticSvg,
  basement: BasementSvg,
  bathroom: BathroomSvg,
  bedroom: BedroomSvg,
  building_exterior: ExteriorSvg,
  closet: ClosetSvg,
  deck_patio_porch: PatioSvg,
  dining: DiningSvg,
  foyer: FoyerSvg,
  garage: GarageSvg,
  home_office: OfficeResSvg,
  kitchen: KitchenSvg,
  laundry: LaundrySvg,
  living_room: LivingSvg,
  new_construction: NewConstructionSvg,
  nursery: NurserySvg,
  other: OtherSvg,
  room_addition: RoomAdditionSvg,
  yard: YardSvg,
};

const RoomTypeCard = ({
  album,
  darkTheme,
  setSelectedRoomType,
  onClickCb,
  roomTypes,
  idx,
}) => {
  const currentRoomType = album.category;

  return (
    <>
      {!album.isHidden && (
        <Card
          aphStyle={[
            ss.exploreCard,
            darkTheme && ss.darkCard,
            idx % 2 === 0 && ss.mobileMargin,
            idx % 6 !== 5 && ss.desktopMargin,
          ]}
          onClick={() => {
            setSelectedRoomType(currentRoomType);
            onClickCb();
          }}
        >
          <img
            src={roomTypeSvgMap[currentRoomType]}
            alt={currentRoomType}
            className={`${css(ss.roomTypeIcon)} ${darkTheme && "roomTypeIcon"}`}
          />
          {
            <div style={{ textAlign: "left" }}>
              {
                roomTypes.find(
                  roomType => roomType.constant === currentRoomType
                ).displayName
              }
            </div>
          }
        </Card>
      )}
    </>
  );
};

RoomTypeCard.propTypes = {
  album: PropTypes.object,
  roomTypes: PropTypes.array,
  setSelectedRoomType: PropTypes.func,
  onClickCb: PropTypes.func,
  darkTheme: PropTypes.bool,
  idx: PropTypes.number,
};

const ExploreRoomTypes = ({
  companyId,
  setSelectedRoomType,
  onClickCb = () => {},
  darkTheme,
}) => {
  const { data, loading } = useQuery(GET_ROOM_TYPES_INFO, {
    variables: {
      id: companyId,
    },
  });

  if (loading) {
    return <Loader.Quadrangle aphStyle={ss.loader} />;
  }

  const albums = get(data, "company.albums");
  const roomTypes = get(data, "roomTypes");

  return (
    <div className={css(ss.exploreMargin)}>
      <div className={css(ss.smallHeader, darkTheme && ss.white)}>
        Explore by room types
      </div>
      <div className={css(ss.flexWrap)}>
        {albums
          .filter(album => album.category !== "featured" && !album.isHidden)
          .map((album, idx) => (
            <RoomTypeCard
              album={album}
              darkTheme={darkTheme}
              setSelectedRoomType={setSelectedRoomType}
              onClickCb={onClickCb}
              roomTypes={roomTypes}
              idx={idx}
            />
          ))}
      </div>
    </div>
  );
};

ExploreRoomTypes.propTypes = {
  companyId: PropTypes.number,
  setSelectedRoomType: PropTypes.func,
  onClickCb: PropTypes.func,
  darkTheme: PropTypes.bool,
};

const LightboxFinalScreen = ({
  companyId,
  onClose,
  setSelectedRoomType,
  setOverlayType,
  setSelectedProject,
  lightboxPrev,
  lightboxNext,
  prevOverlayType,
  onCloseInOverlay,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ alignToTop: true, behavior: "smooth" });
    }
  }, []);

  return (
    <div>
      <div className={css(ss.fixedHeader, ss.black, ss.flexBetween)}>
        <div
          onClick={lightboxNext}
          className={css(ss.arrowLinkHeader, ss.mobile)}
        >
          <Icon name="left" size={16} color="white" />
        </div>
        <div
          onClick={() => {
            if (prevOverlayType) {
              onCloseInOverlay();
            } else {
              onClose();
            }
          }}
          className={css(ss.closeContainer)}
        >
          <img src={CloseWhite} alt="close" />
        </div>
      </div>
      <div className={css(ss.topHeight)} ref={ref} />
      <div onClick={lightboxPrev}>
        <img src={WhiteArrow} alt="arrow_left" className={css(ss.arrowLeft)} />
      </div>
      <div onClick={lightboxNext}>
        <img
          src={WhiteArrow}
          alt="arrow_right"
          className={css(ss.arrowRight)}
        />
      </div>
      <GcProfileProjects
        showCarousel
        setOverlayType={setOverlayType}
        setSelectedProject={setSelectedProject}
        setSelectedRoomType={setSelectedRoomType}
        darkTheme
        companyId={companyId}
      />
      <ExploreRoomTypes
        setSelectedRoomType={setSelectedRoomType}
        onClickCb={() => setOverlayType("wall")}
        darkTheme
        companyId={companyId}
      />
    </div>
  );
};

LightboxFinalScreen.propTypes = {
  companyId: PropTypes.number,
  onClose: PropTypes.func,
  setOverlayType: PropTypes.func,
  setSelectedRoomType: PropTypes.func,
  setSelectedProject: PropTypes.func,
  lightboxNext: PropTypes.func,
  lightboxPrev: PropTypes.func,
  prevOverlayType: PropTypes.string,
  onCloseInOverlay: PropTypes.func,
};

const ProjectThumbnailGrid = ({
  companyId,
  onClose,
  setOverlayType,
  setSelectedRoomType,
  selectedProject,
  setSelectedProject,
  updateImageNumber,
  setPhotos,
  setPrevOverlayType,
}) => {
  useEffect(() => {
    setPhotos(selectedProject.album.images);
  }, [selectedProject.album.images]);

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ alignToTop: true, behavior: "smooth" });
    }
  }, [selectedProject]);

  return (
    <div>
      <div className={css(ss.fixedHeader)}>
        <div onClick={onClose} className={css(ss.closeContainer)}>
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
      <div className={css(ss.topHeight)} ref={ref} />
      <Header tag="h3">{selectedProject.title}</Header>
      <div className={css(ss.neighborhood)}>{selectedProject.neighborhood}</div>
      {selectedProject.isSweeten && (
        <Button
          onClick={() => goToPage(`/projects/${selectedProject.id}`)}
          variant="outlineDark"
          aphStyle={ss.projectDetails}
        >
          Project Details
        </Button>
      )}
      <div className={css(ss.thumbnailPhotos)}>
        {selectedProject.album.images.map((image, idx) => (
          <Photo
            index={idx}
            photo={image}
            updateImageNumber={updateImageNumber}
            urlSize="large"
            setPrevOverlayType={setPrevOverlayType}
            overlayType="thumbnail"
          />
        ))}
      </div>
      <div className={css(ss.marginProjects)}>
        <GcProfileProjects
          showCarousel
          setOverlayType={setOverlayType}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          setSelectedRoomType={setSelectedRoomType}
          companyId={companyId}
        />
      </div>
      <ExploreRoomTypes
        setSelectedRoomType={setSelectedRoomType}
        onClickCb={() => setOverlayType("wall")}
        companyId={companyId}
      />
    </div>
  );
};

ProjectThumbnailGrid.propTypes = {
  companyId: PropTypes.number,
  onClose: PropTypes.func,
  setOverlayType: PropTypes.func,
  setSelectedRoomType: PropTypes.func,
  selectedProject: PropTypes.object,
  setSelectedProject: PropTypes.func,
  updateImageNumber: PropTypes.func,
  setPhotos: PropTypes.func,
  setPrevOverlayType: PropTypes.func,
};

const PortfolioScreen = ({
  companyId,
  onClose,
  setOverlayType,
  setSelectedRoomType,
  setSelectedProject,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ alignToTop: true, behavior: "smooth" });
    }
  }, []);

  return (
    <div>
      <div className={css(ss.fixedHeader)}>
        <div onClick={onClose} className={css(ss.closeContainer)}>
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
      <div className={css(ss.topHeight)} ref={ref} />
      <Header tag="h3">Portfolio</Header>
      <ExploreRoomTypes
        setSelectedRoomType={setSelectedRoomType}
        onClickCb={() => setOverlayType("wall")}
        companyId={companyId}
      />
      <div className={css(ss.marginProjects)}>
        <GcProfileProjects
          forPortfolio
          setOverlayType={setOverlayType}
          setSelectedProject={setSelectedProject}
          setSelectedRoomType={setSelectedRoomType}
          companyId={companyId}
        />
      </div>
    </div>
  );
};

PortfolioScreen.propTypes = {
  companyId: PropTypes.number,
  onClose: PropTypes.func,
  setOverlayType: PropTypes.func,
  setSelectedRoomType: PropTypes.func,
  setSelectedProject: PropTypes.func,
};

const Photo = ({
  index,
  photo,
  updateImageNumber,
  urlSize,
  isPhotoWall,
  onClick = () => {},
  setPrevOverlayType,
  overlayType,
}) => {
  if (isPhotoWall) {
    if (index % 4 === 0) {
      return (
        <div
          onClick={() => {
            onClick();
            updateImageNumber(index);
            setPrevOverlayType(overlayType);
          }}
          className={css(ss.fullContainer)}
        >
          <img
            src={photo.url[urlSize]}
            alt={`photo_${index}`}
            className={css(ss.image)}
          />
        </div>
      );
    }
    if (index % 4 === 1) {
      return (
        <div
          className={css(ss.halfContainer)}
          onClick={() => {
            onClick();
            updateImageNumber(index);
            setPrevOverlayType(overlayType);
          }}
        >
          <img
            src={photo.url[urlSize]}
            alt={`photo_${index}`}
            className={css(ss.image)}
          />
        </div>
      );
    }
    if (index % 4 === 2) {
      return (
        <div
          className={css(ss.quarterContainer)}
          onClick={() => {
            onClick();
            updateImageNumber(index);
            setPrevOverlayType(overlayType);
          }}
        >
          <img
            src={photo.url[urlSize]}
            alt={`photo_${index}`}
            className={css(ss.image)}
            style={{ marginBottom: 48 }}
          />
        </div>
      );
    }
    return (
      <div
        className={css(ss.quarterContainer)}
        onClick={() => {
          onClick();
          updateImageNumber(index);
          setPrevOverlayType(overlayType);
        }}
      >
        <img
          src={photo.url[urlSize]}
          alt={`photo_${index}`}
          className={css(ss.image)}
        />
      </div>
    );
  }
  return (
    <div
      onClick={() => {
        onClick();
        updateImageNumber(index);
        setPrevOverlayType(overlayType);
      }}
      className={css(
        ss.thumbnailImage,
        index % 3 !== 2 && ss.marginRightThumbanil
      )}
    >
      <img
        src={photo.url.large}
        alt={`image_${index}`}
        className={css(ss.image)}
      />
    </div>
  );
};

Photo.propTypes = {
  index: PropTypes.number,
  photo: PropTypes.object,
  updateImageNumber: PropTypes.func,
  urlSize: PropTypes.string,
  isPhotoWall: PropTypes.bool,
  onClick: PropTypes.func,
  setPrevOverlayType: PropTypes.func,
  overlayType: PropTypes.string,
};

const PhotoWall = ({
  companyId,
  onClose,
  urlSize,
  selectedRoomType,
  setSelectedRoomType,
  setOverlayType,
  setSelectedProject,
  updateImageNumber,
  setPhotos,
  setPrevOverlayType,
}) => {
  const ref = useRef(null);

  const { data, loading } = useQuery(GET_PHOTO_WALL_DATA, {
    variables: {
      id: companyId,
      category: selectedRoomType || "featured",
    },
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ alignToTop: true, behavior: "smooth" });
    }
  }, [selectedRoomType, data]);

  if (loading) {
    return <Loader.Quadrangle aphStyle={ss.loader} />;
  }

  const roomTypes = get(data, "roomTypes");

  const albums = get(data, "company.albums");
  const filteredPhotos = [];
  if (selectedRoomType) {
    forEach(albums, album => {
      if (album.category === selectedRoomType) {
        forEach(album.images, image => {
          filteredPhotos.push(image);
        });
      }
    });
  } else {
    const featuredAlbum = albums.filter(
      album => album.category === "featured"
    )[0];
    forEach(featuredAlbum.images, image => filteredPhotos.push(image));
  }
  const photosToUse = selectedRoomType
    ? photosSortAndSlice(filteredPhotos)
    : photosSortAndSlice(filteredPhotos, 15);

  return (
    <div>
      <div className={css(ss.fixedHeader)}>
        <div onClick={onClose} className={css(ss.closeContainer)}>
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
      <div className={css(ss.topHeight)} ref={ref} />
      <Header tag="h3">
        {selectedRoomType
          ? capitalize(
              roomTypes.find(roomType => roomType.constant === selectedRoomType)
                .displayName
            )
          : "Featured"}{" "}
        photos
      </Header>
      {photosToUse && (
        <div className={css(ss.photosContainer)}>
          {photosToUse.map((photo, idx) => (
            <Photo
              index={idx}
              photo={photo}
              updateImageNumber={updateImageNumber}
              urlSize={urlSize}
              isPhotoWall
              onClick={() => setPhotos(photosToUse)}
              setPrevOverlayType={setPrevOverlayType}
              overlayType="wall"
            />
          ))}
        </div>
      )}
      <GcProfileProjects
        showCarousel
        setOverlayType={setOverlayType}
        setSelectedProject={setSelectedProject}
        setSelectedRoomType={setSelectedRoomType}
        companyId={companyId}
      />
      <ExploreRoomTypes
        setSelectedRoomType={setSelectedRoomType}
        companyId={companyId}
      />
    </div>
  );
};

PhotoWall.defaultProps = {
  urlSize: "large",
};

PhotoWall.propTypes = {
  onClose: PropTypes.func,
  urlSize: PropTypes.string,
  companyId: PropTypes.number,
  setOverlayType: PropTypes.func,
  selectedRoomType: PropTypes.string,
  setSelectedRoomType: PropTypes.func,
  setSelectedProject: PropTypes.func,
  updateImageNumber: PropTypes.func,
  setPhotos: PropTypes.func,
  setPrevOverlayType: PropTypes.func,
};

export const LightboxComp = ({
  activeImageNumber,
  photos,
  setImageNumber,
  urlSize,
  setToFinalScreen,
  onClose,
}) => {
  return (
    <Lightbox
      images={photos.map(photo => photo.url[urlSize])}
      index={activeImageNumber}
      onClose={() => {
        setImageNumber(null);
        if (onClose) {
          onClose();
        }
      }}
      onMovePrevRequest={() => {
        if (activeImageNumber === 0) {
          setToFinalScreen(photos);
        } else {
          setImageNumber(
            (activeImageNumber + photos.length - 1) % photos.length
          );
        }
      }}
      onMoveNextRequest={() => {
        if (activeImageNumber === photos.length - 1) {
          setToFinalScreen(photos);
        } else {
          setImageNumber((activeImageNumber + 1) % photos.length);
        }
      }}
      topLeftElements={photos.map(() => (
        <div className={css(ss.flexCentered)} style={{ marginTop: 4 }}>
          <div className={css(ss.absoluteCentered, ss.white)}>
            {activeImageNumber + 1}/{photos.length}
          </div>
        </div>
      ))}
      bottomElements={photos.map(photo => {
        const { project } = photo;
        const { title } = project || {};

        return (
          <div
            className={css(ss.flexCentered)}
            style={{
              justifyContent: "space-evenly",
            }}
          >
            <Body
              tag="div"
              variant="bold"
              aphStyle={[ss.title, ss.white]}
              onClick={() => goToPage(`/projects/${photo.project.id}`)}
            >
              {title}
            </Body>
            <Button
              onClick={() => goToPage(`/projects/${photo.project.id}`)}
              variant="outlineDark"
              aphStyle={[ss.projectDetails, ss.whiteButton]}
            >
              Show project details
            </Button>
          </div>
        );
      })}
    />
  );
};

LightboxComp.defaultProps = {
  urlSize: "large",
};

LightboxComp.propTypes = {
  activeImageNumber: PropTypes.number,
  setImageNumber: PropTypes.func,
  photos: PropTypes.arrayOf(PropTypes.object),
  urlSize: PropTypes.string,
  setToFinalScreen: PropTypes.func,
  onClose: PropTypes.func,
};

const PhotoOverlay = props => {
  const { companyId, variant, project, lightboxNext, lightboxPrev } =
    props || {};
  const [overlayType, setOverlayType] = useState(variant);
  const [prevOverlayType, setPrevOverlayType] = useState(null);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [selectedProject, setSelectedProject] = useState(project);
  const [photos, setPhotos] = useState(null);
  const [activeImageNumber, updateImageNumber] = useState(null);

  useEffect(() => {
    if (!overlayType && window.location.hash === "#portfolio") {
      goToPage(`/members/${companyId}#overview`);
    }
  }, [overlayType]);

  const stateProps = {
    setOverlayType,
    selectedRoomType,
    setSelectedRoomType,
    selectedProject,
    setSelectedProject,
    photos,
    setPhotos,
    activeImageNumber,
    updateImageNumber,
    setPrevOverlayType,
    prevOverlayType,
  };

  return (
    <div
      className={css(
        ss.overlay,
        (overlayType === "lightboxScreen" || !overlayType) && ss.black
      )}
    >
      <div style={{ maxWidth: 1080, margin: "auto" }}>
        {overlayType === "wall" && (
          <PhotoWall companyId={companyId} {...props} {...stateProps} />
        )}
        {overlayType === "portfolio" && (
          <PortfolioScreen companyId={companyId} {...props} {...stateProps} />
        )}
        {overlayType === "thumbnail" && (
          <ProjectThumbnailGrid
            companyId={companyId}
            {...props}
            {...stateProps}
          />
        )}
        {overlayType === "lightboxScreen" && (
          <LightboxFinalScreen
            companyId={companyId}
            {...props}
            {...stateProps}
            onCloseInOverlay={() => setOverlayType(prevOverlayType)}
            lightboxNext={() => {
              if (!photos && lightboxNext) {
                lightboxNext();
              } else {
                updateImageNumber(0);
                setOverlayType(null);
              }
            }}
            lightboxPrev={() => {
              if (!photos && lightboxPrev) {
                lightboxPrev();
              } else {
                updateImageNumber(photos.length - 1);
                setOverlayType(null);
              }
            }}
          />
        )}
        {activeImageNumber !== null && (
          <LightboxComp
            onClose={() => setOverlayType(prevOverlayType)}
            photos={photos}
            activeImageNumber={activeImageNumber}
            setImageNumber={updateImageNumber}
            setToFinalScreen={() => {
              setOverlayType("lightboxScreen");
              updateImageNumber(null);
            }}
          />
        )}
      </div>
    </div>
  );
};

PhotoOverlay.propTypes = {
  companyId: PropTypes.string,
  variant: PropTypes.string,
  project: PropTypes.object,
  lightboxNext: PropTypes.func,
  lightboxPrev: PropTypes.func,
};

export default PhotoOverlay;
