import React from "react";
import PropTypes from "prop-types";
import { Icon, Loader, styles, Tooltip } from "@sweeten/oreo";
import { css, StyleSheet } from "aphrodite/no-important";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import AllStarVariantSvgPath from "../../assets/images/allstar_variant.svg";

const ss = StyleSheet.create({
  allStar: {
    border: `1px solid ${styles.colors.brandPrimary}`,
    whiteSpace: "nowrap",
    borderRadius: 24,
    fontWeight: "bold",
    backgroundColor: styles.colors.brandPrimaryHover,
    color: styles.colors.brandPrimary,
    fontSize: 12,
    lineHeight: "20px",
    padding: "1px 8px",
    marginRight: 8,
    boxSizing: "border-box",
    "::-webkit-box-sizing": "border-box",
    "::-moz-box-sizing": "border-box",
    marginTop: 4,
  },
  flexCentered: {
    ...styles.center("vertical"),
  },
  marginTop: {
    marginTop: 8,
  },
});

const PROJECT_AND_USER_TYPE = gql`
  query projectAndUserType {
    currentUser {
      id
      isExpert
      currentProject {
        id
        shouldAutoOptInToMatches
      }
    }
  }
`;

const AllStar = ({ data }) => {
  const { currentUser } = data;
  const { isExpert, currentProject } = currentUser || {};
  const { shouldAutoOptInToMatches } = currentProject || {};

  let variantContent;
  if (isExpert || !currentProject || shouldAutoOptInToMatches) {
    variantContent = {
      icon: (
        <Icon
          name="multistars"
          color={styles.colors.brandPrimary}
          size={16}
          style={{ marginTop: 4, marginRight: 4 }}
        />
      ),
      copy: "All-Star",
      tooltip: `While all contractors in Sweeten’s network meet our rigorous standards,
      a select few stand out. All-Stars have completed multiple Sweeten renovations,
      and consistently earn top reviews from homeowners.`,
    };
  } else {
    variantContent = {
      icon: (
        <img
          src={AllStarVariantSvgPath}
          alt="allstar"
          style={{ marginRight: 4 }}
        />
      ),
      copy: "Top Recommendation",
      tooltip: `While all contractors in Sweeten’s network meet our rigorous standards,
      a select few stand out. Those labeled Top Recommendation have completed multiple Sweeten
      renovations, and consistently earn top reviews from homeowners.`,
    };
  }

  return (
    <Tooltip content={variantContent.tooltip} position="right">
      <div
        className={css([
          ss.flexCentered,
          ss.allStar,
          variantContent.copy === "All-Star" && ss.marginTop,
        ])}
      >
        {variantContent.icon}
        {variantContent.copy}
      </div>
    </Tooltip>
  );
};

AllStar.propTypes = {
  data: PropTypes.object,
};

export default () => {
  const { data, loading } = useQuery(PROJECT_AND_USER_TYPE);

  return loading ? (
    <Loader.Body style={{ width: 100, height: 24 }} />
  ) : (
    <AllStar data={data} />
  );
};
