import React from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  SingleSelectDropdown,
  styles,
  Input,
  Button,
  Body,
} from "@sweeten/oreo";
import { css, StyleSheet } from "aphrodite";
import { useField } from "react-final-form-hooks";
import { find } from "lodash";
import { required } from "../../validators";
import { trackMixpanel } from "../../utils";

const ss = StyleSheet.create({
  marginRight: {
    marginRight: 16,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginRight: 0,
      },
    }),
  },
  suggestionContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 16,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "block",
      },
    }),
  },
  suggestions: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  suggestButton: {
    color: styles.colors.black,
    fontWeight: "none",
    fontSize: 12,
    padding: "0 18px",
    height: 24,
    marginLeft: 12,
    marginBottom: 4,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginTop: 8,
      },
    }),
  },
});

const FormDropdowns = ({
  form,
  handleAddRoomSubmit,
  isDesktop,
  roomSuggestions,
  roomTypes,
  isBackfill,
  isPortfolio,
  withSuggestions,
}) => {
  const roomTypeConstant = useField("roomTypeConstant", form, required);
  const squareFootageBucket = useField("squareFootageBucket", form, required);
  const cost = useField("cost", form);

  const sqFootageBucketOnChange = val => {
    squareFootageBucket.input.onChange(val);
    if (isDesktop && !isBackfill) {
      handleAddRoomSubmit();
    }
  };

  const selectedRoom = find(
    roomTypes,
    roomType => roomType.constant === roomTypeConstant.input.value
  );
  const {
    minSqFootageSmall,
    maxSqFootageSmall,
    minSqFootageMedium,
    maxSqFootageMedium,
    minSqFootageLarge,
    maxSqFootageLarge,
  } = selectedRoom || {};

  const dropdownItems = [
    {
      label: `Small (${minSqFootageSmall} - ${maxSqFootageSmall} sq ft)`,
      value: "small",
    },
    {
      label: `Medium (${minSqFootageMedium} - ${maxSqFootageMedium} sq ft)`,
      value: "medium",
    },
    {
      label: `Large (${minSqFootageLarge} - ${maxSqFootageLarge}+ sq ft)`,
      value: "large",
    },
  ];

  const renderRoomDropdown = () => (
    <div data-test="room_type_dropdown">
      <SingleSelectDropdown
        {...roomTypeConstant.input}
        error={roomTypeConstant.meta.touched && roomTypeConstant.meta.error}
        items={roomTypes.map(roomType => {
          return {
            label: roomType.displayName,
            value: roomType.constant,
          };
        })}
        placeholder="Search or Select Area"
        style={{ backgroundColor: styles.colors.white }}
        aphStyle={ss.marginRight}
        hasSearch
      />
    </div>
  );

  const renderSquareFootageDropdown = () => (
    <div data-test="square_footage_dropdown">
      <SingleSelectDropdown
        {...squareFootageBucket.input}
        disabled={!selectedRoom}
        error={
          squareFootageBucket.meta.touched && squareFootageBucket.meta.error
        }
        items={dropdownItems}
        onChange={sqFootageBucketOnChange}
        placeholder="Square Feet"
        style={{ backgroundColor: styles.colors.white }}
      />
    </div>
  );

  const renderWithoutSuggestions = () => (
    <Row verticalGap={0}>
      <Col
        width={{
          desktopLarge: 7,
          tabletStandard: 7,
          tabletSmall: 7,
          phoneStandard: 12,
          phoneSmall: 12,
        }}
        style={{ margin: "4px 0" }}
      >
        {renderRoomDropdown()}
      </Col>
      <Col
        width={{
          desktopLarge: 5,
          tabletStandard: 5,
          tabletSmall: 5,
          phoneStandard: 12,
          phoneSmall: 12,
        }}
        style={{ margin: "4px 0" }}
      >
        {renderSquareFootageDropdown()}
      </Col>
    </Row>
  );

  const renderWithSuggestions = () => (
    <>
      <div data-test="room_type_dropdown">
        <SingleSelectDropdown
          {...roomTypeConstant.input}
          error={roomTypeConstant.meta.touched && roomTypeConstant.meta.error}
          items={roomTypes.map(roomType => {
            return {
              label: roomType.displayName,
              value: roomType.constant,
            };
          })}
          placeholder="Search or Select Area"
          style={{ backgroundColor: styles.colors.white }}
          hasSearch
        />
      </div>
      {roomTypeConstant.input.value ? (
        <div data-test="square_footage_dropdown">
          <SingleSelectDropdown
            {...squareFootageBucket.input}
            disabled={!selectedRoom}
            error={
              squareFootageBucket.meta.touched && squareFootageBucket.meta.error
            }
            items={dropdownItems}
            onChange={sqFootageBucketOnChange}
            placeholder="Square Feet"
            style={{ backgroundColor: styles.colors.white }}
          />
        </div>
      ) : (
        <div className={css(ss.suggestionContainer)}>
          <Body tag="span">Suggestions:</Body>
          <div className={css(ss.suggestions)}>
            {roomSuggestions.map(room => {
              return (
                <Button
                  aphStyle={ss.suggestButton}
                  onClick={() => {
                    roomTypeConstant.input.onChange(room.constant);
                    trackMixpanel("Clicked Room Suggestion", {
                      "Room Name": room.displayName,
                    });
                  }}
                  label={room.displayName}
                  type="submit"
                  variant="outlineDark"
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      {withSuggestions ? renderWithSuggestions() : renderWithoutSuggestions()}
      {isBackfill && (
        <Row>
          {!isPortfolio && (
            <Col>
              <Input
                {...cost.input}
                error={cost.meta.touched && cost.meta.error}
                beforeElement="$"
                type="number"
                placeholder="Cost"
                style={{ backgroundColor: styles.colors.white }}
              />
            </Col>
          )}
          <Col>
            <Button
              onClick={handleAddRoomSubmit}
              label="Add Room"
              type="submit"
              style={{ marginTop: 12 }}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

FormDropdowns.propTypes = {
  form: PropTypes.object,
  handleAddRoomSubmit: PropTypes.func,
  isDesktop: PropTypes.bool,
  roomSuggestions: PropTypes.array,
  roomTypes: PropTypes.array,
  isBackfill: PropTypes.bool,
  isPortfolio: PropTypes.bool,
  withSuggestions: PropTypes.bool,
};

export default FormDropdowns;
