import React, { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite";
import { gql } from "apollo-boost";
import { useForm } from "react-final-form-hooks";
import { useMutation } from "@apollo/react-hooks";
import { Button, Modal, TextLink, styles } from "@sweeten/oreo";
import { trimStart } from "lodash";
import Cookies from "js-cookie";
import {
  determineModalHeader,
  MODAL_STEPS,
  ConfirmationStep,
  CategoryStep,
  SuccessScreen,
} from "./modal_steps";
import {
  trackMixpanel,
  hubspotNotifyProjectUpdate,
  trackMixpanelWithCallback,
  goToPage,
} from "../../../../utils";
import PhoneFieldGroup from "../../../../components/account_forms/phone_field_group";
import AddressFieldGroup from "../../../../components/account_forms/address_field_group";
import PhoneAddressInfo from "../../../../components/account_forms/phone_address_info";
import { USER_UPDATE_PHONE } from "../../../../components/modals/update_phone_number_modal";
import { IntroPropType, ACTIVE_MATCH_FRAGMENT } from "../../shared";

// == Helpers ===========================================
const trackCategorySelection = (newCategory, isEditing) => {
  const eventName = isEditing ? "Edited meeting type" : "Type of meeting";
  const payload = {
    "Meeting Type": newCategory,
    Variant: "I'm interested",
  };
  trackMixpanel(eventName, payload);
};

export const triggerLocation = location => {
  if (location === "gc_profile") {
    const currentTab = trimStart(window.location.hash, "#")
      ? trimStart(window.location.hash, "#")
      : "projects";
    return `gc_profile - ${currentTab}`;
  }
  return location;
};

// == GQL QUERIES/MUTATIONS ===========================================

export const UPDATE_ADDRESS = gql`
  mutation updateProject($projectId: ID!, $attributes: ProjectUpdateInput!) {
    projectUpdate(id: $projectId, attributes: $attributes) {
      project {
        id
        location {
          address
          address2
        }
      }
    }
  }
`;

const REN_ACCEPT_MATCH = gql`
  mutation renAcceptMatch(
    $matchId: ID!
    $category: String
    $eventContext: String
    $optInPageName: String
  ) {
    renAcceptMatch(
      matchId: $matchId
      category: $category
      eventContext: $eventContext
      optInPageName: $optInPageName
    ) {
      intro {
        ...ActiveMatchFields
      }
    }
  }
  ${ACTIVE_MATCH_FRAGMENT}
`;

// == COMPONENTS ===================================

const SchedulingModalSS = StyleSheet.create({
  backLink: {
    marginRight: 24,
  },
  modalMod: {
    textAlign: "left",
    fontStyle: "normal",
    padding: "42px 0 32px",
    height: 680,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        height: "100%",
        padding: "42px 0 16px",
      },
    }),
  },
  sidePadding: {
    paddingLeft: 32,
    paddingRight: 32,
  },
});

const SchedulingModal = ({
  isRescheduling,
  onClose,
  onSubmit,
  submitLoading,
  submitButtonLabel,
  data: modalQueryData,
  intro,
  location: pageLocation,
  updateMatchingModalState,
}) => {
  const { project } = modalQueryData;
  const { user, location, scheduledIntroCall } = project || {};
  const { address } = location || {};
  const { company } = intro;
  const { nameSignature } = company;
  const { phone } = user;

  const [modalStep, updateModalStep] = useState(
    MODAL_STEPS.selectIntroCategory
  );
  const [category, updateCategory] = useState();
  const isEditingSelections = useRef(false); // For tracking only
  const [submitIsLoading, setSubmitLoading] = useState(submitLoading);

  const [updatePhoneNumber, { loading: updatePhoneLoading }] = useMutation(
    USER_UPDATE_PHONE
  );

  const [updateAddress, { loading: updateAddressLoading }] = useMutation(
    UPDATE_ADDRESS
  );

  const [acceptMatch, { loading: isAccepting }] = useMutation(REN_ACCEPT_MATCH);

  const { form, handleSubmit } = useForm({
    onSubmit: values => {
      setSubmitLoading(true);
      const mutationArgs = values;
      if (!phone) {
        updatePhoneNumber({
          variables: {
            attributes: {
              phone: mutationArgs.phone,
            },
          },
        })
          .then(({ data: mutationData }) => {
            trackMixpanel("Added phone number");

            const { userUpdate } = mutationData || {};
            const { user: updateUser } = userUpdate || {};
            const { currentProject } = updateUser || {};
            hubspotNotifyProjectUpdate({
              projectId: currentProject.id,
              eventName: "match-phone-provided-REN",
              metadata: { category },
            });
          })
          .then(() => {
            updateAddress({
              variables: {
                projectId: project.id,
                attributes: {
                  address: mutationArgs.address,
                  address2: mutationArgs.address2,
                },
              },
            });
          })
          .then(() => setSubmitLoading(false));
      } else {
        updateAddress({
          variables: {
            projectId: project.id,
            attributes: {
              address: mutationArgs.address,
              address2: mutationArgs.address2,
            },
          },
        }).then(() => setSubmitLoading(false));
      }
    },
  });

  const onCategoryClick = val => {
    trackCategorySelection(val, isEditingSelections.current);
    updateCategory(val);
    updateModalStep(MODAL_STEPS.confirm);
  };

  const modalHeader = determineModalHeader(modalStep, nameSignature);
  const shouldShowSubmitFooter = modalStep === MODAL_STEPS.confirm;
  const shouldDisableSubmit = !category;

  const acceptAndScheduleIntro = () => {
    const variables = {
      matchId: intro.id,
      category,
      optInPageName: pageLocation,
    };

    acceptMatch({
      variables,
    }).then(() => {
      trackMixpanelWithCallback(
        "Accepted GC",
        {
          "Trigger Location": triggerLocation(pageLocation),
          "GC ID": intro.company.id,
          Variant: "I'm interested",
        },
        () => {
          updateCategory(null);
          updateModalStep(MODAL_STEPS.success);
        }
      );
    });
  };

  const updateMatchesModalState = useContext(updateMatchingModalState);

  const onSuccessButtonClick = () => {
    const dontShowScheduleCall =
      Cookies.get("dont-show-schedule-call") === "true";
    if (!dontShowScheduleCall && !scheduledIntroCall) {
      updateMatchesModalState({
        name: "scheduleAdminCall",
        options: { location: pageLocation },
      });
    } else if (
      pageLocation === "gc_profile" ||
      pageLocation === "gc_profile_project"
    ) {
      goToPage("/my-contractors");
    } else {
      updateMatchesModalState({
        name: "",
      });
    }
  };

  const onSubmitFn = onSubmit || (() => acceptAndScheduleIntro());

  const submitCall = () => {
    if (modalStep !== MODAL_STEPS.confirm) {
      onSubmitFn();
    } else {
      handleSubmit();
      const phoneField = form.getFieldState("phone");
      const phoneError = phoneField ? phoneField.error : false;
      const addressField = form.getFieldState("address");
      const addressError = addressField ? addressField.error : false;
      if (!phoneError && !addressError) {
        onSubmitFn();
      }
    }
  };

  const onClickBack = () => {
    updateModalStep(MODAL_STEPS.selectIntroCategory);
  };

  const onEditClick = () => {
    isEditingSelections.current = true;
    updateCategory(null);
    updateModalStep(MODAL_STEPS.selectIntroCategory);
  };

  const showBackLink =
    modalStep !== MODAL_STEPS.selectIntroCategory &&
    modalStep !== MODAL_STEPS.success;

  const showFooter =
    shouldShowSubmitFooter ||
    (category && modalStep === MODAL_STEPS.selectIntroCategory) ||
    modalStep === MODAL_STEPS.success;

  return (
    <Modal
      aphStyle={SchedulingModalSS.modalMod}
      onClose={() => {
        if (
          modalStep === MODAL_STEPS.success &&
          (pageLocation === "gc_profile" ||
            pageLocation === "gc_profile_project")
        ) {
          goToPage("/my-contractors");
        } else {
          onClose();
        }
      }}
      disableOutsideClick
    >
      <Modal.Header aphStyle={SchedulingModalSS.sidePadding}>
        {modalHeader}
      </Modal.Header>
      <Modal.Body>
        <div className={css(SchedulingModalSS.sidePadding)}>
          {modalStep === MODAL_STEPS.selectIntroCategory && (
            <CategoryStep
              category={category}
              onCategoryClick={onCategoryClick}
              hasEmailOption={!isRescheduling}
            />
          )}
          {modalStep === MODAL_STEPS.confirm && (
            <>
              <ConfirmationStep category={category} onEditClick={onEditClick} />
              {!phone && <PhoneFieldGroup form={form} />}
              {!address && (
                <AddressFieldGroup form={form} style={{ marginTop: 16 }} />
              )}
              {(!phone || !address) && (
                <PhoneAddressInfo style={{ marginTop: 16 }} />
              )}
            </>
          )}
          {modalStep === MODAL_STEPS.success && (
            <SuccessScreen name={nameSignature} />
          )}
        </div>
      </Modal.Body>
      {showFooter && (
        <Modal.Footer aphStyle={SchedulingModalSS.sidePadding}>
          {showBackLink && (
            <TextLink
              aphStyle={SchedulingModalSS.backLink}
              onClick={onClickBack}
              variant="black"
            >
              Back
            </TextLink>
          )}
          {category &&
            modalStep === MODAL_STEPS.selectIntroCategory &&
            !shouldShowSubmitFooter && (
              <Button onClick={() => onCategoryClick(category)}>Next</Button>
            )}
          {shouldShowSubmitFooter && (
            <Button
              disabled={shouldDisableSubmit}
              onClick={submitCall}
              loading={
                submitIsLoading ||
                updatePhoneLoading ||
                updateAddressLoading ||
                isAccepting
              }
            >
              {submitButtonLabel}
            </Button>
          )}
          {modalStep === MODAL_STEPS.success && (
            <Button onClick={onSuccessButtonClick}>Got it</Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

SchedulingModal.propTypes = {
  isRescheduling: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  intro: IntroPropType,
  location: PropTypes.string,
  updateMatchingModalState: PropTypes.object,
};

export default SchedulingModal;
