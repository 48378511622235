import React, { useState, useEffect, forwardRef, useRef } from "react";
import {
  Avatar,
  Body,
  styles,
  Header,
  TopNav,
  Row,
  Col,
  CTA,
  Button,
  ArrowLink,
} from "@sweeten/oreo";
import PropTypes from "prop-types";
import { throttle } from "lodash";
import { css, StyleSheet } from "aphrodite/no-important";
import { Fire } from "../components/emojis";
import GcStarRating from "../components/gc_star_rating";
import RespondToMatchCTAs from "../ren/my_contractors/ctas/respond_to_match";
import { trackMixpanelWithCallback, goToPage } from "../utils";
import AllStar from "../components/allstar_badge";
import Verified from "../components/verified_badge";
import PaymentsBadges from "../components/payments/badges";

const ss = StyleSheet.create({
  header: {
    marginTop: 20,
    marginBottom: 10,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginTop: 24,
        marginBottom: 20,
      },
    }),
  },
  stickyHeader: {
    margin: "18px 0px",
  },
  mobileHeader: {
    margin: "16px 0px",
  },
  flexCentered: {
    ...styles.center("vertical"),
  },
  flexStart: {
    display: "flex",
    alignItems: "flex-start",
  },
  flexEnd: {
    display: "flex",
    alignItems: "flex-end",
  },
  desktop: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  mobile: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
      },
    }),
  },
  verified: {
    borderRadius: 24,
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "20px",
    padding: "1px 8px",
    marginRight: 8,
    border: `1px solid ${styles.colors.black}`,
    boxSizing: "border-box",
    "::-webkit-box-sizing": "border-box",
    "::-moz-box-sizing": "border-box",
    backgroundColor: "#F1F1F2",
    ...styles.center("vertical"),
  },
  spaceApart: {
    justifyContent: "space-between",
  },
  largeFont: {
    fontSize: 24,
    lineHeight: "30px",
  },
  sticky: {
    left: 0,
    position: "fixed",
    width: "100%",
    boxShadow: `0 3px 4px 0 ${styles.colors.grey20}`,
    top: TopNav.style.desktop.height,
    backgroundColor: styles.colors.white,
    zIndex: 100,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        top: "auto",
        bottom: 0,
        boxShadow: `0 -6px 20px 0 ${styles.colors.grey20}`,
      },
    }),
  },
  stickyName: {
    ...styles.center("vertical"),
    whiteSpace: "nowrap",
  },
  marginRight: {
    marginRight: 32,
  },
  col: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        width: "100%",
        margin: 0,
      },
    }),
  },
  loggedOut: {
    width: "100%",
  },
  marginTop: {
    marginTop: -8,
  },
});

const CompanyHeader = forwardRef((props, ref) => {
  const {
    canShowInterest,
    currentCompanyLogoUrl,
    currentUser,
    isRenovator,
    recentlyMatchedProjectIntrosCount,
    showLoggedOutCTA,
    company,
  } = props;

  const {
    id,
    name,
    isSweetenAllStar,
    matchWithCurrentUser,
    contact: { location },
    serviceAreas,
  } = company || {};
  const { id: introId } = matchWithCurrentUser || {};

  const bannerRef = useRef(null);

  const [isSticky, setIsSticky] = useState(false);

  const setStickyState = throttle(() => {
    let atOrBelowScrollPoint = false;
    if (window.innerWidth > styles.breakpoints.tabletStandard) {
      if (ref.current) {
        atOrBelowScrollPoint =
          window.pageYOffset + TopNav.style.desktop.height >=
          ref.current.offsetTop + ref.current.offsetHeight;
      } else if (bannerRef.current) {
        atOrBelowScrollPoint =
          window.pageYOffset + TopNav.style.desktop.height >=
          bannerRef.current.offsetTop + bannerRef.current.offsetHeight;
      }
    } else {
      const element = document.getElementById("gc_profile_ctas");
      if (element) {
        atOrBelowScrollPoint =
          TopNav.style.mobile.height >= element.getBoundingClientRect().top;
      } else {
        atOrBelowScrollPoint =
          window.pageYOffset >=
          bannerRef.current.offsetTop + bannerRef.current.offsetHeight;
      }
    }

    if (atOrBelowScrollPoint) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, 60);

  useEffect(() => {
    if (ref) {
      window.addEventListener("scroll", setStickyState);
    }

    return () => window.removeEventListener("scroll", setStickyState);
  }, [ref]);

  const onTabClick = path => {
    window.location.hash = path;
  };

  const loggedOutCTAClick = () => {
    trackMixpanelWithCallback("Clicked Get Started CTA", {}, () =>
      goToPage("/journey")
    );
  };

  const isAllStar = isSweetenAllStar && currentUser && isRenovator;
  const statelessServiceAreas = (serviceAreas || []).map(area =>
    area.replace(/, \w{2}$/, "")
  );
  const serviceAreasString = statelessServiceAreas
    ? statelessServiceAreas.join(", ")
    : "";

  return (
    <>
      <Row
        style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 1200 }}
        verticalGap={32}
      >
        <Col width={12} aphStyle={ss.col}>
          <div className={css(ss.header)} ref={bannerRef}>
            <div className={css(ss.desktop)}>
              <div className={css(ss.flexCentered, ss.spaceApart)}>
                <Header
                  tag="h1"
                  style={{
                    fontSize: 36,
                    lineHeight: "50px",
                    ...styles.center("vertical"),
                  }}
                >
                  <div style={{ marginRight: 8 }}>
                    {name} General Contractor in {location.city},{" "}
                    {location.longState}
                  </div>{" "}
                  <Verified justIcon />
                </Header>
                <GcStarRating
                  companyId={id}
                  starColor={styles.colors.orange2}
                  bodyAphStyle={ss.largeFont}
                  iconSize={24}
                />
              </div>
              <Header tag="h2" style={{ fontSize: 32, lineHeight: "45px" }}>
                Serving {serviceAreasString}
              </Header>
              <div
                className={css([ss.flexCentered, ss.spaceApart])}
                style={{ marginTop: 8 }}
              >
                <div
                  className={css(ss.flexCentered)}
                  style={{ flexWrap: "wrap" }}
                >
                  {isAllStar && <AllStar />}
                  <PaymentsBadges companyId={id} />
                </div>
                {recentlyMatchedProjectIntrosCount >= 8 && (
                  <div className={css(ss.flexCentered)}>
                    <div style={{ display: "flex", marginRight: 4 }}>
                      <Fire />
                    </div>{" "}
                    {recentlyMatchedProjectIntrosCount} homeowners are
                    considering this contractor
                  </div>
                )}
              </div>
            </div>
            <div className={css(ss.mobile)}>
              {isRenovator && (
                <ArrowLink
                  variant="back"
                  onClick={() => goToPage("/my-contractors")}
                  style={{ marginBottom: 8 }}
                >
                  Back to my contractors
                </ArrowLink>
              )}
              <Header
                tag="h1"
                aphStyle={ss.flexCentered}
                style={{ fontSize: 24, lineHeight: "35px" }}
              >
                <div style={{ marginRight: 8 }}>
                  {name} General Contractor in {location.city},{" "}
                  {location.longState}
                </div>{" "}
                <Verified justIcon position="left" />
              </Header>
              <Header tag="h2" style={{ fontSize: 18, lineHeight: "28px" }}>
                Serving {serviceAreasString}
              </Header>
              <div
                className={css([ss.flexCentered, ss.spaceApart])}
                style={{ marginTop: 8, marginBottom: 8 }}
              >
                <div
                  className={css(ss.flexCentered)}
                  style={{ flexWrap: "wrap" }}
                >
                  {isAllStar && <AllStar />}
                  <PaymentsBadges companyId={id} />
                </div>
              </div>
              <div>
                <GcStarRating
                  companyId={id}
                  starColor={styles.colors.orange2}
                />
              </div>
              {recentlyMatchedProjectIntrosCount >= 8 && (
                <div className={css(ss.flexStart)}>
                  <div style={{ marginRight: 8 }}>
                    <Fire />
                  </div>{" "}
                  {recentlyMatchedProjectIntrosCount} homeowners are considering
                  this contractor
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {isSticky && (
        <div className={css(ss.sticky)}>
          <Row
            style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 1200 }}
            verticalGap={32}
          >
            <Col width={12} style={{ margin: "0px 16px" }}>
              <div className={css(ss.desktop)}>
                <div
                  className={css(
                    ss.flexCentered,
                    ss.spaceApart,
                    ss.stickyHeader
                  )}
                >
                  <div className={css(ss.flexCentered)}>
                    <CTA
                      onClick={() => onTabClick("overview")}
                      aphStyle={ss.marginRight}
                    >
                      <Body tag="div" variant="bold">
                        Overview
                      </Body>
                    </CTA>
                    <CTA onClick={() => onTabClick("portfolio")}>
                      <Body tag="div" variant="bold">
                        Portfolio
                      </Body>
                    </CTA>
                  </div>
                  <div className={css(ss.flexCentered)}>
                    {showLoggedOutCTA && (
                      <Button
                        label="Start your renovation"
                        aphStyle={[ss.loggedOut]}
                        onClick={loggedOutCTAClick}
                      />
                    )}
                    {canShowInterest && (
                      <RespondToMatchCTAs
                        variant="interested"
                        location="gc_profile"
                        introId={introId}
                        forStickyHeader
                      />
                    )}
                  </div>
                </div>
              </div>
              {(canShowInterest || showLoggedOutCTA) && (
                <div className={css(ss.mobile)}>
                  <div className={css(ss.flexCentered, ss.mobileHeader)}>
                    <Avatar image={currentCompanyLogoUrl} size={48} />
                    <div style={{ marginLeft: 16 }}>
                      <Body tag="div" variant="bold" aphStyle={ss.stickyName}>
                        <div style={{ marginRight: 8 }}>{name}</div>
                        <div style={{ marginTop: 4 }}>
                          <Verified justIcon position="left" />
                        </div>
                      </Body>
                      <div
                        className={css(ss.flexCentered)}
                        style={{ flexWrap: "wrap" }}
                      >
                        {isAllStar && <AllStar />}
                        <PaymentsBadges companyId={id} />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    {showLoggedOutCTA && (
                      <Button
                        label="Start your renovation"
                        aphStyle={[ss.loggedOut]}
                        onClick={loggedOutCTAClick}
                      />
                    )}
                    {canShowInterest && (
                      <RespondToMatchCTAs
                        variant="interested"
                        location="gc_profile"
                        introId={introId}
                        forStickyHeader
                      />
                    )}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
});

CompanyHeader.propTypes = {
  company: PropTypes.object,
  showLoggedOutCTA: PropTypes.bool,
  canShowInterest: PropTypes.bool,
  currentCompanyLogoUrl: PropTypes.string,
  currentUser: PropTypes.object,
  isRenovator: PropTypes.bool,
  recentlyMatchedProjectIntrosCount: PropTypes.number,
};

export default CompanyHeader;
