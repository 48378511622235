import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Body,
  CheckboxWithContent,
  FormError,
  Header,
  Icon,
  Modal,
  TextLink,
  styles,
} from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import { isEmpty } from "lodash";
import { useField, useForm } from "react-final-form-hooks";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import Cookies from "js-cookie";
import {
  goToPage,
  hubspotNotifyProjectUpdate,
  trackMixpanel,
  updateHubspotDeal,
} from "../../../utils";
import LolaAvatarPath from "../../../../assets/images/lola_schedule_call_modal.png";

const scheduleCallOptions = {
  matchWithContractors: "How does Sweeten match me with general contractors?",
  compareContractors: "How do I compare general contractors?",
  prepareForSiteVisit: "How do I prepare for a site visit?",
  expectDuringRenovation: "What can I expect during my renovation?",
  projectInvestment: "How does Sweeten protect my investment?",
  anotherQuestion: "I have another question",
};

const UPDATE_PROJECT = gql`
  mutation updateProject($id: ID!, $attributes: ProjectUpdateInput!) {
    projectUpdate(id: $id, attributes: $attributes) {
      project {
        id
      }
    }
  }
`;

const formatValues = values =>
  Object.keys(values).map(option => scheduleCallOptions[option]);

const ss = StyleSheet.create({
  arrow: {
    position: "absolute",
    top: 8,
    right: 4,
  },
  buttons: {
    display: "flex",
    marginBottom: 20,
    width: "100%",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        flexFlow: "column-reverse nowrap",
      },
    }),
  },
  checkboxText: {
    fontWeight: 500,
    color: styles.colors.grey40,
  },
  dontShowLink: {
    color: styles.colors.grey40,
    fontWeight: 500,
  },
  footer: {
    flexFlow: "column nowrap",
    alignItems: "flex-start",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        alignItems: "center",
      },
    }),
  },
  maybeLater: {
    marginRight: 10,
    width: "100%",
  },
  scheduleACall: {
    width: "100%",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginBottom: 12,
      },
    }),
  },
});

const onCloseCb = ({ onClose, location, projectId }) => {
  updateHubspotDeal({
    projectId,
    properties: {
      project_needs_cold_call: true,
    },
  });

  if (location === "gc_profile") {
    goToPage("/my-contractors");
  } else {
    onClose();
  }
};

const ScheduleCallModal = ({ onClose, location, projectId }) => {
  useEffect(() => {
    trackMixpanel("Viewed Hype call", {
      "Project ID": projectId,
    });
  }, []);

  const [error, setError] = useState("");
  const [updateProject, { loading: updatingProject }] = useMutation(
    UPDATE_PROJECT
  );
  const { form, handleSubmit } = useForm({
    onSubmit: values => {
      if (isEmpty(values)) {
        setError("Please choose at least one topic");
      } else {
        trackMixpanel("Clicked scheduled on Hype call", {
          "Project ID": projectId,
        });

        updateProject({
          variables: {
            id: projectId,
            attributes: {
              scheduleCallOptions: formatValues(values),
            },
          },
        }).then(() => goToPage("/schedule_intro_call"));
      }
    },
  });

  const matchWithContractors = useField("matchWithContractors", form);
  const compareContactors = useField("compareContractors", form);
  const prepareForSiteVisit = useField("prepareForSiteVisit", form);
  const expectDuringRenovation = useField("expectDuringRenovation", form);
  const projectInvestment = useField("projectInvestment", form);
  const anotherQuestion = useField("anotherQuestion", form);

  return (
    <Modal
      onClose={() => {
        trackMixpanel("Clicked close on Hype call");
        onCloseCb({ onClose, location, projectId });
      }}
    >
      <Modal.Header>
        <Avatar image={LolaAvatarPath} size={80} />
        <Header tag="h6" style={{ marginTop: 14 }}>
          Meet Lola &mdash; a renovation specialist, here to answer your
          questions!
        </Header>
      </Modal.Header>
      <Modal.Body>
        <Body tag="p" style={{ marginTop: 0 }}>
          Select one or more topics to schedule a quick call:
        </Body>
        <CheckboxWithContent
          {...matchWithContractors.input}
          onClick={() =>
            matchWithContractors.input.onChange(
              !matchWithContractors.input.value
            )
          }
          style={{ marginBottom: 12 }}
        >
          <Body aphStyle={ss.checkboxText} tag="span">
            How does Sweeten match me with general contractors?
          </Body>
        </CheckboxWithContent>
        <CheckboxWithContent
          {...compareContactors.input}
          onClick={() =>
            compareContactors.input.onChange(!compareContactors.input.value)
          }
          style={{ marginBottom: 12 }}
        >
          <Body aphStyle={ss.checkboxText} tag="span">
            How do I compare general contractors?
          </Body>
        </CheckboxWithContent>
        <CheckboxWithContent
          {...prepareForSiteVisit.input}
          onClick={() =>
            prepareForSiteVisit.input.onChange(!prepareForSiteVisit.input.value)
          }
          style={{ marginBottom: 12 }}
        >
          <Body aphStyle={ss.checkboxText} tag="span">
            How do I prepare for a site visit?
          </Body>
        </CheckboxWithContent>
        <CheckboxWithContent
          {...expectDuringRenovation.input}
          onClick={() =>
            expectDuringRenovation.input.onChange(
              !expectDuringRenovation.input.value
            )
          }
          style={{ marginBottom: 12 }}
        >
          <Body aphStyle={ss.checkboxText} tag="span">
            What can I expect during my renovation?
          </Body>
        </CheckboxWithContent>
        <CheckboxWithContent
          {...projectInvestment.input}
          onClick={() =>
            projectInvestment.input.onChange(!projectInvestment.input.value)
          }
          style={{ marginBottom: 12 }}
        >
          <Body aphStyle={ss.checkboxText} tag="span">
            How does Sweeten protect my investment?
          </Body>
        </CheckboxWithContent>
        <CheckboxWithContent
          {...anotherQuestion.input}
          onClick={() =>
            anotherQuestion.input.onChange(!anotherQuestion.input.value)
          }
          style={{ marginBottom: 12 }}
        >
          <Body aphStyle={ss.checkboxText} tag="span">
            I have another question
          </Body>
        </CheckboxWithContent>
      </Modal.Body>
      <Modal.Footer aphStyle={ss.footer}>
        <div className={css(ss.buttons)}>
          <Button
            aphStyle={ss.maybeLater}
            variant="outlineDark"
            onClick={() => {
              trackMixpanel("Clicked Maybe Later on Hype call", {
                "Project ID": projectId,
              });
              onCloseCb({ onClose, location, projectId });
            }}
          >
            Maybe later
          </Button>
          <Button
            aphStyle={ss.scheduleACall}
            onClick={handleSubmit}
            loading={updatingProject}
          >
            Schedule a call
            <div className={css(ss.arrow)}>
              <Icon name="chevron-right" color={styles.colors.white} />
            </div>
          </Button>
        </div>
        {error && <FormError style={{ marginBottom: 22 }}>{error}</FormError>}
        <TextLink
          aphStyle={ss.dontShowLink}
          onClick={() => {
            trackMixpanel("Clicked Don't show again on Hype call", {
              "Project ID": projectId,
            });
            Cookies.set("dont-show-schedule-call", "true");

            hubspotNotifyProjectUpdate({
              projectId,
              eventName: "match-hype-dont-show-REN",
            });

            onCloseCb({ onClose, location, projectId });
          }}
        >
          Don&apos;t show me this again
        </TextLink>
      </Modal.Footer>
    </Modal>
  );
};

ScheduleCallModal.propTypes = {
  experimentData: PropTypes.object,
  onClose: PropTypes.func,
  location: PropTypes.string,
  projectId: PropTypes.string,
};

export default ScheduleCallModal;
