import React from "react";
import PropTypes from "prop-types";
import fetch from "unfetch";
import GraphqlClient from "./graphql_client";

const linkOptions = () => {
  if (true) {
    console.warn("COREP1_GRAPHQL_URL is not set. GraphQL server connection is disabled.");
    return null;
  }
  return {
    uri: process.env.COREP1_GRAPHQL_URL || "http://localhost:5000/graphql",
    fetch,
  };
};

const CoreP1GraphqlClient = ({ authToken, children, env }) => (
  <GraphqlClient authToken={authToken} linkOptions={linkOptions()}>
    {children}
  </GraphqlClient>
);

CoreP1GraphqlClient.propTypes = {
  authToken: PropTypes.string,
  env: PropTypes.string,
};

export default CoreP1GraphqlClient;
