/* eslint-disable max-len */
import React from "react";
import { css, StyleSheet } from "aphrodite";
import PropTypes from "prop-types";
import kitchenImage from "../../assets/images/blog_link/kitchen.jpg";
import bathroomImage from "../../assets/images/blog_link/bathroom.jpg";
import generalImage from "../../assets/images/blog_link/general.jpg";

const ss = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const BlogLink = ({ project }) => {
  let url =
    "https://sweeten.com/process-and-planning/the-ultimate-guide-to-hiring-a-general-contractor-for-your-remodel/";
  let image = generalImage;
  let title =
    "Check out Sweeten's Ultimate Guide to Hiring a General Contractor";

  if (project.rooms.length === 1) {
    const room = project.rooms[0].roomTypeConstant;
    if (room === "bathroom") {
      title = "Check out Sweeten's Bathroom Remodeling Cost Guide";
      url =
        "https://sweeten.com/renovation-cost-guides/bathroom-remodeling-costs-national-edition/";
      image = bathroomImage;
    } else if (room === "kitchen") {
      title = "Check out Sweeten's Kitchen Remodeling Cost Guide";
      url =
        "https://sweeten.com/renovation-cost-guides/kitchen-remodeling-cost-guide-city-national-edition/";
      image = kitchenImage;
    }
  }

  return (
    <div className={css(ss.container)}>
      <div>
        <a href={url}>
          <img src={image} alt={title} width={332} />
        </a>
      </div>
      <div>
        <a href={url}>{title}</a>
      </div>
    </div>
  );
};

BlogLink.propTypes = {
  project: PropTypes.shape({
    rooms: PropTypes.array.isRequired,
  }).isRequired,
};

export default BlogLink;
