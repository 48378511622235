import React from "react";
import FireSvgPath from "../../assets/images/fire.svg";

export const PartyPopper = () => (
  <span role="img" aria-label="party_popper">
    🎉
  </span>
);

export const RaisedHands = () => (
  <span role="img" aria-label="raised_hands">
    🙌
  </span>
);

export const HandWave = () => (
  <span role="img" aria-label="hand_wave">
    👋
  </span>
);

export const HandWaves = () => (
  <span
    role="img"
    aria-label="hand_waves"
    style={{ fontSize: 24, lineHeight: "32px" }}
  >
    👋🏻 👋🏼 👋🏽 👋🏾 👋🏿
  </span>
);

export const SmilingFace = () => (
  <span role="img" aria-label="smiling_face">
    😊
  </span>
);

export const Fire = () => <img src={FireSvgPath} alt="fire" />;

export const Money = () => (
  <span
    role="img"
    aria-label="money"
    style={{ fontSize: 48, lineHeight: "68px" }}
  >
    💰
  </span>
);

export const PartyingFace = () => (
  <span
    role="img"
    aria-label="partying-face"
    style={{ fontSize: 48, lineHeight: "56px" }}
  >
    🥳
  </span>
);

export const Sparkle = () => (
  <span role="img" aria-label="sparkle">
    ✨
  </span>
);
