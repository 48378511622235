import React, { useState, useEffect } from "react";
import { styles } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import galleryJpgSweetenPath from "landings/registration_gallery.jpg";
import galleryWebpSweetenPath from "landings/registration_gallery.webp";
import { isWebpSupported } from "../utils";

const { mediaQuery } = styles;
const { desktopStandard } = styles.breakpoints;

const ss = StyleSheet.create({
  galleryImage: {
    display: "none",
    ...mediaQuery({
      minWidth: desktopStandard,
      style: {
        marginLeft: 40,
        height: 800,
        width: 392,
        backgroundSize: "392px 800px",
        display: "block",
      },
    }),
  },
  galleryWebp: {
    backgroundImage: `url(${galleryWebpSweetenPath})`,
  },
  galleryJpg: {
    backgroundImage: `url(${galleryJpgSweetenPath})`,
  },
});

const GalleryImage = () => {
  const [imageClass, setImageClass] = useState(null);
  useEffect(() => {
    isWebpSupported(isSupported => {
      if (isSupported) {
        setImageClass(ss.galleryWebp);
      } else {
        setImageClass(ss.galleryJpg);
      }
    });
  }, []);

  return <div className={css(ss.galleryImage, imageClass)} />;
};

export default GalleryImage;
