import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormLabel, Header, RadioGroup } from "@sweeten/oreo";
import { useField, useForm } from "react-final-form-hooks";
import { required } from "../../../validators";
import StepInfoValue from "../step_info_value";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";
import { hubspotNotifyProjectUpdate } from "../../../utils";
import { ownershipTypeOptions } from "../../../project_show/shared";

const OwnershipType = ({
  isActive,
  subject: project,
  setHandleSubmit,
  update,
}) => {
  const { ownershipType } = project;
  const { form, handleSubmit } = useForm({
    initialValues: { ownershipType },
    onSubmit: values => {
      update(values, {
        customCallback: ({ subject }) => {
          if (subject.ownershipType === "Made an offer") {
            // Triggers a 'journey-message' event. This is based on the hashKey
            // for the 'no ownership' step that displays a message saying they
            // aren't eligible to post their project.
            hubspotNotifyProjectUpdate({
              projectId: project.id,
              eventName: "journey-message",
            });
          }
          return {};
        },
      });
    },
  });
  const field = useField("ownershipType", form, required);
  const error = field.meta.touched && field.meta.error;
  const onChange = val => {
    field.input.onChange(val);
    handleSubmit();
  };

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Ownership Type");
  }, []);

  useEffect(() => {
    if (error) {
      trackViewError("ownership type", field, error);
    }
  }, [error]);

  return (
    <div>
      <Header tag="h5">Do you own the property?</Header>
      <StepInfoValue stepValue="You can add family members or clients later." />
      <RadioGroup error={error} {...field.input} onChange={onChange}>
        <RadioGroup.Radio value="Yes, I own" />
        <FormLabel style={{ marginBottom: 0 }}>
          {ownershipTypeOptions["Yes, I own"]}
        </FormLabel>
        <RadioGroup.Radio value="In contract" />
        <FormLabel style={{ marginBottom: 0 }}>
          {ownershipTypeOptions["In contract"]}
        </FormLabel>
        <RadioGroup.Radio value="Made an offer" />
        <FormLabel style={{ marginBottom: 0 }}>
          {ownershipTypeOptions["Made an offer"]}
        </FormLabel>
        <RadioGroup.Radio
          data-test="represent_owner"
          value="Representing owner"
        />
        <FormLabel style={{ marginBottom: 0 }}>
          {ownershipTypeOptions["Representing owner"]}
        </FormLabel>
      </RadioGroup>
    </div>
  );
};

OwnershipType.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default OwnershipType;
