import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormLabel, Header, RadioGroup } from "@sweeten/oreo";
import { useField, useForm } from "react-final-form-hooks";
import { required } from "../../../validators";
import StepInfoValue from "../step_info_value";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";

const OwnerRelation = ({
  isActive,
  subject: project,
  setHandleSubmit,
  update,
}) => {
  const { ownerRelation } = project;
  const { form, handleSubmit } = useForm({
    initialValues: { ownerRelation },
    onSubmit: update,
  });
  const field = useField("ownerRelation", form, required);
  const error = field.meta.touched && field.meta.error;
  const onChange = val => {
    field.input.onChange(val);
    handleSubmit();
  };

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Owner Relationship");
  }, []);

  useEffect(() => {
    if (error) {
      trackViewError("owner relationship", field, error);
    }
  }, [error]);

  return (
    <div>
      <Header tag="h5">What&apos;s your relationship to the owner?</Header>
      <StepInfoValue stepValue="You'll be able to enter the owner's name later" />
      <RadioGroup {...field.input} error={error} onChange={onChange}>
        <RadioGroup.Radio data-test="architect" value="Architect" />
        <FormLabel style={{ marginBottom: 0 }}>Architect</FormLabel>
        <RadioGroup.Radio value="Interior designer" />
        <FormLabel style={{ marginBottom: 0 }}>Interior designer</FormLabel>
        <RadioGroup.Radio value="Real estate agent" />
        <FormLabel style={{ marginBottom: 0 }}>Real estate agent</FormLabel>
        <RadioGroup.Radio value="Developer" />
        <FormLabel style={{ marginBottom: 0 }}>Developer</FormLabel>
        <RadioGroup.Radio value="Building manager" />
        <FormLabel style={{ marginBottom: 0 }}>Building manager</FormLabel>
        <RadioGroup.Radio value="Relative" />
        <FormLabel style={{ marginBottom: 0 }}>Relative</FormLabel>
        <RadioGroup.Radio value="Engineer" />
        <FormLabel style={{ marginBottom: 0 }}>Engineer</FormLabel>
      </RadioGroup>
    </div>
  );
};

OwnerRelation.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default OwnerRelation;
