import React from "react";
import PropTypes from "prop-types";
import { StyleSheet } from "aphrodite";
import { useForm, useField } from "react-final-form-hooks";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import { get } from "lodash";
import {
  Body,
  Button,
  FormLabel,
  Input,
  Modal,
  TextLink,
  styles,
} from "@sweeten/oreo";
import {
  validateEmail as emailValidator,
  password as passwordValidator,
  required,
  validate,
  name as nameValidator,
} from "../validators";
import {
  goToPage,
  loginWithToken,
  trackMixpanel,
  trackMixpanelWithCallback,
} from "../utils";

const { breakpoints, center, colors } = styles;

/* ======= Styles ======= */

const ss = StyleSheet.create({
  center: { ...center() },
  modalContainer: {
    ...center(),
    width: 632,
    borderRadius: 16,
  },
  textLink: {
    fontSize: 12,
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: { fontSize: 14 },
    }),
  },
});

/* ======= Mutation ======= */

const CREATE_USER = gql`
  mutation createUser($attributes: UserCreateInput!) {
    userCreate(attributes: $attributes, shouldPostProject: false) {
      user {
        id
        firstName
        email
        loginToken
      }
    }
  }
`;

/* ======= Mixpanel ======= */

const trackClosedFinishLater = () => {
  trackMixpanel("Clicked Close Finish Later");
};

const trackSignedUpFinishLater = callback => {
  trackMixpanelWithCallback("Clicked Signup Finish Later", {}, callback);
};

const FinishLaterModal = ({ client, closeModal, projectId }) => {
  const [createUser, { loading: mutationLoading }] = useMutation(CREATE_USER);

  const { form, handleSubmit } = useForm({
    initialValues: {
      defaultProjectId: projectId,
    },
    onSubmit: async values => {
      const { data: mutationData } = await createUser({
        variables: { attributes: values },
      });
      const token = get(mutationData, "userCreate.user.loginToken");
      await loginWithToken(token);

      trackSignedUpFinishLater(() => {
        goToPage("/overview");
        closeModal();
      });
    },
  });

  const firstName = useField("firstName", form, val => {
    const validators = [required, nameValidator];
    return validate(validators, val);
  });
  const email = useField("email", form, val => emailValidator(val, client));
  const password = useField("password", form, val =>
    validate([passwordValidator, required], val)
  );

  return (
    <Modal
      aphStyle={ss.modalContainer}
      onClose={() => {
        trackClosedFinishLater();
        closeModal();
      }}
    >
      <Modal.Header>Save your renovation details!</Modal.Header>
      <Modal.Body style={{ padding: "0 32px" }}>
        <Body
          variant="caption"
          aphStyle={ss.center}
          style={{ marginBottom: 24 }}
        >
          When you come back, we&apos;ll be ready to continue.
        </Body>
        <FormLabel>First Name</FormLabel>
        <Input
          {...firstName.input}
          error={firstName.meta.touched && firstName.meta.error}
          placeholder="First Name"
          style={{ marginBottom: 24 }}
        />
        <FormLabel>Email</FormLabel>
        <Input
          {...email.input}
          error={email.meta.touched && email.meta.error}
          placeholder="example@gmail.com"
          style={{ marginBottom: 24 }}
        />
        <FormLabel>Create a password</FormLabel>
        <Input
          {...password.input}
          error={password.meta.touched && password.meta.error}
          placeholder="Min. 8 characters"
          type="password"
          style={{ marginBottom: 32 }}
        />
        <Button
          loading={mutationLoading}
          onClick={handleSubmit}
          style={{ width: "100%", marginBottom: 8 }}
        >
          Sign Up
        </Button>
        <Body tag="p" style={{ textAlign: "center" }}>
          Already have an account? <TextLink href="/login">Sign In</TextLink>
        </Body>
        <Body tag="span" variant="caption" style={{ color: colors.grey40 }}>
          By clicking, you agree to our{" "}
          <TextLink
            href="/terms-of-use"
            target="_blank"
            rel="noopener referrer"
            aphStyle={ss.textLink}
          >
            Terms of Service
          </TextLink>{" "}
          and{" "}
          <TextLink
            href="/privacy-policy"
            target="_blank"
            rel="noopener referrer"
            aphStyle={ss.textLink}
          >
            Privacy Policy.
          </TextLink>
        </Body>
      </Modal.Body>
    </Modal>
  );
};

FinishLaterModal.propTypes = {
  client: PropTypes.object,
  closeModal: PropTypes.func,
  projectId: PropTypes.string,
};

export default withApollo(FinishLaterModal);
