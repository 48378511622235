import React from "react";
import PropTypes from "prop-types";
import { StyleSheet } from "aphrodite";
import { Body, styles } from "@sweeten/oreo";
import FormDropdowns from "./form_dropdowns";

const ss = StyleSheet.create({
  addRoomLink: {
    padding: "0 24px",
    ...styles.center("vertical"),
  },
  savingText: {
    float: "right",
    marginBottom: 0,
    padding: "16px 24px",
  },
});

const DesktopForm = ({
  form,
  handleAddRoomSubmit,
  mutationLoading,
  roomSuggestions,
  roomTypes,
  isBackfill,
  isPortfolio,
  withSuggestions,
}) => {
  return (
    <>
      <Body tag="p" style={{ margin: 0 }}>
        Select an area and its approximate size
      </Body>
      <FormDropdowns
        form={form}
        handleAddRoomSubmit={handleAddRoomSubmit}
        isDesktop
        roomTypes={roomTypes}
        isBackfill={isBackfill}
        isPortfolio={isPortfolio}
        roomSuggestions={roomSuggestions}
        withSuggestions={withSuggestions}
      />
      {mutationLoading && (
        <Body tag="p" aphStyle={ss.savingText}>
          Saving...
        </Body>
      )}
    </>
  );
};

DesktopForm.propTypes = {
  form: PropTypes.object,
  handleAddRoomSubmit: PropTypes.func,
  mutationLoading: PropTypes.bool,
  roomSuggestions: PropTypes.array,
  roomTypes: PropTypes.array,
  isBackfill: PropTypes.bool,
  isPortfolio: PropTypes.bool,
  withSuggestions: PropTypes.bool,
};

export default DesktopForm;
