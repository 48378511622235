import { Body, Input } from "@sweeten/oreo";
import PropTypes from "prop-types";
import React from "react";
import { budgetOnChange } from "../../../ren/project_posting/steps/budget";

const BudgetFeedback = ({ expEstimatedCostMaxField }) => {
  return (
    <>
      <Body>
        What’s a more reasonable budget range? We can ask if the client is
        willing to increase.
      </Body>
      <Input
        autofocus
        pattern="[0-9]*"
        placeholder="Budget Max"
        {...expEstimatedCostMaxField.input}
        onChange={val => budgetOnChange(expEstimatedCostMaxField, val)}
      />
    </>
  );
};

BudgetFeedback.propTypes = {
  expEstimatedCostMaxField: PropTypes.object,
};

export default BudgetFeedback;
