import { gql } from "apollo-boost";

/* ======= Fragments ======= */

export const PROJECT_FRAGMENT = gql`
  fragment ProjectFields on Project {
    id
    isAngiLead
    location {
      address
      address2
      city
      zip
      county
    }
    desiredStartDate
    isInServiceArea
    propertyType
    ownershipType
    ownerRelation
    ownershipClosingDate
    budget {
      budgetMin: min
      budgetMax: max
    }
    budgetEstimate {
      budgetEstimateMin: min
      budgetEstimateMax: max
    }
    isEstimateHelpful
    additionalService
    umbrellaInsurance
    rooms {
      id
    }
    roomScope
    useType
    user {
      id
      email
      firstName
      lastName
      loginToken
      phone
    }
    additionalInfo
    workingWithArchitectDesigner
    squareFootage
    postedAt
    intention
    formCompletedAt
    lastCompletedStep
    finishLevel
    budgetFlexibility
    shouldAutoOptInToMatches
    sofiInterest
    siteVisitAvailability
    correctContractorInterest
    comparingEstimatesInterest
    guidancePlanningInterest
    guidanceConstructionInterest
    financialProtectionInterest
    isOakProject
  }
`;

/* ======= Queries ======= */

export const GET_PROJECT_AND_EXPERIMENT_USER = gql`
  query getProjectForPostingForm($id: ID!) {
    project(id: $id) {
      ...ProjectFields
    }
    additionalServicesCopyExperimentUser: experimentUser(
      name: "project_posting-additional_services-copy"
    ) {
      id
      variant
    }
    siteVisitAvailExperimentUser: experimentUser(
      name: "journey_site_visit_avail"
    ) {
      id
      variant
    }
  }
  ${PROJECT_FRAGMENT}
`;

/* ======= Mutations ======= */

export const PROJECT_UPDATE = gql`
  mutation projectUpdatePrime($id: ID!, $attributes: ProjectUpdateInput!) {
    updateSubject: projectUpdate(id: $id, attributes: $attributes) {
      subject: project {
        ...ProjectFields
      }
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const USER_UPDATE = gql`
  mutation userCreate($attributes: UserCreateInput!) {
    updateSubject: userCreate(
      attributes: $attributes
      shouldPostProject: false
    ) {
      subject: project {
        ...ProjectFields
      }
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const PROJECT_POST = gql`
  mutation postProject($id: ID!) {
    projectPost(id: $id) {
      project {
        id
      }
    }
  }
`;

export const GENERATE_EXPERIMENT_VARIANT = gql`
  mutation userGenerateExperimentVariant($name: String!, $testGroup: String) {
    userGenerateExperimentVariant(name: $name, testGroup: $testGroup) {
      experimentUser {
        id
        variant
      }
    }
  }
`;
