import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { styles, FormError, Loader } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import { useForm } from "react-final-form-hooks";
import DesktopForm from "./desktop_form";
import RoomList from "./room_list";
import { trackMixpanel } from "../../utils";

const { breakpoints } = styles;

const GET_PROJECT_ROOMS = gql`
  query getProjectRooms($id: ID!) {
    project(id: $id) {
      id
      isPortfolio
      rooms {
        id
        roomTypeConstant
        squareFootageBucket
        squareFootageMin
        squareFootageMax
        cost
      }
      roomSuggestions {
        id
        constant
        displayName
      }
    }
    roomTypes {
      id
      constant
      displayName
      minSqFootageSmall
      maxSqFootageSmall
      minSqFootageMedium
      maxSqFootageMedium
      minSqFootageLarge
      maxSqFootageLarge
      useType
    }
  }
`;

const ADD_PROJECT_ROOM = gql`
  mutation addProjectRoom(
    $projectId: ID!
    $attributes: ProjectRoomCreateInput!
    $isBackfill: Boolean
  ) {
    projectRoomCreate(
      projectId: $projectId
      attributes: $attributes
      isBackfill: $isBackfill
    ) {
      project {
        id
        budgetEstimate {
          min
          max
        }
        rooms {
          id
          roomTypeConstant
          squareFootageBucket
          squareFootageMin
          squareFootageMax
          cost
        }
      }
      projectRoom {
        id
        roomTypeConstant
        squareFootageBucket
      }
    }
  }
`;

const ss = StyleSheet.create({
  desktop: {
    position: "relative",
    marginBottom: 24,
    width: "100%",
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: {
        marginBottom: 40,
      },
    }),
  },
  loader: {
    height: 300,
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: {
        height: 100,
      },
    }),
  },
});

const AddRoomComp = ({
  data,
  error,
  projectType,
  isBackfill,
  setLoadingState,
  withSuggestions,
}) => {
  const { project, roomTypes } = data || {};
  const { id, rooms, isPortfolio, roomSuggestions } = project || {};

  const [createProjectRoom, { loading: mutationLoading }] = useMutation(
    ADD_PROJECT_ROOM
  );

  const { form, handleSubmit } = useForm({
    onSubmit: values => {
      if (setLoadingState) {
        setLoadingState(true);
      }
      createProjectRoom({
        variables: {
          projectId: id,
          attributes: values,
          isBackfill,
        },
      }).then(() => {
        form.reset();
        if (setLoadingState) {
          setLoadingState(false);
        }
      });
    },
  });

  const residentialRoomTypes = roomTypes.filter(
    roomType => roomType.useType !== "commercial"
  );

  return (
    <>
      <div className={css(ss.desktop)}>
        <DesktopForm
          form={form}
          handleAddRoomSubmit={handleSubmit}
          mutationLoading={mutationLoading}
          roomTypes={residentialRoomTypes}
          isBackfill={isBackfill}
          isPortfolio={isPortfolio}
          roomSuggestions={roomSuggestions}
          withSuggestions={withSuggestions}
        />
        {error && rooms.length < 1 && <FormError>{error}</FormError>}
      </div>
      <RoomList
        rooms={rooms}
        roomTypes={residentialRoomTypes}
        projectType={projectType}
        isBackfill={isBackfill}
      />
    </>
  );
};

const ProjectRoomsLoader = () => <Loader.Quadrangle aphStyle={ss.loader} />;

const ProjectRooms = ({
  id,
  error,
  projectType,
  isBackfill,
  loadingVariant,
  setLoadingState,
  withSuggestions,
}) => {
  const { data, loading } = useQuery(GET_PROJECT_ROOMS, {
    variables: {
      id,
    },
  });

  return (
    <div>
      {loading || loadingVariant ? (
        <ProjectRoomsLoader />
      ) : (
        <AddRoomComp
          data={data}
          error={error}
          projectType={projectType}
          isBackfill={isBackfill}
          setLoadingState={setLoadingState}
          withSuggestions={withSuggestions}
        />
      )}
    </div>
  );
};

AddRoomComp.propTypes = {
  data: PropTypes.object,
  error: PropTypes.string,
  projectType: PropTypes.oneOf(["portfolio", "project"]),
  isBackfill: PropTypes.bool,
  setLoadingState: PropTypes.func,
  withSuggestions: PropTypes.bool,
};

ProjectRooms.propTypes = {
  id: PropTypes.string,
  error: PropTypes.string,
  projectType: PropTypes.oneOf(["portfolio", "project"]),
  isBackfill: PropTypes.bool,
  loadingVariant: PropTypes.bool,
  setLoadingState: PropTypes.func,
  withSuggestions: PropTypes.bool,
};

ProjectRooms.defaultProps = {
  projectType: "project",
};

export default ProjectRooms;
