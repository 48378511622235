import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Body,
  Header,
  Icon,
  Modal,
  styles,
  TextLink,
} from "@sweeten/oreo";
import { gql } from "apollo-boost";
import { useMutation } from "react-apollo";
import { StyleSheet, css } from "aphrodite";
import { AppDispatch } from "../../../components/app_provider";

const ss = StyleSheet.create({
  card: {
    backgroundColor: styles.colors.blueLight,
    display: "flex",
    marginTop: 16,
    padding: 12,
  },
  unlockContainer: {
    display: "inline-flex",
    borderRadius: "50%",
    border: `6px solid ${styles.colors.green4}`,
    padding: 16,
    marginBottom: 16,
  },
});

const REN_ACCEPT_MATCH = gql`
  mutation renAcceptMatch($matchId: ID!, $optInPageName: String) {
    renAcceptMatch(matchId: $matchId, optInPageName: $optInPageName) {
      intro {
        id
        interest {
          ren {
            isInterested
            responseAt
          }
        }
      }
    }
  }
`;

const RevealGCNameModal = ({
  onClose,
  intro,
  introCount,
  updateMatchingModalState,
  location,
  maxIntroCount,
}) => {
  const updateMatchesModalState = useContext(updateMatchingModalState);

  const dispatch = useContext(AppDispatch);

  const [acceptMatch, { loading: mutationLoading }] = useMutation(
    REN_ACCEPT_MATCH,
    {
      variables: {
        matchId: intro.id,
        optInPageName: location,
      },
    }
  );

  const onClickInterested = () => {
    if (introCount >= maxIntroCount) {
      dispatch({
        type: "alert:show",
        payload: {
          variant: "error",
          text: (
            <span>
              Sorry, you’ve reached the limit of {maxIntroCount} contractor
              introductions.{" "}
              <TextLink href="/homeowner_sweeten_intro">
                Questions? Contact us.
              </TextLink>
            </span>
          ),
          shouldStayVisible: true,
        },
      });
    } else if (location === "my_contractors") {
      acceptMatch().then(() =>
        updateMatchesModalState({
          name: "myContractorsAccept",
          options: {
            introId: intro.id,
          },
        })
      );
    } else {
      updateMatchesModalState({
        name: "scheduleIntro",
        options: {
          submitButtonLabel: "Send",
          introId: intro.id,
        },
      });
    }
  };

  const generateModalContent = () => {
    if (location === "my_contractors") {
      return {
        header: "Show company name",
        subheader:
          "We show the company name after the contractor reviews your project.",
        showTip: false,
      };
    }
    return {
      header: "Show company name",
      subheader: "To see company name and contact information",
      showTip: true,
    };
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Header>
        <div className={css(ss.unlockContainer)}>
          <Icon name="unlock" color={styles.colors.greenSuccess} size={36} />
        </div>
        <Header tag="h5">{generateModalContent().header}</Header>
        {generateModalContent().showTip && (
          <Body tag="div" style={{ marginTop: 12 }}>
            We show the company name after both parties agree to discuss the
            renovation project.
          </Body>
        )}
      </Modal.Header>
      <Modal.Body>
        <Body tag="p" variant="bold">
          {generateModalContent().subheader}
        </Body>
        <Body tag="p">
          1. Select “I’m interested” to let them know you’d like to chat
        </Body>
        <Body tag="p">
          2. If they’re interested in your project, we’ll email you their name
          and contact details
        </Body>
      </Modal.Body>
      <Modal.Footer aphStyle={ss.footer}>
        <TextLink onClick={onClose} style={{ marginRight: 32 }} variant="black">
          No thanks
        </TextLink>
        <Button onClick={onClickInterested} loading={mutationLoading}>
          I&apos;m interested
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RevealGCNameModal.propTypes = {
  onClose: PropTypes.func,
  intro: PropTypes.object,
  updateMatchingModalState: PropTypes.func,
  introCount: PropTypes.number,
  location: PropTypes.string,
  maxIntroCount: PropTypes.number,
};

export default RevealGCNameModal;
