import React from "react";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import CoreP1GraphqlClient from "../components/apollo/core_p1_graphql_client";
import { formatCountText } from "../utils";
import "../core_p1_styles/headers.scss";

const PROJECT_SCOPE_OF_WORK = gql`
  query projectScopeOfWork($projectId: ID!) {
    projectRenovationSections(projectId: $projectId) {
      id
      customName
      items {
        noun {
          id
          name
        }
        verb {
          id
          name
        }
      }
      space {
        id
        name
      }
      level {
        id
        name
      }
    }
  }
`;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
}

const SectionItems = ({ items }) => {
  return (
    <div className="mb-40">
      {items.map(item => {
        const verbName = item.verb.name.replace(/[{()}]/g, "");
        const nounName = item.noun.name.replace(/[{()}]/g, "");
        return (
          <div className="mt-16">
            {capitalizeFirstLetter(`${verbName} ${nounName}`)}
          </div>
        );
      })}
    </div>
  );
};

const Section = ({ section }) => (
  <>
    <div className="scope-of-work__header mb-24">
      <div className="mb-24 flex-row-nowrap flex-justify-space-between">
        <div className="flex-row-nowrap flex-align-center">
          <div className="space-header__title space-header__title--no-border">
            {section.customName}
          </div>
        </div>
        <div className="scope-of-work__item-count">
          {formatCountText({ count: section.items.length, name: "item" })}
        </div>
      </div>
    </div>
    <SectionItems items={section.items} />
  </>
);

const CoreScopeOfWorkContent = ({ data, loading }) => {
  if (!loading && data.projectRenovationSections.length) {
    return (
      <div className="smart-scope">
        {data.projectRenovationSections.map(section => {
          return <Section section={section} />;
        })}
      </div>
    );
  }

  return null;
};

const CoreScopeOfWorkComponent = props => {
  return (
    <Query
      query={PROJECT_SCOPE_OF_WORK}
      variables={{ projectId: props.projectId }}
    >
      {result =>
        <CoreScopeOfWorkContent data={result.data} loading={result.loading} />
      }
    </Query>
  );
};

const CoreScopeOfWork = props => (
  <CoreP1GraphqlClient authToken={props.authToken} env={props.env}>
    <CoreScopeOfWorkComponent {...props} />
  </CoreP1GraphqlClient>
);

export default CoreScopeOfWork;
