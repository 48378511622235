import React from "react";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite";
import { Body, styles } from "@sweeten/oreo";
import lightbulbSvgPath from "new-project-posting/lightbulb.png";

const ss = StyleSheet.create({
  body: { color: styles.colors.grey40 },
  outerContainer: { ...styles.center("vertical"), margin: "16px 0px 24px" },
});

const StepInfoValue = ({ stepValue, aphStyle }) => {
  return (
    <div className={css(ss.outerContainer, aphStyle)}>
      <img src={lightbulbSvgPath} alt="step value" style={{ marginRight: 8 }} />
      <Body tag="div" variant="large" aphStyle={ss.body}>
        {stepValue}
      </Body>
    </div>
  );
};

StepInfoValue.propTypes = {
  stepValue: PropTypes.string,
  aphStyle: PropTypes.object,
};

export default StepInfoValue;
