import React from "react";
import PropTypes from "prop-types";
import {
  styles,
  Modal,
  RadioGroup,
  FormLabel,
  Header,
  Body,
  Button,
  TextLink,
} from "@sweeten/oreo";
import { StyleSheet } from "aphrodite";
import { useField, useForm } from "react-final-form-hooks";
import { includes } from "lodash";
import { parse } from "date-fns";
import { goToPage, trackMixpanelWithCallback } from "../../../utils";
import BudgetFeedback from "./budget_feedback";
import OtherFeedback from "./other_feedback";
import DistanceFeedback from "./distance_feedback";
import BusyFeedback from "./busy_feedback";
import { UserInfoPropType } from "../../../ren/my_contractors/shared";
import { futureDate } from "../../../validators";

const ss = StyleSheet.create({
  modalContainer: {
    width: 572,
    height: 550,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        height: "auto",
      },
    }),
  },
  neverMind: {
    color: styles.colors.grey50,
    fontSize: 16,
    paddingRight: 32,
  },
});

const FeedbackRadioGroup = ({ reasonField }) => {
  return (
    <RadioGroup numCols={{ desktopLarge: 1 }} {...reasonField.input}>
      <RadioGroup.Radio value="budget" />
      <FormLabel style={{ marginBottom: 0 }}>Budget low for scope</FormLabel>
      <RadioGroup.Radio value="distance" />
      <FormLabel style={{ marginBottom: 0 }}>Too far away</FormLabel>
      <RadioGroup.Radio value="busy" />
      <FormLabel style={{ marginBottom: 0 }}>
        Busy with other projects
      </FormLabel>
      <RadioGroup.Radio value="expertise" />
      <FormLabel style={{ marginBottom: 0 }}>Not my expertise</FormLabel>
      <RadioGroup.Radio value="information" />
      <FormLabel style={{ marginBottom: 0 }}>
        Not enough information about project scope
      </FormLabel>
      <RadioGroup.Radio value="other" />
      <FormLabel style={{ marginBottom: 0 }}>Other</FormLabel>
    </RadioGroup>
  );
};

FeedbackRadioGroup.propTypes = {
  reasonField: PropTypes.object,
};

const ExpFeedbackResponse = ({
  id,
  onClose,
  respondToMatch,
  loading,
  userInfo,
}) => {
  const { form, handleSubmit } = useForm({
    initialValues: {
      maxTravelDistanceInMiles: userInfo.maxTravelDistanceInMiles,
    },
    onSubmit: values =>
      trackMixpanelWithCallback(
        "Clicked Decline - Reject REN",
        { reason: values.reason },
        () => {
          const variables = {
            id,
            isAccepting: false,
            feedbackResponse: {
              reason: values.reason,
              reasonOther: values.reasonOther,
            },
            company: {},
          };
          if (values.reason === "budget") {
            variables.feedbackResponse.expEstimatedCostMax =
              values.expEstimatedCostMax;
          }
          if (values.reason === "distance" && values.maxTravelDistanceInMiles) {
            const travelDistanceInt = parseInt(
              values.maxTravelDistanceInMiles,
              10
            );
            variables.company.maxTravelDistanceInMiles = travelDistanceInt;
            variables.feedbackResponse.maxTravelDistanceInMiles = travelDistanceInt;
          }
          if (values.reason === "busy" && values.paused) {
            if (values.unpauseAt) {
              const unpauseAt = parse(values.unpauseAt, "MM/dd/YYYY");
              variables.company.unpauseAt = unpauseAt;
              variables.feedbackResponse.unpauseAt = unpauseAt;
            }
            variables.company.lastPausedReason = values.pauseReason;
            variables.company.paused = true;
            variables.feedbackResponse.lastPausedReason = values.pauseReason;
            variables.feedbackResponse.paused = true;
          }
          respondToMatch({
            variables,
          }).then(() => goToPage("/dashboard?feedback-success=true"));
        }
      ),
  });
  const reasonField = useField("reason", form);
  const expEstimatedCostMaxField = useField("expEstimatedCostMax", form);
  const reasonOtherField = useField("reasonOther", form);
  const pausedField = useField("paused", form);
  const unpauseAtField = useField("unpauseAt", form, futureDate);
  const pausedFieldOnChange = () => {
    pausedField.input.onChange(!pausedField.input.value);
  };
  const pauseReasonField = useField("pauseReason", form);
  const maxTravelDistanceInMilesField = useField(
    "maxTravelDistanceInMiles",
    form
  );

  return (
    <Modal onClose={onClose} aphStyle={ss.modalContainer} disableOutsideClick>
      <Modal.Header>
        <Header tag="h6">
          Please tell us what you don’t like about this project
        </Header>
        <Body style={{ color: styles.colors.grey40, paddingTop: 10 }}>
          Help us send you projects that are a better fit.
        </Body>
      </Modal.Header>
      <Modal.Body>
        {!reasonField.input.value && (
          <FeedbackRadioGroup reasonField={reasonField} />
        )}
        {reasonField.input.value === "budget" && (
          <BudgetFeedback expEstimatedCostMaxField={expEstimatedCostMaxField} />
        )}
        {reasonField.input.value === "distance" && (
          <DistanceFeedback
            maxDistanceAlreadySet={!!userInfo.maxTravelDistanceInMiles}
            maxTravelDistanceInMilesField={maxTravelDistanceInMilesField}
          />
        )}
        {reasonField.input.value === "busy" && (
          <BusyFeedback
            pausedField={pausedField}
            pausedFieldOnChange={pausedFieldOnChange}
            pauseReasonField={pauseReasonField}
            unpauseAtField={unpauseAtField}
          />
        )}
        {includes(
          ["other", "information", "expertise"],
          reasonField.input.value
        ) && (
          <OtherFeedback
            reason={reasonField.input.value}
            reasonOtherField={reasonOtherField}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {reasonField.input.value ? (
          <TextLink
            onClick={() => form.change("reason", null)}
            aphStyle={ss.neverMind}
          >
            Back
          </TextLink>
        ) : (
          <TextLink onClick={onClose} aphStyle={ss.neverMind}>
            Never Mind
          </TextLink>
        )}
        <Button loading={loading} onClick={handleSubmit}>
          Decline
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ExpFeedbackResponse.propTypes = {
  id: PropTypes.number,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  respondToMatch: PropTypes.func,
  userInfo: UserInfoPropType,
};

export default ExpFeedbackResponse;
