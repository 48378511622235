/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Body,
  Button,
  Col,
  FormLabel,
  Input,
  Modal,
  RadioGroup,
  Row,
  SingleSelectDropdown,
  TabMenu,
  TextLink,
  styles,
} from "@sweeten/oreo";
import { StyleSheet } from "aphrodite";
import { useForm, useField } from "react-final-form-hooks";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { startDateOptions } from "../shared";
import { sameLabelValueOptions } from "../../utils";
import { fieldError } from "../../components/payments/form_utils";
import { required } from "../../validators";
import ProjectRooms from "../../ren/project_rooms";
import {
  BudgetEstimate,
  budgetOnChange,
  determineBudgetMin,
  maxBudgetValidator,
} from "../../ren/project_posting/steps/budget";
import { sqFtOnChange } from "../../ren/project_posting/steps/square_footage";

const UPDATE_PROJECT = gql`
  mutation projectUpdate($id: ID!, $attributes: ProjectUpdateInput!) {
    projectUpdate(id: $id, attributes: $attributes) {
      project {
        id
        budget {
          min
          max
        }
        budgetEstimate {
          min
          max
        }
        desiredStartDate
        location {
          address
          address2
        }
        rooms {
          id
        }
        roomScope
        squareFootage
      }
    }
  }
`;

const ss = StyleSheet.create({
  row: {
    ...styles.center("vertical"),
  },
  tabMenu: {
    marginBottom: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        top: "80px",
      },
    }),
  },
  tab: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 14,
      },
    }),
  },
  cancelLink: {
    marginRight: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginRight: 0,
        marginTop: 16,
      },
    }),
  },
  ctaContainer: {
    paddingRight: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        flexFlow: "column-reverse nowrap",
        marginTop: 0,
      },
    }),
  },
});

const DetailsForm = ({
  closeModal,
  project,
  update,
  setHandleSubmit,
  showSuccessAlert,
  currentUser,
}) => {
  const { id, desiredStartDate, location } = project;
  const { address, address2 } = location;

  const { form, handleSubmit } = useForm({
    initialValues: {
      address,
      address2: address2 || "",
      desiredStartDate,
    },
    onSubmit: values => {
      update({
        variables: {
          id,
          attributes: values,
        },
      }).then(() => {
        closeModal();
        showSuccessAlert();
      });
    },
  });

  const addressField = useField("address", form, required);
  const address2Field = useField("address2", form);
  const desiredStartDateField = useField("desiredStartDate", form, required);

  useEffect(() => setHandleSubmit(() => handleSubmit), []);

  return (
    <>
      <FormLabel>Address</FormLabel>
      <Input
        {...addressField.input}
        disabled={!!project.listedAt && !currentUser.isAdmin}
        error={fieldError(addressField)}
        style={{ marginBottom: 24 }}
      />
      <FormLabel>Apt / Unit #</FormLabel>
      <Input
        {...address2Field.input}
        disabled={!!project.listedAt && !currentUser.isAdmin}
        style={{ marginBottom: 24 }}
      />
      <FormLabel>Desired Start Date</FormLabel>
      <SingleSelectDropdown
        {...desiredStartDateField.input}
        error={fieldError(desiredStartDateField)}
        items={sameLabelValueOptions(startDateOptions)}
        style={{ marginBottom: 24 }}
      />
    </>
  );
};

DetailsForm.propTypes = {
  closeModal: PropTypes.func,
  project: PropTypes.object,
  update: PropTypes.func,
  setHandleSubmit: PropTypes.func,
  showSuccessAlert: PropTypes.func,
  currentUser: PropTypes.func,
};

const squareFootageValidation = (val, allValues) => {
  if (allValues.roomScope === "Entire Home") {
    return required(val);
  }
};

const BudgetAndRoomsForm = ({
  closeModal,
  project,
  update,
  setHandleSubmit,
  showSuccessAlert,
  currentUser,
}) => {
  const {
    id,
    budget,
    budgetEstimate: { budgetEstimateMin, budgetEstimateMax },
    isEstimateHelpful,
    location: { city },
  } = project;
  const { form, handleSubmit } = useForm({
    initialValues: {
      roomScope: project.roomScope,
      squareFootage: project.squareFootage
        ? project.squareFootage.toString()
        : "",
      budgetMin: determineBudgetMin(budget, budgetEstimateMin),
      budgetMax: budget.budgetMax ? budget.budgetMax.toString() : "",
      isEstimateHelpful,
    },
    onSubmit: values =>
      update({
        variables: {
          id,
          attributes: values,
        },
      }).then(() => {
        closeModal();
        showSuccessAlert();
      }),
  });

  const roomScope = useField("roomScope", form, required);
  const squareFootage = useField("squareFootage", form, (val, allValues) =>
    squareFootageValidation(val, allValues)
  );
  const budgetMin = useField("budgetMin", form, required);
  const budgetMax = useField("budgetMax", form, val =>
    maxBudgetValidator(Number(val), Number(budgetMin.input.value))
  );

  useEffect(() => setHandleSubmit(() => handleSubmit), []);

  return (
    <>
      <RadioGroup
        {...roomScope.input}
        disabled={!!project.listedAt && !currentUser.isAdmin}
        error={fieldError(roomScope)}
        numCols={2}
        style={{ marginBottom: 16 }}
      >
        <RadioGroup.Radio value="Entire Home" />
        <FormLabel style={{ marginBottom: 0 }}>Entire Home</FormLabel>
        <RadioGroup.Radio value="Part of your Home" />
        <FormLabel style={{ marginBottom: 0 }}>Part of your Home</FormLabel>
      </RadioGroup>
      {roomScope.input.value === "Entire Home" && (
        <div style={{ marginBottom: 16 }}>
          <FormLabel>
            What&apos;s the approximate size of your home (in square feet)?
          </FormLabel>
          <Input
            {...squareFootage.input}
            error={fieldError(squareFootage)}
            onChange={val => sqFtOnChange(squareFootage, val)}
            pattern="[0-9]*"
            placeholder="Square Feet"
            style={{ marginTop: 8 }}
          />
        </div>
      )}
      <ProjectRooms id={id} />
      <FormLabel style={{ marginTop: 32 }}>Budget</FormLabel>
      <Row verticalGap={0} aphStyle={ss.row}>
        <Col
          width={{ desktopStandard: 5, phoneStandard: 12 }}
          style={{ margin: 0 }}
        >
          <Input
            {...budgetMin.input}
            disabled={!!project.listedAt && !currentUser.isAdmin}
            error={fieldError(budgetMin)}
            beforeElement="$"
            pattern="[0-9]*"
            onChange={val => budgetOnChange(budgetMin, val)}
            placeholder="Minimum Amount"
          />
        </Col>
        <Col
          width={{ desktopStandard: 2, phoneStandard: 12 }}
          style={{ margin: 0 }}
        >
          <Body tag="div" style={{ textAlign: "center", marginTop: 4 }}>
            to
          </Body>
        </Col>
        <Col
          width={{ desktopStandard: 5, phoneStandard: 12 }}
          style={{ margin: 0 }}
        >
          <Input
            {...budgetMax.input}
            disabled={!!project.listedAt && !currentUser.isAdmin}
            error={fieldError(budgetMax)}
            beforeElement="$"
            pattern="[0-9]*"
            onChange={val => budgetOnChange(budgetMax, val)}
            placeholder="Maximum Amount"
          />
        </Col>
      </Row>
      <div style={{ marginTop: 40, padding: "0 4px" }}>
        <BudgetEstimate
          budgetEstimateMin={budgetEstimateMin}
          budgetEstimateMax={budgetEstimateMax}
          city={city}
        />
      </div>
    </>
  );
};

BudgetAndRoomsForm.propTypes = {
  closeModal: PropTypes.func,
  project: PropTypes.object,
  update: PropTypes.func,
  setHandleSubmit: PropTypes.func,
  showSuccessAlert: PropTypes.func,
  currentUser: PropTypes.object,
};

const ProjectOverviewModal = ({
  closeModal,
  project,
  showSuccessAlert,
  currentUser,
}) => {
  const [update, { loading: updatingProject }] = useMutation(UPDATE_PROJECT);
  const [handleSubmit, setHandleSubmit] = useState(null);

  return (
    <Modal
      onClose={closeModal}
      style={{ width: 613, padding: "48px 24px 48px 48px" }}
    >
      <Modal.Header>Project Overview</Modal.Header>
      <Modal.Body style={{ paddingBottom: 24, paddingRight: 24 }}>
        <TabMenu defaultTabId="details" wrapperAphStyle={ss.tabMenu}>
          <TabMenu.Tab id="details" label="Details" aphStyle={ss.tab}>
            <DetailsForm
              closeModal={closeModal}
              project={project}
              update={update}
              setHandleSubmit={setHandleSubmit}
              showSuccessAlert={showSuccessAlert}
              currentUser={currentUser}
            />
          </TabMenu.Tab>
          <TabMenu.Tab
            id="budget_and_rooms"
            label="Budget and Rooms"
            aphStyle={ss.tab}
          >
            <BudgetAndRoomsForm
              closeModal={closeModal}
              project={project}
              update={update}
              setHandleSubmit={setHandleSubmit}
              showSuccessAlert={showSuccessAlert}
              currentUser={currentUser}
            />
          </TabMenu.Tab>
        </TabMenu>
      </Modal.Body>
      <Modal.Footer aphStyle={ss.ctaContainer}>
        <TextLink onClick={closeModal} aphStyle={ss.cancelLink}>
          Cancel
        </TextLink>
        <Button
          loading={updatingProject}
          onClick={handleSubmit}
          style={{ cursor: "pointer" }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ProjectOverviewModal.propTypes = {
  closeModal: PropTypes.func,
  project: PropTypes.object,
  showSuccessAlert: PropTypes.func,
  currentUser: PropTypes.object,
};

export default ProjectOverviewModal;
