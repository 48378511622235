import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { styles } from "@sweeten/oreo";

const ss = StyleSheet.create({
  container: {
    padding: "0 24px",
    ...styles.mediaQuery({
      minWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "flex",
        justifyContent: "center",
      },
    }),
  },
  form: {
    ...styles.mediaQuery({
      minWidth: styles.breakpoints.phoneStandard,
      style: {
        border: styles.border,
        padding: 24,
        width: 560,
      },
    }),
  },
});

const HubspotEmbeddedForm = ({ formId, portalId, targetId }) => {
  const { hbspt } = window;

  useEffect(() => {
    hbspt.forms.create({
      portalId,
      formId,
      target: `#${targetId}`,
    });
  }, []);

  return (
    <div className={css(ss.container)}>
      <div className={css(ss.form)} id={targetId} />
    </div>
  );
};

HubspotEmbeddedForm.propTypes = {
  formId: PropTypes.string,
  portalId: PropTypes.string,
  targetId: PropTypes.string,
};

HubspotEmbeddedForm.defaultProps = {
  targetId: "hubspot-form-target",
};

export default HubspotEmbeddedForm;
