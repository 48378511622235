import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite/no-important";
import { Body, Header, Row, Col } from "@sweeten/oreo";
import GalleryImage from "../gallery_image";

const ss = StyleSheet.create({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    flex: "1 1",
  },
  authFields: {
    margin: "16px 0 0 0",
    textAlign: "center",
  },
  submit: {
    margin: "32px 0 24px 0",
    justifyContent: "flex-end",
    display: "flex",
    flexFlow: "row nowrap",
  },
});

const ForgotPasswordSuccess = ({ envelopeImagePath }) => {
  return (
    <div style={{ display: "flex", flexFlow: "row nowrap" }}>
      <GalleryImage />
      <div className={css(ss.container)}>
        <Row style={{ justifyContent: "center" }}>
          <Col width={5} aphStyle={ss.authFields}>
            <img src={envelopeImagePath} alt="Envelope" />
            <Header tag="h3" style={{ margin: "32px 0" }}>
              Check your email
            </Header>
            <Body tag="div">
              Instructions to reset your password have been emailed to you.
            </Body>
          </Col>
        </Row>
      </div>
    </div>
  );
};

ForgotPasswordSuccess.propTypes = {
  envelopeImagePath: PropTypes.string.isRequired,
};

export default ForgotPasswordSuccess;
