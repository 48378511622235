import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Header,
  Input,
  Row,
  TextLink,
  styles,
} from "@sweeten/oreo";
import "../auth.scss";
import fetch from "unfetch";
import { useForm, useField } from "react-final-form-hooks";
import Cookies from "js-cookie";
import { css, StyleSheet } from "aphrodite";
import { includes } from "lodash";
import SocialAuth from "../social_auth";
import NewFeatureSidebar from "../new_feature_sidebar";
import SecurePayImgPath from "../../../assets/images/secure_pay_login.png";
import ScalesLoginImgPath from "../../../assets/images/scales_login.png";

import {
  goToPage,
  trackMixpanel,
  trackMixpanelWithCallback,
  triggerOnEnter,
} from "../../utils";
import { required } from "../../validators";
import { AppDispatch } from "../../components/app_provider";

const ss = StyleSheet.create({
  container: {
    display: "flex",
    height: "100%",
  },
  mainContent: {
    flex: 1,
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
  error: {
    color: styles.colors.redDark,
    lineHeight: "18px",
    fontSize: 12,
    marginTop: 24,
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 32,
    marginBottom: 24,
  },
  wrongUserLink: {
    color: styles.colors.black,
    borderBottom: `1px dashed ${styles.colors.black}`,
  },
});

const mixpanelPreviousUrlData = projectJourneyCookie =>
  projectJourneyCookie ? { "Previous URL": "/contact-info" } : null;

const login = ({
  values,
  setFormError,
  setIsSubmitting,
  projectJourneyCookie,
}) => {
  setIsSubmitting(true);
  fetch("/new-session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  })
    .then(resp => resp.json())
    .then(respJson => {
      if (respJson.errors) {
        setFormError(respJson.errors[0]);
        trackMixpanel(
          "Viewed Error on Login",
          mixpanelPreviousUrlData(projectJourneyCookie)
        );
        setIsSubmitting(false);
      } else {
        goToPage(respJson.after_sign_in_path);
      }
    });
};

const WrongUserTypeFooter = ({ userType }) => {
  let copy;
  let rightUserType;

  if (userType === "renovator") {
    copy = "Not a renovator? Click here to go to GC login";
    rightUserType = "expert";
  } else {
    copy = "Not a general contractor? Click here to go to renovator login";
    rightUserType = "renovator";
  }

  return (
    <a className={css(ss.wrongUserLink)} href={`/login/${rightUserType}`}>
      {copy}
    </a>
  );
};

WrongUserTypeFooter.propTypes = {
  userType: PropTypes.string.isRequired,
};

const Login = props => {
  const { redirect, userType } = props;
  const [formError, setFormError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useContext(AppDispatch);

  const projectJourneyCookie = Cookies.get("project_journey_data");

  const { form, handleSubmit } = useForm({
    initialValues: { redirect },
    onSubmit: values =>
      login({ values, setFormError, setIsSubmitting, projectJourneyCookie }),
  });

  const email = useField("email", form, required);
  const password = useField("password", form, required);

  const onClickNext = () => {
    trackMixpanelWithCallback(
      "Clicked Login",
      {
        "Login Medium": "Email",
        ...(projectJourneyCookie && {
          "Previous URL": "/contact-info",
        }),
      },
      handleSubmit
    );
  };

  useEffect(() => {
    trackMixpanel("Viewed Login");

    const queryString = window.location.search;

    if (queryString) {
      if (includes(queryString, "password-reset")) {
        dispatch({
          type: "alert:show",
          payload: {
            variant: "success",
            text:
              "Success! Password updated. Please log in to access your account.",
          },
        });
      }
    }

    triggerOnEnter(onClickNext);
  }, []);

  const isGcLogin = window.location.pathname.includes("expert");
  let sidebarProps;

  if (isGcLogin) {
    sidebarProps = {
      checklistItems: [
        "No transaction fees",
        "Speed—funds are available in your account in 1–2 business days",
        `Competition—over 70% of homeowners on our platform want to pay through
          Sweeten. Get a winning advantage by giving clients what they want.`,
        "Efficiency—request payments right from your dashboard. Clients can pay immediately.",
        "Privacy—Sweeten can’t see or store your financial information",
      ],
      subtitle: `Sign up to get hired faster and win more jobs. Accept credit
        cards and electronic payments with no fees.`,
      title: "Get your deposit now! With Sweeten secure pay",
    };
  } else {
    sidebarProps = {
      checklistItems: [
        "You’ll talk one-on-one with a renovation expert who will make sense of the hiring process",
        `You’ll gain inside information about the different Sweeten contractors’ 
        strengths and working styles`,
        `You’ll get answers to any questions about best practices for hiring a general contractor
         and kicking off a successful renovation project`,
        `You’ll come away from the call with clear next steps based on industry standard practices`,
        `It’s completely free`,
      ],
      title: "The Benefits of Sweeten’s Bid Leveling Call",
      subtitle: "Why you’ll love this consultation with Sweeten:",
      type: "bid leveling",
    };
  }

  return (
    <div className={css(ss.container)}>
      <NewFeatureSidebar
        imgPath={isGcLogin ? SecurePayImgPath : ScalesLoginImgPath}
        {...sidebarProps}
      />
      <div className={css(ss.mainContent)}>
        <Row aphStyle={ss.row}>
          <Col
            width={{
              desktopMax: 9,
              desktopLarge: 9,
              tabletStandard: 9,
              tabletSmall: 9,
              phoneStandard: 12,
            }}
          >
            <Header tag="h3" style={{ margin: "32px 0", textAlign: "center" }}>
              Welcome back!
            </Header>
            {userType === "renovator" && <SocialAuth />}
            <div style={{ marginTop: 16 }}>
              <Input
                data-test="login_email"
                error={email.meta.touched && email.meta.error}
                onChange={email.input.onChange}
                placeholder="Email"
                value={email.input.value}
              />
            </div>

            <div style={{ marginTop: 32 }}>
              <Input
                data-test="login_password"
                error={password.meta.touched && password.meta.error}
                onChange={password.input.onChange}
                placeholder="Password"
                value={password.input.value}
                type="password"
              />
            </div>

            <div style={{ marginTop: 24 }}>
              <TextLink
                onClick={() =>
                  trackMixpanelWithCallback(
                    "Clicked Reset Password",
                    mixpanelPreviousUrlData(projectJourneyCookie),
                    goToPage("/password_recovery")
                  )
                }
              >
                Reset Password
              </TextLink>
            </div>

            {formError && <div className={css(ss.error)}>{formError}</div>}

            <div className={css(ss.button)}>
              <Button
                label="Next"
                loading={isSubmitting}
                onClick={onClickNext}
              />
            </div>

            <div
              style={{
                borderTop: `1px solid ${styles.colors.grey20}`,
                marginBottom: 24,
                paddingTop: 8,
              }}
            >
              New to Sweeten?{" "}
              <TextLink href={`/signup/${userType}`}>
                Create an account
              </TextLink>
            </div>
            <WrongUserTypeFooter userType={userType} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

Login.propTypes = {
  redirect: PropTypes.string,
  userType: PropTypes.string.isRequired,
};

export default Login;
