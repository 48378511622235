import React from "react";
import PropTypes from "prop-types";
import { Button } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";

/* =================== Styles =================== */

const ss = StyleSheet.create({
  button: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "24px 0",
  },
});

const FormButton = ({ disabled, handleSubmit, loading, isLastStep }) => {
  return (
    <div className={css(ss.button)}>
      <Button disabled={disabled} loading={loading} onClick={handleSubmit}>
        {isLastStep ? "Submit" : "Next"}
      </Button>
    </div>
  );
};

FormButton.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  isLastStep: PropTypes.bool,
};

export default FormButton;
