import React from "react";
import PropTypes from "prop-types";
import { Body, Button, Modal, TextLink, styles } from "@sweeten/oreo";
import { Textarea } from "@sweeten/domo";
import { StyleSheet } from "aphrodite";
import { useForm, useField } from "react-final-form-hooks";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

const UPDATE_PROJECT = gql`
  mutation projectUpdate($id: ID!, $attributes: ProjectUpdateInput!) {
    projectUpdate(id: $id, attributes: $attributes) {
      project {
        id
        additionalInfo
      }
    }
  }
`;

const ss = StyleSheet.create({
  cancelLink: {
    marginRight: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginRight: 0,
        marginTop: 16,
      },
    }),
  },
  ctaContainer: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        flexFlow: "column-reverse nowrap",
      },
    }),
  },
});

const ProjectDescriptionModal = ({ closeModal, project, showSuccessAlert }) => {
  const { id, additionalInfo, description } = project;
  const [update, { loading: updatingProject }] = useMutation(UPDATE_PROJECT);

  const { form, handleSubmit } = useForm({
    initialValues: {
      additionalInfo: additionalInfo || description || "",
    },
    onSubmit: values =>
      update({
        variables: {
          id,
          attributes: values,
        },
      }).then(() => {
        closeModal();
        showSuccessAlert();
      }),
  });

  const additionalInfoField = useField("additionalInfo", form);

  return (
    <Modal onClose={closeModal} style={{ width: 613, padding: 48 }}>
      <Modal.Header>Project Description</Modal.Header>
      <Modal.Body>
        <Body tag="p" style={{ marginTop: 0 }}>
          Tell us more about your project, including any important
          circumstances. This helps us find the best matches for you.
        </Body>
        <Textarea
          {...additionalInfoField.input}
          widthVariant="large"
          heightVariant="medium"
          placeholder="For example: We've purchased a home and need to gut renovate
          the kitchen, update 2 bathrooms, and re-paint everything before moving in.
          Our target move-in date is in 4 months."
        />
      </Modal.Body>
      <Modal.Footer aphStyle={ss.ctaContainer}>
        <TextLink onClick={closeModal} aphStyle={ss.cancelLink}>
          Cancel
        </TextLink>
        <Button
          loading={updatingProject}
          onClick={handleSubmit}
          style={{ cursor: "pointer" }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ProjectDescriptionModal.propTypes = {
  closeModal: PropTypes.func,
  project: PropTypes.object,
  showSuccessAlert: PropTypes.func,
};

export default ProjectDescriptionModal;
