import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import { styles } from "@sweeten/oreo";
import { includes } from "lodash";
import { StyleSheet } from "aphrodite/no-important";

export const ss = StyleSheet.create({
  alignVertical: {
    ...styles.center("vertical"),
  },
  companyName: {
    fontSize: 18,
    lineHeight: 1,
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: styles.colors.black,
  },
  contentContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    marginLeft: 20,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopLarge,
      style: {
        flexFlow: "column",
        marginTop: 16,
        marginLeft: 0,
      },
    }),
  },
  introStep: {
    marginRight: 32,
    display: "flex",
    flexFlow: "column nowrap",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopLarge,
      style: {
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        width: "100%",
        marginRight: 0,
        marginTop: 8,
        marginBottom: 8,
      },
    }),
  },
  nameAndMessageContainer: {
    display: "flex",
    overflow: "hidden",
    flexFlow: "column nowrap",
    marginLeft: 8,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopLarge,
      style: {
        marginLeft: 16,
      },
    }),
  },
  outerContainer: {
    marginBottom: 32,
    width: "100%",
  },
  stepTitle: {
    fontSize: 12,
    color: styles.colors.grey40,
    textTransform: "uppercase",
  },
  badgesAndServices: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    height: 28,
    borderRadius: 4,
    padding: "0px 12px",
    backgroundColor: styles.colors.grey10,
    color: styles.colors.grey50,
    ...styles.center("vertical"),
  },
  badges: {
    fontWeight: 500,
    lineHeight: "17px",
    display: "flex",
    fontSize: 14,
  },
  serviceText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "24px",
    display: "flex",
    marginBottom: 12,
    color: styles.colors.grey40,
  },
  portfolioPhotos: {
    width: "100%",
    marginBottom: 8,
    position: "relative",
  },
  photos: {
    width: 320,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopLarge,
      style: {
        width: "100%",
        maxWidth: "none",
      },
    }),
  },
  pointer: {
    cursor: "pointer",
  },
  actionHeaders: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 24px",
    borderRadius: "0px 0px 8px 8px",
    backgroundColor: "rgba(13,162,245,0.08)",
    flexDirection: "row",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        flexDirection: "column",
        justifyContent: "center",
        padding: 0,
        backgroundColor: "unset",
      },
    }),
  },
  card: {
    padding: 0,
    height: "100%",
    boxSizing: "border-box",
  },
  width: {
    width: "100%",
  },
  nameAndBudget: {
    flexWrap: "wrap",
    ...styles.center("vertical"),
  },
  lockedCompanyName: {
    ...styles.center("vertical"),
    borderRadius: 4,
    padding: "0px 8px",
    marginRight: 8,
    backgroundColor: styles.colors.grey10,
  },
  photosAndInfo: {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopLarge,
      style: {
        display: "block",
        padding: 16,
      },
    }),
  },
  lockedNameAndStar: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopLarge,
      style: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    }),
  },
  height: {
    height: "100%",
    position: "relative",
  },
  starRating: {
    cursor: "pointer",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopLarge,
      style: {
        marginTop: 16,
      },
    }),
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: styles.colors.grey40,
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 8px",
  },
  nameAndNoteContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  verified: {
    borderRadius: 24,
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "20px",
    padding: "1px 8px",
    marginRight: 8,
    border: `1px solid ${styles.colors.black}`,
    boxSizing: "border-box",
    "::-webkit-box-sizing": "border-box",
    "::-moz-box-sizing": "border-box",
    backgroundColor: "#F1F1F2",
    ...styles.center("vertical"),
  },
  flexCentered: {
    ...styles.center("vertical"),
  },
  accolades: {
    margin: "8px 0px",
    flexWrap: "wrap",
  },
  respondContainer: {
    width: "100%",
    ...styles.center("vertical"),
    flexDirection: "column",
  },
  desktopCtas: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  mobile: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
        width: "100%",
        padding: 16,
      },
    }),
  },
  marginTop: {
    marginTop: -8,
  },
  avatar: {
    cursor: "pointer",
  },
  revealedCompanyName: {
    color: styles.colors.grey40,
    marginTop: 4,
  },
  introStepContainer: {
    flexGrow: 2,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        flexFlow: "column nowrap",
        width: "100%",
        marginTop: 16,
        marginLeft: 0,
      },
    }),
  },
  badge: {
    width: 8,
    height: 8,
    backgroundColor: styles.colors.brandPrimary,
    borderRadius: "50%",
    position: "absolute",
    right: 2,
    top: -3,
    ...styles.mediaQuery({
      minWidth: styles.breakpoints.desktopLarge,
      style: {
        right: -3,
      },
    }),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        right: -3,
      },
    }),
  },
  displayNone: { display: "none" },
  colCenter: {
    ...styles.center("horizontal"),
    alignItems: "flex-end",
    flexDirection: "column",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        alignItems: "center",
      },
    }),
  },
  rowStyle: {
    margin: "12px 0px",
    ...styles.center("vertical"),
  },
  button: {
    height: "auto",
    padding: "4px 24px",
    width: 150,
  },
  declineGCContainer: {
    marginLeft: "auto",
    paddingRight: "16px",
  },
  declineGCLink: {
    cursor: "pointer",
    color: styles.colors.red5,
    marginLeft: "auto",
    textDecoration: "underline",
    fontSize: 12,
    lineHeight: "20px",
  },
});

export const mobileColumnBreakpoint = styles.breakpoints.desktopLarge;

export const CompanyPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  maxTravelDistanceInMiles: PropTypes.number,
  name: PropTypes.string.isRequired,
  team: PropTypes.object,
});

export const ProjectPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  awardedAt: PropTypes.string,
});

export const EstimatePropType = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  attachmentUrl: PropTypes.string,
  isSmart: PropTypes.bool.isRequired,
});

export const IntroPropType = PropTypes.shape({
  company: CompanyPropType.isRequired,
  conversation: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  estimate: EstimatePropType,
  expSignedContractAt: PropTypes.string,
  interest: PropTypes.shape({
    ren: PropTypes.object,
    gc: PropTypes.object,
  }),
  introducedAt: PropTypes.string,
  project: ProjectPropType.isRequired,
  renSignedContractAt: PropTypes.string,
  state: PropTypes.string.isRequired,
  visitedAt: PropTypes.string,
});

export const UserInfoPropType = PropTypes.shape({
  isCollaborator: PropTypes.bool,
  phoneNumber: PropTypes.string,
  company: CompanyPropType,
});

const POTENTIAL_STATES = ["matched"];
const AWARDED_STATES = ["ren_partial_awarded", "awarded"];
const INTRODUCED_STATES = [
  "introduced",
  "site_visited",
  "estimated",
  "exp_partial_awarded",
];
const INTRODUCED_STAGES = [
  "double opt-in",
  "estimate",
  "site visit",
  "partial award",
];

export const filterIntros = intros => {
  const empty = {
    awarded: [],
    introduced: [],
    rejected: [],
    closed: [],
    renInterested: [],
    potential: [],
  };

  if (!intros) {
    return empty;
  }

  return intros.reduce((filtered, intro) => {
    const hasRenResponse = intro.interest.ren.responseAt;
    const closedByGc = intro.state === "closed" && !intro.renOptedOut;

    if (includes(AWARDED_STATES, intro.state)) {
      filtered.awarded.push(intro);
    }
    if (intro.state === "closed" && intro.renOptedOut) {
      filtered.closed.push(intro);
    }
    if (
      includes(INTRODUCED_STATES, intro.state) ||
      (closedByGc && includes(INTRODUCED_STAGES, intro.stage))
    ) {
      filtered.introduced.push(intro);
    }
    if (includes(POTENTIAL_STATES, intro.state) && hasRenResponse) {
      filtered.renInterested.push(intro);
    }
    if (includes(POTENTIAL_STATES, intro.state) && !hasRenResponse) {
      filtered.potential.push(intro);
    }
    if (intro.state === "rejected") {
      filtered.rejected.push(intro);
    }
    return filtered;
  }, empty);
};

export const GET_PROJECT_INTROS_FOR_COUNT = gql`
  query getProjectIntrosForCount($id: ID!) {
    project(id: $id) {
      id
      intros {
        state
        interest {
          ren {
            responseAt
          }
        }
      }
    }
  }
`;

export const GET_PORTFOLIO_PHOTOS = gql`
  query getPortfolioPhotos($companyId: ID!) {
    company(id: $companyId) {
      id
      portfolioProjectImages {
        id
        isHidden
        roomTypeConstant
        url {
          large
        }
        isTopImageForRoomType
        confidenceScore
      }
    }
  }
`;

export const existingIntrosCount = intros => {
  const { awarded, introduced, renInterested } = filterIntros(intros);
  return awarded.length + introduced.length + renInterested.length;
};

export const ACTIVE_MATCH_FRAGMENT = gql`
  fragment ActiveMatchFields on ProjectIntroduction {
    id
    company {
      id
      awardedProjects {
        id
      }
      name
      nameSignature
      team {
        owner {
          photoUrl
          firstName
        }
      }
      averageRating
      avgAskResponseTimeMinutes
      isSweetenAllStar
      isMinorityOwned
      isWomanOwned
      sweetieAwards {
        id
        year
      }
      services
      reviews(status: ["received"]) {
        id
        rating
        feedback {
          public
        }
      }
      firmType
      currentCompanyLogoUrl
      minProjectBudget
      recentlyMatchedProjectIntros {
        id
      }
      createdAt
      topCompanyImages {
        id
        imageId
        position
      }
    }
    conversation {
      id
      messages(isUnread: true) {
        id
        sender {
          company {
            id
          }
        }
      }
    }
    estimate {
      id
      amount
      attachmentUrl
      createdAt
      isSmart
      renNote
      estimateOptions
    }
    contract {
      id
      amount
      attachmentUrl
      signatureRequestStatus
      hellosignCompletedDocumentReceived
      usedHellosign
    }
    interest {
      ren {
        isInterested
        responseAt
      }
      gc {
        responseAt
        isInterested
      }
    }
    introducedAt
    renOptedOut
    project {
      id
      awardedAt
      location {
        address
      }
      additionalInfo
      budgetBuckets {
        budget
      }
      scheduledIntroCall
      shouldAutoOptInToMatches
      user {
        id
        dwollaCustomer {
          id
          fundingSource {
            bankName
          }
        }
      }
      dwollaPayments(statuses: [requested]) {
        id
        amount
        company {
          id
          currentCompanyLogoUrl
          name
        }
        project {
          id
        }
      }
    }
    state
    stage
    visitedAt
    doesFinishLevel
    workedInBuilding
    workedWithRooms
    gcRequestingPhotos
    renNoteOnExp
  }
`;
