import React, { Component } from "react";
import PropTypes from "prop-types";
import fetch from "unfetch";
import GoogleLogin from "react-google-login";
import { withApollo } from "react-apollo";
import { startCase } from "lodash";
import { StyleSheet, css } from "aphrodite";
import GoogleIcon from "../../assets/images/icons/multi-colored_google.svg";
import {
  callFunctionOnEnter,
  goToPage,
  trackMixpanel,
  encodeFormData,
} from "../utils";
import { AppDispatch } from "../components/app_provider";

const ss = StyleSheet.create({
  authButton: {
    fontSize: "11px",
  },
  socialIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
    marginRight: 16,
  },
  googleIcon: {
    transform: "translate(-8px)",
  },
});

const providerNameToToken = {
  google: process.env.GOOGLE_API_KEY,
};

class SocialAuth extends Component {
  constructor(props) {
    super(props);
    this.handleGoogleResponse = this.handleGoogleResponse.bind(this);
    this.handleFailure = this.handleFailure.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
    this.trackMixpanelClick = this.trackMixpanelClick.bind(this);
    this.showErrorAlert = this.showErrorAlert.bind(this);
  }

  showErrorAlert(message) {
    const dispatch = this.context;
    dispatch({
      type: "alert:show",
      payload: {
        variant: "error",
        text: message,
      },
    });
  }

  trackMixpanelClick(providerType) {
    const { creatingAccount } = this.props;
    const authIntent = creatingAccount ? "Sign Up" : "Login";

    trackMixpanel("Clicked Social Auth", {
      "Registration Medium": startCase(providerType),
      "Registration User Type": "Renovator",
      "Registration Page Type": "Static",
      "Social Auth Intent": authIntent,
    });
  }

  handleGoogleClick(renderProps) {
    this.trackMixpanelClick("google");
    renderProps.onClick();
  }

  handleGoogleResponse(data) {
    this.handleCallback({ access_token: data.accessToken }, "google");
  }

  handleFailure(data = {}) {
    const { creatingAccount } = this.props;
    const authIntent = creatingAccount ? "Sign Up" : "Login";

    if (
      data.error !== "idpiframe_initialization_failed" &&
      data.error !== "popup_closed_by_user"
    ) {
      let message = "Looks like something went wrong, please try again";
      if (data.reason === "email") {
        message =
          "We need your email to send you updates about your project. " +
          "Please try again and grant us access.";
      }
      this.showErrorAlert(message);
    }

    trackMixpanel("Social Auth Error", {
      "Social Auth Intent": authIntent,
      "Auth Error": data.error,
      "Auth Error Reason": data.reason,
    });
  }

  handleCallback(data, providerName) {
    const { callback } = this.props;
    const newsletterEl = document.getElementById("mailchimp_state");

    // eslint-disable-next-line camelcase
    const mailchimp_state = newsletterEl ? newsletterEl.value : null;
    const body = {
      ...data,
      mailchimp_state,
      is_static: true,
      page_type: "Static",
    };
    fetch(`/auth/${providerName}/callback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encodeFormData(body),
    })
      .then(response => response.json())
      .then(json => {
        if (callback) {
          callback(json);
        } else if (json.after_sign_in_path) {
          goToPage(json.after_sign_in_path);
        } else {
          this.handleFailure(json);
        }
      })
      .catch(() => this.handleFailure());
  }

  render() {
    const { creatingAccount, includeLoginDivider, onClick } = this.props;

    return (
      <>
        <div>
          <div className={css(ss.authButton)} style={{ marginTop: 16 }}>
            <GoogleLogin
              clientId={providerNameToToken.google}
              cookiePolicy="single_host_origin"
              icon={false}
              onFailure={this.handleFailure}
              onSuccess={this.handleGoogleResponse}
              render={renderProps => {
                const googleOnClick = onClick
                  ? () => onClick(renderProps)
                  : () => this.handleGoogleClick(renderProps);
                return (
                  <div
                    className="connect-button connect-button--google flex-row-nowrap"
                    onClick={googleOnClick}
                    onKeyPress={evt => callFunctionOnEnter(evt, googleOnClick)}
                    role="button"
                    tabIndex="0"
                  >
                    <div className={css(ss.socialIcon, ss.googleIcon)}>
                      <img
                        src={GoogleIcon}
                        alt=""
                        style={{ width: 24, height: 24 }}
                      />
                    </div>
                    <div>
                      {creatingAccount
                        ? "Sign up with Google"
                        : "Sign in with Google"}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
        {includeLoginDivider && (
          <div className="login-divider">
            <span className="login-divider__or">OR</span>
            <span className="login-divider__line" />
          </div>
        )}
      </>
    );
  }
}

SocialAuth.propTypes = {
  includeLoginDivider: PropTypes.bool,
  creatingAccount: PropTypes.bool,
  callback: PropTypes.func,
  onClick: PropTypes.func,
};

SocialAuth.defaultProps = {
  includeLoginDivider: true,
};

SocialAuth.contextType = AppDispatch;

export default withApollo(SocialAuth);
