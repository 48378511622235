/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import { Modal, Button, TextLink, Textarea, Header } from "@sweeten/oreo";
import { ACTIVE_MATCH_FRAGMENT, IntroPropType } from "../shared";

const INTRO_UPDATE = gql`
  mutation sendGcDeclineEmail($id: ID!, $messageBody: String!) {
    renSendGcDeclineEmail(id: $id, messageBody: $messageBody) {
      intro {
        ...ActiveMatchFields
      }
    }
  }
  ${ACTIVE_MATCH_FRAGMENT}
`;

const RenDeclineGCModal = props => {
  const { onClose, onSuccessCb, intro, userInfo } = props;
  const greetings = intro.company.team.owner.firstName
    ? `Hi ${intro.company.team.owner.firstName},`
    : "Hi,";
  const signature = userInfo.firstName ? `Regards,\n${userInfo.firstName}` : "";
  const templateNote = `${greetings}\n\nThank you for your time. We have decided to move forward with another contractor.\n\n\n${signature}`;
  const [declineNotes, updateDeclineNotes] = useState(templateNote);
  const [updateIntro, { loading: mutationLoading }] = useMutation(INTRO_UPDATE);
  const onSubmit = () => {
    updateIntro({
      variables: {
        id: intro.id,
        messageBody: declineNotes,
      },
    }).then(() => {
      onSuccessCb();
    });
  };

  return (
    <Modal>
      <Modal.Header>
        <Header tag="h6">
          Will you consider letting {intro.company.name} know why you&apos;re
          not moving forward with them? It will help them improve their
          business.
        </Header>
      </Modal.Header>
      <Modal.Body style={{ overflow: "initial" }}>
        <Textarea
          heightVariant="medium"
          placeholder="Type your response here..."
          value={declineNotes}
          onChange={val => updateDeclineNotes(val)}
        />
      </Modal.Body>
      <Modal.Footer>
        <TextLink
          variant="black"
          style={{ marginRight: 16 }}
          // onClick={messageModalOnClose}
          onClick={onClose}
        >
          Cancel
        </TextLink>
        <Button loading={mutationLoading} onClick={onSubmit}>
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RenDeclineGCModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccessCb: PropTypes.func,
  intro: IntroPropType,
};

export default RenDeclineGCModal;
