import React from "react";
import PropTypes from "prop-types";
import { styles } from "@sweeten/oreo";
import { css, StyleSheet } from "aphrodite/no-important";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import BankSvgPath from "../../../assets/images/payments/my_contractors_bank.svg";
import CardSvgPath from "../../../assets/images/payments/my_contractors_card.svg";

const ss = StyleSheet.create({
  paymentsBadge: {
    border: `1px solid ${styles.colors.black}`,
    whiteSpace: "nowrap",
    borderRadius: 24,
    fontWeight: "bold",
    backgroundColor: "rgba(26,43,68,0.04)",
    fontSize: 12,
    lineHeight: "20px",
    padding: "1px 8px",
    marginRight: 8,
    boxSizing: "border-box",
    "::-webkit-box-sizing": "border-box",
    "::-moz-box-sizing": "border-box",
  },
  flexCentered: {
    ...styles.center("vertical"),
  },
});

const COMPANY_PAYMENT_INFO = gql`
  query companyPaymentInfo($companyId: ID!) {
    company(id: $companyId) {
      id
      stripePayoutsEnabled
      dwollaCustomer {
        id
        hasFundingSource
      }
    }
  }
`;

const Badges = ({ data }) => {
  const { company } = data || {};
  const { stripePayoutsEnabled, dwollaCustomer } = company || {};
  const { hasFundingSource } = dwollaCustomer || {};

  const badgesArr = [];
  if (stripePayoutsEnabled) {
    badgesArr.push({
      icon: <img src={CardSvgPath} alt="card" style={{ marginRight: 4 }} />,
      copy: "Accepts credit card",
    });
  }
  if (hasFundingSource) {
    badgesArr.push({
      icon: <img src={BankSvgPath} alt="bank" style={{ marginRight: 4 }} />,
      copy: "Accepts bank transfer",
    });
  }

  return (
    <>
      {badgesArr.map(content => (
        <div className={css([ss.flexCentered, ss.paymentsBadge])}>
          {content.icon}
          {content.copy}
        </div>
      ))}
    </>
  );
};

Badges.propTypes = {
  data: PropTypes.object,
};

const PaymentsBadges = ({ companyId }) => {
  const { data, loading } = useQuery(COMPANY_PAYMENT_INFO, {
    variables: {
      companyId,
    },
  });

  return loading ? null : <Badges data={data} />;
};

PaymentsBadges.propTypes = {
  companyId: PropTypes.string,
};

export default PaymentsBadges;
