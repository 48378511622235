import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Header,
  Loader,
  TextLink,
  styles,
} from "@sweeten/oreo";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "react-apollo";
import { css, StyleSheet } from "aphrodite";
import { get } from "lodash";
import { goToPage, trackMixpanel, trackMixpanelWithCallback } from "../utils";
import RespondToMatchCTAs from "../ren/my_contractors/ctas/respond_to_match";
import RenMatchingModalProvider from "../ren/my_contractors/modals/matching_modal_provider";
import { UserInfoPropType } from "../ren/my_contractors/shared";
import ExpFeedbackResponse from "./modals/exp_feedback_response/index";
import { RaisedHands } from "./emojis";
import AllStar from "./allstar_badge";
import Verified from "./verified_badge";
import PaymentsBadges from "./payments/badges";

const GET_USER_INFO = gql`
  query getUserInfo($introId: ID!) {
    currentUser {
      id
      email
      enabledForTwilioProxy
      isRenovator
      isExpert
      phone
      company {
        id
        maxTravelDistanceInMiles
      }
      currentProject {
        id
        location {
          address
        }
      }
    }
    projectIntroduction(id: $introId) {
      id
      projectId
      company {
        id
        avgAskResponseTimeMinutes
        currentCompanyLogoUrl
        isSweetenAllStar
        name
      }
    }
  }
`;

export const GC_RESPONSE = gql`
  mutation gcRespond(
    $id: ID!
    $isAccepting: Boolean!
    $feedbackResponse: FeedbackResponseInputType
    $company: CompanyUpdateInput!
  ) {
    gcAskResponse(
      id: $id
      isAccepting: $isAccepting
      feedbackResponse: $feedbackResponse
      company: $company
    ) {
      intro {
        conversation {
          id
        }
        id
        project {
          id
          shortName
        }
        proxyPhoneNumberForGc
      }
    }
  }
`;

const CATEGORY_TO_READABLE_STRING = {
  email: "email",
  phone_call: "phone call",
  phone: "phone call",
  site_visit: "meeting on-site",
};

const ss = StyleSheet.create({
  expMatchButtonsContainer: {
    display: "flex",
    flexFlow: "row nowrap",
  },
  myMatchesArrowLink: {
    position: "absolute",
    top: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        top: 32,
      },
    }),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        position: "relative",
        marginRight: "auto",
        top: 0,
        marginBottom: 8,
      },
    }),
  },
  noThanksCta: {
    marginRight: 8,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        height: "28px",
        fontWeight: 500,
      },
    }),
  },
  interestedCta: {
    width: "100%",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        height: "28px",
        fontWeight: 500,
        width: "auto",
      },
    }),
  },
  messageIcon: {
    marginTop: 4,
    marginRight: 8,
  },
  readReceipt: {
    textAlign: "right",
    color: styles.colors.grey40,
    fontSize: 13,
  },
  flexCentered: {
    ...styles.center("vertical"),
  },
  sweetenAllStar: {
    ...styles.center("vertical"),
    backgroundColor: styles.colors.brandPrimaryHover,
    borderRadius: 24,
    border: `1px solid ${styles.colors.brandPrimary}`,
    marginRight: 8,
    padding: "1px 8px",
    fontWeight: "700",
    fontSize: 12,
  },
  flexColumn: {
    display: "flex",
    flexFlow: "column nowrap",
  },
});

export const generateContent = ({
  loading,
  isRenovator,
  isExpert,
  intro,
  matchWithCurrentUser,
  enabledForTwilioProxy,
}) => {
  const { renRejectionReason, interest } = matchWithCurrentUser || {};
  const { ren, gc } = interest || {};
  const { isInterested: renIsInterested } = ren || {};
  const { isInterested: gcIsInterested } = gc || {};

  let copy;
  let header;
  if (loading) {
    copy = <Loader.Body tag="p" />;
    header = <Loader.Header tag="h6" />;
  } else if (isRenovator) {
    if (
      (intro && intro.isActive) ||
      (matchWithCurrentUser && matchWithCurrentUser.isActive)
    ) {
      copy = "Would you like to connect with this contractor?";
    } else {
      copy = "You declined this contractor. Are you interested again?";
    }
  } else if (
    enabledForTwilioProxy &&
    renIsInterested &&
    gcIsInterested === null
  ) {
    header = "Interested in this project?";
    copy =
      "The homeowner is interested in your firm and would like you to contact them.";
  } else if (isExpert && (matchWithCurrentUser || intro)) {
    header = "Interested in this project?";

    let renMessage = "";
    if (intro) {
      renMessage = intro.renIntroMessage;
    } else if (matchWithCurrentUser) {
      renMessage = matchWithCurrentUser.renIntroMessage;
    }
    if (renMessage) {
      copy = "";
    } else if (renIsInterested === null && gcIsInterested === null) {
      copy = `Take the first step to express interest in this project.`;
    } else if (renIsInterested === null && gcIsInterested) {
      header = "You have shown your interest";
      copy = (
        <>
          <RaisedHands /> Your interest has been saved. If the client likes your
          profile, you will receive an introduction email.
          <div>
            <TextLink
              onClick={() =>
                trackMixpanelWithCallback(
                  "Click to stay up to date with pending projects",
                  {},
                  () => goToPage("/overview")
                )
              }
            >
              Stay up to date with pending projects
            </TextLink>
          </div>
        </>
      );
    } else if (renRejectionReason) {
      header = "Sorry, this client is not interested in your profile";
      copy = `Reason: ${renRejectionReason}`;
    } else {
      const optInCategory = get(matchWithCurrentUser, "optInCategory");
      copy = `Client prefers introduction by ${CATEGORY_TO_READABLE_STRING[optInCategory]}.`;
      if (optInCategory === "phone_call") {
        copy +=
          " If you choose 'I'm interested', message the client and suggest a time for the call.";
      } else if (optInCategory === "site_visit") {
        copy +=
          " If you choose 'I'm interested', message the client and suggest a time to meet.";
      }
    }
  }

  return { copy, header };
};

export const ExpertCTAs = ({ intro, userInfo, setModalState }) => {
  const { id, interest, renIntroMessage, project } = intro;
  const { user, shouldAutoOptInToMatches } = project;
  const { enabledForTwilioProxy } = user;
  const [respondToMatch, { loading }] = useMutation(GC_RESPONSE);
  const [showFeedbackResponseModal, toggleFeedbackResponseModal] = useState(
    false
  );

  const { gc } = interest || {};
  const { isInterested: gcIsInterested } = gc || {};

  const onClick = isAccepting => {
    if (isAccepting) {
      respondToMatch({
        variables: {
          id,
          isAccepting,
          company: {},
        },
      }).then(({ data }) => {
        const { gcAskResponse } = data;
        const { intro: mutationIntroData } = gcAskResponse;
        const { conversation } = mutationIntroData;
        if (renIntroMessage && conversation) {
          goToPage(`/conversations/${conversation.id}`);
        } else {
          goToPage(`/feedback/expert-ask?intro_id=${id}&response=yes`);
        }
      });
    } else {
      trackMixpanel("Clicked Reject REN");
      toggleFeedbackResponseModal(true);
    }
  };

  return (
    <>
      {gcIsInterested === null && (
        <div className={css(ss.expMatchButtonsContainer)}>
          <Button
            loading={loading}
            onClick={() => onClick(false)}
            variant="outlineDark"
            aphStyle={ss.noThanksCta}
          >
            No thanks
          </Button>
          {enabledForTwilioProxy || shouldAutoOptInToMatches ? (
            <Button
              loading={loading}
              onClick={() =>
                enabledForTwilioProxy
                  ? setModalState("phoneNumber")
                  : setModalState("nextSteps")
              }
              aphStyle={ss.interestedCta}
            >
              I&apos;m interested
            </Button>
          ) : (
            <Button
              loading={loading}
              onClick={() => onClick(true)}
              aphStyle={ss.interestedCta}
            >
              I&apos;m interested
            </Button>
          )}
        </div>
      )}
      {showFeedbackResponseModal && (
        <ExpFeedbackResponse
          id={id}
          onClose={() => {
            trackMixpanel("Clicked Cancel - Reject REN");
            toggleFeedbackResponseModal(false);
          }}
          respondToMatch={respondToMatch}
          loading={loading}
          userInfo={userInfo}
        />
      )}
    </>
  );
};

ExpertCTAs.propTypes = {
  intro: PropTypes.object,
  userInfo: UserInfoPropType,
  setModalState: PropTypes.func,
};

export const RenCTAs = forwardRef(
  (
    {
      avgAskResponseTimeMinutes,
      introId,
      projectId,
      enabledForTwilioProxy,
      location,
    },
    ref
  ) => {
    return (
      <RenMatchingModalProvider location={location} projectId={projectId}>
        <RespondToMatchCTAs
          avgAskResponseTimeMinutes={avgAskResponseTimeMinutes}
          enabledForTwilioProxy={enabledForTwilioProxy}
          introId={introId}
          variant="gcProfile"
          ref={ref}
        />
      </RenMatchingModalProvider>
    );
  }
);

RenCTAs.propTypes = {
  introId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  enabledForTwilioProxy: PropTypes.bool,
  avgAskResponseTimeMinutes: PropTypes.number,
  location: PropTypes.string,
};

// TODO: Have this fetch all its own info on the intro based on some intro id...
const MatchBanner = forwardRef(({ introId, location }, ref) => {
  const { loading, data } = useQuery(GET_USER_INFO, { variables: { introId } });
  if (loading) {
    return <Loader.Body style={{ height: 200 }} />;
  }

  const { currentUser, projectIntroduction } = data;
  const { enabledForTwilioProxy, isRenovator } = currentUser;
  const { company, projectId } = projectIntroduction;
  const {
    avgAskResponseTimeMinutes,
    currentCompanyLogoUrl,
    id: companyId,
    isSweetenAllStar,
    name,
  } = company;

  const isAllStar = isSweetenAllStar && currentUser && isRenovator;

  return (
    <>
      <div className={css(ss.flexCentered)} style={{ marginBottom: 32 }}>
        <Avatar image={currentCompanyLogoUrl} size={48} />
        <div className={css(ss.flexColumn)} style={{ marginLeft: 16 }}>
          <Header
            tag="h6"
            style={{ marginBottom: 4 }}
            aphStyle={ss.flexCentered}
          >
            <div style={{ marginRight: 8 }}>{name}</div>
            <Verified justIcon />
          </Header>
          <div
            className={css(ss.flexCentered)}
            style={{ marginBottom: 4, flexWrap: "wrap" }}
          >
            {isAllStar && <AllStar />}
            <PaymentsBadges companyId={companyId} />
          </div>
        </div>
      </div>
      {isRenovator && (
        <RenCTAs
          avgAskResponseTimeMinutes={avgAskResponseTimeMinutes}
          enabledForTwilioProxy={enabledForTwilioProxy}
          introId={introId}
          projectId={projectId}
          location={location}
          ref={ref}
        />
      )}
    </>
  );
});

MatchBanner.propTypes = {
  introId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  location: PropTypes.string,
};

export default MatchBanner;
