import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { styles, Body, Icon } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite/no-important";
import MatchingCarousel from "./matching_carousel";
import { AppDispatch } from "./app_provider";
import { LightboxComp } from "./photo_overlay";

export const ss = StyleSheet.create({
  imageDisplayContainer: {
    width: "100%",
    height: 496,
    display: "flex",
    justifyContent: "space-between",
  },
  hideMobile: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "none",
      },
    }),
  },
  mobileContainer: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "block",
        position: "relative",
      },
    }),
  },
  halfContainer: {
    width: "49%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  column: {
    flexDirection: "column",
  },
  quarterContainer: {
    width: "100%",
    height: "49%",
  },
  imageDisplayImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 8,
    cursor: "pointer",
  },
  viewAllPhotos: {
    fontSize: 12,
    fontWeight: 500,
    padding: "12px 8px",
    borderRadius: 4,
    position: "absolute",
    bottom: 16,
    right: 16,
    backgroundColor: styles.colors.white,
    border: styles.border,
    cursor: "pointer",
    display: "flex",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "none",
      },
    }),
  },
  heroImage: {
    height: 243,
    width: 292,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
        height: "100%",
      },
    }),
  },
  heroContainer: {
    height: 496,
    width: 292,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "24%",
      },
    }),
  },
  heroSubcontainer: {
    height: 243,
    width: 292,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
        height: "49%",
      },
    }),
  },
  coverImage: {
    height: 496,
    width: 595,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
        height: "100%",
      },
    }),
  },
  coverContainer: {
    height: 496,
    width: 595,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "48%",
        height: "100%",
      },
    }),
  },
  marginBottom: {
    marginBottom: 10,
  },
});

const GC_PROFILE_NUM_PHOTOS = 5;

const DEFAULT_NUM_PHOTOS = 3;

const ImageDisplay = ({
  photos,
  activeImageNum = null,
  setActiveImageNum,
  setNoImages,
  location,
  urlSize,
  companyId,
}) => {
  const [activeImageNumber, updateImageNumber] = useState(activeImageNum);
  const [numImagesFailed, setNumImagesFailed] = useState(0);

  const dispatch = useContext(AppDispatch);

  let numPhotos = DEFAULT_NUM_PHOTOS;

  if (location === "gc_profile") {
    numPhotos = GC_PROFILE_NUM_PHOTOS;
  }

  const lengthToUse = photos.length >= numPhotos ? numPhotos : photos.length;

  const setImageNumber = value => {
    updateImageNumber(value);
    if (setActiveImageNum) {
      setActiveImageNum(value);
    }
  };

  const onImageError = () => {
    setNumImagesFailed(numImagesFailed + 1);
    if (numImagesFailed + 1 >= lengthToUse) {
      setNoImages(true);
    }
  };

  useEffect(() => {
    setImageNumber(activeImageNum);
  }, [activeImageNum]);

  const setToFinalScreen = photosUsed => {
    dispatch({
      type: "overlay:show",
      payload: {
        variant: "lightboxScreen",
        companyId,
        lightboxPrev: () => {
          setImageNumber(photosUsed.length - 1);
          dispatch({
            type: "overlay:hide",
          });
        },
        lightboxNext: () => {
          setImageNumber(0);
          dispatch({
            type: "overlay:hide",
          });
        },
      },
    });
    setImageNumber(null);
  };

  if (location === "gc_profile") {
    const photosForLightbox = photos.slice(0, 15);
    const photosToUse = photos.slice(0, 5);
    switch (lengthToUse - numImagesFailed) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        return null;
      default:
        return (
          <>
            <div className={css([ss.imageDisplayContainer, ss.hideMobile])}>
              <div
                onClick={() => setImageNumber(0)}
                className={css(ss.coverContainer)}
              >
                <img
                  src={photosToUse[0].url.large}
                  alt="photo_1"
                  className={css(ss.imageDisplayImage, ss.coverImage)}
                  onError={onImageError}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                />
              </div>
              <div
                style={{ margin: "0px 10px" }}
                className={css(ss.heroContainer)}
              >
                <div
                  onClick={() => setImageNumber(1)}
                  className={css(ss.heroSubcontainer, ss.marginBottom)}
                >
                  <img
                    src={photosToUse[1].url.large}
                    alt="photo_2"
                    className={css(ss.imageDisplayImage, ss.heroImage)}
                    onError={onImageError}
                    style={{ borderRadius: 0 }}
                  />
                </div>
                <div
                  onClick={() => setImageNumber(2)}
                  className={css(ss.heroSubcontainer)}
                >
                  <img
                    src={photosToUse[2].url.large}
                    alt="photo_3"
                    className={css(ss.imageDisplayImage, ss.heroImage)}
                    onError={onImageError}
                    style={{ borderRadius: 0 }}
                  />
                </div>
              </div>
              <div className={css(ss.heroContainer)}>
                <div
                  onClick={() => setImageNumber(3)}
                  className={css(ss.heroSubcontainer, ss.marginBottom)}
                >
                  <img
                    src={photosToUse[3].url.large}
                    alt="photo_4"
                    className={css(ss.imageDisplayImage, ss.heroImage)}
                    onError={onImageError}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  />
                </div>
                <div
                  onClick={() => setImageNumber(4)}
                  className={css(ss.heroSubcontainer)}
                >
                  <img
                    src={photosToUse[4].url.large}
                    alt="photo_5"
                    className={css(ss.imageDisplayImage, ss.heroImage)}
                    onError={onImageError}
                    style={{
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={css(ss.viewAllPhotos)}
              onClick={() =>
                dispatch({
                  type: "overlay:show",
                  payload: {
                    variant: "wall",
                    companyId,
                  },
                })
              }
            >
              <Icon name="layers" style={{ marginRight: 8 }} />
              <Body tag="div" variant="bold">
                Show all photos
              </Body>
            </div>
            <div className={css(ss.mobileContainer)}>
              <MatchingCarousel
                photos={photosToUse}
                setImageNumber={setImageNumber}
                companyId={companyId}
              />
            </div>
            {activeImageNumber !== null && (
              <LightboxComp
                photos={photosForLightbox}
                activeImageNumber={activeImageNumber}
                setImageNumber={setImageNumber}
                urlSize={urlSize}
                setToFinalScreen={setToFinalScreen}
              />
            )}
          </>
        );
    }
  } else {
    switch (lengthToUse - numImagesFailed) {
      case 0:
        return null;
      case 1:
        return (
          <>
            <div
              className={css(ss.imageDisplayContainer)}
              onClick={() => setImageNumber(0)}
            >
              <img
                src={photos[0].url.large}
                alt="photo_1"
                className={css(ss.imageDisplayImage)}
                onError={onImageError}
              />
            </div>
            {activeImageNumber !== null && (
              <LightboxComp
                photos={photos}
                activeImageNumber={activeImageNumber}
                setImageNumber={setImageNumber}
                urlSize={urlSize}
                setToFinalScreen={setToFinalScreen}
              />
            )}
          </>
        );
      case 2:
        return (
          <>
            <div className={css(ss.imageDisplayContainer)}>
              <div
                className={css(ss.halfContainer)}
                onClick={() => setImageNumber(0)}
              >
                <img
                  src={photos[0].url.large}
                  alt="photo_1"
                  className={css(ss.imageDisplayImage)}
                  onError={onImageError}
                />
              </div>
              <div
                className={css(ss.halfContainer)}
                onClick={() => setImageNumber(1)}
              >
                <img
                  src={photos[1].url.large}
                  alt="photo_2"
                  className={css(ss.imageDisplayImage)}
                  onError={onImageError}
                />
              </div>
            </div>
            {activeImageNumber !== null && (
              <LightboxComp
                photos={photos}
                activeImageNumber={activeImageNumber}
                setImageNumber={setImageNumber}
                urlSize={urlSize}
                setToFinalScreen={setToFinalScreen}
              />
            )}
          </>
        );
      default:
        return (
          <>
            <div className={css(ss.imageDisplayContainer)}>
              <div
                className={css(ss.halfContainer)}
                onClick={() => setImageNumber(0)}
              >
                <img
                  src={photos[0].url.large}
                  alt="photo_1"
                  className={css(ss.imageDisplayImage)}
                  onError={onImageError}
                />
              </div>
              <div className={css([ss.halfContainer, ss.column])}>
                <div
                  className={css(ss.quarterContainer)}
                  onClick={() => setImageNumber(1)}
                >
                  <img
                    src={photos[1].url.large}
                    alt="photo_2"
                    className={css(ss.imageDisplayImage)}
                    onError={onImageError}
                  />
                </div>
                <div
                  className={css(ss.quarterContainer)}
                  onClick={() => setImageNumber(2)}
                >
                  <img
                    src={photos[2].url.large}
                    alt="photo_3"
                    className={css(ss.imageDisplayImage)}
                    onError={onImageError}
                  />
                </div>
              </div>
            </div>
            {activeImageNumber !== null && (
              <LightboxComp
                photos={photos}
                activeImageNumber={activeImageNumber}
                setImageNumber={setImageNumber}
                urlSize={urlSize}
                setToFinalScreen={setToFinalScreen}
              />
            )}
          </>
        );
    }
  }
};

ImageDisplay.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.object),
  activeImageNum: PropTypes.number,
  setActiveImageNum: PropTypes.func,
  setNoImages: PropTypes.func,
  location: PropTypes.string,
  companyId: PropTypes.string,
};

export default ImageDisplay;
