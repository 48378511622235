import React from "react";
import PropTypes from "prop-types";
import { TextLink } from "@sweeten/domo";
import { StyleSheet, css } from "aphrodite";
import { goToPage } from "../../utils";

const ss = StyleSheet.create({
  title: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 700,
    marginRight: 16,
  },
});

const Title = ({ projectId, projectTitle }) => {
  const title = projectTitle || "Portfolio project";

  if (projectTitle) {
    return (
      <TextLink
        aphStyle={ss.title}
        handleClick={() => {
          goToPage(`/projects/${projectId}`, { shouldOpenNewTab: true });
        }}
        variant="black"
      >
        {title}
      </TextLink>
    );
  }

  return <span className={css(ss.title)}>{title}</span>;
};

Title.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  projectTitle: PropTypes.string,
};

export default Title;
