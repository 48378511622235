import React, { useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Body, TextLink, styles } from "@sweeten/oreo";
import { Stars } from "@sweeten/domo";
import { truncate } from "lodash";
import { StyleSheet, css } from "aphrodite";
import AnswersModal from "../components/reviews/answers_modal_gql";
import Title from "../components/reviews/title";
import SweetenProjectLabel from "../components/sweeten_project_label";
import { formatUserName } from "../utils";

const flexCenterSpaceBetween = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const ss = StyleSheet.create({
  container: {
    marginBottom: 32,
    borderTop: `1px solid ${styles.colors.grey20}`,
  },
  firstReview: { borderTop: "none" },
  header: {
    ...flexCenterSpaceBetween,
    marginTop: 32,
    marginBottom: 8,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        flexFlow: "column nowrap",
        alignItems: "flex-start",
      },
    }),
  },
  feedbackPreview: {
    marginTop: 16,
    marginBottom: 8,
    fontSize: 16,
    lineHeight: "26px",
    color: styles.colors.grey40,
  },
  sweetenProject: {
    display: "flex",
    flexRow: "no-wrap",
  },
  avatarNameLocation: {
    ...styles.center("vertical"),
    marginTop: 16,
    marginBottom: 32,
  },
  avatarInitials: {
    color: "#4D72D6",
    fontWeight: 700,
    backgroundColor: styles.colors.grey10,
  },
  nameAndLocation: {
    display: "flex",
    flexFlow: "column nowrap",
    marginLeft: 16,
  },
  locationRoomScope: {
    display: "flex",
    color: styles.colors.grey40,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 12,
        flexFlow: "column nowrap",
      },
    }),
  },
  bullet: {
    fontWeight: 700,
    margin: "0px 8px",
    color: styles.colors.grey40,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "none",
      },
    }),
  },
  title: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginBottom: 8,
      },
    }),
  },
});

const locationString = location => `${location.county}, ${location.state}`;

const Review = ({ review, idx }) => {
  const [showModal, toggleModal] = useState(false);
  const { reviewer } = review;
  const project = review.project || {};
  const location = project.location || {};

  return (
    <div className={css([ss.container, idx === 0 && ss.firstReview])}>
      <div className={css(ss.header)}>
        <div className={css(ss.title)}>
          <Title projectId={project.id} projectTitle={project.title} />
        </div>
        {review.rating === 0 ? (
          "N/A"
        ) : (
          <Stars
            stars={review.rating}
            filledStarOptions={{ color: styles.colors.orange2 }}
          />
        )}
      </div>
      <Body tag="div">
        {review.type === "testimonial" && <SweetenProjectLabel />}
        <div className={css(ss.feedbackPreview)}>
          {truncate(review.feedback.public, { length: 200 })}
        </div>
        <TextLink onClick={() => toggleModal(true)} style={{ fontSize: 16 }}>
          {" "}
          View entire review
        </TextLink>
        <div className={css(ss.avatarNameLocation)}>
          <Avatar
            firstName={reviewer.firstName}
            lastName={reviewer.lastName}
            image={reviewer.avatar ? reviewer.avatar.url.thumb : null}
            size={36}
            aphStyle={ss.avatarInitials}
          />
          <div className={css(ss.nameAndLocation)}>
            {(project.title || reviewer) && (
              <div style={{ color: styles.colors.black, fontWeight: 700 }}>
                {formatUserName(reviewer, "abbrLast")}
              </div>
            )}
            {location.county && (
              <div className={css(ss.locationRoomScope)}>
                {locationString(project.location)}
                <Body tag="div" aphStyle={ss.bullet}>
                  &#183;
                </Body>
                <div>
                  {project.roomScope === "Entire Home"
                    ? "Entire home renovation"
                    : "Partial home renovation"}
                </div>
              </div>
            )}
          </div>
        </div>
      </Body>
      {showModal && (
        <AnswersModal
          handleClose={() => toggleModal(false)}
          review={review}
          showDefaultCTAs={false}
          size="large"
        />
      )}
    </div>
  );
};

Review.propTypes = {
  idx: PropTypes.number,
  review: PropTypes.object,
};

export default Review;
