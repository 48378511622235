import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormLabel, Icon, Input, styles } from "@sweeten/oreo";
import { css } from "aphrodite";
import { useField } from "react-final-form-hooks";
import { required } from "../../validators";
import { geolocate } from "../../ren/project_posting/steps/address";

const AddressFieldGroup = ({ aphStyle, form, style }) => {
  const addressField = useField("address", form, required);
  const address2Field = useField("address2", form);

  const [geolocateLoading, setGeolocateLoading] = useState(false);
  const [geolocateError, setGeolocateError] = useState("");

  return (
    <div className={css(aphStyle)} style={style}>
      <FormLabel>Property Address</FormLabel>
      <Input
        {...addressField.input}
        data-test="street_address"
        disabled={geolocateLoading}
        error={
          geolocateError ||
          (addressField.meta.touched && addressField.meta.error)
        }
        afterElement={
          <div
            onClick={() =>
              geolocate(addressField, setGeolocateLoading, setGeolocateError)
            }
          >
            <Icon
              name="pin"
              size={16}
              color={styles.colors.brandPrimary}
              style={{ cursor: "pointer" }}
            />
          </div>
        }
      />
      <Input
        {...address2Field.input}
        style={{ marginTop: 24 }}
        placeholder="Apt/Unit/Suite/etc"
      />
    </div>
  );
};

AddressFieldGroup.propTypes = {
  aphStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  form: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default AddressFieldGroup;
