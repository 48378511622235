import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite/no-important";
import { Header, Card, Body, styles } from "@sweeten/oreo";
import LicenseSvgPath from "../../assets/images/license.svg";
import { formatDateMMDDYYYY } from "../utils";

const ss = StyleSheet.create({
  header: {
    fontSize: 22,
    marginBottom: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 18,
      },
    }),
  },
  licenseCard: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 40,
    borderRadius: 8,
    boxSizing: "border-box",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        padding: 16,
        marginBottom: 16,
      },
    }),
  },
  image: {
    marginRight: 8,
    width: 40,
    height: 29,
  },
  marginBottom: {
    marginBottom: 16,
  },
  caption: {
    fontSize: 14,
    color: styles.colors.grey40,
  },
  container: {
    marginBottom: 64,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginBottom: 40,
      },
    }),
  },
});

const License = ({ license, isMatched }) => (
  <Card aphStyle={ss.licenseCard}>
    <img
      src={LicenseSvgPath}
      alt="sweeten_verified_icon"
      className={css(ss.image)}
    />
    <div>
      {isMatched && license.licenseNumber && (
        <div className={css(ss.marginBottom)}>
          <Body tag="div" variant="bold">
            License #
          </Body>
          <Body tag="div" variant="caption" aphStyle={ss.caption}>
            {license.licenseNumber}
          </Body>
        </div>
      )}
      {license.expiresOn && (
        <div className={css(ss.marginBottom)}>
          <Body tag="div" variant="bold">
            Status
          </Body>
          <Body tag="div" variant="caption" aphStyle={ss.caption}>
            {new Date(license.expiresOn) < new Date()
              ? `Expired on ${formatDateMMDDYYYY(license.expiresOn)}`
              : `Active (Exp ${formatDateMMDDYYYY(license.expiresOn)})`}
          </Body>
        </div>
      )}
      {license.licenseType && (
        <div className={css(ss.marginBottom)}>
          <Body tag="div" variant="bold">
            Type
          </Body>
          <Body tag="div" variant="caption" aphStyle={ss.caption}>
            {license.licenseType}
          </Body>
        </div>
      )}
      {license.licenseState && (
        <div>
          <Body tag="div" variant="bold">
            License&apos;s state
          </Body>
          <Body tag="div" variant="caption" aphStyle={ss.caption}>
            {license.licenseState}
          </Body>
        </div>
      )}
    </div>
  </Card>
);

License.propTypes = {
  license: PropTypes.object,
  isMatched: PropTypes.bool,
};

const Licenses = ({ companyId, currentUser, licenses, introducedAt }) => {
  const { company } = currentUser || {};
  const { id } = company || {};

  return (
    <div className={css(ss.container)}>
      <Header tag="h5" aphStyle={ss.header}>
        Licenses ({licenses.length})
      </Header>
      {licenses.map(license => (
        <License
          license={license}
          isMatched={!!introducedAt || id === companyId}
        />
      ))}
    </div>
  );
};

Licenses.propTypes = {
  companyId: PropTypes.string,
  currentUser: PropTypes.object,
  licenses: PropTypes.array,
  introducedAt: PropTypes.string,
};

export default Licenses;
