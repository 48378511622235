import React from "react";
import { Icon, styles, Tooltip } from "@sweeten/oreo";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite/no-important";
import { getWindowWidth } from "../utils";

const ss = StyleSheet.create({
  verified: {
    borderRadius: 24,
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "20px",
    padding: "1px 8px",
    marginRight: 8,
    border: `1px solid ${styles.colors.black}`,
    boxSizing: "border-box",
    "::-webkit-box-sizing": "border-box",
    "::-moz-box-sizing": "border-box",
    backgroundColor: "#F1F1F2",
    ...styles.center("vertical"),
  },
});

const Verified = ({ justIcon, position = "bottom" }) => {
  const windowWidth = getWindowWidth();
  return justIcon ? (
    <Tooltip content="Sweeten verified" position={position}>
      <Icon
        name="shield"
        size={windowWidth > styles.breakpoints.phoneStandard ? 20 : 16}
      />
    </Tooltip>
  ) : (
    <div className={css(ss.verified)}>
      <Icon name="shield" size={16} style={{ marginRight: 4 }} />
      Verified
    </div>
  );
};

Verified.propTypes = {
  justIcon: PropTypes.bool,
  position: PropTypes.string,
};

export default Verified;
