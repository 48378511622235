import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  Body,
  Button,
  Header,
  Modal,
  styles,
  Icon,
  CTA,
  Input,
} from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import Cookies from "js-cookie";
import { useForm, useField } from "react-final-form-hooks";
import { gql } from "apollo-boost";
import { withApollo } from "react-apollo";
import { useMutation } from "@apollo/react-hooks";
import NumIntrosProgress from "../components/num_intros_progress";
import { filterIntros } from "../shared";
import { phoneNumber } from "../../../validators";
import { formatPhoneNumber, goToPage } from "../../../utils";

const UPDATE_USER_PHONE = gql`
  mutation userUpdate($attributes: UserUpdateInput!) {
    userUpdate(attributes: $attributes) {
      user {
        id
        phone
      }
    }
  }
`;

const ss = StyleSheet.create({
  progressContainer: {
    ...styles.center("horizontal"),
    marginBottom: 32,
  },
  modal: {
    width: 652,
    alignItems: "center",
  },
  flexCentered: {
    ...styles.center("vertical"),
  },
  phone: {
    border: `1px solid ${styles.colors.grey20}`,
    boxSizing: "border-box",
    "::-webkit-box-sizing": "border-box",
    "::-moz-box-sizing": "border-box",
    padding: "18px 0px",
    margin: "24px 60px 16px",
    ...styles.center(),
    flexWrap: "wrap",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        margin: "24px 0px 16px",
      },
    }),
  },
  underline: {
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottomColor: styles.colors.blueInfo,
    height: 24,
    margin: "0px 8px",
    width: 150,
    ":nth-child(1n) > :first-child": {
      textAlign: "center",
    },
  },
  button: {
    height: 28,
    ":nth-child(1n) > div > svg": {
      width: 28,
      height: 28,
    },
  },
  subheader: {
    fontSize: 16,
    lineHeight: "26px",
    marginTop: 16,
  },
});

const MyContractorsAccept = ({
  data,
  onClose,
  updateMatchingModalState,
  location,
  intro,
  client,
}) => {
  const { project } = data;
  const { user, intros } = project || {};
  const { phone } = user || {};
  const { company } = intro;
  const { name } = company;

  const [editingPhone, setEditingPhone] = useState(false);

  const [updatePhone, { loading: mutationLoading }] = useMutation(
    UPDATE_USER_PHONE
  );

  const onCloseCb =
    location === "gc_profile" ? () => goToPage("/my-contractors") : onClose;

  const { form, handleSubmit } = useForm({
    initialValues: {
      phone,
    },
    onSubmit: values => {
      updatePhone({
        variables: {
          attributes: {
            phone: values.phone,
          },
        },
      }).then(() => setEditingPhone(false));
    },
  });

  const phoneField = useField("phone", form, val => phoneNumber(val, client));

  const updateMatchesModalState = useContext(updateMatchingModalState);

  const { introduced, renInterested } = filterIntros(intros);

  const introducedOptedIn = introduced.concat(renInterested);

  const phoneOnChange = val => {
    const newVal = formatPhoneNumber(val);
    phoneField.input.onChange(newVal);
  };

  const onCTAClick = () => {
    const dontShowScheduleCall =
      Cookies.get("dont-show-schedule-call") === "true";
    if (!dontShowScheduleCall && introducedOptedIn.length === 1) {
      updateMatchesModalState({
        name: "scheduleAdminCall",
        options: {
          location,
        },
      });
    } else {
      onCloseCb();
    }
  };

  return (
    <Modal aphStyle={ss.modal} fullScreenOnMobile disableOutsideClick>
      <Modal.Header>
        <Icon
          name="checkmark"
          color={styles.colors.greenSuccess}
          size={32}
          style={{
            borderRadius: "50%",
            border: `6px solid ${styles.colors.green4}`,
            padding: 12,
            marginBottom: 8,
            strokeWidth: 4,
          }}
        />
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center", width: "100%" }}>
        <Header tag="h5">
          Great! {name} will review your project and reach out to you.
        </Header>
        <Body tag="div" aphStyle={ss.subheader}>
          Contractors usually respond within two business days and often send a
          text introducing themselves before giving a call.
        </Body>
        <Body tag="div" aphStyle={ss.phone}>
          Mobile number the contractor should reach you on:{" "}
          {!editingPhone ? (
            <div className={css(ss.flexCentered)} style={{ marginLeft: 8 }}>
              <b>{phone}</b>
              <CTA onClick={() => setEditingPhone(true)}>
                <Body
                  tag="div"
                  style={{ color: styles.colors.brandPrimary, marginLeft: 8 }}
                >
                  (Change)
                </Body>
              </CTA>
            </div>
          ) : (
            <div className={css(ss.flexCentered)}>
              <Input
                {...phoneField.input}
                error={phoneField.meta.touched && phoneField.meta.error}
                placeholder="Phone"
                aphStyle={ss.underline}
                onChange={phoneOnChange}
              />
              <Button
                variant="outlineDark"
                loading={mutationLoading}
                onClick={handleSubmit}
                aphStyle={ss.button}
              >
                OK
              </Button>
            </div>
          )}
        </Body>
        {introducedOptedIn.length <= 3 && (
          <div className={css(ss.progressContainer)}>
            <NumIntrosProgress
              introCount={introducedOptedIn.length}
              fromSuccessModal
            />
          </div>
        )}
        <Button onClick={onCTAClick}>Back to recommended contractors</Button>
      </Modal.Body>
    </Modal>
  );
};

MyContractorsAccept.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  updateMatchingModalState: PropTypes.func,
  location: PropTypes.string,
  intro: PropTypes.object,
  client: PropTypes.object,
};

export default withApollo(MyContractorsAccept);
