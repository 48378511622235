import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Textarea } from "@sweeten/domo";
import { Header, RadioGroup, FormLabel, Body } from "@sweeten/oreo";
import { StyleSheet } from "aphrodite";
import { useField, useForm } from "react-final-form-hooks";
import { useMutation } from "@apollo/react-hooks";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";
import { required } from "../../../validators";
import {
  GENERATE_EXPERIMENT_VARIANT,
  GET_PROJECT_AND_EXPERIMENT_USER,
} from "../graphql_constants";

export const servicesMap = showAlternative => {
  const prepend = showAlternative ? "I may need" : "I'd like";
  return {
    just_a_general_contractor:
      "No design services — just a general contractor.",
    material_selections: `${prepend} basic advice choosing finish materials and fixtures.`,
    architect_engineer_referral: `${prepend} to move walls/pipes or
      make other infrastructure changes.`,
    full_architectural_design_service: `${prepend} help reimagining the space,
      and choosing materials and fixtures.`,
    not_sure: "I’m not sure yet.",
  };
};

const ss = StyleSheet.create({
  caption: {
    fontSize: 12,
    lineHeight: "18px",
  },
});

const AdditionalService = ({
  isActive,
  subject: project,
  setHandleSubmit,
  update,
  experimentUsers: { additionalServicesCopyExperimentUser },
}) => {
  const { additionalService, additionalInfo, user } = project;

  const { variant: copyVariant } = additionalServicesCopyExperimentUser || {};

  const [textareaError, setTextareaError] = useState("");

  const [generateAlternateCopyExpVariant, { client }] = useMutation(
    GENERATE_EXPERIMENT_VARIANT,
    {
      variables: {
        name: "project_posting-additional_services-copy",
      },
    }
  );

  const { form, handleSubmit } = useForm({
    initialValues: { additionalService, additionalInfo },
    onSubmit: values => {
      if (
        !(values.additionalService === "not_sure" && !values.additionalInfo)
      ) {
        update(values);
      } else {
        setTextareaError(required(""));
      }
    },
  });

  const additionalServiceField = useField("additionalService", form, required);
  const additionalServiceError =
    additionalServiceField.meta.touched && additionalServiceField.meta.error;

  const onChange = val => {
    additionalServiceField.input.onChange(val);
    if (val !== "not_sure") {
      handleSubmit();
    }
  };

  const textAreaField = useField("additionalInfo", form);

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Additional Services");

    if (!copyVariant && !user) {
      generateAlternateCopyExpVariant().then(({ data }) => {
        const { userGenerateExperimentVariant } = data;
        const { experimentUser } = userGenerateExperimentVariant;
        client.writeQuery({
          query: GET_PROJECT_AND_EXPERIMENT_USER,
          data: {
            project,
            additionalServicesCopyExperimentUser: experimentUser,
          },
        });
      });
    }
  }, []);

  useEffect(() => {
    if (additionalServiceError) {
      trackViewError(
        "additional service",
        "",
        "Please select an additional service"
      );
    }
  }, [additionalServiceError]);

  useEffect(() => {
    if (additionalServiceField.input.value === "not_sure") {
      setTextareaError(required(textAreaField.input.value));
    }
  }, [textAreaField.input.value]);

  const serviceMap = servicesMap(copyVariant === "alternative");

  return (
    <>
      <Header tag="h5" style={{ marginBottom: 24 }}>
        Will you need help with design?
      </Header>
      {(copyVariant || user) && (
        <RadioGroup
          {...additionalServiceField.input}
          error={additionalServiceError}
          onChange={onChange}
        >
          <RadioGroup.Radio
            value="just_a_general_contractor"
            data-test="additional-service-first-choice"
          />
          <FormLabel style={{ marginBottom: 0, lineHeight: "18px" }}>
            {serviceMap.just_a_general_contractor}
          </FormLabel>
          <RadioGroup.Radio
            value="material_selections"
            data-test="additional-service-second-choice"
          />
          <FormLabel style={{ marginBottom: 0, lineHeight: "18px" }}>
            {serviceMap.material_selections}
          </FormLabel>
          <RadioGroup.Radio
            value="architect_engineer_referral"
            data-test="additional-service-third-choice"
          />
          <FormLabel style={{ marginBottom: 0, lineHeight: "18px" }}>
            {serviceMap.architect_engineer_referral}
          </FormLabel>
          <RadioGroup.Radio
            value="full_architectural_design_service"
            data-test="additional-service-fourth-choice"
          />
          <FormLabel style={{ marginBottom: 0, lineHeight: "18px" }}>
            {serviceMap.full_architectural_design_service}
          </FormLabel>
          <RadioGroup.Radio
            value="not_sure"
            data-test="additional-service-fifth-choice"
          />
          <FormLabel style={{ marginBottom: 0, lineHeight: "18px" }}>
            {serviceMap.not_sure}
          </FormLabel>
        </RadioGroup>
      )}
      {additionalServiceField.input.value === "not_sure" && (
        <div style={{ marginLeft: 32 }}>
          <Body tag="div" aphStyle={ss.caption}>
            Tell us more about your project, including any important
            circumstances. This helps us find the best matches for you.
          </Body>
          <Textarea
            {...textAreaField.input}
            error={textareaError}
            placeholder="For example: We've purchased a home and need to gut renovate
          the kitchen, update 2 bathrooms, and re-paint everything before moving in.
          Our target move-in date is in 4 months."
            widthVariant="large"
            style={{ height: 128 }}
          />
        </div>
      )}
    </>
  );
};

AdditionalService.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
  experimentUsers: PropTypes.oneOfType(
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ),
};

export default AdditionalService;
