import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Icon, styles, Button } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import { AppDispatch } from "./app_provider";

const ss = StyleSheet.create({
  image: {
    width: "100%",
    height: 220,
    borderRadius: 8,
    objectFit: "cover",
    ...styles.center(),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "inline-block",
        marginRight: 16,
      },
    }),
  },
  arrowInImageLeft: {
    position: "absolute",
    left: 16,
    ...styles.center("vertical"),
    cursor: "pointer",
    marginTop: "auto",
    marginBottom: "auto",
    top: 0,
    bottom: 0,
  },
  arrowInImageRight: {
    position: "absolute",
    right: 16,
    ...styles.center("vertical"),
    cursor: "pointer",
    marginTop: "auto",
    marginBottom: "auto",
    top: 0,
    bottom: 0,
  },
  imageContainer: {
    position: "relative",
    height: "100%",
  },
  pointer: {
    cursor: "pointer",
  },
  desktop: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "none",
      },
    }),
  },
  mobile: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "block",
        overflowX: "auto",
        whiteSpace: "nowrap",
      },
    }),
  },
  viewAllPhotos: {
    marginTop: 12,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        margin: "12px 12px 0px",
      },
    }),
  },
});

const MatchingCarousel = ({
  photos,
  urlSize,
  onClickArrows = () => {},
  photoOnClick,
  setImageNumber,
  companyId,
  hideShowAll,
}) => {
  const [photoIdx, setPhotoIdx] = useState(0);

  const dispatch = useContext(AppDispatch);

  return (
    <>
      <div className={css(ss.imageContainer)}>
        <div className={css(ss.desktop)}>
          <div
            className={css([ss.image, photoOnClick && ss.pointer])}
            onClick={() => {
              if (setImageNumber) {
                setImageNumber(photoIdx);
              }
              if (photoOnClick) {
                photoOnClick();
              }
            }}
          >
            <img
              src={photos[photoIdx].url[urlSize]}
              alt={`photo_${photoIdx + 1}`}
              className={css(ss.image)}
            />
          </div>
          {photoIdx > 0 && (
            <div
              className={css(ss.arrowInImageLeft)}
              onClick={e => {
                onClickArrows(e);
                setPhotoIdx(photoIdx - 1);
              }}
            >
              <Icon
                name="chevron-left"
                size={16}
                backgroundColor={styles.colors.white}
              />
            </div>
          )}
          {photoIdx + 1 < photos.length && (
            <div
              className={css(ss.arrowInImageRight)}
              onClick={e => {
                onClickArrows(e);
                setPhotoIdx(photoIdx + 1);
              }}
            >
              <Icon
                name="chevron-right"
                size={16}
                backgroundColor={styles.colors.white}
              />
            </div>
          )}
        </div>
        <div className={css(ss.mobile)}>
          {photos.map((photo, idx) => (
            <div
              className={css([ss.image, photoOnClick && ss.pointer])}
              onClick={() => {
                if (setImageNumber) {
                  setImageNumber(idx);
                }
                if (photoOnClick) {
                  photoOnClick();
                }
              }}
            >
              <img
                src={photo.url[urlSize]}
                alt={`photo_${idx + 1}`}
                className={css(ss.image)}
              />
            </div>
          ))}
        </div>
      </div>
      {!hideShowAll && (
        <Button
          variant="outlineDark"
          onClick={() =>
            dispatch({
              type: "overlay:show",
              payload: {
                variant: "wall",
                companyId,
              },
            })
          }
          aphStyle={ss.viewAllPhotos}
        >
          View all photos
        </Button>
      )}
    </>
  );
};

MatchingCarousel.defaultProps = {
  urlSize: "large",
};

MatchingCarousel.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.object),
  urlSize: PropTypes.string,
  onClickArrows: PropTypes.func,
  photoOnClick: PropTypes.func,
  setImageNumber: PropTypes.func,
  companyId: PropTypes.string,
  hideShowAll: PropTypes.bool,
};

export default MatchingCarousel;
