import React from "react";
import {
  Avatar,
  Body,
  Icon,
  styles,
  Header,
  Loader,
  Row,
  Col,
  TextLink,
} from "@sweeten/oreo";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite/no-important";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { translateToHours } from "../gc/insights/shared";
import VerifiedSvgPath from "../../assets/images/sweeten-verified.svg";
import ReviewSvgPath from "../../assets/images/review.svg";
import ResponseSvgPath from "../../assets/images/response.svg";
import AllStar from "../components/allstar_badge";
import Verified from "../components/verified_badge";
import { goToPage } from "../utils";

const ABOUT_CONTRACTOR_QUERY = gql`
  query getDataForAboutContractor($companyId: ID!) {
    company(id: $companyId) {
      id
      currentCompanyLogoUrl
      nameSignature
      isSweetenAllStar
      createdAt
      averageRating
      reviews {
        id
      }
      leads {
        firstMessageResponseTimeMinutes
      }
      portfolioProjects {
        id
        isSweeten
      }
      contact {
        location {
          city
          neighborhood
          state
        }
      }
      awardedProjects {
        id
      }
      name
      matchWithCurrentUser {
        id
        introducedAt
      }
    }
  }
`;

const ss = StyleSheet.create({
  aboutContainer: {
    backgroundColor: styles.colors.yellow1,
    padding: "32px 40px",
    borderRadius: 8,
    margin: "0px -16px 64px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginBottom: 40,
        padding: "32px 8px",
      },
    }),
  },
  descriptionAndStats: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexCentered: {
    ...styles.center("vertical"),
  },
  accolades: {
    flexWrap: "wrap",
    margin: "16px 0px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginBottom: 8,
      },
    }),
  },
  iconTitleSubtext: {
    marginBottom: 40,
  },
  verticalLine: {
    margin: "0px 64px",
    borderRight: `1px solid ${styles.colors.grey20}`,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  stats: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  section: {
    marginBottom: 24,
  },
  header: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 8,
  },
  verifiedContainer: {
    marginTop: "auto",
    marginLeft: "auto",
    transform: "rotate(45deg)",
    opacity: 0.5,
    width: 96,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  statsCol: {
    display: "flex",
    justifyContent: "flex-end",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
      },
    }),
  },
  desktop: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  mobile: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
        marginBottom: 16,
      },
    }),
  },
});

const AboutContractor = ({ companyId, currentUserIsRen }) => {
  const { data, loading } = useQuery(ABOUT_CONTRACTOR_QUERY, {
    variables: { companyId },
  });

  if (loading) {
    return <Loader.Body style={{ height: 400, marginBottom: 24 }} />;
  }

  const { company } = data;
  const {
    currentCompanyLogoUrl,
    nameSignature,
    isSweetenAllStar,
    createdAt,
    averageRating,
    reviews,
    leads,
    portfolioProjects,
    contact,
    awardedProjects,
    name,
    matchWithCurrentUser,
  } = company || {};
  const { introducedAt } = matchWithCurrentUser || {};
  const { firstMessageResponseTimeMinutes } = leads || {};
  const { location } = contact || {};
  const { city, state, neighborhood } = location || {};

  let numSweetenProjects = 0;
  portfolioProjects.forEach(project => {
    if (project.isSweeten) {
      numSweetenProjects += 1;
    }
  });

  return (
    <div className={css(ss.aboutContainer)}>
      <Row verticalGap={8}>
        <Col width={12} style={{ marginTop: 0, marginBottom: 0 }}>
          <Header tag="h5" style={{ marginBottom: 24 }}>
            About this Sweeten general contractor
          </Header>
        </Col>
        <Col
          width={{ desktopMax: 7, tabletStandard: 12 }}
          style={{ display: "flex", marginTop: 0 }}
        >
          <div>
            <div className={css(ss.flexCentered)}>
              <Avatar image={currentCompanyLogoUrl} size={64} />
              <div style={{ marginLeft: 12 }}>
                <Body tag="div" variant="bold">
                  {nameSignature}
                </Body>
                {!!introducedAt && (
                  <Body
                    tag="div"
                    variant="bold"
                    style={{ color: styles.colors.grey40 }}
                  >
                    {name}
                  </Body>
                )}
              </div>
            </div>
            <div className={css([ss.flexCentered, ss.accolades])}>
              {isSweetenAllStar && currentUserIsRen && <AllStar />}
              <Verified />
              <Body
                tag="div"
                variant="bold"
                aphStyle={ss.desktop}
                style={{ color: styles.colors.grey40 }}
              >
                Sweeten contractor since {new Date(createdAt).getFullYear()}
              </Body>
            </div>
            <Body
              tag="div"
              variant="bold"
              aphStyle={ss.mobile}
              style={{ color: styles.colors.grey40 }}
            >
              Sweeten contractor since {new Date(createdAt).getFullYear()}
            </Body>
            <Body tag="div" aphStyle={ss.section}>
              {nameSignature}&apos;s company is based in{" "}
              {(neighborhood || city) && `${neighborhood || city},`} {state}.
              They&apos;ve completed {awardedProjects.length} renovations with
              Sweeten since joining us in {new Date(createdAt).getFullYear()}.
            </Body>
            {isSweetenAllStar && currentUserIsRen && (
              <>
                <Body tag="div" aphStyle={ss.header}>
                  {nameSignature} is a Sweeten All-Star
                </Body>
                <Body tag="div" aphStyle={ss.section}>
                  In addition to meeting the rigorous standards we set for all
                  our general contractors, Sweeten All-Stars have completed
                  several renovations with us and have been recognized by
                  homeowners for exceptional service.
                </Body>
              </>
            )}
            <Body tag="div" aphStyle={ss.header}>
              Hire {nameSignature} with total confidence!
            </Body>
            <Body tag="div">
              {nameSignature} is a trusted and verified contractor in Sweeten’s
              network — we’ve done the hard work so you don’t have to! Get peace
              of mind hiring your contractor on Sweeten using{" "}
              <TextLink
                onClick={() =>
                  goToPage(
                    "https://sweeten.com/pay-safely-for-home-renovations/",
                    { shouldOpenNewTab: true }
                  )
                }
              >
                Sweeten Secure Pay.
              </TextLink>
            </Body>
          </div>
          <div className={css(ss.verticalLine)} />
        </Col>
        <Col
          width={{ desktopMax: 5, tabletStandard: 12 }}
          aphStyle={ss.statsCol}
        >
          <div className={css(ss.stats)}>
            {!!reviews.length && (
              <div className={css([ss.iconTitleSubtext, ss.flexCentered])}>
                <img src={ReviewSvgPath} alt="reviews" />
                <div style={{ marginLeft: 24 }}>
                  <Body tag="div" variant="bold">
                    {reviews.length} reviews
                  </Body>
                  {averageRating} overall rating
                </div>
              </div>
            )}
            {!!firstMessageResponseTimeMinutes && (
              <div className={css([ss.iconTitleSubtext, ss.flexCentered])}>
                <img src={ResponseSvgPath} alt="responseTime" />
                <div style={{ marginLeft: 24 }}>
                  <Body tag="div" variant="bold">
                    Average response time
                  </Body>
                  {translateToHours(firstMessageResponseTimeMinutes)}
                </div>
              </div>
            )}
            {!!numSweetenProjects && (
              <div className={css([ss.iconTitleSubtext, ss.flexCentered])}>
                <Icon name="agreement" size={36} />
                <div style={{ marginLeft: 24 }}>
                  <Body tag="div" variant="bold">
                    Sweeten projects
                  </Body>
                  {numSweetenProjects}
                </div>
              </div>
            )}
          </div>
          <div className={css(ss.verifiedContainer)}>
            <img src={VerifiedSvgPath} alt="verified" style={{ width: 96 }} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

AboutContractor.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentUserIsRen: PropTypes.bool,
};

export default AboutContractor;
