import React, { useContext, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "react-apollo";
import { css, StyleSheet } from "aphrodite";
import Cookies from "js-cookie";
import { Button, styles, TextLink, Tooltip, Body, CTA } from "@sweeten/oreo";
import { trackMixpanel, goToPage } from "../../../utils";
import {
  UpdateMatchingModalState,
  MAX_INTRO_COUNT,
} from "../modals/matching_modal_provider";
import { triggerLocation } from "../modals/scheduling_modal/index";
import { existingIntrosCount, GET_PROJECT_INTROS_FOR_COUNT } from "../shared";
import { AppDispatch } from "../../../components/app_provider";

const RESPOND_TO_MATCH_FRAGMENT = gql`
  fragment RespondToMatchFields on ProjectIntroduction {
    id
    company {
      id
      name
    }
    project {
      id
      location {
        address
      }
      additionalInfo
      scheduledIntroCall
      user {
        id
        phone
      }
    }
  }
`;

const GET_EXPERIMENT_AND_INTRO_DATA = gql`
  query getMatchCtaExperimentData($introId: ID!) {
    showSelectContractorCtaUser: experimentUser(
      name: "show_select_contractor_cta"
    ) {
      id
      variant
    }
    projectIntroduction(id: $introId) {
      ...RespondToMatchFields
    }
  }
  ${RESPOND_TO_MATCH_FRAGMENT}
`;

const UPDATE_INTRO = gql`
  mutation introUpdate(
    $id: ID!
    $attributes: ProjectIntroductionUpdateInput
    $renStillInterested: Boolean
  ) {
    projectIntroductionUpdate(
      id: $id
      attributes: $attributes
      renStillInterested: $renStillInterested
    ) {
      intro {
        id
        state
        interest {
          ren {
            isInterested
            responseAt
          }
        }
      }
    }
  }
`;

const ss = StyleSheet.create({
  displayRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
  },
  mobileColumn: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        padding: "0px 16px 16px",
        flexDirection: "column",
      },
    }),
  },
  declineCta: {
    border: "none",
    backgroundColor: styles.colors.grey10,
    color: styles.colors.red4,
    width: "100%",
    ":hover": {
      color: styles.colors.white,
      background: styles.colors.red5,
    },
  },
  declineMyMatchesButton: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 11,
        marginRight: 0,
      },
    }),
  },
  messageCta: {
    marginBottom: 16,
    width: "100%",
  },
  scheduleIntroCta: {
    width: "100%",
    minWidth: 168,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        minWidth: "unset",
      },
    }),
  },
  marginBottom: {
    marginBottom: 16,
  },
  marginRightCta: {
    marginRight: 8,
  },
  colButton: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginBottom: 12,
        marginRight: 8,
      },
    }),
    marginRight: 8,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 11,
        marginBottom: 8,
      },
    }),
  },
  declineLinkCta: {
    color: styles.colors.grey40,
  },
  myMatchesMessageCta: {
    marginRight: 8,
    background: styles.colors.blueInfo,
    ":hover": {
      background: styles.colors.blueDark,
    },
  },
  introCtaTooltip: {
    backgroundColor: styles.colors.blueInfo,
    color: styles.colors.white,
    border: `1px solid ${styles.colors.blueInfo}`,
    width: 240,
  },
  introCtaTooltipAfter: {
    ":after": {
      borderTop: `9px solid ${styles.colors.blueInfo}`,
    },
  },
  selectContractor: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
      },
    }),
  },
  viewProfile: {
    width: 168,
  },
  mobileWidth: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
      },
    }),
  },
  desktop: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  mobile: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
      },
    }),
  },
  responseContainer: {
    marginTop: 12,
    display: "flex",
    alignItems: "center",
  },
  typicalResponse: {
    fontSize: 12,
    fontStyle: "italic",
    color: styles.colors.orange4,
    lineHeight: "20px",
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stickyResponse: {
    marginTop: 0,
    marginRight: 8,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginTop: 8,
      },
    }),
  },
  stickyHeaderResponse: {
    flexDirection: "row-reverse",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        flexDirection: "column",
        alignItems: "center",
      },
    }),
  },
  declineGCLink: {
    cursor: "pointer",
    color: styles.colors.red5,
    marginLeft: "auto",
    textDecoration: "underline",
    fontSize: 12,
    lineHeight: "20px",
  },
});

export const onDeclineClick = (
  location,
  forExpressInterest,
  matchId,
  updateMatchesModalState
) => {
  updateMatchesModalState({
    name: "reject",
    options: {
      onSuccessCb: () => {
        if (location === "gc_profile") {
          goToPage("/my-contractors");
        }
      },
      triggerLocation: triggerLocation(location),
      introId: matchId,
    },
  });
  if (forExpressInterest) {
    trackMixpanel("Clicked Don't Show My Project by REN");
  }
};

const onInterestedClick = (
  intros,
  dispatch,
  updateMatchesModalState,
  optionsProps
) => {
  const { location, companyId, matchId } = optionsProps;
  trackMixpanel("Clicked Introduce", {
    "Trigger Location": triggerLocation(location),
    "GC ID": companyId,
    Variant: "I'm interested",
  });

  if (existingIntrosCount(intros) >= MAX_INTRO_COUNT) {
    dispatch({
      type: "alert:show",
      payload: {
        variant: "error",
        text: (
          <span>
            Sorry, you’ve reached the limit of {MAX_INTRO_COUNT} contractor
            introductions.{" "}
            <TextLink href="/homeowner_sweeten_intro">
              Questions? Contact us.
            </TextLink>
          </span>
        ),
        shouldStayVisible: true,
      },
    });
  } else {
    updateMatchesModalState({
      name: "scheduleIntro",
      options: {
        submitButtonLabel: "Send",
        introId: matchId,
      },
    });
  }
};

const ScheduleIntroButton = ({
  aphStyle,
  isAccepting,
  onClick,
  buttonText,
}) => {
  return (
    <Button
      aphStyle={aphStyle}
      loading={isAccepting}
      onClick={onClick}
      variant="dark"
    >
      {buttonText}
    </Button>
  );
};

ScheduleIntroButton.propTypes = {
  aphStyle: PropTypes.object,
  isAccepting: PropTypes.bool,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
};

const SendMessageButton = ({ aphStyle, isAccepting, onClick }) => {
  return (
    <Button
      aphStyle={aphStyle}
      loading={isAccepting}
      onClick={onClick}
      variant="outlineDark"
    >
      Send Message
    </Button>
  );
};

SendMessageButton.propTypes = {
  aphStyle: PropTypes.object,
  isAccepting: PropTypes.bool,
  onClick: PropTypes.func,
};

const DeclineButton = ({ aphStyle, isAccepting, onClick, text }) => {
  return (
    <Button
      aphStyle={aphStyle}
      loading={isAccepting}
      onClick={onClick}
      variant="outlineDark"
    >
      {text || "I'm not interested"}
    </Button>
  );
};

DeclineButton.propTypes = {
  aphStyle: PropTypes.object,
  isAccepting: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export const GCProfileMatchCTAs = forwardRef(
  ({ aphStyle, data, location, enabledForTwilioProxy, isDecline }, ref) => {
    const { projectIntroduction: intro } = data || {};
    const updateMatchesModalState = useContext(UpdateMatchingModalState);
    const {
      id: matchId,
      company: { id: companyId, name: companyName },
      project,
    } = intro;
    const dispatch = useContext(AppDispatch);
    const {
      location: projectLocation,
      id: projectId,
      additionalInfo,
      scheduledIntroCall,
      user,
    } = project || {};
    const { address } = projectLocation || {};
    const { phone: phoneNumber } = user || {};

    const { loading: matchesLoading, data: matchesData } = useQuery(
      GET_PROJECT_INTROS_FOR_COUNT,
      {
        variables: { id: intro.project.id },
      }
    );

    if (matchesLoading) {
      return null;
    }
    const {
      project: { intros },
    } = matchesData;

    const optionsProps = {
      location,
      companyId,
      matchId,
      companyName,
      additionalInfo,
      scheduledIntroCall,
      phoneNumber,
      address: address || projectLocation.address,
      projectId,
    };

    if (enabledForTwilioProxy) {
      return (
        <div className={css(aphStyle)} id="gc_profile_ctas" ref={ref}>
          <SelectContractorStack
            data={data}
            location={location}
            isDecline={isDecline}
          />
        </div>
      );
    }

    return (
      <div className={css(aphStyle)} id="gc_profile_ctas" ref={ref}>
        <ScheduleIntroButton
          aphStyle={[ss.scheduleIntroCta, ss.marginBottom]}
          onClick={() =>
            onInterestedClick(
              intros,
              dispatch,
              updateMatchesModalState,
              optionsProps
            )
          }
          buttonText="I'm interested"
        />
        <DeclineButton
          aphStyle={ss.declineCta}
          onClick={() =>
            onDeclineClick(location, false, matchId, updateMatchesModalState)
          }
        />
      </div>
    );
  }
);

GCProfileMatchCTAs.propTypes = {
  aphStyle: PropTypes.object,
  location: PropTypes.string,
  data: PropTypes.object,
  enabledForTwilioProxy: PropTypes.bool,
  isDecline: PropTypes.bool,
};

const SelectContractorStack = ({
  aphStyle,
  data,
  location,
  showIntroTooltip,
  isDecline,
}) => {
  const updateMatchesModalState = useContext(UpdateMatchingModalState);
  const { projectIntroduction: intro } = data;
  const {
    id: matchId,
    company: { id: companyId, name: companyName },
    project,
  } = intro;
  const dispatch = useContext(AppDispatch);
  const {
    location: projectLocation,
    id: projectId,
    additionalInfo,
    user,
    scheduledIntroCall,
  } = project || {};
  const { address } = projectLocation || {};
  const { phone } = user || {};

  const { loading: matchesLoading, data: matchesData } = useQuery(
    GET_PROJECT_INTROS_FOR_COUNT,
    {
      variables: { id: intro.project.id },
    }
  );

  if (isDecline) {
    return (
      <DeclineButton
        aphStyle={ss.declineCta}
        onClick={() =>
          onDeclineClick(location, false, matchId, updateMatchesModalState)
        }
        text="Decline"
      />
    );
  }

  if (matchesLoading) {
    return null;
  }
  const {
    project: { intros },
  } = matchesData;

  const optionsProps = {
    location,
    companyId,
    matchId,
    companyName,
    additionalInfo,
    phoneNumber: phone,
    address,
    projectId,
    scheduledIntroCall,
  };

  const [showTooltip, toggleShowTooltip] = useState(showIntroTooltip);

  return (
    <div className={css(ss.displayRow, ss.mobileColumn, aphStyle)}>
      {showTooltip ? (
        <Tooltip
          content={
            <>
              <Body tag="div" style={{ color: styles.colors.white }}>
                Select to send your project details to this contractor. If
                they’re also interested, we’ll help you connect.
              </Body>
              <br />
              <CTA
                onClick={() => {
                  Cookies.set("hide-intro-tooltip", "true");
                  toggleShowTooltip(false);
                }}
                role="link"
                tabIndex="0"
              >
                <Body
                  tag="span"
                  style={{
                    fontWeight: "bold",
                    color: styles.colors.white,
                  }}
                >
                  Got it
                </Body>
              </CTA>
            </>
          }
          position="top"
          isAlwaysDisplayed
          aphStyle={ss.introCtaTooltipAfter}
          contentAphStyle={ss.introCtaTooltip}
        >
          <ScheduleIntroButton
            aphStyle={[ss.scheduleIntroCta, ss.colButton]}
            onClick={() =>
              onInterestedClick(
                intros,
                dispatch,
                updateMatchesModalState,
                optionsProps
              )
            }
            buttonText="I'm interested"
          />
        </Tooltip>
      ) : (
        <ScheduleIntroButton
          aphStyle={[ss.scheduleIntroCta, ss.colButton]}
          onClick={() =>
            onInterestedClick(
              intros,
              dispatch,
              updateMatchesModalState,
              optionsProps
            )
          }
          buttonText="I'm interested"
        />
      )}
      <DeclineButton
        aphStyle={[ss.declineCta, ss.declineMyMatchesButton]}
        onClick={() =>
          onDeclineClick(location, false, matchId, updateMatchesModalState)
        }
      />
    </div>
  );
};

SelectContractorStack.propTypes = {
  aphStyle: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.string,
  showIntroTooltip: PropTypes.bool,
  isDecline: PropTypes.bool,
};

const InterestedCTA = ({ location, data }) => {
  const updateMatchesModalState = useContext(UpdateMatchingModalState);

  const { projectIntroduction: intro } = data;
  const {
    id: matchId,
    company: { id: companyId, name: companyName },
    project,
  } = intro;
  const dispatch = useContext(AppDispatch);
  const {
    location: projectLocation,
    id: projectId,
    additionalInfo,
    user,
    scheduledIntroCall,
  } = project || {};
  const { address } = projectLocation || {};
  const { phone } = user || {};

  const { loading: matchesLoading, data: matchesData } = useQuery(
    GET_PROJECT_INTROS_FOR_COUNT,
    {
      variables: { id: intro.project.id },
    }
  );

  if (matchesLoading) {
    return null;
  }
  const {
    project: { intros },
  } = matchesData;

  const optionsProps = {
    location,
    companyId,
    matchId,
    companyName,
    additionalInfo,
    phoneNumber: phone,
    address,
    projectId,
    scheduledIntroCall,
  };

  return (
    <ScheduleIntroButton
      aphStyle={ss.scheduleIntroCta}
      onClick={() =>
        onInterestedClick(
          intros,
          dispatch,
          updateMatchesModalState,
          optionsProps
        )
      }
      buttonText="I'm interested"
    />
  );
};

InterestedCTA.propTypes = {
  data: PropTypes.object,
  location: PropTypes.string,
};

const MyContractorsCTAs = ({ data, fromInactiveMatch }) => {
  const { projectIntroduction: intro } = data;

  const [updateRenInterest] = useMutation(UPDATE_INTRO);

  if (fromInactiveMatch) {
    return (
      <TextLink
        onClick={() =>
          updateRenInterest({
            variables: {
              id: intro.id,
              renStillInterested: true,
            },
          })
        }
        aphStyle={ss.declineGCLink}
      >
        Undo?
      </TextLink>
    );
  }

  return <SelectContractorStack data={data} />;
};

MyContractorsCTAs.propTypes = {
  data: PropTypes.object,
  fromInactiveMatch: PropTypes.bool,
};

const RespondToMatchCTAs = forwardRef((props, ref) => {
  const { variant, introId } = props;
  const { data, loading } = useQuery(GET_EXPERIMENT_AND_INTRO_DATA, {
    variables: {
      introId,
    },
  });

  if (!loading) {
    if (variant === "interested") {
      return <InterestedCTA data={data} {...props} />;
    }
    if (variant === "gcProfile") {
      return <GCProfileMatchCTAs data={data} {...props} ref={ref} />;
    }
    return <MyContractorsCTAs data={data} {...props} />;
  }
  return null;
});

RespondToMatchCTAs.propTypes = {
  enabledForTwilioProxy: PropTypes.bool,
  variant: PropTypes.string,
  location: PropTypes.string,
  introId: PropTypes.string,
  showIntroTooltip: PropTypes.bool,
};

export default RespondToMatchCTAs;
