import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Header,
  Input,
  Body,
  styles,
  Row,
  Col,
  Tooltip,
  Icon,
  Card,
} from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import { useField, useForm } from "react-final-form-hooks";
import { required } from "../../../validators";
import { addCommas, trackMixpanel, trackMixpanelUserSet } from "../../../utils";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";

const ss = StyleSheet.create({
  mobileField: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        width: "100%",
      },
    }),
  },
  mobileContainer: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "block",
      },
    }),
  },
  estimate: {
    fontWeight: "bold",
  },
  estimateTooltip: {
    ...styles.center("vertical"),
  },
  stepInfo: {
    margin: "8px 0px",
  },
  estimator: {
    marginTop: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginTop: 16,
      },
    }),
  },
  estimatorCard: {
    backgroundColor: styles.colors.grey10,
    padding: "16px 24px",
  },
  budgetGraph: {
    ...styles.center("horizontal"),
    margin: "96px 0px 48px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        margin: "80px 0px 48px",
      },
    }),
  },
  graphContainerLowHighEnd: {
    position: "relative",
    margin: "0px 4px",
    height: 8,
    borderRadius: 15,
    width: 88,
    backgroundColor: styles.colors.blueLight,
  },
  graphContainerMiddle: {
    ...styles.center("horizontal"),
    height: 8,
    borderRadius: 15,
    width: 176,
    backgroundColor: styles.colors.brandPrimary,
  },
  dot: {
    borderRadius: "50%",
    height: 12,
    width: 12,
    backgroundColor: styles.colors.white,
    border: `1px solid ${styles.colors.brandPrimary}`,
  },
  estimateMinAlternative: {
    position: "absolute",
    top: 16,
    right: -24,
  },
  estimateMaxAlternative: {
    position: "absolute",
    top: 16,
    left: -32,
  },
  estimatorSubtext: {
    marginTop: 40,
    display: "flex",
    alignItems: "flex-start",
  },
  subtext: {
    marginLeft: 8,
    color: styles.colors.grey40,
    fontSize: 12,
    lineHeight: "18px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 11,
        lineHeight: "16px",
      },
    }),
  },
  tip: {
    color: styles.colors.grey40,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 11,
        lineHeight: "16px",
      },
    }),
  },
  headerCaption: {
    verticalAlign: "middle",
    color: styles.colors.grey40,
  },
});

export const BudgetEstimate = ({
  budgetEstimateMin,
  budgetEstimateMax,
  city,
}) => {
  if (budgetEstimateMin && budgetEstimateMax) {
    useEffect(() => {
      trackMixpanel("Viewed Budget Estimator");
    }, []);

    return (
      <Card aphStyle={ss.estimatorCard}>
        <Body type="div" variant="large" aphStyle={ss.estimate}>
          ${addCommas(budgetEstimateMin)} - ${addCommas(budgetEstimateMax)}
        </Body>
        <Body type="div" aphStyle={ss.tip}>
          Tip: Similar renovations in {city} have cost between
        </Body>
        <div className={css(ss.budgetGraph)}>
          <div className={css(ss.graphContainerLowHighEnd)}>
            <Body tag="span" aphStyle={ss.estimateMinAlternative}>
              ${addCommas(budgetEstimateMin)}
            </Body>
          </div>
          <div className={css(ss.graphContainerMiddle)}>
            <Tooltip
              theme="light"
              content="Most renovations fall in this range"
              position="top"
              isAlwaysDisplayed
            >
              <div className={css(ss.dot)} />
            </Tooltip>
          </div>
          <div className={css(ss.graphContainerLowHighEnd)}>
            <Body tag="span" aphStyle={ss.estimateMaxAlternative}>
              ${addCommas(budgetEstimateMax)}
            </Body>
          </div>
        </div>
        <div className={css(ss.estimatorSubtext)}>
          <div>
            <Icon name="info" size={24} color={styles.colors.brandPrimary} />
          </div>
          <Body tag="div" aphStyle={ss.subtext}>
            Costs will vary based on the quality of materials, and whether the
            renovation is cosmetic or gut, etc.
          </Body>
        </div>
      </Card>
    );
  }

  // show hubspot chat if no estimate
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.load();
  }

  return null;
};

BudgetEstimate.propTypes = {
  budgetEstimateMin: PropTypes.number,
  budgetEstimateMax: PropTypes.number,
  city: PropTypes.string,
};

export const maxBudgetValidator = (val, budgetMin) => {
  const numMax = Number(val);
  const numMin = Number(budgetMin);
  if (!numMax || numMax < 15000) {
    return "Maximum should be at least $15,000";
  }
  if (numMax <= numMin) {
    return "Maximum should be greater than minimum";
  }
  return "";
};

export const budgetOnChange = (field, val) => {
  const newVal = val.replace(/\D/g, "");
  if (newVal !== "0") {
    field.input.onChange({
      target: {
        value: newVal,
      },
    });
  }
};

export const determineBudgetMin = (budget, budgetEstimateMin) => {
  if (budget.budgetMin) {
    return budget.budgetMin.toString();
  }
  if (budgetEstimateMin && (!budget.budgetMin && !budget.budgetMax)) {
    return budgetEstimateMin.toString();
  }
  return "";
};

const Budget = ({ isActive, subject: project, setHandleSubmit, update }) => {
  const {
    budget,
    isEstimateHelpful,
    budgetEstimate: { budgetEstimateMin, budgetEstimateMax },
  } = project;

  const [budgetMinError, setBudgetMinError] = useState("");
  const [budgetMaxError, setBudgetMaxError] = useState("");

  const { form, handleSubmit } = useForm({
    initialValues: {
      budgetMin: determineBudgetMin(budget, budgetEstimateMin),
      budgetMax: budget.budgetMax ? budget.budgetMax.toString() : "",
      isEstimateHelpful,
    },
    onSubmit: formValues => {
      let hasError = false;
      if (!formValues.budgetMin) {
        setBudgetMinError(required);
        hasError = true;
      }
      const budgetMaxValidator = maxBudgetValidator(
        formValues.budgetMax,
        formValues.budgetMin
      );
      if (budgetMaxValidator) {
        setBudgetMaxError(budgetMaxValidator);
        hasError = true;
      }
      if (!hasError) {
        setBudgetMinError("");
        setBudgetMaxError("");
        if (budgetEstimateMin && budgetEstimateMax) {
          if (formValues.isEstimateHelpful) {
            trackMixpanel("Found Budget Estimate Helpful");
          }
          trackMixpanelUserSet({
            "Budget Estimate": formValues.isEstimateHelpful
              ? "Helpful"
              : "Not helpful / Didn't answer",
          });
        }
        update(formValues);
      }
    },
  });

  const budgetMin = useField("budgetMin", form);
  const budgetMax = useField("budgetMax", form);

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Budget");
  }, []);

  useEffect(() => {
    if (budgetMaxError) {
      trackViewError("budget max", budgetMax, budgetMaxError);
    } else if (budgetMinError) {
      trackViewError("budget min", budgetMin, budgetMinError);
    }
  }, [budgetMaxError, budgetMinError]);

  return (
    <>
      <Header tag="h5" style={{ marginBottom: 8 }}>
        What&apos;s your renovation budget, including labor and materials?
        <Body tag="span" variant="caption" aphStyle={ss.headerCaption}>
          {" "}
          (You’ll be able to adjust your budget later)
        </Body>
      </Header>
      <Row style={{ ...styles.center("vertical") }}>
        <Col
          width={{
            desktopLarge: 5,
            desktopStandard: 5,
            tabletStandard: 5,
            tabletSmall: 5,
            phoneStandard: 12,
            phoneSmall: 12,
          }}
          style={{ margin: "0px 12px" }}
        >
          <Input
            data-test="budget_min"
            error={budgetMinError}
            {...budgetMin.input}
            value={addCommas(budgetMin.input.value)}
            beforeElement="$"
            pattern="[0-9]*"
            onChange={val => budgetOnChange(budgetMin, val)}
            placeholder="Minimum Amount"
            style={{ lineHeight: "inherit" }}
          />
        </Col>
        <Col
          width={{
            desktopLarge: 2,
            desktopStandard: 2,
            tabletStandard: 2,
            tabletSmall: 2,
            phoneStandard: 12,
            phoneSmall: 12,
          }}
        >
          <Body
            tag="div"
            aphStyle={ss.mobileField}
            style={{ textAlign: "center", marginTop: 8 }}
          >
            {" "}
            to{" "}
          </Body>
        </Col>
        <Col
          width={{
            desktopLarge: 5,
            desktopStandard: 5,
            tabletStandard: 5,
            tabletSmall: 5,
            phoneStandard: 12,
            phoneSmall: 12,
          }}
          style={{ position: "relative", margin: "0px 12px" }}
        >
          <Input
            data-test="budget_max"
            error={budgetMaxError}
            {...budgetMax.input}
            value={addCommas(budgetMax.input.value)}
            beforeElement="$"
            aphStyle={ss.mobileField}
            pattern="[0-9]*"
            onChange={val => budgetOnChange(budgetMax, val)}
            placeholder="Maximum Amount"
            style={{ lineHeight: "inherit" }}
          />
        </Col>
      </Row>
      <Row aphStyle={ss.estimator}>
        <Col width={12}>
          <BudgetEstimate
            budgetEstimateMin={budgetEstimateMin}
            budgetEstimateMax={budgetEstimateMax}
            city={project.location.city}
          />
        </Col>
      </Row>
    </>
  );
};

Budget.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default Budget;
