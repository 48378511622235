import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormLabel,
  Input,
  Modal,
  TextLink,
  styles,
} from "@sweeten/oreo";
import { StyleSheet } from "aphrodite";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useForm, useField } from "react-final-form-hooks";
import { fieldError } from "../../components/payments/form_utils";
import { required } from "../../validators";

const UPDATE_PROJECT = gql`
  mutation projectUpdate($id: ID!, $attributes: ProjectUpdateInput!) {
    projectUpdate(id: $id, attributes: $attributes) {
      project {
        id
        title
      }
    }
  }
`;

const ss = StyleSheet.create({
  cancelLink: {
    marginRight: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginRight: 0,
        marginTop: 16,
      },
    }),
  },
  ctaContainer: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        flexFlow: "column-reverse nowrap",
        marginTop: 0,
      },
    }),
  },
});

const ProjectTitleModal = ({ closeModal, project, showSuccessAlert }) => {
  const [update, { loading: updatingProject }] = useMutation(UPDATE_PROJECT);

  const { form, handleSubmit } = useForm({
    initialValues: { title: project.title },
    onSubmit: values =>
      update({
        variables: {
          id: project.id,
          attributes: values,
        },
      }).then(() => {
        closeModal();
        showSuccessAlert();
      }),
  });

  const title = useField("title", form, required);

  return (
    <Modal onClose={closeModal} style={{ width: 613, padding: 48 }}>
      <Modal.Header>Project Title</Modal.Header>
      <Modal.Body>
        <FormLabel>Title</FormLabel>
        <Input
          {...title.input}
          error={fieldError(title)}
          style={{ marginBottom: 24 }}
        />
      </Modal.Body>
      <Modal.Footer aphStyle={ss.ctaContainer}>
        <TextLink onClick={closeModal} aphStyle={ss.cancelLink}>
          Cancel
        </TextLink>
        <Button
          loading={updatingProject}
          onClick={handleSubmit}
          style={{ cursor: "pointer" }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ProjectTitleModal.propTypes = {
  closeModal: PropTypes.func,
  project: PropTypes.object,
  showSuccessAlert: PropTypes.func,
};

export default ProjectTitleModal;
