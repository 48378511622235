/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
import {
  ArrowLink,
  Button,
  Body,
  Col,
  FormLabel,
  Header,
  Icon,
  Input,
  Row,
  styles,
  TextLink,
} from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import { gql } from "apollo-boost";
import { withApollo } from "react-apollo";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useForm, useField } from "react-final-form-hooks";
import { get } from "lodash";
import compareSvgPath from "contact_info/compare.svg";
import introducedSvgPath from "contact_info/introduced.svg";
import scheduleSvgPath from "contact_info/schedule.svg";
import {
  goToPage,
  hubspotNotifyProjectUpdate,
  trackMixpanelWithCallback,
  loginWithToken,
  trackMixpanel,
} from "../../utils";
import {
  required,
  validateEmail,
  password as passwordValidator,
  name as nameValidator,
  validate,
} from "../../validators";
import { trackViewNeedsInfo } from "../project_posting/tracking_events";
import SocialAuth from "../../guest/social_auth";
import PhoneFieldGroup from "../../components/account_forms/phone_field_group";
import UpdatePhoneNumberModal from "../../components/modals/update_phone_number_modal";
import { PROJECT_POST } from "../project_posting/graphql_constants";

const { breakpoints, center, colors } = styles;

const nameInputColWidths = {
  desktopMax: 6,
  desktopLarge: 6,
  desktopStandard: 6,
  tabletStandard: 12,
  tabletSmall: 12,
  phoneStandard: 12,
  phoneSmall: 12,
};

/* =================== Styles =================== */

const horizontalLine = {
  content: "''",
  flex: 1,
  borderBottom: `1px solid ${colors.grey20}`,
};

const ss = StyleSheet.create({
  bodyText: {
    color: colors.grey40,
    textAlign: "center",
    marginLeft: 8,
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: { margin: "8px 0 0 0" },
    }),
  },
  center: { ...center() },
  firstNameInput: {
    marginRight: 8,
    ...styles.mediaQuery({
      maxWidth: breakpoints.tabletStandard,
      style: { marginRight: 0 },
    }),
  },
  formContainer: {
    ...center(),
    maxWidth: 433,
    width: "100%",
  },
  header: {
    textAlign: "center",
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: {
        fontSize: 18,
        lineHeight: "24px",
        marginTop: 24,
      },
    }),
  },
  headerIconContainer: {
    ...center(),
    flexDirection: "column",
    maxWidth: 680,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: colors.grey10,
    border: `1px solid ${colors.grey20}`,
    borderRadius: 32,
    margin: "24px 0",
    width: "calc(100% - 48px)",
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: { borderRadius: 3, padding: 16 },
    }),
  },
  iconBodyContainer: {
    ...center(),
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: { flexDirection: "column" },
    }),
  },
  siteVisitCompareEstimateIconBody: {
    ...center(),
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: { flexDirection: "column", paddingTop: 1 },
    }),
  },
  shareContact: {
    color: colors.grey40,
    textAlign: "center",
    marginTop: 0,
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: {
        fontSize: 12,
      },
    }),
  },
  termsOfServicePP: {
    marginTop: 16,
    padding: "0 24px",
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: {
        fontSize: 12,
      },
    }),
  },
  textLink: {
    color: colors.grey40,
    borderBottom: `1px dashed ${colors.grey40}`,
    fontSize: 12,
  },
  socialAuthText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: colors.grey40,
    ":before": {
      ...horizontalLine,
      marginRight: 8,
    },
    ":after": {
      ...horizontalLine,
      marginLeft: 8,
    },
  },
  socialAuthContainer: {
    maxWidth: 433,
    margin: "auto",
    marginTop: 8,
    padding: "0px 24px",
  },
  greyLine: {
    margin: "24px 0px 16px",
    ...horizontalLine,
  },
  formError: {
    color: colors.redDark,
    lineHeight: "18px",
    fontSize: 12,
    position: "absolute",
    display: "block",
  },
  signInError: {
    fontSize: 12,
    lineHeight: "18px",
  },
  tooltip: {
    width: 500,
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: {
        width: 250,
      },
    }),
  },
});

/* ======= Mixpanel Event Triggers ======= */

const trackCreatedAccount = () => {
  trackMixpanel("Created Account", {
    "Registration Page Type": "Register Later",
  });
};

const trackViewSignup = () => {
  trackMixpanel("Viewed Sign Up", {
    "Registration Page Type": "Register Later",
  });
};

const trackViewEmailExistsError = () => {
  trackMixpanel("Viewed Error for Email Already Exists");
};

const trackSubmittedContactInfo = callback => {
  trackMixpanelWithCallback("Clicked View General Contractors", {}, callback);
};

/* =================== Hubspot Proxy Tracking =================== */

const notifyHubspotProxy = projectId => {
  hubspotNotifyProjectUpdate({
    projectId,
    eventName: "journey-contact-details",
  });
};

/* =================== Queries/Mutations =================== */

const GET_PROJECT_DATA = gql`
  query getProjectData($id: ID!) {
    project(id: $id) {
      id
      isAngiLead
      shouldAutoOptInToMatches
      user {
        id
        email
        firstName
        lastName
        phone
      }
    }
  }
`;

const CONFIRM_CONTACT = gql`
  mutation confirmUserContactInfo($attributes: UserCreateInput!) {
    userCreate(attributes: $attributes) {
      user {
        id
        loginToken
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUserContactInfo($attributes: UserUpdateInput!) {
    userUpdate(attributes: $attributes) {
      user {
        id
        loginToken
      }
    }
  }
`;

/* =================== Components =================== */

const ContactInfo = withApollo(({ client, data }) => {
  const { project } = data;
  const { id: projectId, isAngiLead, shouldAutoOptInToMatches, user } = project;
  const { email, firstName, lastName, phone } = user || {};

  const [confirmContact, { loading: mutationLoading }] = useMutation(
    CONFIRM_CONTACT
  );
  const [updateContact, { loading: updateMutationLoading }] = useMutation(
    UPDATE_USER
  );
  const [postProject, { loading: projectPostingLoading }] = useMutation(
    PROJECT_POST
  );

  const [socialPropsForPhoneModal, setPropsForPhoneModal] = useState(null);

  useEffect(() => {
    trackViewSignup();
  }, []);

  const callConfirmContact = async (form, values) => {
    if (user) {
      await postProject({
        variables: {
          id: projectId,
        },
      });
      await updateContact({
        variables: {
          attributes: {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
          },
        },
      });
    } else {
      const { data: mutationData } = await confirmContact({
        variables: {
          attributes: { ...values, lastCompletedStep: "contactDetails" },
        },
      });
      const token = get(mutationData, "userCreate.user.loginToken");
      await loginWithToken(token);

      trackCreatedAccount();
      trackViewNeedsInfo();
    }

    notifyHubspotProxy(projectId);
    trackSubmittedContactInfo(() =>
      goToPage(`/projects/${projectId}/matching`)
    );
  };

  const { form, handleSubmit } = useForm({
    initialValues: {
      defaultProjectId: projectId,
      smsOptIn: false,
      email,
      firstName,
      lastName,
      phone,
    },
    onSubmit: values => callConfirmContact(form, values),
  });

  const firstNameField = useField("firstName", form, val => {
    const validators = [required, nameValidator];
    return validate(validators, val);
  });
  const lastNameField = useField("lastName", form, val => {
    const validators = [required, nameValidator];
    return validate(validators, val);
  });
  const emailField = useField("email", form, value =>
    validateEmail(value, client)
  );
  const emailError = emailField.meta.touched && emailField.meta.error;
  const password =
    !user || isAngiLead
      ? useField("password", form, val => {
          const validators = [required, passwordValidator];
          return validate(validators, val);
        })
      : null;

  const passwordOnChange = (field, val) => {
    const newVal = val.replace(/\s/g, "");
    field.input.onChange({
      target: {
        value: newVal,
      },
    });
  };

  useEffect(() => {
    if (emailError === "Email is taken") {
      trackViewEmailExistsError();
    }
  }, [emailError]);

  return (
    <>
      {socialPropsForPhoneModal && (
        <UpdatePhoneNumberModal
          onClose={() => setPropsForPhoneModal(null)}
          onSuccessCb={() => {
            socialPropsForPhoneModal.onClick();
            setPropsForPhoneModal(null);
          }}
          shouldSetAsCookie
        />
      )}
      <div style={{ padding: "0 24px" }}>
        <ArrowLink
          onClick={() => goToPage(`/journey/${projectId}`)}
          variant="back"
        >
          Back
        </ArrowLink>
        <div className={css(ss.headerIconContainer)}>
          <Header tag="h2" aphStyle={ss.header}>
            Good news! We have contractors who match your project.
          </Header>
          <div className={css(ss.iconContainer)}>
            <div className={css(ss.iconBodyContainer)}>
              <img src={introducedSvgPath} alt="get introduced" />
              <Body tag="p" aphStyle={ss.bodyText}>
                Get introduced
              </Body>
            </div>
            <div className={css(ss.siteVisitCompareEstimateIconBody)}>
              <img src={scheduleSvgPath} alt="schedule site visits" />
              <Body tag="p" aphStyle={ss.bodyText}>
                Schedule site visits
              </Body>
            </div>
            <div className={css(ss.siteVisitCompareEstimateIconBody)}>
              <img src={compareSvgPath} alt="compare estimates" />
              <Body tag="p" aphStyle={ss.bodyText}>
                Compare estimates
              </Body>
            </div>
          </div>
          <Body tag="p" aphStyle={ss.shareContact}>
            Sign up or sign in to view your contractors.
          </Body>
        </div>
      </div>

      <Row aphStyle={ss.formContainer}>
        <Col width={nameInputColWidths}>
          <FormLabel>First Name</FormLabel>
          <Input
            {...firstNameField.input}
            autofocus
            data-test="first_name"
            error={firstNameField.meta.touched && firstNameField.meta.error}
            aphStyle={ss.firstNameInput}
          />
        </Col>
        <Col width={nameInputColWidths}>
          <FormLabel>Last Name</FormLabel>
          <Input
            {...lastNameField.input}
            data-test="last_name"
            error={lastNameField.meta.touched && lastNameField.meta.error}
          />
        </Col>
        <Col width={12}>
          <FormLabel>Email Address</FormLabel>
          <Input
            {...emailField.input}
            data-test="email_address"
            disabled={!!user || (isAngiLead && !!email)}
            error={
              emailField.meta.error === "Email is taken"
                ? emailField.meta.touched && !!emailField.meta.error
                : emailField.meta.touched && emailField.meta.error
            }
          />
          {emailField.meta.error === "Email is taken" && (
            <Body tag="span" aphStyle={ss.formError}>
              We already have an account with that email. Please{" "}
              <TextLink
                onClick={() => {
                  trackMixpanelWithCallback(
                    "Clicked Sign In on Contact Form Error",
                    {},
                    () => goToPage("/login")
                  );
                }}
                aphStyle={ss.signInError}
              >
                sign in.
              </TextLink>
            </Body>
          )}
        </Col>
        <Col width={12}>
          <PhoneFieldGroup
            form={form}
            isOptional={!shouldAutoOptInToMatches}
            showTos={false}
            label="Phone number where we can text you important project updates"
          />
        </Col>
        {(!user || isAngiLead) && (
          <Col width={12}>
            <FormLabel>Password</FormLabel>
            <Input
              {...password.input}
              afterElement={
                <Icon color={colors.grey30} name="lock" size={16} />
              }
              data-test="password"
              error={password.meta.touched && password.meta.error}
              onChange={val => passwordOnChange(password, val)}
              placeholder="Minimum 8 characters"
              style={{ marginBottom: 8 }}
              type="password"
            />
          </Col>
        )}
        <Col width={12}>
          <Button
            data-test="submit"
            label="View General Contractors"
            loading={
              mutationLoading || updateMutationLoading || projectPostingLoading
            }
            onClick={handleSubmit}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      {!user && (
        <>
          <div className={css(ss.center)}>
            <Body tag="p" variant="caption" aphStyle={ss.termsOfServicePP}>
              By clicking, you agree to our&nbsp;
              <TextLink
                aphStyle={ss.textLink}
                href="/terms-of-use"
                target="_blank"
              >
                Terms of Service
              </TextLink>
              &nbsp; and&nbsp;
              <TextLink
                aphStyle={ss.textLink}
                href="/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </TextLink>
              .
            </Body>
          </div>
          <div className={css(ss.socialAuthContainer)}>
            <div className={css(ss.socialAuthText)}>Or sign up with</div>
            <div style={{ marginTop: 32 }}>
              <SocialAuth
                creatingAccount
                includeLoginDivider={false}
                callback={() => {
                  notifyHubspotProxy(projectId);
                  trackSubmittedContactInfo(() =>
                    goToPage(`/projects/${projectId}/matching`)
                  );
                }}
                onClick={
                  shouldAutoOptInToMatches ? setPropsForPhoneModal : null
                }
              />
              <div className={css(ss.greyLine)} />
              <div>
                Already have an account?{" "}
                <TextLink
                  onClick={() => {
                    trackMixpanelWithCallback(
                      "Clicked Sign In on Contact Form",
                      {},
                      () => goToPage("/login")
                    );
                  }}
                >
                  Sign in
                </TextLink>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});

export default props => {
  // eslint-disable-next-line react/prop-types
  const { projectId } = props;

  const { data, loading } = useQuery(GET_PROJECT_DATA, {
    variables: {
      id: projectId,
    },
  });

  return loading ? null : <ContactInfo data={data} {...props} />;
};
