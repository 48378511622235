import React from "react";
import PropTypes from "prop-types";
import { Body, Button, Card, Header, Icon, Modal, styles } from "@sweeten/oreo";
import { StyleSheet } from "aphrodite";
import { useMutation } from "@apollo/react-hooks";
import { GC_RESPONSE } from "../../components/match_banner";
import { goToPage } from "../../utils";
import PhoneSvg from "../../../assets/images/phone.svg";

const flexColumnCentered = {
  ...styles.center(),
  flexFlow: "column nowrap",
};

const ss = StyleSheet.create({
  modalBody: {
    ...flexColumnCentered,
    padding: "24px 72px 0px 72px",
    marginBottom: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginBottom: 0,
        padding: 0,
      },
    }),
  },
  header: {
    marginTop: 34,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginTop: 27,
        fontSize: 20,
      },
    }),
  },
  subHeader: {
    color: styles.colors.grey40,
    textAlign: "center",
    marginTop: 20,
    marginBottom: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginBottom: 24,
      },
    }),
  },
  modalFooter: {
    ...flexColumnCentered,
    padding: "0px 72px 24px 72px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        padding: 0,
      },
    }),
  },
  card: {
    display: "flex",
    backgroundColor: styles.colors.blueLight,
    padding: 12,
    border: "none",
  },
  button: {
    marginTop: 20,
    width: 315,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        width: "100%",
      },
    }),
  },
});

const NextStepsModal = ({
  enabledForTwilioProxy,
  name,
  onClose,
  phoneNumberForProxySessions,
  proxyPhoneNumberForGc,
  phone,
  introId,
}) => {
  const [respondToMatch, { loading: respondingToMatch }] = useMutation(
    GC_RESPONSE
  );

  return (
    <Modal style={{ width: 652 }}>
      <Modal.Body aphStyle={ss.modalBody}>
        <img alt="" src={PhoneSvg} />
        <Header tag="h5" aphStyle={ss.header}>
          What&apos;s next
        </Header>
        <Body tag="div" variant="large" aphStyle={ss.subHeader}>
          This client is waiting to hear from you. Don’t lose this job to
          another contractor! Call or text as soon as possible:
        </Body>
        <Body
          tag="div"
          variant="large"
          style={{
            fontWeight: 700,
            color: styles.colors.brandPrimary,
            marginBottom: 8,
          }}
        >
          {enabledForTwilioProxy ? proxyPhoneNumberForGc : phone}
        </Body>
        <Body tag="div" variant="large" style={{ fontWeight: 700 }}>
          {name}
        </Body>
      </Modal.Body>
      <Modal.Footer aphStyle={ss.modalFooter}>
        {enabledForTwilioProxy && (
          <Card aphStyle={ss.card}>
            <Icon
              name="info"
              size={16}
              style={{
                color: styles.colors.blueInfo,
                marginRight: 10,
                minWidth: 16,
                marginTop: 4,
              }}
            />
            <Body
              tag="div"
              variant="caption"
              style={{ color: styles.colors.blueInfo, fontSize: 12 }}
            >
              You’ll have to use the phone you registered with Sweeten for this
              project: {phoneNumberForProxySessions}.
            </Body>
          </Card>
        )}
        <Button
          onClick={() =>
            !enabledForTwilioProxy
              ? respondToMatch({
                  variables: {
                    id: introId,
                    isAccepting: true,
                    company: {},
                  },
                }).then(() =>
                  goToPage(
                    `/feedback/expert-ask?intro_id=${introId}&response=yes`
                  )
                )
              : onClose()
          }
          loading={respondingToMatch}
          aphStyle={ss.button}
        >
          Got it!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

NextStepsModal.propTypes = {
  enabledForTwilioProxy: PropTypes.bool,
  name: PropTypes.string,
  onClose: PropTypes.func,
  phoneNumberForProxySessions: PropTypes.string,
  proxyPhoneNumberForGc: PropTypes.string,
  phone: PropTypes.string,
  introId: PropTypes.number,
};

export default NextStepsModal;
