import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Body, Header, TextLink, styles } from "@sweeten/oreo";
import { goToPage } from "../../../utils";
import { trackViewStepPrimeForm } from "../tracking_events";

const handleSubmit = () => {
  goToPage("/overview");
};

const NoOwnership = ({ isActive, setHandleSubmit }) => {
  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("No Ownership");
  }, []);

  return (
    <>
      <Header tag="h5">We&apos;ll be able to help you renovate soon</Header>
      <Body style={{ color: styles.colors.grey40 }} tag="p">
        We can start planning your renovation with you as soon as you are in
        contract on the property. Please check back with us then.
      </Body>
      <Body tag="p" variant="bold">
        In the mean time, we hope you’ll find our renovation&nbsp;
        <TextLink
          onClick={() =>
            goToPage(
              "https://blog.sweeten.com/category/renovation-101/cost-guides/",
              { shouldOpenNewTab: true }
            )
          }
        >
          cost guides
        </TextLink>
        &nbsp;and&nbsp;
        <TextLink
          onClick={() =>
            goToPage("https://blog.sweeten.com/", { shouldOpenNewTab: true })
          }
        >
          our blog
        </TextLink>
        &nbsp;helpful.
      </Body>
    </>
  );
};

NoOwnership.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
};

export default NoOwnership;
