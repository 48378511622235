/* eslint-disable react/default-props-match-prop-types */
import React from "react";
import {
  Circle,
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import PropTypes from "prop-types";
import { styles } from "@sweeten/oreo";

const Map = withScriptjs(
  withGoogleMap(({ lat, lng, showExactLocation }) => {
    return (
      <GoogleMap
        defaultZoom={showExactLocation ? 15 : 14}
        defaultCenter={{ lat, lng }}
      >
        {showExactLocation && <Marker position={{ lat, lng }} />}
        {!showExactLocation && (
          <Circle
            center={{ lat, lng }}
            options={{
              fillColor: styles.colors.grey40,
              strokeColor: styles.colors.grey50,
              strokeWeight: 1,
            }}
            radius={1000}
          />
        )}
      </GoogleMap>
    );
  })
);

Map.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  showExactLocation: PropTypes.bool,
};

Map.defaultProps = {
  googleMapURL:
    // eslint-disable-next-line max-len
    `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=${process.env.GOOGLE_MAPS_KEY}`,
  loadingElement: <div style={{ height: "100%" }} />,
  containerElement: <div style={{ height: "191px" }} />,
  mapElement: <div style={{ height: "100%", borderRadius: 10 }} />,
};

export default Map;
