import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { styles, Icon } from "@sweeten/oreo";

const ss = StyleSheet.create({
  iconCircle: {
    ...styles.center(""),
    borderRadius: "50%",
    width: 20,
    height: 20,
    border: `1px solid ${styles.colors.brandPrimary}`,
    boxSizing: "border-box",
    "::-webkit-box-sizing": "border-box",
    "::-moz-box-sizing": "border-box",
    marginRight: 12,
  },
  greyCircle: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: styles.colors.grey20,
    marginRight: 12,
  },
});

const Checkmark = ({ notChecked }) =>
  !notChecked ? (
    <div className={css(ss.iconCircle)}>
      <Icon name="checkmark" size={12} color={styles.colors.brandPrimary} />
    </div>
  ) : (
    <div className={css(ss.greyCircle)} />
  );

Checkmark.propTypes = {
  notChecked: PropTypes.bool,
};

export default Checkmark;
