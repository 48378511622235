import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, TextLink } from "@sweeten/oreo";
import PhotoUploader from "./photo_uploader";

const AfterPhotoUploaderModal = ({ projectId, onClose }) => {
  return (
    <Modal onClose={onClose}>
      <Modal.Header>Upload after photos</Modal.Header>
      <Modal.Body>
        <PhotoUploader projectId={projectId} category="after" />
      </Modal.Body>
      <Modal.Footer>
        <TextLink onClick={onClose} style={{ marginRight: 32 }}>
          Cancel
        </TextLink>
        <Button onClick={onClose}>Done</Button>
      </Modal.Footer>
    </Modal>
  );
};

AfterPhotoUploaderModal.propTypes = {
  onClose: PropTypes.func,
  projectId: PropTypes.string,
};

export default AfterPhotoUploaderModal;
