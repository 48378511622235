import React from "react";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite";
import { Button, Header, Icon, TextLink, styles } from "@sweeten/oreo";
import StickyBanner from "../components/sticky_banner";
import { goToPage, getFormAuthToken } from "../utils";

const ss = StyleSheet.create({
  copyContainer: {
    display: "flex",
    flewFlow: "row nowrap",
    alignItems: "center",
  },
  hideOnTabletSmall: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        display: "none",
      },
    }),
  },
  showOnTabletSmall: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        display: "block",
      },
    }),
  },
  ctaContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        marginLeft: 0,
      },
    }),
  },
  renGuideLink: {
    marginRight: 24,
    whiteSpace: "nowrap",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneSmall,
      style: {
        whiteSpace: "normal",
      },
    }),
  },
});

const PreviewHeader = () => (
  <div className={css(ss.copyContainer, ss.hideOnTabletSmall)}>
    <Icon
      color={styles.colors.black}
      name="preview"
      size={16}
      style={{ marginRight: 8 }}
    />
    <Header tag="h6">Project Preview</Header>
  </div>
);

const ShowMatchesButton = ({ requestMatchesUrl }) => {
  return (
    <form action={requestMatchesUrl} method="post">
      <div style={{ marginLeft: 24 }}>
        <Button type="submit">Show Me My Matches</Button>
        <input
          name="authenticity_token"
          type="hidden"
          value={getFormAuthToken()}
        />
      </div>
    </form>
  );
};

ShowMatchesButton.propTypes = {
  requestMatchesUrl: PropTypes.string,
};

export const PreviewBanner = props => {
  const { canRequestMatches, requestMatchesUrl } = props;

  return (
    <StickyBanner>
      <StickyBanner.Content header={<PreviewHeader />}>
        This is how potential general contractors will see your project.
        <br />
        Your contact info will be visible only to general contractors
        you&apos;ve asked to be introduced to.
      </StickyBanner.Content>
      <div className={css(ss.ctaContainer)}>
        {canRequestMatches && (
          <ShowMatchesButton requestMatchesUrl={requestMatchesUrl} />
        )}
      </div>
    </StickyBanner>
  );
};

PreviewBanner.propTypes = {
  canRequestMatches: PropTypes.bool,
  requestMatchesUrl: PropTypes.string,
};

PreviewBanner.defaultProps = {
  canRequestMatches: false,
};

export const EditProjectBanner = () => (
  <StickyBanner>
    <StickyBanner.Content header={<PreviewHeader />}>
      This is how potential General Contractors will see your project proposal.
    </StickyBanner.Content>
    <div className={css(ss.ctaContainer)}>
      <TextLink
        onClick={() => goToPage("/overview")}
        aphStyle={ss.renGuideLink}
      >
        Back to Dashboard
      </TextLink>
    </div>
  </StickyBanner>
);
