import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite/no-important";
import { Body, Button, LeftNav, TopNav, styles, Icon } from "@sweeten/oreo";
import { dropdownNavLinks, mobileNavLinks } from "./data";
import {
  ContentWrapper,
  LayoutWrapper,
  LeftNavItem,
} from "../../components/layout";
import PlatformGraphqlClient from "../../components/apollo/platform_graphql_client";
import { goToPage } from "../../utils";
import AppProvider from "../../components/app_provider";
import ProgressBar from "../../components/progress_bar";

const ss = StyleSheet.create({
  separator: {
    display: "inline-block",
    height: 30,
    borderLeft: `1px solid ${styles.colors.grey20}`,
    marginLeft: 32,
    marginRight: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopLarge,
      style: {
        marginLeft: 20,
        marginRight: 20,
      },
    }),
  },
  navLinkMargins: {
    marginLeft: 48,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopLarge,
      style: {
        marginLeft: 24,
      },
    }),
  },
  navButton: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopLarge,
      style: {
        height: 32,
        fontSize: 13,
      },
    }),
  },
  navLinkContainer: {
    display: "flex",
    alignItems: "center",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: { display: "none" },
    }),
  },
  withStepCounter: {
    justifyContent: "initial",
  },
  desktop: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: { display: "none" },
    }),
  },
  mobile: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: { display: "block" },
    }),
  },
  stepNumber: {
    color: styles.colors.brandPrimary,
    marginLeft: 226,
    ...styles.mediaQuery({
      minWidth: styles.breakpoints.tabletStandard + 1,
      maxWidth: styles.breakpoints.desktopLarge,
      style: { marginLeft: 200 },
    }),
  },
  mobileProgress: {
    position: "fixed",
    top: TopNav.style.mobile.height,
    height: TopNav.style.mobile.height,
    backgroundColor: styles.colors.white,
    ...styles.center("vertical"),
    width: "100%",
    color: styles.colors.brandPrimary,
    fontWeight: "bold",
  },
  progress: {
    position: "fixed",
    top: TopNav.style.desktop.height,
    height: TopNav.style.desktop.height,
    backgroundColor: styles.colors.grey10,
    ...styles.center("vertical"),
    width: "100%",
    marginLeft: -40,
  },
  mobileProgressBar: {
    marginLeft: "auto",
    marginRight: 40,
    display: "flex",
    alignItems: "center",
    width: "25%",
    minWidth: 48,
  },
  progressBar: {
    position: "absolute",
    top: TopNav.style.desktop.height,
    width: "100%",
  },
  step: {
    padding: "0px 24px",
    ...styles.center("vertical"),
    fontWeight: 500,
    color: styles.colors.grey40,
    height: "100%",
  },
  currentStep: {
    fontWeight: 700,
    color: styles.colors.brandPrimary,
    backgroundColor: styles.colors.white,
  },
});

const stepToName = {
  1: "INTRODUCTION",
  2: "PORTFOLIO",
  3: "SCHEDULE A CALL",
  4: "SUBMIT",
};

const totalSteps = Object.keys(stepToName).length;

const Step = ({ stepNumber, currentStepNumber, aphStyle, style }) => (
  <div
    className={css(
      ss.step,
      aphStyle,
      currentStepNumber === stepNumber && ss.currentStep
    )}
    style={style}
  >
    {stepNumber < currentStepNumber ? (
      <Icon
        name="checkmark"
        color={styles.colors.greenSuccess}
        size={16}
        style={{ marginRight: 12 }}
      />
    ) : (
      <span style={{ marginRight: 4 }}>{stepNumber}.</span>
    )}
    {stepToName[stepNumber]}
  </div>
);

Step.propTypes = {
  currentStepNumber: PropTypes.number,
  aphStyle: PropTypes.object,
  style: PropTypes.object,
  stepNumber: PropTypes.number,
};

const GCSteps = ({ stepNumber }) => {
  return (
    <div className={css(ss.progress)}>
      <Step
        stepNumber={1}
        currentStepNumber={stepNumber}
        style={{ marginLeft: 16 }}
      />
      <Step stepNumber={2} currentStepNumber={stepNumber} />
      <Step stepNumber={3} currentStepNumber={stepNumber} />
      <Step stepNumber={4} currentStepNumber={stepNumber} />
      <div className={css(ss.progressBar)}>
        <ProgressBar totalSteps={totalSteps} stepsCompleted={stepNumber - 1} />
      </div>
    </div>
  );
};

GCSteps.propTypes = {
  stepNumber: PropTypes.number,
};

const GCStepMobile = ({ stepNumber }) => {
  return (
    <div className={css(ss.mobileProgress)}>
      <div style={{ display: "flex" }}>
        {stepNumber}. {stepToName[stepNumber]}
      </div>
      <div className={css(ss.mobileProgressBar)}>
        <ProgressBar
          totalSteps={totalSteps}
          stepsCompleted={stepNumber - 1}
          hideStepCounter
        />
      </div>
    </div>
  );
};

GCStepMobile.propTypes = {
  stepNumber: PropTypes.number,
};

const GuestLayout = props => {
  const {
    children,
    contentAphStyle,
    currentPage,
    hideTopNav,
    hideTopNavContent,
    layoutAphStyle,
    sweetenLogoPath,
    stepNumber,
  } = props;

  return (
    <LayoutWrapper aphStyle={layoutAphStyle}>
      {!hideTopNav && (
        <TopNav
          handleLogoClick={() => goToPage(sweetenLogoPath)}
          isLeftNavLayout={false}
          logoPath={sweetenLogoPath}
          aphStyle={!!stepNumber && ss.withStepCounter}
        >
          <LeftNav.Mobile>
            <div role="navigation" style={{ margin: "16px 0" }}>
              {mobileNavLinks.map(item => {
                const shouldBeActive = item.label === currentPage;

                return (
                  <LeftNavItem {...item} isActive={shouldBeActive}>
                    <Body tag="p">{item.label}</Body>
                  </LeftNavItem>
                );
              })}
            </div>
          </LeftNav.Mobile>
          {!hideTopNavContent && (
            <>
              <div className={css(ss.navLinkContainer)}>
                <TopNav.Link
                  aphStyle={ss.navLink}
                  href="/signup/renovator"
                  variant="black"
                  id="start_your_renovation_nav_cta"
                >
                  Start Your Renovation
                </TopNav.Link>
                <TopNav.Dropdown
                  aphStyle={ss.navLinkMargins}
                  label="How It Works"
                  links={dropdownNavLinks}
                />
                <TopNav.Link
                  aphStyle={ss.navLinkMargins}
                  href="https://sweeten.com/general-contractor-network/"
                  variant="black"
                >
                  For Contractors
                </TopNav.Link>
                <TopNav.Link
                  aphStyle={ss.navLinkMargins}
                  href="https://sweeten.com/blog/"
                  variant="black"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </TopNav.Link>
                <div className={css(ss.separator)} />
                <TopNav.Link
                  href="/login/renovator"
                  style={{ marginRight: 24 }}
                  variant="black"
                >
                  Log In
                </TopNav.Link>
                <Button
                  aphStyle={ss.navButton}
                  variant="outlineDark"
                  label="Sign Up"
                  id="nav_signup_cta"
                  onClick={() => goToPage("/signup/renovator")}
                />
              </div>
            </>
          )}
          {stepNumber && (
            <>
              <div className={css(ss.desktop)}>
                <Body tag="span" aphStyle={ss.stepNumber}>
                  Step {stepNumber} of {totalSteps}
                </Body>
                <GCSteps stepNumber={stepNumber} />
              </div>
              <div className={css(ss.mobile)}>
                <GCStepMobile stepNumber={stepNumber} />
              </div>
            </>
          )}
        </TopNav>
      )}
      <ContentWrapper
        aphStyle={contentAphStyle}
        hasProgress={stepNumber}
        hideTopNav={hideTopNav}
      >
        {children}
      </ContentWrapper>
    </LayoutWrapper>
  );
};

const GuestLayoutWrapper = props => (
  <PlatformGraphqlClient>
    <AppProvider>
      <GuestLayout {...props} />
    </AppProvider>
  </PlatformGraphqlClient>
);

GuestLayout.propTypes = {
  children: PropTypes.PropTypes.element.isRequired,
  contentAphStyle: PropTypes.object,
  currentPage: PropTypes.string,
  hideTopNav: PropTypes.bool,
  hideTopNavContent: PropTypes.bool,
  layoutAphStyle: PropTypes.object,
  sweetenLogoPath: PropTypes.string,
  stepNumber: PropTypes.number,
};

GuestLayout.defaultProps = {
  hideTopNav: false,
  hideTopNavContent: false,
};

export default GuestLayoutWrapper;
