import React from "react";
import PropTypes from "prop-types";
import {
  Body,
  Button,
  Checkbox,
  Input,
  Modal,
  TextLink,
  styles,
} from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import { useForm, useField } from "react-final-form-hooks";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { withApollo } from "react-apollo";
import {
  required,
  phoneNumber as phoneNumberValidator,
  validate,
} from "../../validators";
import { fieldError } from "../../components/payments/form_utils";
import { GC_RESPONSE } from "../../components/match_banner";
import { formatPhoneNumber, goToPage } from "../../utils";

const UPDATE_COMPANY = gql`
  mutation updateCompany($attributes: CompanyUpdateInput!) {
    companyUpdate(attributes: $attributes) {
      currentUser {
        company {
          id
          phoneNumberForProxySessions
          isOptedInForProxySms
        }
      }
    }
  }
`;

const ss = StyleSheet.create({
  modalWrapper: {
    width: 652,
    padding: "56px 64px 39px 64px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        padding: 32,
      },
    }),
  },
  phoneInput: {
    width: 353,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        width: "auto",
      },
    }),
  },
  modalFooter: {
    display: "flex",
    flexFlow: "row nowrap",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "flex",
        flexFlow: "column-reverse",
      },
    }),
  },
  cancelLink: {
    color: styles.colors.black,
    marginRight: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginRight: 0,
        marginTop: 21,
      },
    }),
  },
  confirmCta: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        width: "100%",
      },
    }),
  },
  checkboxAndText: {
    display: "flex",
    flexFlow: "row nowrap",
    marginTop: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginTop: 16,
      },
    }),
  },
  caption: {
    fontSize: 12,
    lineHeight: "18px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 11,
        lineHeight: "16px",
      },
    }),
  },
  tosLink: {
    fontSize: 12,
    lineHeight: "18px",
  },
});

const PhoneNumberModal = ({
  intro,
  onClose,
  phoneNumberForProxySessions,
  renResponseAt,
  setModalState,
  isOptedInForProxySms,
  client,
}) => {
  const { id } = intro;

  const [updateCompany, { loading: companyUpdating }] = useMutation(
    UPDATE_COMPANY
  );
  const [respondToMatch, { loading: respondingToMatch }] = useMutation(
    GC_RESPONSE
  );

  const { form, handleSubmit } = useForm({
    initialValues: {
      phone: phoneNumberForProxySessions,
      smsOptIn: true,
    },
    onSubmit: values => {
      const { phone, smsOptIn } = values;
      return updateCompany({
        variables: {
          attributes: {
            phoneNumberForProxySessions: phone,
            shouldReceiveSmsForProxyNumber: smsOptIn,
          },
        },
      }).then(() =>
        respondToMatch({
          variables: {
            id,
            isAccepting: true,
            company: {},
          },
        }).then(() =>
          renResponseAt
            ? setModalState("nextSteps")
            : goToPage(`/feedback/expert-ask?intro_id=${id}&response=yes`)
        )
      );
    },
  });

  const phone = useField("phone", form, newVal =>
    validate([required, val => phoneNumberValidator(val, client)], newVal)
  );
  const smsOptIn = useField("smsOptIn", form);

  const phoneOnChange = val => {
    const newVal = formatPhoneNumber(val);
    phone.input.onChange(newVal);
  };

  return (
    <Modal aphStyle={ss.modalWrapper}>
      <Modal.Header>Confirm the number you will be calling from</Modal.Header>
      <Modal.Body>
        <Body
          tag="div"
          variant="large"
          style={{ color: styles.colors.grey40, marginBottom: 32 }}
        >
          We will create a unique Sweeten-only phone number for the connection
          with this client. Once the unique number is created, all contact with
          this client should be made using the mobile number you enter here.
        </Body>
        <Input
          {...phone.input}
          onChange={phoneOnChange}
          error={fieldError(phone)}
          aphStyle={ss.phoneInput}
          pattern="[0-9]*"
          placeholder="917-555-5555"
        />
        {!isOptedInForProxySms && (
          <div className={css(ss.checkboxAndText)}>
            <Checkbox
              {...smsOptIn.input}
              onClick={() => smsOptIn.input.onChange(!smsOptIn.input.value)}
              style={{ marginTop: 4, minWidth: 16 }}
            />
            <Body aphStyle={ss.caption}>
              Please check in order to receive text messages with important
              project updates and meeting reminders. We will NEVER spam you or
              share your number without your permission. See our{" "}
              <TextLink
                aphStyle={ss.tosLink}
                href="/terms-of-use"
                target="_blank"
              >
                terms of service
              </TextLink>
              .
            </Body>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer aphStyle={ss.modalFooter}>
        <TextLink aphStyle={ss.cancelLink} onClick={onClose}>
          Cancel
        </TextLink>
        <Button
          aphStyle={ss.confirmCta}
          loading={companyUpdating || respondingToMatch}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PhoneNumberModal.propTypes = {
  intro: PropTypes.object,
  isOptedInForProxySms: PropTypes.bool,
  onClose: PropTypes.func,
  phoneNumberForProxySessions: PropTypes.string,
  renResponseAt: PropTypes.string,
  setModalState: PropTypes.func,
  client: PropTypes.object,
};

export default withApollo(PhoneNumberModal);
