/* eslint-disable camelcase */
import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import {
  ActionItem,
  ArrowLink,
  Body,
  Button,
  Card,
  Col,
  Header,
  Icon,
  Row,
  TextLink,
  Tooltip,
  styles,
  Modal,
  TabMenu,
} from "@sweeten/oreo";
import { css, StyleSheet } from "aphrodite";
import { get, isString, includes, findIndex } from "lodash";
import {
  addCommas,
  formatUserName,
  goToPage,
  trackMixpanel,
  loadUppy,
  fetchUploadedPhotos,
  getWindowWidth,
} from "../utils";
import {
  generateContent,
  ExpertCTAs,
  RenCTAs,
} from "../components/match_banner";
import { EditProjectBanner, PreviewBanner } from "./banners";
import CoreScopeOfWork from "./core_scope_of_work";
import { AppDispatch } from "../components/app_provider";
import { finishLevelOptions, propertyTypeOptions } from "./shared";
import { servicesMap } from "../ren/project_posting/steps/additional_services";
import ProjectTitleModal from "./modals/project_title_modal";
import ProjectDetailsModal from "./modals/project_details_modal";
import ProjectOverviewModal from "./modals/project_overview_modal";
import ProjectDescriptionModal from "./modals/project_description_modal";
import ReadRENMessageModal from "./read_ren_message_modal";
import Map from "./map";
import ExpressInterestSvgPath from "../../assets/images/ceiling-light.svg";
import ImageDisplay from "../components/image_display";
import AfterPhotoUploaderModal from "../components/after_photo_uploader_modal";
import NextStepsModal from "./modals/next_steps_modal";
import PhoneNumberModal from "./modals/phone_number_modal";
import FireSvgPath from "../../assets/images/fire.svg";
import BlogLink from "./blog_link";
import Faqs from "./faqs";

const GET_PROJECT_DATA = gql`
  query getProjectData($id: ID!) {
    currentUser {
      id
      enabledForTwilioProxy
      firstName
      isAdmin
      isRenovator
      isExpert
      company {
        id
        maxTravelDistanceInMiles
        name
        isOptedInForProxySms
      }
      projects(states: [posted, awarded, completed]) {
        id
      }
    }
    project(id: $id) {
      id
      additionalInfo
      additionalService
      alterationAgreementUrl
      archDrawingsUrl
      awardedCompany {
        id
      }
      budget {
        budgetMin: min
        budgetMax: max
      }
      budgetEstimate {
        budgetEstimateMin: min
        budgetEstimateMax: max
      }
      budgetFlexibility
      budgetScopeDetails
      collaborators(states: [active]) {
        id
        user {
          firstName
          lastName
          email
          phone
        }
      }
      description
      desiredCondition
      desiredStartDate
      email
      finishLevel
      firmType
      formCompletedAt
      images {
        id
        category
        url {
          large
        }
        project {
          id
          title
          location {
            neighborhood
          }
          completedAt
          awardedAt
          createdAt
          isSweeten
        }
      }
      isDeleted
      isEstimateHelpful
      isPortfolio
      isSweeten
      listedAt
      location {
        lat
        lng
        address
        address2
        county
        neighborhood
        city
        state
        zip
      }
      matchWithCurrentUser {
        id
        canShowInterest
        company {
          id
          avgAskResponseTimeMinutes
          name
          phoneNumberForProxySessions
        }
        successfulContactMade
        waitingForDelayedProxySession
        isActive
        interest {
          gc {
            isInterested
          }
          ren {
            isInterested
            responseAt
          }
        }
        introducedAt
        proxyPhoneNumberForGc
        renIntroMessage
        project {
          id
          user {
            id
            firstName
            enabledForTwilioProxy
          }
          location {
            address
          }
          shouldAutoOptInToMatches
        }
        renRejectionReason
        optInCategory
        visitedAt
      }
      ownerRelation
      ownershipType
      pressLinks
      propertyType
      rooms {
        id
        roomTypeConstant
      }
      roomScope
      scheduledIntroCall
      scopeOfWork {
        submittedAt
      }
      shortName
      shouldAutoOptInToMatches
      space
      squareFootage
      state
      style
      title
      umbrellaInsurance
      user {
        id
        enabledForTwilioProxy
        additionalServicesCopyExperimentVariant: experimentVariant(
          name: "project_posting-additional_services-copy"
        )
        phone
      }
      useType
      workingWithArchitectDesigner
    }
    roomTypes {
      id
      constant
      displayName
    }
  }
`;

const ss = StyleSheet.create({
  adminButtons: {
    display: "flex",
    flexFlow: "row wrap",
    marginBottom: 24,
  },
  adminButton: { margin: "8px 8px 0 0" },
  bottomBuffer: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
        paddingTop: 200,
      },
    }),
  },
  ctaContainer: {
    display: "flex",
    flexDirection: "column",
  },
  detailItemContainer: {
    ...styles.center("vertical"),
    marginTop: 16,
  },
  horizontalLine: {
    margin: "16px 0",
    borderTop: `1px solid ${styles.colors.grey30}`,
  },
  mobileCtas: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "flex",
        justifyContent: "center",
        position: "fixed",
        borderRadius: "8px 8px 0 0",
        border: `1px solid ${styles.colors.grey30}`,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: styles.zIndexes.secondaryNav,
        backgroundColor: styles.colors.white,
        padding: "16px 24px",
        ...styles.boxShadow({ vOffset: 2, blur: 12 }),
      },
    }),
  },
  myMatchesArrowLink: {
    position: "absolute",
    top: 24,
    fontSize: 18,
    color: styles.colors.black,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        top: 32,
      },
    }),
  },
  showOnMobile: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
      },
    }),
  },
  hideOnMobile: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: { display: "none" },
    }),
  },
  title: {
    marginBottom: 40,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginTop: 8,
      },
    }),
  },
  postedBySubHeader: { marginBottom: 40 },
  verticalAlign: { ...styles.center("vertical") },
  tag: {
    backgroundColor: styles.colors.brandAccentLight,
    color: styles.colors.brandAccent,
    padding: "5px 11px",
    borderRadius: 5,
    marginTop: 4,
    lineHeight: "15px",
  },
  sidebar: {
    width: 380,
  },
  matchBannerHeader: {
    marginBottom: 8,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontWeight: 700,
        marginTop: 0,
        fontSize: 14,
      },
    }),
  },
  marginTop: { marginTop: 32 },
  flexColumn: {
    display: "flex",
    flexFlow: "column nowrap",
  },
  editButton: {
    height: "18px",
    fontSize: 10,
    borderRadius: 9,
    fontWeight: 700,
    lineHeight: "12px",
    textTransform: "uppercase",
    cursor: "pointer",
  },
  flexSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  uploadPhotos: {
    width: 272,
    marginTop: 16,
    borderRadius: 10,
    backgroundColor: styles.colors.grey10,
    cursor: "pointer",
    ...styles.center(""),
  },
  messageContainer: {
    backgroundColor: styles.colors.greenLight,
    padding: "16px 0px",
    ...styles.center(),
    borderRadius: 4,
  },
  expressInterestTips: {
    marginTop: 16,
    paddingTop: 0,
    backgroundColor: styles.colors.grey10,
  },
  tip: {
    marginBottom: 12,
    color: styles.colors.grey40,
  },
  tipHeader: {
    ...styles.center("vertical"),
  },
  list: {
    paddingInlineStart: 24,
  },
  viewAllProjects: {
    padding: "12px 8px",
    borderRadius: 8,
    position: "absolute",
    bottom: 16,
    right: 16,
    backgroundColor: styles.colors.white,
    border: styles.border,
    cursor: "pointer",
  },
  signInHeader: {
    marginBottom: 24,
    fontSize: 18,
    lineHeight: "28px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        fontSize: 16,
        marginBottom: 16,
      },
    }),
  },
  signInCta: {
    fontSize: 16,
    height: 48,
    width: "100%",
    marginBottom: 8,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        fontSize: 14,
        height: 40,
      },
    }),
  },
  loginHere: {
    marginTop: 8,
    ...styles.center("vertical"),
  },
  renTwilioContact: {
    border: `1px solid #EDE8E9`,
    borderRadius: 10,
    backgroundColor: "#FFFAF6",
    boxShadow: "0 0 16px 5px rgba(0,0,0,0.06)",
    marginBottom: 24,
  },
  hotLead: {
    marginLeft: 8,
    marginTop: 8,
    ...styles.center("vertical"),
    padding: "0px 12px",
    height: 24,
    border: "1px solid #F37575",
    borderRadius: 12,
    color: "#F37575",
    fontSize: 12,
    whiteSpace: "nowrap",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        margin: "8px 0px 24px",
      },
    }),
  },
});

const PhotoCarousel = ({
  images,
  activeImageNum,
  setActiveImageNum,
  setNoImages,
}) => {
  if (images.length) {
    const galleryPhotos = {};
    images.forEach(i => {
      if (!galleryPhotos[i.category]) {
        galleryPhotos[i.category] = [];
      }
      galleryPhotos[i.category].push({
        url: {
          large: i.url.large,
        },
        project: i.project,
      });
    });

    const categoryToPriority = {
      before: 1,
      inspiration: 2,
      progress: 3,
      after: 4,
    };

    const keys = Object.keys(galleryPhotos).sort((a, b) => {
      if (categoryToPriority[a] < categoryToPriority[b]) {
        return -1;
      }
      if (categoryToPriority[b] < categoryToPriority[a]) {
        return 1;
      }
      return 0;
    });

    const hash = window.location.hash.substring(1).replace(/\?.*/, "");

    return (
      <TabMenu
        defaultTabId={hash.length ? hash : Object.keys(galleryPhotos)[0]}
      >
        {keys.map(category => {
          const titleizedCategory =
            category.charAt(0).toUpperCase() + category.slice(1);

          return (
            <TabMenu.Tab
              label={titleizedCategory}
              id={category}
              path={`#${category}`}
            >
              <ImageDisplay
                photos={galleryPhotos[category]}
                activeImageNum={activeImageNum}
                setActiveImageNum={setActiveImageNum}
                setNoImages={setNoImages}
              />
            </TabMenu.Tab>
          );
        })}
      </TabMenu>
    );
  }

  return null;
};

PhotoCarousel.propTypes = {
  images: PropTypes.array,
  activeImageNum: PropTypes.number,
  setActiveImageNum: PropTypes.func,
  setNoImages: PropTypes.func,
};

export const ProjectDetailItem = ({
  icon,
  id,
  label,
  shouldShowMatchCta,
  value,
  onClick,
}) => {
  const dispatch = useContext(AppDispatch);
  const showSuccessAlert = () => {
    dispatch({
      type: "alert:show",
      payload: {
        variant: "success",
        text: "Address Copied",
      },
    });
  };
  const copyTextToClipboard = val => {
    const input = document.createElement("input");
    input.value = val;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  };
  const attachments = () => {
    let attachmentCopy;
    if (id === "alteration_agreement") {
      attachmentCopy = "Alteration Agreement";
    } else {
      attachmentCopy = "Architectural Drawings";
    }

    if (!shouldShowMatchCta) {
      return (
        <TextLink href={value} target="_blank" rel="noopener noreferrer">
          {attachmentCopy}
        </TextLink>
      );
    }

    return (
      <Body tag="p" style={{ margin: 0 }}>
        Yes
      </Body>
    );
  };

  return (
    <div className={css(ss.detailItemContainer)}>
      {icon && <Icon name={icon} style={{ marginRight: 16, minWidth: 24 }} />}
      <div>
        {label && (
          <div onClick={onClick} style={onClick ? { cursor: "pointer" } : null}>
            <Body
              tag="p"
              style={{
                margin: 0,
                color: styles.colors.grey40,
                fontWeight: 500,
              }}
            >
              {label}
            </Body>
          </div>
        )}
        {isString(value) ? (
          <div className={css(ss.verticalAlign)}>
            <Body
              tag="p"
              style={{ margin: 0, wordBreak: "break-word" }}
              onClick={
                id === "address"
                  ? () => {
                      copyTextToClipboard(value);
                      showSuccessAlert();
                    }
                  : null
              }
            >
              {id === "alteration_agreement" || id === "arch_drawings_url"
                ? attachments()
                : value}
            </Body>
            {id === "address" && (
              <div
                className={css(ss.verticalAlign)}
                onClick={() => {
                  copyTextToClipboard(value);
                  showSuccessAlert();
                }}
              >
                <Icon
                  name="copy"
                  size={16}
                  style={{
                    marginLeft: 8,
                    minWidth: 16,
                    color: styles.colors.grey40,
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          value
        )}
      </div>
    </div>
  );
};

ProjectDetailItem.propTypes = {
  icon: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  shouldShowMatchCta: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

const ProjectDetails = ({
  alterationAgreementUrl,
  archDrawingsUrl,
  firmType,
  isResidential,
  project,
  shouldShowMatchCta,
  style,
  copyVariant,
  isAdmin,
  isExpert,
}) => {
  const {
    additionalService,
    finishLevel,
    ownerRelation,
    ownershipType,
    propertyType,
    squareFootage,
    umbrellaInsurance,
    workingWithArchitectDesigner,
  } = project;
  const isOwnerRelationArchitectOrDesigner =
    ownershipType === "Representing owner" &&
    (ownerRelation === "Architect" || ownerRelation === "Interior designer");

  const [showGuidanceModal, toggleShowGuidanceModal] = useState(false);

  const serviceOptionMap = servicesMap(copyVariant === "alternative");

  const additionalServiceEle =
    isExpert || isAdmin ? (
      <div>
        <Body tag="span">Not Sure - Client needs guidance</Body>
        <div>
          <TextLink onClick={() => toggleShowGuidanceModal(true)}>
            What does this mean?
          </TextLink>
        </div>
      </div>
    ) : (
      serviceOptionMap[additionalService]
    );

  return (
    <Row verticalGap={0}>
      <Col width={6} style={{ margin: 0 }}>
        {firmType && (
          <ProjectDetailItem icon="command" label="Services" value={firmType} />
        )}
        {squareFootage && (
          <ProjectDetailItem
            icon="move"
            label="Size"
            value={`${squareFootage} sq ft`}
          />
        )}
        {isResidential && finishLevel && (
          <ProjectDetailItem
            icon="bar-chart"
            label="Finish Level"
            value={finishLevelOptions[finishLevel]}
          />
        )}
        {workingWithArchitectDesigner !== null &&
          additionalService === "just_a_general_contractor" &&
          !isOwnerRelationArchitectOrDesigner && (
            <ProjectDetailItem
              icon="verified-icon"
              label="Client has a Designer / Architect"
              value={workingWithArchitectDesigner ? "Yes" : "No"}
            />
          )}
        {additionalService && (
          <ProjectDetailItem
            icon="help"
            label="Design Advice or Services"
            value={additionalServiceEle}
          />
        )}
        {style && (
          <ProjectDetailItem icon="image" label="Style" value={style} />
        )}
      </Col>
      <Col width={6} style={{ margin: 0 }}>
        {isResidential && ownershipType && (
          <ProjectDetailItem
            icon="file-text"
            label="Ownership Status"
            value={
              ownershipType === "Representing owner"
                ? `${ownershipType} ${
                    ownerRelation ? `- ${ownerRelation}` : ""
                  }`
                : ownershipType
            }
          />
        )}
        {propertyType && (
          <ProjectDetailItem
            icon="box"
            label="Property Type"
            value={propertyTypeOptions[propertyType]}
          />
        )}
        {umbrellaInsurance && propertyType === "apartment" && (
          <ProjectDetailItem
            icon="umbrella"
            label="Umbrella Insurance"
            value={umbrellaInsurance}
          />
        )}
        {alterationAgreementUrl && (
          <ProjectDetailItem
            icon="file"
            id="alteration_agreement"
            label="Alteration Agreement"
            shouldShowMatchCta={shouldShowMatchCta}
            value={alterationAgreementUrl}
          />
        )}
        {archDrawingsUrl && (
          <ProjectDetailItem
            icon="file"
            id="arch_drawings_url"
            label="Architectural Drawings"
            shouldShowMatchCta={shouldShowMatchCta}
            value={archDrawingsUrl}
          />
        )}
        {showGuidanceModal && (
          <Modal>
            <Modal.Header>
              Client Needs Guidance on Design Services
            </Modal.Header>
            <Modal.Body>
              <Body tag="div">
                Client is unsure what design services they’ll need to complete
                the project, such as a design-build firm or an architect
                referral. Please look closely at the project description to see
                if this project is a good match for your firm’s services.
              </Body>
            </Modal.Body>
            <Modal.Footer>
              <Button
                label="OK"
                onClick={() => toggleShowGuidanceModal(false)}
                type="submit"
                variant="dark"
              />
            </Modal.Footer>
          </Modal>
        )}
      </Col>
    </Row>
  );
};

ProjectDetails.propTypes = {
  additionalService: PropTypes.string,
  alterationAgreementUrl: PropTypes.string,
  archDrawingsUrl: PropTypes.string,
  finishLevel: PropTypes.string,
  firmType: PropTypes.string,
  isResidential: PropTypes.bool,
  project: PropTypes.object,
  shouldShowMatchCta: PropTypes.bool,
  style: PropTypes.string,
  copyVariant: PropTypes.string,
  isAdmin: PropTypes.bool,
  isExpert: PropTypes.bool,
};

const Description = ({
  additionalInfo,
  canShowEditCta,
  description,
  toggleProjectDescriptionModal,
}) => (
  <>
    <div className={css(ss.horizontalLine)} />
    <div className={css(ss.flexSpaceBetween)}>
      <Header tag="h2" style={{ fontSize: 16, lineHeight: "28px" }}>
        Description
      </Header>
      {canShowEditCta && (
        <Button
          aphStyle={ss.editButton}
          onClick={() => toggleProjectDescriptionModal(true)}
          variant="outlineDark"
        >
          Edit
        </Button>
      )}
    </div>
    <Body tag="p" style={{ whiteSpace: "pre-line" }}>
      {description || additionalInfo}
    </Body>
  </>
);

Description.propTypes = {
  additionalInfo: PropTypes.string,
  canShowEditCta: PropTypes.bool,
  description: PropTypes.string,
  toggleProjectDescriptionModal: PropTypes.func,
};

const DesiredConditions = ({ canShowEditCta, desiredCondition, projectId }) => (
  <>
    <div className={css(ss.horizontalLine)} />
    <div className={css(ss.flexSpaceBetween)}>
      <Header tag="h2" style={{ fontSize: 16, lineHeight: "28px" }}>
        Desired Conditions
      </Header>
      {canShowEditCta && (
        <Button
          aphStyle={ss.editButton}
          onClick={() => goToPage(`/commercial/${projectId}/edit`)}
          variant="outlineDark"
        >
          Edit
        </Button>
      )}
    </div>
    <Body tag="p" style={{ whiteSpace: "pre-line" }}>
      {desiredCondition}
    </Body>
  </>
);

DesiredConditions.propTypes = {
  canShowEditCta: PropTypes.bool,
  desiredCondition: PropTypes.string,
  projectId: PropTypes.string,
};

const ScopeOfWork = ({ canShowEditCta, coreAuthToken, env, projectId }) => (
  <>
    <div className={css(ss.horizontalLine)} />
    <div className={css(ss.flexSpaceBetween)}>
      <Header tag="h4" style={{ fontSize: 16, lineHeight: "28px" }}>
        Scope of Work
      </Header>
      {canShowEditCta && (
        <Button
          aphStyle={ss.editButton}
          onClick={() => goToPage(`/projects/${projectId}/scope-of-work`)}
          variant="outlineDark"
        >
          Edit
        </Button>
      )}
    </div>
    <CoreScopeOfWork
      authToken={coreAuthToken}
      env={env}
      projectId={projectId}
    />
  </>
);

ScopeOfWork.propTypes = {
  canShowEditCta: PropTypes.bool,
  coreAuthToken: PropTypes.string,
  env: PropTypes.string,
  projectId: PropTypes.string,
};

const Budget = ({ budgetMin, budgetMax, budgetFlexibility }) => (
  <div className={css(ss.flexColumn)}>
    ${addCommas(budgetMin)} - ${addCommas(budgetMax)}
    {includes(["yes", "maybe"], budgetFlexibility) && (
      <Tooltip content="Client may need guidance" position="bottom">
        <div className={css(ss.tag)}>
          {budgetFlexibility === "yes"
            ? "Flexible budget, fixed scope"
            : "Flexible budget and scope"}
        </div>
      </Tooltip>
    )}
  </div>
);

Budget.propTypes = {
  budgetMin: PropTypes.number,
  budgetMax: PropTypes.number,
  budgetFlexibility: PropTypes.string,
};

const ProjectOverview = ({
  budgetFlexibility,
  budgetScopeDetails,
  canShowEditCta,
  neighborhood,
  project,
  isResidential,
  roomTypes,
  toggleProjectOverviewModal,
}) => {
  const {
    budget: { budgetMin, budgetMax },
    desiredStartDate,
    isPortfolio,
    location,
    rooms,
    space,
  } = project;
  const { city, state, zip } = location;

  const generateRoomsString = () => {
    const roomCountObj = {};

    rooms.forEach(room => {
      roomTypes.forEach(roomType => {
        if (room.roomTypeConstant === roomType.constant) {
          if (roomCountObj[roomType.displayName]) {
            roomCountObj[roomType.displayName] += 1;
          } else {
            roomCountObj[roomType.displayName] = 1;
          }
        }
      });
    });

    return Object.keys(roomCountObj)
      .map(room => `${room} (${roomCountObj[room]})`)
      .join(", ");
  };

  let roomsLabel;

  if (project.state === "completed" || isPortfolio) {
    roomsLabel = "Room(s) renovated";
  } else if (isResidential) {
    roomsLabel = "Room(s) to be renovated";
  } else {
    roomsLabel = "Space";
  }

  const locationString = `${
    neighborhood ? `${neighborhood}, ` : ""
  }${city}, ${state} ${zip}`;

  return (
    <>
      <div className={css(ss.flexSpaceBetween)}>
        <Header tag="h2" style={{ fontSize: 16, lineHeight: "28px" }}>
          Project Overview
        </Header>
        {canShowEditCta && (
          <Button
            aphStyle={ss.editButton}
            onClick={() => toggleProjectOverviewModal(true)}
            variant="outlineDark"
          >
            Edit
          </Button>
        )}
      </div>
      {!isPortfolio && budgetMin >= 0 && budgetMax > 0 && (
        <ProjectDetailItem
          icon="dollar-sign"
          label="Budget"
          value={
            <Budget
              budgetMin={budgetMin}
              budgetMax={budgetMax}
              budgetFlexibility={budgetFlexibility}
            />
          }
        />
      )}
      {budgetScopeDetails && (
        <ProjectDetailItem
          label="Budget/scope details"
          value={budgetScopeDetails}
        />
      )}
      {(rooms.length > 0 || space) && (
        <ProjectDetailItem
          icon="check-square"
          label={roomsLabel}
          value={
            isResidential && rooms.length > 0 ? generateRoomsString() : space
          }
        />
      )}
      {state && (
        <ProjectDetailItem
          icon="map"
          label="Location (neighborhood)"
          value={locationString}
        />
      )}
      {!isPortfolio && desiredStartDate && (
        <ProjectDetailItem
          icon="calendar"
          label="Desired Start Date"
          value={desiredStartDate}
        />
      )}
    </>
  );
};

ProjectOverview.propTypes = {
  budgetMin: PropTypes.number,
  budgetMax: PropTypes.number,
  budgetFlexibility: PropTypes.string,
  budgetScopeDetails: PropTypes.string,
  canShowEditCta: PropTypes.bool,
  isResidential: PropTypes.bool,
  neighborhood: PropTypes.string,
  project: PropTypes.object,
  roomTypes: PropTypes.array,
  toggleProjectOverviewModal: PropTypes.func,
};

const Collaborator = ({ collaborators }) =>
  collaborators.map(collaborator => {
    const { user } = collaborator;

    return (
      <div key={collaborator.id} style={{ marginBottom: 4 }}>
        {user && <Body tag="div">{formatUserName(user)}</Body>}
        {user.email && (
          <TextLink href={`mailto:${user.email}`}>{user.email}</TextLink>
        )}
        {user.phone && <Body tag="div">{user.phone}</Body>}
      </div>
    );
  });

const ContactInformation = ({
  currentUser,
  project,
  proxyPhoneNumberForGc,
  successfulContactMade,
}) => {
  const { collaborators, email, location, shortName, user } = project;
  const { address, address2, city, state, zip } = location;
  const { enabledForTwilioProxy, phone } = user;

  const detailedAddress = `${address ? `${address} ` : ""} ${
    address2 ? `${address2} ` : ""
  }${city}, ${state} ${zip}`;

  let phoneNumber;
  if (enabledForTwilioProxy && currentUser.isExpert) {
    phoneNumber = proxyPhoneNumberForGc;
  } else {
    phoneNumber = phone;
  }

  const showEmail = enabledForTwilioProxy ? successfulContactMade : true;

  return (
    <>
      <ProjectDetailItem icon="profile" label="Owner" value={shortName} />
      {collaborators.length > 0 && (
        <ProjectDetailItem
          icon="users"
          label="Collaborators"
          value={<Collaborator collaborators={collaborators} />}
        />
      )}
      {detailedAddress && (
        <ProjectDetailItem
          icon="pin"
          id="address"
          label="Detailed Address"
          value={detailedAddress}
        />
      )}
      {phoneNumber && (
        <ProjectDetailItem
          icon="phone"
          label="Phone Number"
          value={phoneNumber}
        />
      )}
      {showEmail && (
        <ProjectDetailItem icon="mail" label="Email" value={email} />
      )}
    </>
  );
};

ContactInformation.propTypes = {
  currentUser: PropTypes.object,
  successfulContactMade: PropTypes.string,
  project: PropTypes.object,
  proxyPhoneNumberForGc: PropTypes.string,
};

const FooterFields = ({ isSweeten, pressLinks }) => {
  const isRequestFromRenApp = window.navigator.userAgent === "SweetenRenApp";

  return (
    <>
      {isSweeten && (
        <div className={css(ss.verticalAlign)} style={{ marginBottom: 8 }}>
          <Icon name="sweeten-house" style={{ marginRight: 8, minWidth: 24 }} />
          <Body tag="span">Sweeten Project</Body>
        </div>
      )}
      {pressLinks
        ? pressLinks.map(pressLink => (
            <Body tag="div" style={{ marginLeft: 4 }}>
              <TextLink
                href={isRequestFromRenApp ? null : pressLink[1]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {pressLink[0]}
              </TextLink>
            </Body>
          ))
        : null}
    </>
  );
};

FooterFields.propTypes = {
  isSweeten: PropTypes.bool,
  pressLinks: PropTypes.array,
};

const AdminButtons = ({ adminProps, authToken, hubspotDealUrl }) => (
  <div className={css(ss.adminButtons)}>
    <form
      action={adminProps.impersonatePath}
      method="post"
      className={css(ss.adminButton)}
    >
      <Button label="Impersonate" type="submit" />
      <input name="authenticity_token" type="hidden" value={authToken} />
    </form>
    <form
      action={adminProps.editPhotosPath}
      method="get"
      className={css(ss.adminButton)}
    >
      <Button label="Edit Photos" type="submit" />
    </form>
    <form
      action={adminProps.editProjectPath}
      method="get"
      className={css(ss.adminButton)}
    >
      <Button label="Edit Project" type="submit" />
    </form>
    <form
      action={adminProps.projectMasterPath}
      method="get"
      className={css(ss.adminButton)}
    >
      <Button label="Project Master" type="submit" />
    </form>
    {!!hubspotDealUrl && (
      <Button
        label="HubSpot"
        aphStyle={ss.adminButton}
        onClick={() => goToPage(hubspotDealUrl, { shouldOpenNewTab: true })}
      />
    )}
  </div>
);

AdminButtons.propTypes = {
  adminProps: PropTypes.object,
  authToken: PropTypes.string,
  hubspotDealUrl: PropTypes.string,
};

const ExpertMessageCTA = ({ intro }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className={css(ss.messageContainer)}>
        <Icon name="chat" size={24} style={{ marginRight: 8 }} />
        <ArrowLink variant="forward" onClick={() => setModalOpen(true)}>
          You have a message from this renovator
        </ArrowLink>
      </div>
      <div className={css(ss.horizontalLine)} />
      {modalOpen && (
        <ReadRENMessageModal
          onClose={() => setModalOpen(false)}
          intro={intro}
        />
      )}
    </>
  );
};

ExpertMessageCTA.propTypes = {
  intro: PropTypes.object,
};

const RespondedDisplay = ({ copy, header }) => (
  <>
    {header && (
      <Header tag="h6" aphStyle={ss.matchBannerHeader}>
        {header}
      </Header>
    )}
    {copy && (
      <Body tag="p" style={{ marginTop: 0, marginBottom: 16 }}>
        {copy}
      </Body>
    )}
  </>
);

RespondedDisplay.propTypes = {
  copy: PropTypes.string,
  header: PropTypes.string,
};

const RespondToMatchCTAs = ({
  copy,
  enabledForTwilioProxy,
  header,
  intro,
  isRenovator,
  isExpert,
  userInfo,
  setModalState,
}) => (
  <>
    {isExpert && intro.renIntroMessage && <ExpertMessageCTA intro={intro} />}
    <RespondedDisplay header={header} copy={copy} />
    {isExpert && (
      <ExpertCTAs
        intro={intro}
        userInfo={userInfo}
        setModalState={setModalState}
      />
    )}
    {isRenovator && (
      <RenCTAs
        enabledForTwilioProxy={enabledForTwilioProxy}
        introId={intro.id}
        projectId={intro.project.id}
        location="gc_profile_project"
      />
    )}
  </>
);

RespondToMatchCTAs.propTypes = {
  copy: PropTypes.string,
  enabledForTwilioProxy: PropTypes.bool,
  header: PropTypes.string,
  intro: PropTypes.object,
  isRenovator: PropTypes.bool,
  isExpert: PropTypes.bool,
  userInfo: PropTypes.object,
  setModalState: PropTypes.func,
};

const Banners = ({ isPreview, isCurrentRenProject, previewCtaProps }) => {
  if (isPreview) {
    return <PreviewBanner {...previewCtaProps} />;
  }
  if (isCurrentRenProject) {
    return <EditProjectBanner />;
  }

  return null;
};

Banners.propTypes = {
  isPreview: PropTypes.bool,
  isCurrentRenProject: PropTypes.bool,
  previewCtaProps: PropTypes.object,
  projectId: PropTypes.string,
};

const TipList = ({ header, tips }) => (
  <>
    <div className={css(ss.tipHeader)}>
      <img src={ExpressInterestSvgPath} alt="light bulb" />
      <Header tag="h6" style={{ marginLeft: 24 }}>
        {header}
      </Header>
    </div>
    <ul className={css(ss.list)}>
      {tips.map(tip => (
        <li className={css(ss.tip)}>{tip}</li>
      ))}
    </ul>
  </>
);

TipList.propTypes = {
  header: PropTypes.string,
  tips: PropTypes.array,
};

const ProjectShow = withApollo(({ client, data, props, refetch }) => {
  const {
    adminProps,
    authToken,
    canEditProject,
    coreAuthToken,
    env,
    isPreview,
    isCurrentExpProject,
    isCurrentRenProject,
    mixpanelMatchState,
    previewCtaProps,
    residentialFormId,
    userIsCollaborator,
    userType,
    hubspotDealUrl,
  } = props;
  const { currentUser, project, roomTypes } = data || {};
  const {
    company,
    enabledForTwilioProxy: currUserEnabledForTwilioProxy,
    firstName,
    isAdmin,
    isRenovator,
    isExpert,
    projects,
  } = currentUser || {};
  const {
    id: companyId,
    maxTravelDistanceInMiles,
    name,
    isOptedInForProxySms,
  } = company || {};
  const {
    additionalInfo,
    alterationAgreementUrl,
    archDrawingsUrl,
    awardedCompany,
    budgetFlexibility,
    budgetScopeDetails,
    contactPhone,
    description,
    desiredCondition,
    email,
    firmType,
    formCompletedAt,
    images,
    isDeleted,
    isPortfolio,
    isSweeten,
    location,
    matchWithCurrentUser,
    pressLinks,
    scopeOfWork,
    shortName,
    style,
    title,
    useType,
    user,
  } = project || {};
  const { id: awardedCompanyId } = awardedCompany || {};
  const [showCarousel, setShowCarousel] = useState(false);

  const { submittedAt } = scopeOfWork || {};
  const {
    canShowInterest,
    company: introCompany,
    successfulContactMade,
    interest,
    proxyPhoneNumberForGc,
    introducedAt,
    waitingForDelayedProxySession,
    visitedAt,
  } = matchWithCurrentUser || {};
  const { phoneNumberForProxySessions } = introCompany || {};
  const { gc, ren } = interest || {};
  const { isInterested: gcIsInterested } = gc || {};
  const { isInterested: renIsInterested, responseAt: renResponseAt } =
    ren || {};
  const {
    additionalServicesCopyExperimentVariant,
    enabledForTwilioProxy,
    phone,
  } = user || {};
  const shouldShowMatchCta = !!matchWithCurrentUser && canShowInterest;
  const needsBottomBuffer =
    shouldShowMatchCta || isPreview || isCurrentRenProject;
  const userInfo = {
    phoneNumber: contactPhone,
    maxTravelDistanceInMiles,
    name,
    firstName,
  };
  const isResidential = useType === "residential";
  const { copy, header } = generateContent({
    enabledForTwilioProxy,
    isRenovator,
    isExpert,
    matchWithCurrentUser,
  });
  const isClassicOrResJ =
    residentialFormId === "classic" || residentialFormId === "res_j";
  const isHomeowner = userType === "homeowner";
  const canShowContactInfo =
    (contactPhone || email || location.address) && !isDeleted;
  const shouldShowExactLocation =
    (get(matchWithCurrentUser, "interest.gc.isInterested") &&
      get(matchWithCurrentUser, "interest.ren.isInterested")) ||
    isCurrentRenProject;

  const [showProjectTitleModal, toggleProjectTitleModal] = useState(false);
  const [showProjectDetailsModal, toggleProjectDetailsModal] = useState(false);
  const [showProjectOverviewModal, toggleProjectOverviewModal] = useState(
    false
  );
  const [showProjectDescriptionModal, toggleProjectDescriptionModal] = useState(
    false
  );
  const [activeImageNum, setActiveImageNum] = useState(null);

  const [noImages, setNoImages] = useState(false);

  const canShowEditCta =
    formCompletedAt && !isPortfolio && (isCurrentRenProject || isAdmin);
  const shouldShowGetStarted =
    (isRenovator && !projects.length) || !currentUser;

  const [isUppyLoaded, setUppyLoaded] = useState(false);
  const [showPhotoUploaderModal, togglePhotoUploaderModal] = useState(false);

  const dispatch = useContext(AppDispatch);

  const showSuccessAlert = () => {
    dispatch({
      type: "alert:show",
      payload: {
        variant: "success",
        text: "Project Saved",
      },
    });
  };

  useEffect(() => {
    if (isCurrentRenProject || userIsCollaborator) {
      trackMixpanel("Viewed Project Preview", {
        Location: isPreview ? "Project Posting" : "Project Tile",
      });
    } else if (isCurrentExpProject) {
      // Note: Mixpanel super project properties do not get added for experts
      trackMixpanel("Viewed Portfolio Project Page", {
        "Project ID": project.id,
      });
    } else if (isHomeowner) {
      trackMixpanel("Viewed Project Page", {
        "Match State": mixpanelMatchState,
      });
    } else if (!isHomeowner) {
      // Note: Mixpanel super project properties do not get added for experts
      trackMixpanel("Viewed Lead Project Preview", {
        "Project ID": project.id,
        "Match State": mixpanelMatchState,
      });
    }

    loadUppy("photo-uploader").then(() => setUppyLoaded(true));

    setTimeout(() => setShowCarousel(true), 100);
  }, []);

  const shouldShowGCMatchText =
    !shouldShowMatchCta &&
    gcIsInterested !== false &&
    !renIsInterested &&
    (copy || header);

  useEffect(() => {
    const handlePhotoUpload = evt => {
      fetchUploadedPhotos(evt, project.id, () => {
        refetch();
      });
    };

    const handlePhotoDelete = evt => {
      if (evt.detail.projectId === project.id) {
        const result = client.readQuery({
          query: GET_PROJECT_DATA,
          variables: { id: project.id },
        });
        const { images: cachedImages } = result.project;
        const imageIdxToRemove = findIndex(cachedImages, image => {
          return image.id === evt.detail.photoId;
        });

        client.writeQuery({
          query: GET_PROJECT_DATA,
          data: {
            currentUser,
            roomTypes,
            project: {
              ...project,
              images: [
                ...cachedImages.slice(0, imageIdxToRemove),
                ...cachedImages.slice(imageIdxToRemove + 1),
              ],
            },
          },
        });
      }
    };

    document.addEventListener("photoUpload", handlePhotoUpload);
    document.addEventListener("photoDelete", handlePhotoDelete);

    return () => {
      document.removeEventListener("photoUpload", handlePhotoUpload);
      document.removeEventListener("photoDelete", handlePhotoDelete);
    };
  }, []);

  const [modalState, setModalState] = useState(null);

  const windowWidth = getWindowWidth();

  return (
    <div>
      <Banners
        isPreview={isPreview}
        isCurrentRenProject={isCurrentRenProject}
        previewCtaProps={previewCtaProps}
        projectId={project.id}
      />
      <Row style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 1021 }}>
        <Col width={{ desktopStandard: 7, tabletStandard: 12 }}>
          {isRenovator && (
            <ArrowLink
              aphStyle={ss.myMatchesArrowLink}
              onClick={() => goToPage("/my-contractors")}
              variant="back"
            >
              Back
            </ArrowLink>
          )}
          {isClassicOrResJ && canEditProject && (
            <ActionItem
              variant="info"
              onClick={() =>
                goToPage(
                  `mailto:support@sweeten.com?subject=Please update me on project ${project.id}`
                )
              }
            >
              Some project details cannot be edited (they may have been created
              using an outdated form). To make edits, contact
              support@sweeten.com.
            </ActionItem>
          )}
          <div className={css(ss.flexSpaceBetween)}>
            <Header
              tag="h1"
              aphStyle={
                shouldShowMatchCta &&
                images.length &&
                !get(matchWithCurrentUser, "interest.ren.isInterested") &&
                ss.title
              }
              style={{ fontSize: 24, lineHeight: "35px" }}
            >
              {title}
            </Header>
            {get(matchWithCurrentUser, "interest.ren.isInterested") && (
              <Tooltip
                position={
                  windowWidth > styles.breakpoints.tabletStandard
                    ? "bottom"
                    : "right"
                }
                content="This lead said Yes to your profile — they’re ready to talk! Reply ASAP."
              >
                <div className={css(ss.hotLead)}>
                  <img
                    src={FireSvgPath}
                    alt="hot_lead"
                    style={{ marginRight: 4 }}
                  />
                  Hot lead
                </div>
              </Tooltip>
            )}
            {canShowEditCta && (
              <Button
                aphStyle={ss.editButton}
                onClick={() => toggleProjectTitleModal(true)}
                variant="outlineDark"
                style={{ marginLeft: 16 }}
              >
                Edit
              </Button>
            )}
          </div>
          {!shouldShowMatchCta && !!shortName && (
            <Body
              tag="div"
              style={{
                fontWeight: 500,
                color: styles.colors.grey40,
              }}
              aphStyle={images.length && ss.postedBySubHeader}
            >
              Posted by {shortName}
            </Body>
          )}
          {adminProps && (
            <AdminButtons
              adminProps={adminProps}
              authToken={authToken}
              hubspotDealUrl={hubspotDealUrl}
            />
          )}
          {showCarousel && images.length > 0 && !noImages && (
            <div style={{ position: "relative" }}>
              <PhotoCarousel
                images={images}
                activeImageNum={activeImageNum}
                setActiveImageNum={setActiveImageNum}
                setNoImages={setNoImages}
              />
              <div
                className={css(ss.viewAllProjects)}
                onClick={() => setActiveImageNum(0)}
              >
                View All Photos
              </div>
            </div>
          )}
          {!images.length > 0 && isCurrentRenProject && (
            <div
              className={css(ss.uploadPhotos)}
              onClick={() => goToPage(`/projects/${project.id}/files`)}
            >
              <Icon name="image" />
              <Body tag="p" style={{ marginLeft: 16, fontWeight: 700 }}>
                Upload Photos
              </Body>
            </div>
          )}
          {!isPortfolio && canShowContactInfo && (
            <>
              <div className={css(ss.flexSpaceBetween)}>
                <Header tag="h2" style={{ fontSize: 16, marginTop: 32 }}>
                  Contact Information
                </Header>
                {canShowEditCta && (
                  <Button
                    aphStyle={ss.editButton}
                    onClick={() => goToPage("/settings/user")}
                    variant="outlineDark"
                    style={{ marginTop: 32 }}
                  >
                    Edit
                  </Button>
                )}
              </div>
              <ContactInformation
                currentUser={currentUser}
                project={project}
                proxyPhoneNumberForGc={proxyPhoneNumberForGc}
                successfulContactMade={successfulContactMade}
              />
              <div className={css(ss.horizontalLine)} />
            </>
          )}
          <div className={css(ss.showOnMobile)}>
            <ProjectOverview
              budgetFlexibility={budgetFlexibility}
              budgetScopeDetails={budgetScopeDetails}
              canShowEditCta={canShowEditCta}
              isResidential={isResidential}
              neighborhood={location.neighborhood}
              project={project}
              roomTypes={roomTypes}
              toggleProjectOverviewModal={toggleProjectOverviewModal}
            />
            <div className={css(ss.horizontalLine)} />
            <BlogLink project={project} />
            {isExpert && companyId === awardedCompanyId && (
              <>
                <div className={css(ss.horizontalLine)} />
                <Header tag="h6" style={{ marginBottom: 16 }}>
                  Photos of this finished project
                </Header>
                <Button
                  onClick={() => togglePhotoUploaderModal(true)}
                  style={{ width: "100%" }}
                >
                  {images.length > 0 ? "Add / delete photos" : "Add photos"}
                </Button>
                <div className={css(ss.horizontalLine)} />
                <TipList
                  header="Tips for photos that win clients"
                  tips={[
                    "Declutter the space if you can.",
                    "Use natural daylight. Avoid flash.",
                    "Hold camera / phone level, to keep angles square.",
                    "Use landscape orientation (width greater than height) when possible.",
                    "Include equal amounts of floor and ceiling.",
                    "For a cleaner look, shoot spaces head-on, not from corners/angles.",
                  ]}
                />
              </>
            )}
            {(canShowEditCta || description || additionalInfo) && (
              <Description
                additionalInfo={additionalInfo}
                description={description}
                canShowEditCta={canShowEditCta}
                toggleProjectDescriptionModal={toggleProjectDescriptionModal}
              />
            )}
            {useType === "commercial" && desiredCondition && (
              <DesiredConditions
                canShowEditCta={canShowEditCta}
                desiredCondition={desiredCondition}
                projectId={project.id}
              />
            )}
            {!isPortfolio && (!description || !additionalInfo) && (
              <div className={css(ss.horizontalLine)} />
            )}
          </div>
          {!isPortfolio && (
            <>
              <div className={css(ss.flexSpaceBetween)}>
                <Header
                  tag="h2"
                  aphStyle={!canShowContactInfo && ss.marginTop}
                  style={{ fontSize: 16, lineHeight: "28px" }}
                >
                  Project Details
                </Header>
                {canShowEditCta && (
                  <Button
                    aphStyle={[
                      ss.editButton,
                      !canShowContactInfo && ss.marginTop,
                    ]}
                    onClick={() => toggleProjectDetailsModal(true)}
                    variant="outlineDark"
                  >
                    Edit
                  </Button>
                )}
              </div>
              <ProjectDetails
                alterationAgreementUrl={alterationAgreementUrl}
                archDrawingsUrl={archDrawingsUrl}
                firmType={firmType}
                isResidential={isResidential}
                shouldShowMatchCta={shouldShowMatchCta}
                style={style}
                project={project}
                copyVariant={additionalServicesCopyExperimentVariant}
                isAdmin={isAdmin}
                isExpert={isExpert}
              />
            </>
          )}
          <div className={css(ss.hideOnMobile)}>
            {(canShowEditCta || description || additionalInfo) && (
              <Description
                additionalInfo={additionalInfo}
                description={description}
                canShowEditCta={canShowEditCta}
                toggleProjectDescriptionModal={toggleProjectDescriptionModal}
              />
            )}
            {useType === "commercial" && desiredCondition && (
              <DesiredConditions
                canShowEditCta={canShowEditCta}
                desiredCondition={desiredCondition}
                projectId={project.id}
              />
            )}
          </div>
          {submittedAt && (
            <ScopeOfWork
              canShowEditCta={canShowEditCta}
              coreAuthToken={coreAuthToken}
              env={env}
              projectId={project.id}
            />
          )}
          {project.location.lat && project.location.lng && (
            <>
              <div className={css(ss.horizontalLine)} />
              <Header
                tag="h2"
                style={{ fontSize: 16, marginBottom: 24, lineHeight: "28px" }}
              >
                Map
              </Header>
              <Map
                lat={project.location.lat}
                lng={project.location.lng}
                showExactLocation={shouldShowExactLocation}
              />
            </>
          )}
          {isExpert &&
            enabledForTwilioProxy &&
            introducedAt &&
            !visitedAt &&
            !waitingForDelayedProxySession &&
            !successfulContactMade && (
              <div className={css([ss.mobileCtas, ss.showOnMobile])}>
                <Header tag="h6" style={{ fontSize: 16, marginBottom: 12 }}>
                  {shortName} is waiting to hear from you
                </Header>
                <Body tag="span">Call or text as soon as possible: </Body>
                <Body
                  tag="span"
                  style={{
                    fontWeight: 700,
                    color: styles.colors.brandPrimary,
                  }}
                >
                  {proxyPhoneNumberForGc}.
                </Body>
                <Body tag="p" style={{ marginBottom: 0 }}>
                  Be sure to use the phone you registered with Sweeten for this
                  project: {phoneNumberForProxySessions}.
                </Body>
              </div>
            )}
          {isExpert && (
            <>
              {shouldShowMatchCta && (
                <div className={css([ss.mobileCtas, ss.showOnMobile])}>
                  <RespondToMatchCTAs
                    copy={copy}
                    header={header}
                    isExpert={isExpert}
                    intro={matchWithCurrentUser}
                    userInfo={userInfo}
                    setModalState={setModalState}
                  />
                </div>
              )}
              {shouldShowGCMatchText && (
                <div
                  className={css([ss.mobileCtas, ss.showOnMobile])}
                  style={{ padding: 40 }}
                >
                  <RespondedDisplay copy={copy} header={header} />
                </div>
              )}
            </>
          )}
          {isRenovator && shouldShowMatchCta && (
            <div
              className={css([ss.mobileCtas, ss.showOnMobile])}
              style={{ padding: 40 }}
            >
              <RespondToMatchCTAs
                copy={copy}
                enabledForTwilioProxy={currUserEnabledForTwilioProxy}
                header={header}
                isRenovator={isRenovator}
                intro={matchWithCurrentUser}
                userInfo={userInfo}
              />
            </div>
          )}
          {shouldShowGetStarted && (
            <>
              <div
                className={css(ss.showOnMobile)}
                style={{ paddingTop: 200 }}
              />
              <div className={css([ss.mobileCtas, ss.showOnMobile])}>
                <Header tag="h2" aphStyle={ss.signInHeader}>
                  Remodel a home with Sweeten
                </Header>
                <Button
                  aphStyle={ss.signInCta}
                  onClick={() => goToPage("/journey")}
                  id="project_page_cta"
                >
                  Get Started
                </Button>
              </div>
            </>
          )}
          <div className={css(ss.horizontalLine)} />
          <Faqs project={project} />
        </Col>
        <Col width={5}>
          <div className={css([ss.sidebar, ss.hideOnMobile])}>
            {isExpert && companyId === awardedCompanyId && (
              <>
                <Card style={{ marginBottom: 16 }}>
                  <Header tag="h6" style={{ marginBottom: 16 }}>
                    Photos of this finished project
                  </Header>
                  <Button
                    onClick={() => togglePhotoUploaderModal(true)}
                    style={{ width: "100%" }}
                  >
                    {images.length > 0 ? "Add / delete photos" : "Add photos"}
                  </Button>
                </Card>
                <Card
                  aphStyle={ss.expressInterestTips}
                  style={{ marginBottom: 16 }}
                >
                  <TipList
                    header="Tips for photos that win clients"
                    tips={[
                      "Declutter the space if you can.",
                      "Use natural daylight. Avoid flash.",
                      "Hold camera / phone level, to keep angles square.",
                      "Use landscape orientation (width greater than height) when possible.",
                      "Include equal amounts of floor and ceiling.",
                      "For a cleaner look, shoot spaces head-on, not from corners/angles.",
                    ]}
                  />
                </Card>
              </>
            )}
            {isExpert &&
              enabledForTwilioProxy &&
              introducedAt &&
              !visitedAt &&
              !waitingForDelayedProxySession &&
              !successfulContactMade && (
                <Card aphStyle={ss.renTwilioContact}>
                  <Header tag="h6" style={{ fontSize: 16, marginBottom: 12 }}>
                    {shortName} is waiting to hear from you
                  </Header>
                  <Body tag="span">Call or text as soon as possible: </Body>
                  <Body
                    tag="span"
                    style={{
                      fontWeight: 700,
                      color: styles.colors.brandPrimary,
                    }}
                  >
                    {proxyPhoneNumberForGc}.
                  </Body>
                  <Body tag="p" style={{ marginBottom: 0 }}>
                    Be sure to use the phone you registered with Sweeten for
                    this project: {phoneNumberForProxySessions}.
                  </Body>
                </Card>
              )}
            <Card>
              {shouldShowGetStarted && (
                <>
                  <Header tag="h2" aphStyle={ss.signInHeader}>
                    Remodel a home with Sweeten
                  </Header>
                  <Button
                    aphStyle={ss.signInCta}
                    onClick={() => goToPage("/journey")}
                    id="project_page_cta"
                  >
                    Get Started
                  </Button>
                  <div className={css(ss.horizontalLine)} />
                </>
              )}
              {isExpert && (
                <>
                  {shouldShowMatchCta && (
                    <>
                      <RespondToMatchCTAs
                        copy={copy}
                        header={header}
                        isExpert={isExpert}
                        intro={matchWithCurrentUser}
                        userInfo={userInfo}
                        setModalState={setModalState}
                      />
                      <div className={css(ss.horizontalLine)} />
                    </>
                  )}
                  {shouldShowGCMatchText && (
                    <>
                      <RespondedDisplay copy={copy} header={header} />
                      <div className={css(ss.horizontalLine)} />
                    </>
                  )}
                </>
              )}
              {isRenovator && shouldShowMatchCta && (
                <>
                  <RespondToMatchCTAs
                    copy={copy}
                    enabledForTwilioProxy={currUserEnabledForTwilioProxy}
                    header={header}
                    isRenovator={isRenovator}
                    intro={matchWithCurrentUser}
                    userInfo={userInfo}
                    variant="gcProfile"
                  />
                  <div className={css(ss.horizontalLine)} />
                </>
              )}
              <ProjectOverview
                budgetFlexibility={budgetFlexibility}
                budgetScopeDetails={budgetScopeDetails}
                canShowEditCta={canShowEditCta}
                isResidential={isResidential}
                neighborhood={location.neighborhood}
                project={project}
                roomTypes={roomTypes}
                toggleProjectOverviewModal={toggleProjectOverviewModal}
              />
              {(isSweeten || pressLinks) && (
                <>
                  <div className={css(ss.horizontalLine)} />
                  <FooterFields isSweeten={isSweeten} pressLinks={pressLinks} />
                </>
              )}
            </Card>
            <Card style={{ marginTop: 8 }}>
              <BlogLink project={project} />
            </Card>
            {!currentUser && (
              <div className={css(ss.loginHere)}>
                <Body tag="div">
                  Have an account? Login{" "}
                  <TextLink href="/login">here.</TextLink>
                </Body>
              </div>
            )}
          </div>
        </Col>
      </Row>
      {showProjectTitleModal && (
        <ProjectTitleModal
          closeModal={() => toggleProjectTitleModal(false)}
          project={project}
          showSuccessAlert={showSuccessAlert}
        />
      )}
      {showProjectDetailsModal && (
        <ProjectDetailsModal
          closeModal={() => toggleProjectDetailsModal(false)}
          project={project}
          showSuccessAlert={showSuccessAlert}
          currentUser={currentUser}
        />
      )}
      {showProjectOverviewModal && (
        <ProjectOverviewModal
          closeModal={() => toggleProjectOverviewModal(false)}
          project={project}
          showSuccessAlert={showSuccessAlert}
          currentUser={currentUser}
        />
      )}
      {showProjectDescriptionModal && (
        <ProjectDescriptionModal
          closeModal={() => toggleProjectDescriptionModal(false)}
          project={project}
          showSuccessAlert={showSuccessAlert}
        />
      )}
      <div id="photo-uploader">
        {isUppyLoaded && showPhotoUploaderModal && (
          <AfterPhotoUploaderModal
            onClose={() => togglePhotoUploaderModal(false)}
            projectId={project.id}
          />
        )}
      </div>
      {modalState === "nextSteps" && (
        <NextStepsModal
          enabledForTwilioProxy={enabledForTwilioProxy}
          name={shortName}
          onClose={() => goToPage("/dashboard")}
          phoneNumberForProxySessions={phoneNumberForProxySessions}
          proxyPhoneNumberForGc={proxyPhoneNumberForGc}
          phone={phone}
          introId={matchWithCurrentUser.id}
        />
      )}
      {modalState === "phoneNumber" && (
        <PhoneNumberModal
          enabledForTwilioProxy={enabledForTwilioProxy}
          intro={matchWithCurrentUser}
          onClose={() => setModalState(null)}
          phoneNumberForProxySessions={phoneNumberForProxySessions}
          renResponseAt={renResponseAt}
          setModalState={setModalState}
          isOptedInForProxySms={isOptedInForProxySms}
        />
      )}
      {needsBottomBuffer && <div className={css(ss.bottomBuffer)} />}
    </div>
  );
});

ProjectShow.propTypes = {
  adminProps: PropTypes.object,
  authToken: PropTypes.string,
  canEditProject: PropTypes.bool,
  coreAuthToken: PropTypes.string,
  data: PropTypes.object,
  env: PropTypes.string,
  isPreview: PropTypes.bool,
  isCurrentExpProject: PropTypes.bool,
  isCurrentRenProject: PropTypes.bool,
  mixpanelMatchState: PropTypes.oneOf([
    "N/A",
    "Introduced",
    "Matched",
    "Declined",
  ]),
  previewCtaProps: PropTypes.object,
  props: PropTypes.object,
  residentialFormId: PropTypes.string,
  userIsCollaborator: PropTypes.bool,
  userType: PropTypes.string,
  hubspotDealUrl: PropTypes.string,
};

ProjectShow.defaultProps = {
  isPreview: false,
};

export default props => {
  // eslint-disable-next-line react/prop-types
  const { projectId } = props;
  const { data, loading, refetch } = useQuery(GET_PROJECT_DATA, {
    variables: { id: projectId },
  });

  return loading ? null : (
    <ProjectShow data={data} props={props} refetch={refetch} />
  );
};
