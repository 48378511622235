import React from "react";
import PropTypes from "prop-types";
import { find, capitalize } from "lodash";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite";
import { styles, Icon, Body, Row, Col } from "@sweeten/oreo";
import { getWindowWidth } from "../../utils";

const { breakpoints, colors } = styles;

const DELETE_PROJECT_ROOM = gql`
  mutation deleteProjectRoom($projectRoomId: ID!, $isBackfill: Boolean) {
    projectRoomDelete(projectRoomId: $projectRoomId, isBackfill: $isBackfill) {
      project {
        id
        budgetEstimate {
          min
          max
        }
        rooms {
          id
        }
      }
    }
  }
`;

const ss = StyleSheet.create({
  itemContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 32,
    ...styles.mediaQuery({
      maxWidth: breakpoints.tabletStandard,
      style: { paddingRight: 0 },
    }),
  },
  roomsHeader: {
    color: colors.grey40,
    margin: 0,
  },
});

const RoomListItem = ({ room, roomTypes, isBackfill }) => {
  const [deleteRoom, { loading: mutationLoading }] = useMutation(
    DELETE_PROJECT_ROOM
  );

  const {
    squareFootageBucket,
    squareFootageMax,
    squareFootageMin,
    roomTypeConstant,
    id,
    cost,
  } = room;

  const sqFtDisplayText =
    squareFootageMin && squareFootageMax
      ? `(${capitalize(squareFootageBucket)} ${squareFootageMin}
      - ${squareFootageMax}${squareFootageBucket === "large" ? "+" : ""} sq ft)`
      : null;
  return (
    <div
      className={css(ss.itemContainer)}
      style={{ opacity: mutationLoading ? "0.2" : "1.0" }}
    >
      <div>
        <Body tag="p" style={{ margin: 0 }}>
          {
            find(roomTypes, roomType => roomType.constant === roomTypeConstant)
              .displayName
          }{" "}
        </Body>
        <Body tag="p" style={{ color: colors.grey40, margin: 0 }}>
          {sqFtDisplayText}
        </Body>
      </div>
      {cost && <Body tag="p">Cost: ${cost}</Body>}
      <div
        style={{ marginTop: 4 }}
        onClick={() => {
          deleteRoom({
            variables: {
              projectRoomId: id,
              isBackfill,
            },
          });
        }}
      >
        {" "}
        <Icon name="close" size={16} style={{ cursor: "pointer" }} />
      </div>
    </div>
  );
};

const RoomList = ({ rooms, roomTypes, projectType, isBackfill }) => {
  let roomsBodyText;

  const hasRooms = !!rooms.length;

  if (hasRooms) {
    roomsBodyText =
      projectType === "portfolio"
        ? "Areas you renovated"
        : "Areas you're renovating";
  } else {
    roomsBodyText = "No areas chosen";
  }

  const windowWidth = getWindowWidth();
  const isDesktop = windowWidth > styles.breakpoints.phoneStandard;

  return (
    <div
      style={{
        backgroundColor: styles.colors.grey10,
        padding: 16,
        borderRadius: 8,
      }}
    >
      <Body tag="p" variant="bold" aphStyle={ss.roomsHeader}>
        {roomsBodyText}
      </Body>
      {hasRooms && (
        <Row aphStyle={ss.list} verticalGap={0}>
          {rooms.map(room => (
            <Col
              style={{ margin: "16px 0 0 0" }}
              width={{
                desktopLarge: 6,
                desktopStandard: 6,
                tabletStandard: 11,
                tabletSmall: 12,
                phoneStandard: 12,
                phoneSmall: 12,
              }}
            >
              <RoomListItem
                room={room}
                roomTypes={roomTypes}
                isBackfill={isBackfill}
              />
            </Col>
          ))}
        </Row>
      )}
      {!hasRooms && isDesktop && (
        <Row aphStyle={ss.list} verticalGap={0}>
          <Col style={{ width: "100%", height: 32 }} />
        </Row>
      )}
    </div>
  );
};

RoomListItem.propTypes = {
  room: PropTypes.object,
  roomTypes: PropTypes.arrayOf(PropTypes.object),
  isBackfill: PropTypes.bool,
};

RoomList.propTypes = {
  rooms: PropTypes.arrayOf(PropTypes.object),
  roomTypes: PropTypes.arrayOf(PropTypes.object),
  projectType: PropTypes.oneOf(["portfolio", "project"]),
  isBackfill: PropTypes.bool,
};

export default RoomList;
