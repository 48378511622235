import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Body,
  Header,
  FormLabel,
  Input,
  FileInput,
  styles,
  Textarea,
} from "@sweeten/oreo";
import { forEach, keys } from "lodash";
import { StyleSheet, css } from "aphrodite";
import fetch from "unfetch";
import Cookies from "js-cookie";
import { withApollo } from "react-apollo";
import { useForm, useField } from "react-final-form-hooks";
import FormButton from "./form_button";
import { goToPage, getUrlParameterValue } from "../../utils";
import {
  required,
  phoneNumber as phoneNumberValidator,
  validateEmail,
  validate,
  postalCode,
} from "../../validators";
import { AppDispatch } from "../../components/app_provider";

const ss = StyleSheet.create({
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    margin: "32px 0px",
  },
  addressContainer: {
    display: "flex",
    marginBottom: 16,
  },
  fileInputCaption: {
    marginTop: 24,
    color: styles.colors.blueInfo,
  },
  marginBottom: {
    marginBottom: 24,
  },
  textarea: {
    width: "100%",
  },
  borderRed: {
    border: "1px solid red",
  },
});

const numberOnChange = (field, val) => {
  const newVal = val.replace(/[^\d.]/g, "");
  field.input.onChange({
    target: {
      value: newVal,
    },
  });
};

const ReferenceComp = ({
  num,
  firstNameField,
  lastNameField,
  emailField,
  phoneField,
  zipField,
  descriptionField,
}) => {
  const descriptionError =
    descriptionField.meta.touched && descriptionField.meta.error;

  return (
    <>
      <Header tag="h6" aphStyle={ss.header}>
        Reference {num}
      </Header>
      <div className={css(ss.marginBottom)}>
        <FormLabel>First Name</FormLabel>
        <Input
          {...firstNameField.input}
          error={firstNameField.meta.touched && firstNameField.meta.error}
        />
      </div>
      <div className={css(ss.marginBottom)}>
        <FormLabel>Last Name (Optional)</FormLabel>
        <Input
          {...lastNameField.input}
          error={lastNameField.meta.touched && lastNameField.meta.error}
        />
      </div>
      <div className={css(ss.marginBottom)}>
        <FormLabel>Email</FormLabel>
        <Input
          {...emailField.input}
          error={emailField.meta.touched && emailField.meta.error}
        />
      </div>
      <div className={css(ss.marginBottom)}>
        <FormLabel>Phone</FormLabel>
        <Input
          {...phoneField.input}
          error={phoneField.meta.touched && phoneField.meta.error}
          pattern="[0-9]*"
          onChange={val => numberOnChange(phoneField, val)}
        />
      </div>
      <div className={css(ss.marginBottom)}>
        <FormLabel>Zip Code (Optional)</FormLabel>
        <Input
          {...zipField.input}
          error={zipField.meta.touched && zipField.meta.error}
          pattern="[0-9]*"
          onChange={val => numberOnChange(zipField, val)}
        />
      </div>
      <div className={css(ss.marginBottom)}>
        <FormLabel>Description</FormLabel>
        <Textarea
          {...descriptionField.input}
          placeholder="Tell us briefly about the project"
          aphStyle={[ss.textarea, descriptionError && ss.borderRed]}
          error={descriptionError}
          heightVariant="small"
        />
      </div>
    </>
  );
};

ReferenceComp.propTypes = {
  num: PropTypes.number,
  firstNameField: PropTypes.object,
  lastNameField: PropTypes.object,
  emailField: PropTypes.object,
  phoneField: PropTypes.object,
  zipField: PropTypes.object,
  descriptionField: PropTypes.object,
};

const PortfolioComp = ({
  num,
  addressField,
  zipField,
  photosField,
  error,
  setError,
}) => {
  return (
    <>
      <Header tag="h6" aphStyle={ss.header}>
        Recent Project {num}
      </Header>
      <FormLabel>Address (Optional)</FormLabel>
      <div className={css(ss.addressContainer)}>
        <Input
          {...addressField.input}
          error={addressField.meta.touched && addressField.meta.error}
          style={{ width: "75%" }}
          placeholder="Address Line 1"
        />
        <Input
          {...zipField.input}
          error={zipField.meta.touched && zipField.meta.error}
          placeholder="Zip Code"
          style={{ marginLeft: "5%", width: "20%" }}
          pattern="[0-9]*"
          onChange={val => numberOnChange(zipField, val)}
        />
      </div>
      <FormLabel>&ldquo;After&rdquo; Photos</FormLabel>
      <FileInput
        {...photosField.input}
        error={photosField.meta.error || error}
        placeholder={`Please include at least 1 "after" photos`}
        multiple
        onChange={files => {
          const isAcceptedFileSize = fileSize => fileSize / 1024 / 1024 <= 10;
          let validFileSizes = true;
          forEach(files, file => {
            if (!isAcceptedFileSize(file.size)) {
              setError("File size must be less than 10 MB");
              validFileSizes = false;
            }
          });
          if (validFileSizes) {
            setError("");
          }
          photosField.input.onChange(files);
        }}
        accept="image/*"
      />
      <Body tag="p" aphStyle={ss.fileInputCaption} variant="caption">
        Max file size: 10 MB. Acceptable file types: .jpg/jpeg, .png, .gif.
      </Body>
    </>
  );
};

PortfolioComp.propTypes = {
  num: PropTypes.number,
  addressField: PropTypes.object,
  zipField: PropTypes.object,
  photosField: PropTypes.object,
  error: PropTypes.string,
  setError: PropTypes.func,
};

const validateAfterPhotos = val => {
  if (val) {
    if (val.length < 1) {
      return "You must include at least 1 after photos";
    }
  }
  return "";
};

const Portfolio = withApollo(({ client }) => {
  const [afterPhotosOneError, setAfterPhotosOneError] = useState("");
  const [afterPhotosTwoError, setAfterPhotosTwoError] = useState("");
  const [afterPhotosThreeError, setAfterPhotosThreeError] = useState("");

  const [submitLoading, setSubmitLoading] = useState(false);

  const dispatch = useContext(AppDispatch);

  let initialEmail = getUrlParameterValue("applicant_email");

  const hsCookie = Cookies.get("hs_applicant");
  const hsCookieParsed = hsCookie ? JSON.parse(hsCookie) : {};
  if (hsCookieParsed.email) {
    initialEmail = hsCookieParsed.email;
  }
  const { isSmallerServiceArea } = hsCookieParsed;

  const { form, handleSubmit } = useForm({
    initialValues: {
      email: initialEmail,
    },
    onSubmit: values => {
      if (
        values.gc_portfolio_1_photos &&
        ((!isSmallerServiceArea &&
          values.gc_portfolio_2_photos &&
          values.gc_portfolio_3_photos) ||
          isSmallerServiceArea)
      ) {
        setSubmitLoading(true);
        const formData = new FormData();
        forEach(keys(values), key => {
          if (key.includes("photos")) {
            forEach(values[key], file => {
              formData.append(key, file);
            });
          } else {
            formData.append(key, values[key]);
          }
        });
        fetch(
          // eslint-disable-next-line max-len
          `https://forms.hubspot.com/uploads/form/v2/${process.env.HUBSPOT_PORTAL_ID}/${process.env.HUBSPOT_PORTFOLIO_FORM_ID}`,
          {
            method: "POST",
            body: formData,
          }
        ).then(resp => {
          setSubmitLoading(false);
          if (resp.status === 204) {
            window.dataLayer.push({
              event: "hubspot-form-success",
              "hs-form-guid": "gc-portfolio-form",
              eventCallback: () => goToPage("/schedule-interview"),
              eventTimeout: 5000,
            });
          } else {
            dispatch({
              type: "alert:show",
              payload: {
                variant: "error",
                text: "Submission failed. Please try again",
              },
            });
            if (typeof Sentry !== "undefined") {
              Sentry.captureMessage(
                `GC application portfolio/references submission failed for user ${values.email}`
              );
            }
          }
        });
      }
    },
  });

  const emailUser = useField("email", form, value =>
    validateEmail(value, client)
  );

  const addressPortfolioOne = useField("gc_portfolio_1_address", form);
  const addressPortfolioTwo = useField("gc_portfolio_2_address", form);
  const addressPortfolioThree = useField("gc_portfolio_3_address", form);

  const zipPortfolioOne = useField("gc_portfolio_1_zip_code", form, postalCode);
  const zipPortfolioTwo = useField(
    "gc_portfolio_2_zip_code",
    form,
    !isSmallerServiceArea && postalCode
  );
  const zipPortfolioThree = useField(
    "gc_portfolio_3_zip_code",
    form,
    !isSmallerServiceArea && postalCode
  );

  const afterPhotosPortfolioOne = useField(
    "gc_portfolio_1_photos",
    form,
    validateAfterPhotos
  );
  const afterPhotosPortfolioTwo = useField(
    "gc_portfolio_2_photos",
    form,
    !isSmallerServiceArea && validateAfterPhotos
  );
  const afterPhotosPortfolioThree = useField(
    "gc_portfolio_3_photos",
    form,
    !isSmallerServiceArea && validateAfterPhotos
  );

  const firstNameReferenceOne = useField(
    "gc_reference_1_first_name",
    form,
    required
  );
  const firstNameReferenceTwo = useField(
    "gc_reference_2_first_name",
    form,
    !isSmallerServiceArea && required
  );
  const firstNameReferenceThree = useField(
    "gc_reference_3_first_name",
    form,
    !isSmallerServiceArea && required
  );

  const lastNameReferenceOne = useField("gc_reference_1_last_name", form);
  const lastNameReferenceTwo = useField("gc_reference_2_last_name", form);
  const lastNameReferenceThree = useField("gc_reference_3_last_name", form);

  const emailReferenceOne = useField("gc_reference_1_email", form, value =>
    validateEmail(value, client, false)
  );
  const emailReferenceTwo = useField(
    "gc_reference_2_email",
    form,
    !isSmallerServiceArea && (value => validateEmail(value, client, false))
  );
  const emailReferenceThree = useField(
    "gc_reference_3_email",
    form,
    !isSmallerServiceArea && (value => validateEmail(value, client, false))
  );

  const phoneReferenceOne = useField("gc_reference_1_phone", form, value =>
    validate([phoneNumberValidator, required], value)
  );
  const phoneReferenceTwo = useField(
    "gc_reference_2_phone",
    form,
    !isSmallerServiceArea &&
      (value => validate([phoneNumberValidator, required], value))
  );
  const phoneReferenceThree = useField(
    "gc_reference_3_phone",
    form,
    !isSmallerServiceArea &&
      (value => validate([phoneNumberValidator, required], value))
  );

  const zipReferenceOne = useField("gc_reference_1_zip_code", form, postalCode);
  const zipReferenceTwo = useField(
    "gc_reference_2_zip_code",
    form,
    !isSmallerServiceArea && postalCode
  );
  const zipReferenceThree = useField(
    "gc_reference_3_zip_code",
    form,
    !isSmallerServiceArea && postalCode
  );

  const descriptionReferenceOne = useField(
    "gc_reference_1_description",
    form,
    required
  );
  const descriptionReferenceTwo = useField(
    "gc_reference_2_description",
    form,
    !isSmallerServiceArea && required
  );
  const descriptionReferenceThree = useField(
    "gc_reference_3_description",
    form,
    !isSmallerServiceArea && required
  );

  return (
    <Row aphStyle={ss.center}>
      <Col
        width={{
          desktopLarge: 6,
          desktopStandard: 6,
          tabletStandard: 9,
          tabletSmall: 9,
          phoneStandard: 12,
          phoneSmall: 12,
        }}
      >
        <Header tag="h3">Build Your Portfolio</Header>
        <Body tag="p" style={{ color: styles.colors.grey40, marginBottom: 24 }}>
          Show off your best work to win the highest quality leads
        </Body>
        <FormLabel>Email</FormLabel>
        <Input
          {...emailUser.input}
          error={emailUser.meta.touched && emailUser.meta.error}
        />
        <PortfolioComp
          num={1}
          addressField={addressPortfolioOne}
          zipField={zipPortfolioOne}
          photosField={afterPhotosPortfolioOne}
          error={afterPhotosOneError}
          setError={setAfterPhotosOneError}
        />
        {!isSmallerServiceArea && (
          <>
            <PortfolioComp
              num={2}
              addressField={addressPortfolioTwo}
              zipField={zipPortfolioTwo}
              photosField={afterPhotosPortfolioTwo}
              error={afterPhotosTwoError}
              setError={setAfterPhotosTwoError}
            />
            <PortfolioComp
              num={3}
              addressField={addressPortfolioThree}
              zipField={zipPortfolioThree}
              photosField={afterPhotosPortfolioThree}
              error={afterPhotosThreeError}
              setError={setAfterPhotosThreeError}
            />
          </>
        )}
      </Col>
      <Col
        width={{
          desktopLarge: 6,
          desktopStandard: 6,
          tabletStandard: 9,
          tabletSmall: 9,
          phoneStandard: 12,
          phoneSmall: 12,
        }}
      >
        <Header tag="h3">Share Your Best References</Header>
        <Body tag="p" style={{ color: styles.colors.grey40, marginBottom: 24 }}>
          Happy clients are your most effective advertising
        </Body>
        <ReferenceComp
          num={1}
          firstNameField={firstNameReferenceOne}
          lastNameField={lastNameReferenceOne}
          emailField={emailReferenceOne}
          phoneField={phoneReferenceOne}
          zipField={zipReferenceOne}
          descriptionField={descriptionReferenceOne}
        />
        {!isSmallerServiceArea && (
          <>
            <ReferenceComp
              num={2}
              firstNameField={firstNameReferenceTwo}
              lastNameField={lastNameReferenceTwo}
              emailField={emailReferenceTwo}
              phoneField={phoneReferenceTwo}
              zipField={zipReferenceTwo}
              descriptionField={descriptionReferenceTwo}
            />
            <ReferenceComp
              num={3}
              firstNameField={firstNameReferenceThree}
              lastNameField={lastNameReferenceThree}
              emailField={emailReferenceThree}
              phoneField={phoneReferenceThree}
              zipField={zipReferenceThree}
              descriptionField={descriptionReferenceThree}
            />
          </>
        )}
        <FormButton
          loading={submitLoading}
          handleSubmit={evt => {
            if (!form.getFieldState("gc_portfolio_1_photos").value) {
              setAfterPhotosOneError(
                "You must include at least 1 after photos"
              );
            }
            if (!isSmallerServiceArea) {
              if (!form.getFieldState("gc_portfolio_2_photos").value) {
                setAfterPhotosTwoError(
                  "You must include at least 1 after photos"
                );
              }
              if (!form.getFieldState("gc_portfolio_3_photos").value) {
                setAfterPhotosThreeError(
                  "You must include at least 1 after photos"
                );
              }
            }
            handleSubmit(evt);
          }}
        />
      </Col>
    </Row>
  );
});

Portfolio.propTypes = {
  client: PropTypes.object,
};

export default Portfolio;
