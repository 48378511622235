import React, { useEffect, useState } from "react";
import { Row, Col, Body, Header, styles, Button } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import Cookies from "js-cookie";
import FormButton from "./form_button";
import { goToPage, attachMeetingScriptToDiv } from "../../utils";

const ss = StyleSheet.create({
  col: {
    margin: "auto",
    width: 850,
    ...styles.mediaQuery({
      maxWidth: 631,
      style: {
        maxWidth: 400,
      },
    }),
  },
  leftAlignCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  buttonContainer: {
    ...styles.center("vertical"),
    justifyContent: "flex-end",
  },
  skip: {
    marginRight: 16,
  },
});

const ScheduleInterview = () => {
  const [isMeetingBooked, setIsMeetingBooked] = useState(false);

  useEffect(() => {
    attachMeetingScriptToDiv("schedule_interview_container", () =>
      setIsMeetingBooked(true)
    );
  }, []);

  const onSkipNext = () => {
    window.dataLayer.push({
      event: "hubspot-form-success",
      "hs-form-guid": "gc-application-schedule-interview",
      eventCallback: () => goToPage("/success-confirmation"),
      eventTimeout: 5000,
    });
  };

  const hsCookie = Cookies.get("hs_applicant");
  const hsCookieParsed = hsCookie ? JSON.parse(hsCookie) : {};

  const cookieFirstName =
    hsCookieParsed.firstName && `&firstName=${hsCookieParsed.firstName}`;
  const cookieLastName =
    hsCookieParsed.lastName && `&lastName=${hsCookieParsed.lastName}`;
  const cookieEmail = hsCookieParsed.email && `&email=${hsCookieParsed.email}`;
  const cookieCompany =
    hsCookieParsed.company && `&company=${hsCookieParsed.company}`;

  const cookieQueryStr = `${cookieFirstName}${cookieLastName}${cookieEmail}${cookieCompany}`;

  // eslint-disable-next-line max-len
  const calendarWidgetLink = `https://meetings.hubspot.com/katherine79/sweeten-interview-call?embed=true${cookieQueryStr}`;

  return (
    <div id="schedule_interview_container">
      <Row>
        <Col aphStyle={ss.col}>
          <div className={css(ss.leftAlignCol)}>
            <Header tag="h5">Schedule a call with a Sweeten team member</Header>
            <Body tag="p" style={{ color: styles.colors.grey40 }}>
              We’ll answer your questions and discuss how Sweeten can help your
              business grow.
            </Body>
          </div>
        </Col>
      </Row>
      <div
        className="meetings-iframe-container"
        data-src={calendarWidgetLink}
      />
      <Row>
        <Col aphStyle={ss.col}>
          <div className={css(ss.buttonContainer)}>
            <Button aphStyle={ss.skip} onClick={onSkipNext}>
              Skip
            </Button>
            <FormButton disabled={!isMeetingBooked} handleSubmit={onSkipNext} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ScheduleInterview;
