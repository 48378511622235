import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { LeftNav, TopNav, styles } from "@sweeten/oreo";
import PostForm from "./post_form";

export const ss = StyleSheet.create({
  content: {
    width: "100%",
    boxSizing: "border-box",
    padding: "40px 16px",
    margin: "0 auto",
    height: "100%",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: { padding: "40px 0" },
    }),
  },
  contentContainer: {
    height: "100%",
    position: "absolute",
    boxSizing: "border-box",
    top: TopNav.style.desktop.height,
    left: 0,
    right: 0,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: { left: 0, top: TopNav.style.mobile.height },
    }),
  },
  noTopNavOffsetMobile: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: { top: 0 },
    }),
  },
  hideTopNav: {
    top: 0,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: { top: 0 },
    }),
  },
  contentLeftNavOffset: {
    left: LeftNav.style.desktop.width,
  },
  layout: {
    boxSizing: "border-box",
    display: "flex",
  },
  verticalLine: {
    height: "100%",
    margin: "0 16px",
    borderRight: `1px solid ${styles.colors.grey20}`,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: { display: "none" },
    }),
  },
  verticalAlign: { ...styles.center("vertical") },
  contentProgressOffset: {
    top: TopNav.style.desktop.height * 2,
  },
  subheader: {
    paddingLeft: 40,
  },
  activeSubHeader: {
    borderLeft: "none",
    color: styles.colors.brandPrimary,
    backgroundColor: "transparent",
  },
  newLabelContainer: {
    borderRadius: 4,
    backgroundColor: styles.colors.brandPrimary,
    color: styles.colors.white,
    position: "absolute",
    fontSize: 10,
    fontWeight: 700,
    padding: "0 4px",
    top: -8,
    right: -36,
    lineHeight: "18px",
  },
  updateDot: {
    width: 8,
    height: 8,
    backgroundColor: styles.colors.brandPrimary,
    borderRadius: "50%",
    position: "absolute",
    right: 2,
    top: -3,
    ...styles.mediaQuery({
      minWidth: styles.breakpoints.desktopLarge,
      style: {
        right: -12,
      },
    }),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        right: -12,
      },
    }),
  },
});

export const ContentWrapper = ({
  aphStyle,
  children,
  hasLeftNav,
  hasProgress,
  hideTopNav,
}) => {
  const shouldOffsetLeftNav = hasLeftNav && ss.contentLeftNavOffset;
  const shouldOffsetProgress = hasProgress && ss.contentProgressOffset;

  const isRequestFromExpApp = window.navigator.userAgent === "SweetenExpApp";
  const shouldNotOffsetTopNavMobile =
    isRequestFromExpApp && ss.noTopNavOffsetMobile;
  const shouldOffsetTopNavDesktop = hideTopNav && ss.hideTopNav;

  return (
    <div
      className={css(
        ss.contentContainer,
        shouldOffsetLeftNav,
        shouldNotOffsetTopNavMobile,
        shouldOffsetTopNavDesktop,
        shouldOffsetProgress
      )}
    >
      <div className={`${css(ss.content, aphStyle)}`}>{children}</div>
    </div>
  );
};

ContentWrapper.propTypes = {
  aphStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  hasLeftNav: PropTypes.bool,
  hasProgress: PropTypes.bool,
  hideTopNav: PropTypes.bool,
};

ContentWrapper.defaultProps = {
  hideTopNav: false,
};

export const LayoutWrapper = ({ aphStyle, children }) => (
  <div className={css(ss.layout, aphStyle)}>{children}</div>
);

LayoutWrapper.propTypes = {
  aphStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export const NewLabel = ({ aphStyle }) => (
  <div className={css(ss.newLabelContainer, aphStyle)}>New</div>
);

NewLabel.propTypes = {
  aphStyle: PropTypes.object,
};

export const UpdateDot = () => <div className={css(ss.updateDot)} />;

export const LeftNavItem = ({
  children,
  href,
  method,
  isActive,
  isSubHeader,
  openInNewTab,
  style,
}) => {
  if (method) {
    return (
      <PostForm href={href} method={method}>
        <LeftNav.Item
          isActive={isActive}
          aphStyle={[
            ss.verticalAlign,
            isSubHeader && ss.subheader,
            isActive && isSubHeader && ss.activeSubHeader,
          ]}
          style={{ marginBottom: 4 }}
        >
          {children}
        </LeftNav.Item>
      </PostForm>
    );
  }

  return (
    <LeftNav.Item
      isActive={isActive}
      aphStyle={[
        ss.verticalAlign,
        isSubHeader && ss.subheader,
        isActive && isSubHeader && ss.activeSubHeader,
      ]}
      href={href}
      style={style}
      target={openInNewTab ? "_blank" : null}
    >
      {children}
    </LeftNav.Item>
  );
};

LeftNavItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  href: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  method: PropTypes.string,
  openInNewTab: PropTypes.bool,
  style: PropTypes.object,
  isSubHeader: PropTypes.bool,
};

export const VerticalLine = ({ aphStyle }) => (
  <div className={css(ss.verticalLine, aphStyle)} />
);

VerticalLine.propTypes = {
  aphStyle: PropTypes.object,
};
