import PropTypes from "prop-types";
import React from "react";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import { StyleSheet } from "aphrodite";
import { Loader } from "@sweeten/oreo";
import { PhotoUploader as DomoPhotoUploader } from "@sweeten/domo";

export const transloaditEnvVars = {
  bucketName: process.env.S3_BUCKET,
  s3Key: process.env.S3_ACCESS_KEY_ID,
  s3SecretKey: process.env.S3_SECRET_ACCESS_KEY,
  transloaditKey: process.env.TRANSLOADIT_KEY,
};

const ss = StyleSheet.create({
  loaderText: {
    height: 24,
  },
  loaderUploader: {
    height: 250,
  },
});

const PhotoUploadLoader = () => (
  <div>
    <Loader.Body tag="p" aphStyle={ss.loaderText} />
    <Loader.Quadrangle aphStyle={ss.loaderUploader} />
  </div>
);

const PHOTO_DATA = gql`
  query getPhotos($id: ID!, $category: ProjectImageCategory!) {
    project(id: $id) {
      id
      images(categories: [$category]) {
        id
        url {
          small
        }
      }
    }
  }
`;

const PhotoUploader = props => {
  const { projectId, category } = props;

  return (
    <Query
      fetchPolicy="network-only"
      query={PHOTO_DATA}
      variables={{
        id: projectId,
        category,
      }}
    >
      {result => {
        if (result.loading) {
          return <PhotoUploadLoader />;
        }
        const { data } = result;
        if (data) {
          const { project } = data;
          const uploaderProjectImages = project.images.map(image => ({
            id: image.id,
            url: image.url.small,
          }));
          return (
            <DomoPhotoUploader
              photoAssemblies={[]}
              photoType={category}
              projectId={projectId}
              uploadedPhotos={uploaderProjectImages}
              {...transloaditEnvVars}
            />
          );
        }
        return null;
      }}
    </Query>
  );
};

PhotoUploader.propTypes = {
  projectId: PropTypes.string,
  category: PropTypes.string,
};

export default PhotoUploader;
