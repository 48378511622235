import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite/no-important";
import { TextLink, styles } from "@sweeten/oreo";
import { trackMixpanelWithCallback } from "../utils";

const ss = StyleSheet.create({
  progressBarBackground: {
    position: "relative",
    backgroundColor: styles.colors.brandPrimaryHover,
    width: "100%",
    minHeight: 4,
  },
  progressBar: {
    position: "absolute",
    backgroundColor: styles.colors.brandPrimary,
    minHeight: 4,
    transition: "width .2s ease-in",
  },
  stepCounter: {
    marginTop: 8,
    display: "flex",
    float: "right",
    marginRight: 24,
    fontSize: 16,
    color: styles.colors.white,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        color: styles.colors.brandPrimary,
      },
    }),
  },
  finishLater: {
    marginRight: 24,
    color: styles.colors.white,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        color: styles.colors.brandPrimary,
      },
    }),
  },
});

const trackClickedFinishLater = callback => {
  trackMixpanelWithCallback("Clicked on Finish Later", {}, callback);
};

const ProgressBar = ({
  onFinishLater,
  totalSteps,
  stepsCompleted,
  hideStepCounter,
}) => {
  const percentComplete = Math.round((stepsCompleted * 100) / totalSteps);

  return (
    <>
      <div className={css(ss.progressBarBackground)}>
        <div
          className={css(ss.progressBar)}
          style={{ width: `calc(${percentComplete}%)` }}
        />
      </div>
      {!hideStepCounter && (
        <div className={css(ss.stepCounter)}>
          {onFinishLater && (
            <TextLink
              onClick={() => {
                trackClickedFinishLater(() => onFinishLater());
              }}
              aphStyle={ss.finishLater}
            >
              Finish Later
            </TextLink>
          )}
          {stepsCompleted} / {totalSteps}
        </div>
      )}
    </>
  );
};

ProgressBar.propTypes = {
  onFinishLater: PropTypes.func,
  totalSteps: PropTypes.number,
  stepsCompleted: PropTypes.number,
  hideStepCounter: PropTypes.bool,
};

export default ProgressBar;
