import React, { useState } from "react";
import PropTypes from "prop-types";
import { styles, Icon } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite/no-important";
import { getWindowWidth } from "../utils";

const ss = StyleSheet.create({
  cardsAndArrows: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
        overflow: "auto",
        // Chrome, Opera, Safari
        "::-webkit-scrollbar": {
          display: "none",
        },
        // IE, Edge
        "-ms-overflow-style": "none",
        // Firefox
        scrollbarWidth: "none",
      },
    }),
  },
  widthFull: {
    width: "100%",
    flexWrap: "wrap",
  },
  arrowLeft: {
    ...styles.center("vertical"),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  arrowRight: {
    ...styles.center("vertical"),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  container: {
    display: "flex",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        justifyContent: "initial",
        margin: 0,
      },
    }),
  },
  justifyCenter: {
    ...styles.center("horizontal"),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        justifyContent: "initial",
      },
    }),
  },
});

const ItemCarousel = ({
  children,
  numShown,
  onClickLeftArrow = () => {},
  onClickRightArrow = () => {},
}) => {
  const [currentBucketGCIndex, updateCurrentBucketGCIndex] = useState(0);

  const showArrowLeft = currentBucketGCIndex !== 0;
  const showArrowRight = currentBucketGCIndex + numShown < children.length;

  const windowWidth = getWindowWidth();

  return (
    <div className={css(ss.cardsAndArrows)}>
      {showArrowLeft && (
        <div
          className={css(ss.arrowLeft)}
          onClick={() => {
            updateCurrentBucketGCIndex(currentBucketGCIndex - numShown);
            onClickLeftArrow();
          }}
        >
          <Icon
            name="chevron-left"
            size={18}
            backgroundColor={styles.colors.white}
          />
        </div>
      )}
      <div
        className={css(
          ss.container,
          showArrowLeft && showArrowRight ? ss.justifyCenter : ss.widthFull
        )}
      >
        {children.map((childElement, idx) => {
          if (
            (idx >= currentBucketGCIndex &&
              idx < currentBucketGCIndex + numShown) ||
            windowWidth <= styles.breakpoints.phoneStandard
          ) {
            return <>{childElement}</>;
          }
          return null;
        })}
      </div>
      {showArrowRight && (
        <div
          className={css(ss.arrowRight)}
          onClick={() => {
            updateCurrentBucketGCIndex(currentBucketGCIndex + numShown);
            onClickRightArrow();
          }}
        >
          <Icon
            name="chevron-right"
            size={18}
            backgroundColor={styles.colors.white}
          />
        </div>
      )}
    </div>
  );
};

ItemCarousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  numShown: PropTypes.number,
  onClickLeftArrow: PropTypes.func,
  onClickRightArrow: PropTypes.func,
};

export default ItemCarousel;
