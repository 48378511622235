/*
This component replaces a few of our uses of JQUERY and UJS
for links that do a DELETE/POST, not GET.
https://www.viget.com/articles/delete-in-rails-without-jquery-and-ujs/
The href passed to the textLink will be plucked out from the TextLink props and
used in the form, and the actual wrapped TextLink gets an onClick/handleClick
(whichever one the component uses) that submits a form.
*/

import React, { cloneElement } from "react";
import PropTypes from "prop-types";

class PostForm extends React.Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { href, handleSubmit } = this.props;

    const form = document.getElementById(href);
    if (form) {
      if (handleSubmit) {
        handleSubmit(form);
      } else {
        form.submit();
      }
    }
  }

  render() {
    const { authToken, children, href, method } = this.props;
    const childrenWithProps = cloneElement(children, {
      onClick: this.onClick,
    });

    return (
      <>
        <form id={href} action={href} method="post">
          <input type="hidden" name="_method" value={method} />
          {authToken && (
            <input type="hidden" name="authenticity_token" value={authToken} />
          )}
        </form>
        {childrenWithProps}
      </>
    );
  }
}

PostForm.propTypes = {
  authToken: PropTypes.string,
  handleSubmit: PropTypes.func,
  children: PropTypes.element.isRequired,
  href: PropTypes.string.isRequired,
  method: PropTypes.string,
};

export default PostForm;
