/* eslint-disable max-len */
/* eslint-disable no-control-regex */
import { useState, useLayoutEffect } from "react";
import { last, sortBy, get, startsWith, isEqual, noop, merge } from "lodash";
import { matchPath, navigate } from "@reach/router";
import { format } from "date-fns";
/**
 * Adds a hash to the current path without modifying browser history
 * @param {String} hash
 * @returns {void}
 */
export const addHashWithoutHistory = hash => {
  const { origin, pathname } = window.location;
  const newUrl = `${origin}${pathname}#${hash}`;

  window.location.replace(newUrl);
};

/**
 * This function formats and object of {id: 'id value here', someKey: 'key'} to
 * an object with {label: 'id value here', value: 'key'} keys.
 * @param {Object} options The object to format
 * @param {String} keyForLabel The key that should map to the label key.
 */
export const formatKeysInOptions = (options, keyForLabel = "name") => {
  const result = [];

  options.forEach(option => {
    result.push({
      value: option.id,
      label: option[keyForLabel],
    });
  });

  return result;
};

/**
 * Takes an array of strings and returns the formatted array that SingleSelectDropDown expects:
 * [{ label: 'display value', value: 'display value' }]
 * @param {Array} items The items to select in the dropdown
 */
export const sameLabelValueOptions = options => {
  const result = [];

  options.forEach(option => {
    result.push({
      label: option,
      value: option,
    });
  });

  return result;
};

/**
 * Extract the container props and container name from the domo
 * @returns {Object} pageData object and reactRoute string name.
 */
export const extractReactData = () => {
  const pageContentNode = document.getElementById("page-data");
  const layoutDataNode = document.getElementById("layout-data");
  const pageData = JSON.parse(pageContentNode.getAttribute("data-props"));
  const pageName = pageContentNode.getAttribute("data-page-name");
  const layoutData = layoutDataNode
    ? JSON.parse(layoutDataNode.getAttribute("data"))
    : {};

  return {
    layoutData,
    pageData,
    pageName,
  };
};

// List of FE routing pages' paths
// Keep this up to date when adding a new FE page
// See app/javascript/ren/ren_layout/index.js
const FRONTEND_ROUTES = [
  "/overview",
  "/my-contractors",
  "/project-settings",
  "/explore",
  "/project-files",
  "/payments",
  "/settings/user",
  "/projects/:projectId/my-contractors",
  "/projects/:projectId/settings",
  "/projects/:projectId/files",
  "/projects/:projectId/update-budget",
];

/**
 * Checks if the given path is a frontend route and returns extracted data
 * containing params, route, and uri of the path.
 * @param {String} path URL path, ex: "projects/1234/my-contractors"
 */
export const frontendRouteData = path => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < FRONTEND_ROUTES.length; i++) {
    const matchPathData = matchPath(FRONTEND_ROUTES[i], path);
    if (matchPathData) {
      return matchPathData;
    }
  }
  return null;
};

/**
 * Updates the window location with the specified string.
 * @param {String} location The new url you want the user to go to.
 * @param {object} options - Extra options to configure while opening a new page
 * @param {boolean} [options.shouldOpenNewTab=false] - Whether to open the page on a new tab
 */
/* eslint-disable no-lonely-if */
export const goToPage = (
  location = window.location,
  options = { shouldOpenNewTab: false }
) => {
  if (
    !options.isExpert &&
    frontendRouteData(window.location.pathname) &&
    frontendRouteData(location)
  ) {
    navigate(location);
  } else {
    if (options.shouldOpenNewTab) {
      const openNewTab = window.open(location, "_blank");
      if (!openNewTab) {
        Object.assign(window, { location });
      }
    } else {
      Object.assign(window, { location });
    }
  }
};
/* eslint-enable no-lonely-if */

/**
 * This is a function that handles api errors when using the api controller's layout.
 * If the error message isn't formatted like it expects it will give a default error message.
 * @param {Object} response The response object from the backend
 */
export const handleError = response => {
  try {
    const messages = response.responseJSON.messages.error.map(e => e.message);
    this.context({
      type: "alert:show",
      payload: {
        variant: "error",
        text: messages[0],
      },
    });
  } catch (e) {
    this.context({
      type: "alert:show",
      payload: {
        variant: "error",
        text: "An unknown error occurred.",
      },
    });
  }
};

/**
 * This is for selecting category with a radio button and
 * opening up a new radio set with the space options
 * like in S10.Reviews.Edit.Container
 * @param {String} category
 * @param {Array} oneSpaceOptions
 * @param {Array} millworkOptions
 * @param {Array} commercialOptions
 */
export const getSpaceOptions = (
  category,
  oneSpaceOptions,
  millworkOptions,
  commercialOptions
) => {
  if (category === "1 space") {
    return oneSpaceOptions;
  }
  if (category === "Custom millwork") {
    return millworkOptions;
  }
  if (category === "Commercial") {
    return commercialOptions;
  }
  return null;
};

/**
 * Formats a count string like '12 items' or '1 item' and pluralizes it based on the count
 * @param {Integer} count Number of items
 * @param {String} name Name of item
 * @param {String} pluralSuffix (defaults to 's')
 * Suffix to add to end of name for plural amounts (ex. 'es')
 */
export const formatCountText = ({ count, name, pluralSuffix = "s" }) => {
  if (count === 1 || count === -1) {
    return `${count} ${name}`;
  }
  return `${count} ${name}${pluralSuffix}`;
};

/**
 * Allows for tabbable links to execute functions on an enter keypress
 * @param evt {Object} the keydown event
 * @param func {Function} the function to call
 */
export const callFunctionOnEnter = (evt, func) => {
  if (evt.charCode === 13) {
    func();
  }
};

/**
 * Sends event to Google Analytics (GA)
 * @param {object} eventParams - GA event parameters
 * @param {string} eventParams.eventCategory - Typically the object that was interacted with
 * @param {string} eventParams.eventAction - The type of interaction
 * @param {string} eventParams.eventLabel - Useful for categorizing events
 */
export const trackEvent = (eventParams = {}) => {
  ga(
    "send",
    "event",
    eventParams.eventCategory,
    eventParams.eventAction,
    eventParams.eventLabel
  );
};

export const trackMixpanelWithCallback = (
  name,
  properties,
  callback = noop
) => {
  const { mixpanel } = window;

  // eslint-disable-next-line no-underscore-dangle
  if (typeof mixpanel !== "undefined" && mixpanel.__loaded) {
    mixpanel.track(name, properties, callback);
  } else {
    callback();
  }
};

export const trackMixpanel = (name, properties) => {
  const { mixpanel } = window;

  if (typeof mixpanel !== "undefined") {
    mixpanel.track(name, properties);
  }
};

export const trackMixpanelForm = (formId, eventName, properties) => {
  const { mixpanel } = window;

  if (typeof mixpanel !== "undefined") {
    mixpanel.track_forms(formId, eventName, properties);
  }
};

export const trackMixpanelUserSet = properties => {
  const { mixpanel } = window;

  if (typeof mixpanel !== "undefined") {
    mixpanel.people.set(properties);
  }
};

export const trackMixpanelExperiment = (
  experimentName,
  variant,
  callback = noop
) => {
  if (!callback) {
    trackMixpanel("$experiment_started", {
      "Experiment name": experimentName,
      "Variant name": variant,
    });
  } else {
    trackMixpanelWithCallback(
      "$experiment_started",
      {
        "Experiment name": experimentName,
        "Variant name": variant,
      },
      callback
    );
  }
};

export const monitorHubspotConversations = () => {
  const monitor = () => {
    window.HubSpotConversations.on("conversationStarted", () => {
      trackMixpanel("Hubspot Conversation Started");
    });
  };

  if (window.HubSpotConversations) {
    monitor();
  } else {
    window.hsConversationsOnReady = [monitor];
  }
};

export const trackMixpanelUserSetOnce = properties => {
  const { mixpanel } = window;

  if (typeof mixpanel !== "undefined") {
    mixpanel.people.set_once(properties);
  }
};

/**
 * Returns an array of phone number parts from a string input:
 * [prefix, area code, first 3 digits, final 4 digits]
 * @param {string} input
 */
export const parsePhoneNumberParts = input => {
  const stripped = input.replace(/\D/g, "");
  //                             pfix areaCode        firstThree lastFour
  const matches =
    stripped.match(/(1)?([2-9]\d{0,2})?(\d{1,3})?(\d{1,4})?/) || [];
  return matches.slice(1);
};

/**
 * Accepts a string, calls parsePhoneNumberParts on that string, then
 * returns a formatted phone number with the following pattern: (555) 555-5555
 * @param {string} phone
 */
export const formatPhoneNumber = phone => {
  const [prefix, areaCode, firstThree, lastFour] = parsePhoneNumberParts(phone);

  let formatted = "";

  if (prefix) {
    formatted = prefix;
  }
  if (areaCode) {
    formatted += ` (${areaCode}`;
    if (firstThree) {
      formatted += `) ${firstThree}`;
      if (lastFour) {
        formatted += `-${lastFour}`;
      }
    }
  }
  return formatted.trim();
};

/**
 * If the condition is true, then this wraps the children with the component passed in the
 * wrapper function
 * @param {boolean} condition
 * @param {function} wrapper - a function with the wrapper component
 * @param {children} element - the children passed to the component
 */
export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

/**
 * Checks whether the current user is a collaborator for the current project
 * @param {Array} collaborators - array of collaborators for the current project
 * @param {number} currentUserId - ID of current user
 */
export const userIsCollaborator = (collaborators, currentUserId) =>
  collaborators.find(obj => {
    return Number(obj.user.id) === currentUserId;
  });

/**
 * Finds the largest value of an attribute in a list
 * @param list, a list of objects
 * @param path, a path string to access the value in the object
 * @returns {null|*}
 */
export const findLargestValue = (list, path) => {
  if (list.length === 0) {
    return null;
  }

  return get(last(sortBy(list, path)), path);
};

/**
 * Executes a smooth scroll to a given point on the window
 * @param {number} targetY - Y value that the window should scroll to
 */

export const smoothWindowScroll = targetY => {
  const isScrollingDown = window.pageYOffset < targetY;
  let scrollIncrement = 1;
  let previousPageYOffset = window.pageYOffset;

  const interval = setInterval(() => {
    let nextScrollPosition;

    // If the next scroll position exceeds the target in either direction,
    // the window scrolls directly to the target and clears the interval
    if (isScrollingDown) {
      nextScrollPosition =
        window.pageYOffset + scrollIncrement > targetY
          ? targetY
          : window.pageYOffset + scrollIncrement;
    } else {
      nextScrollPosition =
        window.pageYOffset - scrollIncrement < targetY
          ? targetY
          : window.pageYOffset - scrollIncrement;
    }

    window.scrollTo(0, nextScrollPosition);
    // Adds to the scroll increment on each interval to create momentum
    // for long scroll distances
    scrollIncrement += 1;
    if (
      window.pageYOffset === targetY ||
      window.pageYOffset === previousPageYOffset
    ) {
      clearInterval(interval);
    } else {
      // Keeps track of of previous pageYOffset value so the interval can be cleared
      // when the window can not scroll any farther toward the target
      previousPageYOffset = window.pageYOffset;
    }
  });
};

/**
 * Grabs the form auth token from a hidden field in our html.
 * HACK NOTE: This is a temporary solution since querying for
 * the token leads to unexpected behavior.
 */
export const getFormAuthToken = () => {
  const el = document.querySelector("#form-authenticity-token");

  if (!el) {
    // eslint-disable-next-line no-console
    console.error("Form authenticity token not found.");
    return "";
  }
  return el.value;
};

/**
 * Checks for invalid URLs and prepends 'http://' to them.
 * If target web server is configured to HTTPS, this still works, as
 * it will convert HTTP to HTTPS.
 * NOTE: This is a temporary solution which we will improve.
 */
export const prependUrlWithHttp = url => {
  const validUrl = startsWith(url, "http://") || startsWith(url, "https://");

  return validUrl ? url : `http://${url}`;
};

/**
 * @param callback, the callback that gets called when we know if the browser supports webp
 */
export const isWebpSupported = callback => {
  // If the browser doesn't have the method createImageBitmap, you can't display webp format
  if (!window.createImageBitmap || !window.fetch) {
    callback(false);
    return;
  }

  // Base64 representation of a white point image
  const webpdata =
    "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";

  // Retrieve the Image in Blob Format
  fetch(webpdata)
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      // If the createImageBitmap method succeeds, return true, otherwise false
      createImageBitmap(blob).then(
        () => {
          callback(true);
        },
        () => {
          callback(false);
        }
      );
    });
};

export const encodeFormData = data => {
  if (data) {
    return Object.keys(data)
      .map(key => {
        if (data[key]) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
        }
        return null;
      })
      .filter(Boolean)
      .join("&");
  }
  return "";
};

/**
 * Format user object
 * @param user {Object} user object with firstName and lastName attributes
 * @param nameFormat {string} "full" | "abbrLast"
 */
export const formatUserName = (user, nameFormat) => {
  const { firstName, lastName } = user;
  if (isEqual(nameFormat, "full")) {
    return `${firstName || ""} ${lastName || ""}`;
  }

  if (isEqual(nameFormat, "abbrLast")) {
    // eslint-disable-next-line prefer-template
    return `${firstName || ""} ${lastName ? lastName[0] + "." : ""}`;
  }

  return formatUserName(user, "full");
};

/**
 * Capitalize first letter of every word in the sentence.
 * Note: If the sentence does not have symbols, make use of lodash's startCase instead.
 * TODO: This function can be removed once the following startCase issue gets resolved,
 * https://github.com/lodash/lodash/issues/3383
 * @param {string} sentence - Sentence to be converted
 * @param {object} options - Extra options to configure while capitalizing
 * @param {string} options.splitCharacter - A character used to split the sentence
 * @returns {string} Sentence with the first letter of every word capitalized
 */
export const capitalizeWords = (
  sentence,
  options = { splitCharacter: " " }
) => {
  const words = sentence.split(options.splitCharacter);
  const capitalizedWords = words.map(
    word => word && word[0].toUpperCase() + word.slice(1)
  );

  return capitalizedWords.join(" ");
};

/**
 * Checks if an email address matches the pattern of a temporary email address
 * that we create for unregistered users.
 * @param {string} email - Email address to check
 */
export const isUnregisteredEmail = email => /unreg-.*?@sweeten.com/.test(email);

/**
 * Returns formatter function that accepts a datetime string, a timezone and custom options,
 * and that will use Intl.DateTimeFormat api to format the date string passed in
 * using the default options provided, converting it to the timezone specified as well.
 * @param {object} defaultOptions
 */
export const createIntlDateFormatter = (defaultOptions = {}) => {
  return (datetimeStr, timeZone, customOptions = {}) => {
    if (datetimeStr) {
      const date = new Date(datetimeStr);
      const options = merge(defaultOptions, { timeZone }, customOptions);
      try {
        return new Intl.DateTimeFormat("en-US", options).format(date);
      } catch (err) {
        // IE 11 only accepts UTC as a timezone. We delete the passed key so
        // browser can use the default, which is the runtime's default time zone
        delete options.timeZone;
        return new Intl.DateTimeFormat("en-US", options).format(date);
      }
    }
    return null;
  };
};

/**
 * Converts date to the provided timezone and formats it to a string.
 * Default formatting is: Sept 3, 2020
 * @param {string} datetimeStr The datetime string to convert and format
 * @param {string} timeZone IANA Timezone string to convert the date to
 * @param {object} customOptions Options to override the default options
 */
export const formatDateToTimezone = createIntlDateFormatter({
  year: "numeric",
  month: "short",
  day: "numeric",
});

/**
 * Converts time to the provided timezone and formats it to a string.
 * Default formatting is: 9:00 PM
 * @param {string} datetimeStr The datetime string to convert and format
 * @param {string} timeZone IANA Timezone string to convert the date to
 * @param {object} customOptions Options to override the default options
 */
export const formatTimeToTimezone = createIntlDateFormatter({
  hour: "numeric",
  minute: "numeric",
});

export const formatDateMMDDYYYY = (dateStr, timeZoneOffset = false) => {
  let date = new Date(dateStr);

  if (timeZoneOffset) {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() + offset * 60000);
  }

  return format(date, "MM/DD/YYYY");
};

/**
 * Transform an integer into a string with currency formatting.
 * @param  {Number} int Unformatted number, ie 1000
 * @return {String} Formatted string with commas, i.e. $1,000.00
 */
export const formatToCurrency = (int, digits = 2) => {
  if (!int) {
    return "$0";
  }
  const currency = `$${int
    .toFixed(digits)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;

  return currency.replace(/\.00$/, "");
};

/**
 * Transform an integer into a string with commas.
 * @param  {Number} num Unformatted number
 * @return {String} Formatted string with commas, i.e. 1,000
 */
export const addCommas = num => {
  if (num === undefined || num === null) {
    return "";
  }
  // Removes commas before re-adding them
  let unformattedNum = num.toString().replaceAll(",", "");
  // Remove any non-number characters except periods
  unformattedNum = unformattedNum.replace(/[^\d.]/g, "");

  return unformattedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Determines if the request is originated from the REN app
 * @return {boolean}
 */
export const isRequestFromRenApp =
  window.navigator.userAgent === "SweetenRenApp";

/**
 * Posts message to React Native WebView (used for REN app)
 * More Info: https://tinyurl.com/ydazud66
 * @param {string} message - Message to post
 */
export const postMessageToRNWebView = message => {
  if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
    window.ReactNativeWebView.postMessage(message);
  }
};

/**
 * Parses the URL's search params, and returns the value for the given parameter
 * For example, getParameterValue("name", "?name=joe") returns "joe"
 * @param {string} param - Name of parameter
 * @param {string} query - Optional, query to examine; if not included, then take current URL query
 * @return {string} value of parameter
 */
export const getUrlParameterValue = (param, query = window.location.search) => {
  const newParam = param.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${newParam}=([^&#]*)`);
  const results = regex.exec(query);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

/**
 * Logs in a user using an encoded token generated via the GQL API.
 * @param {string} token - Encoded token containing user information
 */
export const loginWithToken = token => {
  return fetch("/login_with_token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
  });
};

/**
 * Logs out the current user
 */
export const logout = () => {
  return fetch("/logout", {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  });
};

/**
 * Loads Uppy JS + CSS by appending script and rel tags to a specified container, then throw the callback
 * The callback is usually a set state to rerender.
 * @param {string} containerId - ID of element to append Uppy tags to
 */
export const loadUppy = containerId => {
  return new Promise((resolve, reject) => {
    const container = document.getElementById(containerId);
    const uppyJs = document.createElement("script");
    const uppyCss = document.createElement("link");

    uppyJs.type = "text/javascript";
    uppyJs.src =
      "https://cdnjs.cloudflare.com/ajax/libs/uppy/1.31.1/uppy.min.js";

    uppyCss.type = "text/css";
    uppyCss.rel = "stylesheet";
    uppyCss.href =
      "https://cdnjs.cloudflare.com/ajax/libs/uppy/1.31.1/uppy.min.css";

    uppyJs.addEventListener("load", () => {
      resolve();
    });
    uppyJs.addEventListener("error", e => {
      reject(e);
    });

    container.appendChild(uppyJs);
    container.appendChild(uppyCss);
  });
};

/**
 * Emits a project update event to our HubSpot proxy server
 * @param {number} projectId - ID of the updated project
 * @param {string} eventName - Name of the event to trigger
 * @param {object} metadata - Additional information to send up with the event
 */
export const hubspotNotifyProjectUpdate = ({
  projectId,
  eventName,
  metadata = {},
}) => {
  const metadataJson = JSON.stringify(metadata);

  return fetch("/api/v1/hubspot/emit_project_update_event", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      projectId,
      eventName,
      metadata: metadataJson,
    }),
  });
};

/**
 * To be used with final form fields, this will show an error if
 * the form has been touched and there is an inline validation error or
 * the form has been submitted, has not been updated since submit and the
 * field has a submit error.
 * @param {object} field - The field instance generated by useField.
 */
export const touchedOrSubmittedError = field => {
  return (
    (!field.meta.dirtySinceLastSubmit && field.meta.submitError) ||
    (field.meta.touched && field.meta.error)
  );
};

/**
 * Used if we want to attach a hubspot scheduler to an element, similar to an iframe.
 * @param {string} containerId - ID attached to container element
 * @param {function} meetingBookSuccessCb - callback that runs when meeting is successfully booked
 */
export const attachMeetingScriptToDiv = (containerId, meetingBookSuccessCb) => {
  const container = document.querySelector(`#${containerId}`);
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src =
    "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
  container.appendChild(script);

  window.addEventListener("message", event => {
    if (event.origin !== "https://meetings.hubspot.com") return false;
    if (event.data.meetingBookSucceeded && meetingBookSuccessCb) {
      meetingBookSuccessCb();
    }
    return false;
  });
};

/**
 * Generates the percentage increase between two values
 * @param {Number} originalNum - original value to compare
 * @param {Number} newNum - new value to compare
 * @param {Object} options - extra options, including the number of decimal places returned,
 * and the ceiling/floor of the returned percentage
 */
export const calculatePercentageIncrease = (
  originalNum,
  newNum,
  options = { numDecimalPlaces: 0, ceiling: null, floor: null }
) => {
  if (originalNum === 0) {
    if (newNum === 0) {
      return 0;
    }
    return 100;
  }
  if (newNum === 0) {
    return -100;
  }
  const increase = newNum - originalNum;
  let percentage = (increase / originalNum) * 100;

  if (options.ceiling !== null) {
    if (percentage > options.ceiling) {
      percentage = options.ceiling;
    }
  }

  if (options.floor !== null) {
    if (percentage < options.floor) {
      percentage = options.floor;
    }
  }

  return options.numDecimalPlaces > 0
    ? percentage.toFixed(options.numDecimalPlaces)
    : percentage;
};

/**
 * Generates the percentage difference between two values
 * @param {Number} originalNum - original value to compare
 * @param {Number} newNum - new value to compare
 * @param {Object} options - extra options, including the number of decimal places returned,
 * and the ceiling/floor of the returned percentage
 */
export const calculatePercentageDiff = (
  originalNum,
  newNum,
  options = { numDecimalPlaces: 0, ceiling: null, floor: null }
) => {
  if (originalNum === 0) {
    if (newNum === 0) {
      return 0;
    }
    return 100;
  }
  if (newNum === 0) {
    return -100;
  }
  let percentage = (originalNum / newNum - 1) * 100;

  if (options.ceiling !== null) {
    if (percentage > options.ceiling) {
      percentage = options.ceiling;
    }
  }

  if (options.floor !== null) {
    if (percentage < options.floor) {
      percentage = options.floor;
    }
  }

  return options.numDecimalPlaces > 0
    ? percentage.toFixed(options.numDecimalPlaces)
    : percentage;
};

/**
 * Calculates the difference between two dates. Accounts for DST, as it uses UTC.
 * @param {Date} date1 - first date to compare
 * @param {Date} date2 - second date to compare
 * @param {String} measurement - measurement of time to return in;
 * either ms, seconds, minutes, hours or days. Default is in milliseconds
 */
export const calculateDateDiff = (date1, date2, measurement = "ms") => {
  let millisecondConversion = 1;
  switch (measurement) {
    case "seconds":
      millisecondConversion = 1000;
      break;
    case "minutes":
      millisecondConversion = 1000 * 60;
      break;
    case "hours":
      millisecondConversion = 1000 * 60 * 60;
      break;
    case "days":
      millisecondConversion = 1000 * 60 * 60 * 24;
      break;
    default:
      break;
  }

  const utc1 = Date.UTC(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate(),
    date1.getHours(),
    date1.getMinutes(),
    date1.getSeconds(),
    date1.getMilliseconds()
  );
  const utc2 = Date.UTC(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate(),
    date2.getHours(),
    date2.getMinutes(),
    date2.getSeconds(),
    date2.getMilliseconds()
  );

  return Math.floor((utc2 - utc1) / millisecondConversion);
};

/**
 * Calculates the current width of the browser window using React's useLayoutEffect hook.
 */
export const getWindowWidth = () => {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize(window.innerWidth);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

/**
 * Updates property values for a specific HubSpot deal
 * @param {number} projectId - ID of the updated project
 * @param {object} properties - Properties to update on the deal
 */
export const updateHubspotDeal = ({ projectId, properties = {} }) => {
  return fetch("/api/v1/hubspot/update_deal", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      project_id: projectId,
      properties,
    }),
  });
};

/**
 * Calculates the difference between the time given and the current time.
 * Returns a string. Accounts for DST, as it uses UTC.
 * @param {Date} timeToCompare - date to compare to present
 */
export const calculateTimeAgo = timeToCompare => {
  const minuteDiff = calculateDateDiff(
    new Date(timeToCompare),
    new Date(),
    "minutes"
  );
  const hrDiff = calculateDateDiff(
    new Date(timeToCompare),
    new Date(),
    "hours"
  );
  const dayDiff = calculateDateDiff(
    new Date(timeToCompare),
    new Date(),
    "days"
  );

  const gcResponseToReadableStr = formatTimeToTimezone(timeToCompare);

  let timeAgoStr;
  if (dayDiff >= 1) {
    timeAgoStr = `${dayDiff} days ago at ${gcResponseToReadableStr}`;
  } else if (hrDiff >= 1) {
    timeAgoStr = `${hrDiff} hours ago at ${gcResponseToReadableStr}`;
  } else if (minuteDiff >= 1) {
    timeAgoStr = `${minuteDiff} minutes ago at ${gcResponseToReadableStr}`;
  } else {
    timeAgoStr = "just now";
  }

  return timeAgoStr;
};

/**
 * Adds an event listener for hitting the enter key. When added to a page,
 * hitting enter will trigger the callback function given
 */
export const triggerOnEnter = callback => {
  document.addEventListener("keyup", e => {
    if (e.key === "Enter") {
      e.preventDefault();
      callback();
    }
  });

  return () => {
    document.removeEventListener("keyup", e => {
      if (e.key === "Enter") {
        e.preventDefault();
        callback();
      }
    });
  };
};

/**
 * After a "photoUpload" event is emitted, this function takes the list of processing
 * photos provided by the event and pings the server continuously to check if
 * the processing has completed. When complete, a callback function is called.
 */
export const fetchUploadedPhotos = (evt, projectId, callback) => {
  const { detail } = evt;
  const { photoList, projectId: evtProjectId } = detail;

  if (evtProjectId === projectId) {
    const queryStr = photoList
      .map(photo => {
        return `transloadit_ids%5B%5D=${photo.file_id}`;
      })
      .join("&");

    let attempts = 0;
    let previousProcessedLength = -1;
    const interval = setInterval(() => {
      fetch(`/api/v1/projects/${projectId}/processed_images?${queryStr}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        dataType: "json",
      })
        .then(resp => resp.json())
        .then(respJson => {
          const { processed } = respJson;

          if (
            attempts > 20 ||
            (!!processed && processed.length === photoList.length)
          ) {
            clearInterval(interval);
            callback(respJson);
          } else if (
            previousProcessedLength > 0 &&
            (!!processed && previousProcessedLength < processed.length)
          ) {
            // Resets num of attempts if 1 or more photos in a group have completed,
            // but some photos are still incomplete
            attempts = 0;
          } else {
            attempts += 1;
          }
          previousProcessedLength = processed ? processed.length : -1;
        });
    }, 1000);
  }
};

export const daysHoursMinutesStr = minutes => {
  if (minutes < 60) {
    const minsRounded = Math.ceil(minutes);
    if (minsRounded === 1) {
      return "1 minute";
    }
    return `${minsRounded} minutes`;
  }
  if (minutes / 60 < 24) {
    const hoursRounded = Math.ceil(minutes / 60);
    if (hoursRounded === 1) {
      return "1 hour";
    }
    return `${hoursRounded} hours`;
  }
  const daysRounded = Math.floor(minutes / 1440);
  if (daysRounded === 1) {
    return "1 day";
  }
  return `${daysRounded} days`;
};

const checkValue = (str, max) => {
  let newStr = str;
  if (newStr.charAt(0) !== "0" || newStr === "00") {
    let num = parseInt(newStr, 10);
    if (Number.isNaN(num) || num <= 0 || num > max) num = 1;
    newStr =
      num > parseInt(max.toString().charAt(0), 10) &&
      num.toString().length === 1
        ? `0${num}`
        : num.toString();
  }
  return newStr;
};

export const dateOfBirthFormatter = val => {
  let newVal = val;
  if (/\D\/$/.test(newVal)) {
    newVal = newVal.substr(0, newVal.length - 1);
  }
  const values = newVal.split("/").map(v => {
    return v.replace(/\D/g, "");
  });
  if (values[0]) {
    values[0] = checkValue(values[0], 12);
  }
  if (values[1]) {
    values[1] = checkValue(values[1], 31);
  }
  const output = values.map((v, i) => {
    return v.length === 2 && i < 2 ? `${v}/` : v;
  });
  return output.join("").substr(0, 10);
};

export const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
