import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import UpdatePhoneNumberModal from "../../../components/modals/update_phone_number_modal";
import RenRejectMatchModal from "./ren_reject_match_modal";
import RenDeclineGCModal from "./ren_decline_gc_modal";
import SchedulingModal from "./scheduling_modal/index";
import ScheduleCallModal from "./schedule_call_modal";
import RevealGCNameModal from "./reveal_gc_name_modal";
import MyContractorsAcceptModal from "./my_contractors_accept";
import { existingIntrosCount } from "../shared";
import BidLevelingModal from "./bid_leveling_modal";

export const MAX_INTRO_COUNT = 10;

const MATCHING_DATA = gql`
  query getMatchingData($id: ID!) {
    project(id: $id) {
      id
      images(categories: [before]) {
        id
        url {
          small
        }
      }
      intros(
        states: [
          awarded
          introduced
          ren_partial_awarded
          estimated
          site_visited
          matched
          rejected
        ]
      ) {
        id
        interest {
          ren {
            isInterested
            responseAt
          }
        }
        state
        company {
          id
          name
          nameSignature
          averageRating
          currentCompanyLogoUrl
          team {
            owner {
              firstName
            }
          }
          reviews(status: ["received"]) {
            id
          }
        }
      }
      location {
        address
      }
      additionalInfo
      scheduledIntroCall
      user {
        id
        phone
      }
    }
  }
`;

export const UpdateMatchingModalState = createContext(() => {});

const RenMatchingModalProvider = ({ data, children, location }) => {
  const [modalState, updateModalState] = useState({
    name: null,
    options: null,
  });

  const { introId } = modalState.options || {};

  const { project } = data;
  const { id, intros } = project;

  const currentIntro = intros.find(intro => intro.id === introId);

  const introCount = existingIntrosCount(intros);

  return (
    <UpdateMatchingModalState.Provider value={updateModalState}>
      {children}
      {modalState.name === "phone" && (
        <UpdatePhoneNumberModal
          onClose={() => updateModalState({})}
          projectId={id}
          {...modalState.options}
        />
      )}
      {modalState.name === "reject" && (
        <RenRejectMatchModal
          onClose={() => updateModalState({})}
          intro={currentIntro}
          {...modalState.options}
        />
      )}
      {modalState.name === "renDeclineGC" && (
        <RenDeclineGCModal
          onClose={() => updateModalState({})}
          intro={currentIntro}
          {...modalState.options}
        />
      )}
      {modalState.name === "scheduleIntro" && (
        <SchedulingModal
          onClose={() => updateModalState({})}
          intro={currentIntro}
          data={data}
          location={location}
          updateMatchingModalState={UpdateMatchingModalState}
          {...modalState.options}
        />
      )}
      {modalState.name === "scheduleAdminCall" && (
        <ScheduleCallModal
          onClose={() => updateModalState({})}
          projectId={id}
          {...modalState.options}
        />
      )}
      {modalState.name === "revealGCName" && (
        <RevealGCNameModal
          onClose={() => updateModalState({})}
          intro={currentIntro}
          introCount={introCount}
          updateMatchingModalState={UpdateMatchingModalState}
          maxIntroCount={MAX_INTRO_COUNT}
          {...modalState.options}
        />
      )}
      {modalState.name === "myContractorsAccept" && (
        <MyContractorsAcceptModal
          onClose={() => updateModalState({})}
          data={data}
          intro={currentIntro}
          updateMatchingModalState={UpdateMatchingModalState}
          {...modalState.options}
        />
      )}
      {modalState.name === "scheduleBidLevelingCall" && (
        <BidLevelingModal
          onClose={() => updateModalState({})}
          stage="site_visit"
        />
      )}
    </UpdateMatchingModalState.Provider>
  );
};

RenMatchingModalProvider.propTypes = {
  data: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  location: PropTypes.string,
};

export default props => {
  // eslint-disable-next-line react/prop-types
  const { projectId } = props;
  const { data, loading } = useQuery(MATCHING_DATA, {
    variables: { id: projectId },
  });

  return loading ? null : <RenMatchingModalProvider data={data} {...props} />;
};
