import React, { useEffect, createRef, useContext } from "react";
import { Col, Row, TabMenu, ArrowLink, styles } from "@sweeten/oreo";
import { StyleSheet } from "aphrodite";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import CompanyHeader from "./header";
import Overview from "./overview";
import AdminButtons from "./admin_buttons";
import "./index.scss";
import { getFormAuthToken, trackMixpanel, goToPage } from "../utils";
import RenMatchingModalProvider from "../ren/my_contractors/modals/matching_modal_provider";
import Carousel from "./carousel";
import { AppDispatch } from "../components/app_provider";

const COMPANY_INFO = gql`
  query getCompanyProfileData($id: ID!) {
    company(id: $id) {
      id
      name
      isSweetenAllStar
      matchWithCurrentUser {
        id
        projectId
        canShowInterest
      }
      serviceAreas
      contact {
        location {
          city
          longState
        }
      }
    }
    currentUser {
      id
      isRenovator
    }
  }
`;

const ss = StyleSheet.create({
  tabMenu: {
    margin: "24px 0px 0px",
    width: "100%",
    border: "none",
  },
  tabMenuWrapper: {
    border: "none",
  },
  myMatchesArrowLink: {
    marginTop: 24,
  },
  col: {
    marginTop: 0,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        width: "100%",
        margin: 0,
      },
    }),
  },
});

const Overlay = ({ companyId }) => {
  const dispatch = useContext(AppDispatch);

  useEffect(() => {
    dispatch({
      type: "overlay:show",
      payload: {
        variant: "portfolio",
        companyId,
      },
    });
  }, []);

  return null;
};

const CompanyShowComp = props => {
  const {
    adminProps,
    data,
    hubspotCompanyUrl,
    recentlyMatchedProjectIntrosCount,
    loggedOutCtaVariant,
  } = props;
  const { company, currentUser } = data || {};
  const {
    id: companyId,
    name,
    isSweetenAllStar,
    currentCompanyLogoUrl,
    matchWithCurrentUser,
  } = company || {};
  const { canShowInterest, projectId } = matchWithCurrentUser || {};
  const { isRenovator } = currentUser || {};
  const showLoggedOutCTA = loggedOutCtaVariant === "show";

  const interestedLoggedOutRef = createRef();

  const headerProps = {
    id: companyId,
    name,
    isSweetenAllStar,
    currentCompanyLogoUrl,
    currentUser,
    canShowInterest,
    matchWithCurrentUser,
    recentlyMatchedProjectIntrosCount,
    isRenovator,
    company,
  };

  useEffect(() => {
    const queryString = window.location.search;
    if (queryString.includes("utm=GC_EI_SMS")) {
      trackMixpanel("GC EI SMS Click");
    }
  }, []);

  return (
    <>
      <Row style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 1200 }}>
        <Col width={12} style={{ marginTop: 0 }}>
          {isRenovator && canShowInterest && (
            <ArrowLink
              aphStyle={ss.myMatchesArrowLink}
              onClick={() => goToPage("/my-contractors")}
              variant="back"
            >
              Back
            </ArrowLink>
          )}
        </Col>
      </Row>
      {projectId ? (
        <RenMatchingModalProvider projectId={projectId} location="gc_profile">
          <CompanyHeader ref={interestedLoggedOutRef} {...headerProps} />
        </RenMatchingModalProvider>
      ) : (
        <CompanyHeader
          {...headerProps}
          ref={interestedLoggedOutRef}
          showLoggedOutCTA={showLoggedOutCTA}
        />
      )}
      <Row
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 1200,
          marginBottom: 120,
        }}
        verticalGap={32}
      >
        <Col width={12} aphStyle={ss.col}>
          <Carousel companyId={companyId} />
          <div>
            <TabMenu
              defaultTabId={
                window.location.hash === "#portfolio" ? "portfolio" : "overview"
              }
              aphStyle={ss.tabMenu}
              wrapperAphStyle={ss.tabMenuWrapper}
              isListeningForHashChange
              isSticky={false}
            >
              <TabMenu.Tab label="Overview" id="overview" path="#overview">
                {adminProps && (
                  <AdminButtons
                    authToken={getFormAuthToken()}
                    editCompanyPath={adminProps.editCompanyPath}
                    impersonatePath={adminProps.impersonatePath}
                    hubspotCompanyUrl={hubspotCompanyUrl}
                  />
                )}
                {projectId ? (
                  <RenMatchingModalProvider
                    projectId={projectId}
                    location="gc_profile"
                  >
                    <Overview {...props} ref={interestedLoggedOutRef} />
                  </RenMatchingModalProvider>
                ) : (
                  <Overview
                    {...props}
                    showLoggedOutCTA={showLoggedOutCTA}
                    ref={interestedLoggedOutRef}
                  />
                )}
              </TabMenu.Tab>
              <TabMenu.Tab label="Portfolio" id="portfolio" path="#portfolio">
                <Overlay companyId={companyId} />
              </TabMenu.Tab>
            </TabMenu>
          </div>
        </Col>
      </Row>
    </>
  );
};

const CompanyShow = props => {
  const { companyId } = props;
  const start = new Date();
  start.setDate(start.getDate() - 30);

  return (
    <Query
      query={COMPANY_INFO}
      variables={{
        id: companyId,
      }}
    >
      {({ data, loading }) => {
        return loading ? null : <CompanyShowComp data={data} {...props} />;
      }}
    </Query>
  );
};

CompanyShowComp.propTypes = {
  adminProps: PropTypes.object,
  data: PropTypes.object,
  hubspotCompanyUrl: PropTypes.string,
  recentlyMatchedProjectIntrosCount: PropTypes.number,
  loggedOutCtaVariant: PropTypes.string,
};

CompanyShow.propTypes = {
  companyId: PropTypes.number,
};

export default CompanyShow;
