import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite/no-important";
import { Header, Card, Body, styles } from "@sweeten/oreo";
import { formatToCurrency } from "../utils";
import InsuranceSvgPath from "../../assets/images/insurance.svg";

const ss = StyleSheet.create({
  insurerCard: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 40,
    borderRadius: 8,
    boxSizing: "border-box",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        padding: 16,
        marginBottom: 16,
      },
    }),
  },
  marginBottom: {
    marginBottom: 16,
  },
  caption: {
    fontSize: 14,
    color: styles.colors.grey40,
  },
  container: {
    marginBottom: 64,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginBottom: 40,
      },
    }),
  },
  flexCentered: {
    ...styles.center("vertical"),
  },
});

const Insurer = ({ insurance }) => (
  <Card aphStyle={ss.insurerCard}>
    <div className={css(ss.flexCentered)}>
      <img
        alt="insurance"
        src={InsuranceSvgPath}
        width={36}
        height={36}
        style={{ marginRight: 16 }}
      />
    </div>
    <div>
      <div className={css(ss.marginBottom)}>
        <Body tag="div" variant="bold">
          Type
        </Body>
        <Body tag="div" variant="caption" aphStyle={ss.caption}>
          {insurance.type} Insurance
        </Body>
      </div>
      <div className={css(ss.marginBottom)}>
        <Body tag="div" variant="bold">
          Insured up to
        </Body>
        <Body tag="div" variant="caption" aphStyle={ss.caption}>
          {formatToCurrency(insurance.amount)}
        </Body>
      </div>
    </div>
  </Card>
);

Insurer.propTypes = {
  insurance: PropTypes.object,
};

const Insurance = ({ umbrellaInsuranceAmount, liabilityInsuranceAmount }) => {
  const insuranceArr = [];
  if (umbrellaInsuranceAmount) {
    insuranceArr.push({
      type: "Umbrella",
      amount: umbrellaInsuranceAmount,
    });
  }
  if (liabilityInsuranceAmount) {
    insuranceArr.push({
      type: "General Liability",
      amount: liabilityInsuranceAmount,
    });
  }
  return (
    <div className={css(ss.container)}>
      <Header tag="h5" style={{ marginBottom: 24 }}>
        Insurance ({insuranceArr.length})
      </Header>
      {insuranceArr.map(insurance => (
        <Insurer insurance={insurance} />
      ))}
    </div>
  );
};

Insurance.propTypes = {
  umbrellaInsuranceAmount: PropTypes.number,
  liabilityInsuranceAmount: PropTypes.number,
};

export default Insurance;
