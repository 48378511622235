import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Header, RadioGroup, FormLabel } from "@sweeten/oreo";
import { useField, useForm } from "react-final-form-hooks";
import { required } from "../../../validators";
import StepInfoValue from "../step_info_value";
import {
  trackClickEntireHome,
  trackClickOneOrMoreRooms,
  trackAddRoomScopeError,
} from "../../project_rooms/tracking_events";
import { trackViewStepPrimeForm } from "../tracking_events";

const RoomScope = ({ isActive, setHandleSubmit, subject: project, update }) => {
  const { roomScope } = project;
  const { form, handleSubmit } = useForm({
    initialValues: { roomScope },
    onSubmit: update,
  });

  const roomScopeField = useField("roomScope", form, required);
  const roomScopeError =
    roomScopeField.meta.touched && roomScopeField.meta.error;

  const onChange = val => {
    roomScopeField.input.onChange(val);
    handleSubmit();
    if (val === "Entire Home") {
      trackClickEntireHome();
    } else {
      trackClickOneOrMoreRooms();
    }
  };

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    if (roomScopeError) {
      trackAddRoomScopeError();
    }
  }, [roomScopeError]);

  useEffect(() => {
    trackViewStepPrimeForm("Room Scope");
  }, []);

  return (
    <>
      <Header tag="h5">What would you like to renovate?</Header>
      <StepInfoValue
        stepValue="Sweeten is here to help you find, hire,
        work with and pay the best general contractors!"
      />
      <RadioGroup
        error={roomScopeError}
        {...roomScopeField.input}
        onChange={onChange}
        numCols={2}
        style={{ marginBottom: 12 }}
      >
        <RadioGroup.Radio data-test="entire_home" value="Entire Home" />
        <FormLabel style={{ marginBottom: 0 }}>Entire Home</FormLabel>
        <RadioGroup.Radio
          data-test="part_of_your_home"
          value="Part of your Home"
        />
        <FormLabel style={{ marginBottom: 0 }}>Part of your Home</FormLabel>
      </RadioGroup>
    </>
  );
};

RoomScope.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default RoomScope;
