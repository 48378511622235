import React, { useReducer, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Modal } from "@sweeten/oreo";
import { merge, cloneDeep, isEqual, get } from "lodash";
import PhotoOverlay from "./photo_overlay";

const initialState = {
  alert: {
    isVisible: false,
    shouldStayVisible: false,
    text: null,
    variant: null,
  },
  modal: {
    isVisible: false,
    componentName: null,
    componentProps: null,
  },
  overlay: {
    isVisible: false,
    variant: null,
    companyId: null,
    project: null,
    lightboxPrev: null,
    lightboxNext: null,
  },
};

export const AppState = createContext(initialState);
export const AppDispatch = createContext(() => {});

/**
 * This function is called by the reducer to show an alert.
 * @param {Object} state The current state of the AppProvider
 * @param {Object} payload The information stored on the payload
 * Payload format is { variant: "success" || "error", text: "alert text"}
 * Passing in payload/payload arguments is optional, with the default being an error alert.
 */
const showAlert = (state, payload) => {
  const defaultText = isEqual(payload.variant, "success")
    ? "Your request completed successfully!"
    : "There was an error processing your request. Please try again.";

  const obj = cloneDeep(state);
  return merge(obj, {
    alert: {
      isVisible: true,
      variant: get(payload, "variant", "error"),
      text: get(payload, "text", defaultText),
      shouldStayVisible: get(payload, "shouldStayVisible", false),
    },
  });
};

/**
 * This function is called by the reducer to hide an alert.
 * @param {Object} state The current state of the AppProvider
 */
const hideAlert = state => {
  const obj = cloneDeep(state);
  return merge(obj, {
    alert: {
      isVisible: false,
    },
  });
};

/**
 * This function is called by the reducer to show a modal.
 * @param {Object} state The current state of the AppProvider
 * @param {Object} payload The information stored on the payload
 * Payload format is { componentName: NameOfComponent, componentProps: { ...props} }
 * Passing in payload/payload arguments is required,
 * as you'd want to show a specific kind of modal ideally.
 */
const showModal = (state, payload) => {
  const obj = cloneDeep(state);
  return merge(obj, {
    modal: {
      isVisible: true,
      componentName: get(payload, "componentName", Modal),
      componentProps: get(payload, "componentProps", {}),
    },
  });
};

/**
 * This function is called by the reducer to hide a modal.
 * @param {Object} state The current state of the AppProvider
 */
const hideModal = state => {
  const obj = cloneDeep(state);
  return merge(obj, {
    modal: {
      isVisible: false,
    },
  });
};

/**
 * Function for overlay scroll event listener.
 * Emulates the prevention of scrolling in the background (vs in the overlay)
 */
const noScroll = () => {
  const bodyEle = document.body;
  bodyEle.style.overflowY = "hidden";
  window.scrollTo(0, 0);
};

/**
 * This function is called by the reducer to show an overlay.
 * @param {Object} state The current state of the AppProvider
 * @param {Object} payload The information stored on the payload
 * Payload format example is
 * { variant: "wall", companyId: 1234, project: {...} }
 * Project is optional.
 */
const showOverlay = (state, payload) => {
  const obj = cloneDeep(state);
  window.addEventListener("scroll", noScroll);
  return merge(obj, {
    overlay: {
      isVisible: true,
      variant: get(payload, "variant", "wall"),
      companyId: get(payload, "companyId"),
      project: get(payload, "project"),
      lightboxPrev: get(payload, "lightboxPrev"),
      lightboxNext: get(payload, "lightboxNext"),
    },
  });
};

/**
 * This function is called by the reducer to hide an overlay.
 * @param {Object} state The current state of the AppProvider
 */
const hideOverlay = state => {
  const bodyEle = document.body;
  window.removeEventListener("scroll", noScroll);
  bodyEle.style.overflowY = "";
  const obj = cloneDeep(state);
  return merge(obj, {
    overlay: {
      isVisible: false,
    },
  });
};

/**
 * Reducer that will handle state management
 * @param {Object} state The current state of the AppProvider
 * @param {Object} action The information being sent by the dispatch
 * Action format is { type: "action_type", payload: {...payloadInfo}}
 */
const appReducer = (state, action) => {
  switch (action.type) {
    case "alert:show": {
      return showAlert(
        state,
        action.payload
          ? {
              text: action.payload.text,
              variant: action.payload.variant,
              shouldStayVisible: action.payload.shouldStayVisible,
            }
          : {}
      );
    }
    case "alert:hide":
      return hideAlert(state);
    case "modal:show": {
      return showModal(
        state,
        action.payload
          ? {
              componentName: action.payload.componentName,
              componentProps: action.payload.componentProps,
            }
          : {}
      );
    }
    case "modal:hide":
      return hideModal(state);
    case "overlay:show": {
      return showOverlay(
        state,
        action.payload
          ? {
              variant: action.payload.variant,
              companyId: action.payload.companyId,
              project: action.payload.project,
              lightboxPrev: action.payload.lightboxPrev,
              lightboxNext: action.payload.lightboxNext,
            }
          : {}
      );
    }
    case "overlay:hide":
      return hideOverlay(state);
    default:
      return state;
  }
};

/**
 * Main component which will handle state management
 * @param {Element} children Component that is being wrapped by the AppProvider
 */
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    if (!state.alert.shouldStayVisible) {
      const timer = setTimeout(() => dispatch({ type: "alert:hide" }), 5000);
      return () => {
        clearTimeout(timer);
      };
    }
    return undefined;
  }, [state.alert.isVisible]);

  const ModalComponent = state.modal.componentName;

  return (
    <AppState.Provider value={state}>
      <AppDispatch.Provider value={dispatch}>
        {state.alert.isVisible && (
          <Alert
            variant={state.alert.variant}
            onClose={() =>
              dispatch({
                type: "alert:hide",
              })
            }
          >
            {state.alert.text}
          </Alert>
        )}
        {state.modal.isVisible && (
          <ModalComponent
            {...state.modal.componentProps}
            onClose={() => dispatch({ type: "modal:hide" })}
          />
        )}
        {state.overlay.isVisible && (
          <PhotoOverlay
            variant={state.overlay.variant}
            onClose={() => {
              dispatch({
                type: "overlay:hide",
              });
              if (window.location.hash === "#portfolio") {
                window.location.hash = "#overview";
              }
            }}
            companyId={state.overlay.companyId}
            project={state.overlay.project}
            lightboxPrev={state.overlay.lightboxPrev}
            lightboxNext={state.overlay.lightboxNext}
          />
        )}
        {children}
      </AppDispatch.Provider>
    </AppState.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.element,
};

export default AppProvider;
