import { Body, SingleSelectDropdown } from "@sweeten/oreo";
import PropTypes from "prop-types";
import React from "react";

const DistanceFeedback = ({
  maxTravelDistanceInMilesField,
  maxDistanceAlreadySet,
}) => {
  return (
    <>
      {maxDistanceAlreadySet ? (
        <Body>
          Do you wish to update the travelling distance? We use the distance
          from your office address to job site
        </Body>
      ) : (
        <Body>
          How far are you willing to travel? We use the distance from your
          office address to job site.
        </Body>
      )}
      <SingleSelectDropdown
        {...maxTravelDistanceInMilesField.input}
        items={[1, 2, 3, 5, 10, 20, 25, 30, 40, 50, 100, 150].map(num => {
          return {
            label: `${num} miles`,
            value: num,
          };
        })}
        placeholder="Max distance"
      />
    </>
  );
};

DistanceFeedback.propTypes = {
  maxTravelDistanceInMilesField: PropTypes.object,
  maxDistanceAlreadySet: PropTypes.bool,
};

export default DistanceFeedback;
