/* eslint-disable consistent-return */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Body, Header, Modal, styles, TextLink } from "@sweeten/oreo";
import { StyleSheet } from "aphrodite";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from "react-final-form-hooks";
import Cookies from "js-cookie";
import { trackMixpanel /* , hubspotNotifyProjectUpdate */ } from "../../utils";
import PhoneFieldGroup from "../account_forms/phone_field_group";
import PhoneAddressInfo from "../account_forms/phone_address_info";

// == Mutations ====================================

export const USER_UPDATE_PHONE = gql`
  mutation userUpdateAccount($attributes: UserUpdateInput!) {
    userUpdate(attributes: $attributes) {
      user {
        id
        phone
        currentProject {
          id
        }
      }
    }
  }
`;

// == Components =====================================

const ss = StyleSheet.create({
  input: {
    width: 255,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        width: "100%",
      },
    }),
  },
  modalContainer: {
    width: 572,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        height: "auto",
      },
    }),
  },
  cancel: {
    marginRight: 16,
  },
  body: {
    marginBottom: 16,
    fontSize: 16,
    lineHeight: "26px",
    color: styles.colors.grey40,
  },
});

const generateCopy = isExpert => {
  const header = isExpert
    ? "Please confirm your phone number"
    : "Please enter your phone number";
  const body = isExpert
    ? `Before we start, please confirm your phone number,
      so your clients and Account Manager can reach you.`
    : `We’ll only share your contact information with
    contractors you select to discuss your project.`;
  const cta = isExpert ? "Confirm" : "Save";

  return { header, body, cta };
};

const setAsCookie = phoneNumber => {
  Cookies.set("sign-up-phone-number", phoneNumber);
};

const UpdatePhoneNumberModal = ({
  onClose,
  onSuccessCb,
  isExpert,
  initialValues,
  // projectId,
  isMandatory,
  showPrivacyDisclaimer,
  shouldSetAsCookie,
}) => {
  const { header, body, cta } = generateCopy(isExpert);
  const [updatePhoneNumber, { loading }] = useMutation(USER_UPDATE_PHONE, {
    onCompleted: () => {
      // trackMixpanel("Added phone number");
      // if (!isExpert) {
      //   hubspotNotifyProjectUpdate({
      //     projectId,
      //     eventName: "match-phone-provided-REN",
      //     metadata: { category: "email" },
      //   });
      // }
      if (onSuccessCb) {
        onSuccessCb();
      }
      onClose();
    },
  });
  const { form, handleSubmit } = useForm({
    initialValues,
    onSubmit: values => {
      if (shouldSetAsCookie) {
        setAsCookie(form.getFieldState("phone").value);
        onSuccessCb();
      } else {
        updatePhoneNumber({
          variables: {
            attributes: values,
          },
        });
      }
    },
  });

  useEffect(() => {
    trackMixpanel("Viewed Phone Number Modal");
  }, []);

  return (
    <Modal aphStyle={ss.modalContainer} onClose={!isMandatory && onClose}>
      <Modal.Header>
        <Header tag="h6">{header}</Header>
      </Modal.Header>
      <Modal.Body>
        <Body aphStyle={ss.body}>{body}</Body>
        <PhoneFieldGroup
          form={form}
          hideSmsSection={shouldSetAsCookie}
          style={{ marginTop: 16 }}
        />
        {showPrivacyDisclaimer && (
          <div style={{ marginTop: 24 }}>
            <PhoneAddressInfo
              bodyText={`Our privacy promise: We only share your contact information
                with contractors you select.`}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {onClose && !isMandatory && (
          <TextLink onClick={onClose} variant="black" aphStyle={ss.cancel}>
            Cancel
          </TextLink>
        )}
        <Button onClick={handleSubmit} loading={loading}>
          {cta}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UpdatePhoneNumberModal.propTypes = {
  initialValues: PropTypes.object,
  isExpert: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccessCb: PropTypes.func,
  // projectId: PropTypes.string,
  isMandatory: PropTypes.bool,
  showPrivacyDisclaimer: PropTypes.bool,
  shouldSetAsCookie: PropTypes.bool,
};

export default UpdatePhoneNumberModal;
