import {
  trackMixpanel,
  trackMixpanelWithCallback,
  trackMixpanelUserSetOnce,
} from "../../utils";

export const trackClickNextPrimeForm = () =>
  window.dataLayer.push({ event: "clicked-next-prime-form" });

export const trackClickBackPrimeForm = () =>
  window.dataLayer.push({ event: "clicked-back-prime-form" });

export const trackViewStepPrimeForm = step =>
  trackMixpanel(`Viewed ${step} on Project Posting Form`);

export const trackViewNeedsInfo = () =>
  window.dataLayer.push({ event: "viewed-needs-info" });

export const trackViewError = (field, fieldValue, error) =>
  trackMixpanel("Viewed Form Field Error", {
    "Form Field": field || "user left blank",
    "Form Field Value": fieldValue,
    "Error Text": error,
  });

export const trackSubmittedIntention = (intention, update) => {
  trackMixpanelUserSetOnce({
    "Initial Intention": intention,
  });

  trackMixpanelWithCallback(
    "Submitted Intention",
    {
      Intention: intention,
    },
    update({ intention })
  );
};
