import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { Modal, Header, TextLink, Button, Body, styles } from "@sweeten/oreo";
import Cookies from "js-cookie";
import Telephone from "../../../../assets/images/telephone.png";
import {
  goToPage,
  getWindowWidth,
  trackMixpanel,
  trackMixpanelWithCallback,
} from "../../../utils";

const ss = StyleSheet.create({
  container: {
    display: "flex",
    backgroundColor: "#EFF0F3",
    borderRadius: 20,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        flexDirection: "column",
      },
    }),
  },
  leftContent: {
    height: "100%",
    padding: "70px 70px 35px 70px",
    width: 612,
    borderRadius: "20px 0px 0px 20px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "auto",
        height: "auto",
        padding: 20,
        borderRadius: "20px 20px 0px 0px",
      },
    }),
  },
  rightContent: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    fontWeight: 700,
    fontSize: 16,
    padding: "70px 26px 35px 48px",
    backgroundColor: styles.colors.white,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        padding: 20,
        borderRadius: "24px 24px 0px 0px",
      },
    }),
  },
  linkAndButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        alignItems: "center",
        flexDirection: "column-reverse",
        marginTop: 70,
      },
    }),
  },
  phoneImage: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        float: "right",
      },
    }),
  },
  closeStyle: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: styles.colors.white,
        borderRadius: "100%",
        height: 32,
        width: 32,
        top: 20,
        right: 20,
        padding: 0,
      },
    }),
  },
});

const BidLevelingModal = ({ onClose, stage }) => {
  const windowWidth = getWindowWidth();
  const isDesktop = windowWidth > styles.breakpoints.tabletStandard;

  useEffect(() => {
    trackMixpanel("Viewed bid leveling modal", { "Stage Viewed": stage });
  }, []);

  return (
    <Modal
      closeAphStyle={ss.closeStyle}
      style={{
        padding: 0,
        borderRadius: 20,
        width: isDesktop ? 840 : "100%",
      }}
      onClose={onClose}
    >
      <Modal.Body aphStyle={ss.container}>
        <div className={css(ss.leftContent)}>
          <Header
            tag="h3"
            style={{
              color: "#263154",
              fontWeight: 700,
              lineHeight: "40px",
              marginBottom: 40,
              paddingTop: isDesktop ? 0 : 26,
              fontSize: 28,
            }}
          >
            Take advantage of a free call to{" "}
            <Body
              tag="span"
              style={{
                background: "linear-gradient(135deg, #0DA2F5, #C6C6C4)",
                webkitBackgroundClip: "text",
                webkitTextFillColor: "transparent",
                fontSize: 28,
                fontWeight: 700,
                lineHeight: "40px",
              }}
            >
              compare estimates!
            </Body>
          </Header>
          <div className={css(ss.phoneImage)}>
            <img
              alt=""
              src={Telephone}
              width={isDesktop ? 170 : 120}
              height={isDesktop ? 170 : 120}
            />
          </div>
        </div>
        <div className={css(ss.rightContent)}>
          <div style={{ paddingRight: isDesktop ? 24 : 0 }}>
            <Body
              tag="div"
              style={{
                fontSize: 16,
                color: "#3E4968",
                marginBottom: 24,
                fontWeight: 500,
              }}
            >
              Let Sweeten help you compare your recommended contractors. We can
              discuss your estimates, share our real-world feedback about each
              of the contractors, and answer all your questions. We’ll help you
              make the best, most informed hiring decision.
            </Body>
          </div>
          <div className={css(ss.linkAndButton)}>
            <TextLink
              onClick={onClose}
              style={{
                fontWeight: 500,
                color: styles.colors.black,
                fontSize: 16,
              }}
            >
              Cancel
            </TextLink>
            <Button
              onClick={() => {
                Cookies.set("schedule-bid-leveling-call", true);
                trackMixpanelWithCallback(
                  "Clicked schedule bid leveling call from modal",
                  { clickedStage: stage },
                  () =>
                    goToPage(
                      // eslint-disable-next-line max-len
                      "https://meetings.hubspot.com/christina159/bid-leveling-call-platform-link-round-robin"
                    )
                );
              }}
              style={{
                fontSize: 16,
                fontWeight: 700,
                marginLeft: isDesktop ? 32 : 0,
                marginBottom: isDesktop ? 0 : 13,
                width: isDesktop ? "auto" : "100%",
              }}
            >
              Schedule meeting
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

BidLevelingModal.propTypes = {
  onClose: PropTypes.func,
  stage: PropTypes.string,
};

export default BidLevelingModal;
