import React from "react";
import PropTypes from "prop-types";
import { Stars, ModalWrapper } from "@sweeten/domo";
import { styles } from "@sweeten/oreo";
import { format } from "date-fns";
import { StyleSheet, css } from "aphrodite";
import Title from "./title";
import SweetenProjectLabel from "../sweeten_project_label";
import { formatUserName } from "../../utils";

const ss = StyleSheet.create({
  sweetenProject: { margin: "16px 0" },
  feedbackQuestion: { marginTop: 16 },
  gray: { color: styles.colors.grey40 },
});

const locationString = location => `${location.county}, ${location.state}`;

const PublicFeedback = ({ feedback }) => (
  <div className={css(ss.feedbackQuestion)}>
    <b>What did you think of them?</b>
    <div className={css(ss.gray)}>{feedback}</div>
  </div>
);

const PositiveFeedback = ({ feedback }) => (
  <div className={css(ss.feedbackQuestion)}>
    <b>What did they do especially well?</b>
    <div className={css(ss.gray)}>{feedback}</div>
  </div>
);

const ChallengesFeedback = ({ feedback }) => (
  <div className={css(ss.feedbackQuestion)}>
    <b>
      How did they handle unexpected challenges or surprises during the
      renovation?
    </b>
    <div className={css(ss.gray)}>{feedback}</div>
  </div>
);

PublicFeedback.propTypes = { feedback: PropTypes.string };
PositiveFeedback.propTypes = { feedback: PropTypes.string };
ChallengesFeedback.propTypes = { feedback: PropTypes.string };

const Questions = ({ feedback }) => (
  <>
    {feedback.public && <PublicFeedback feedback={feedback.public} />}
    {feedback.positive && <PositiveFeedback feedback={feedback.positive} />}
    {feedback.challenge && <ChallengesFeedback feedback={feedback.challenge} />}
  </>
);

Questions.propTypes = { feedback: PropTypes.object };

const AnswersModal = ({ review }) => {
  const { project, reviewer } = review;

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Title
          projectId={project && project.id}
          projectTitle={project && project.title}
          reviewerName={{
            firstName: reviewer.firstName,
            lastName: reviewer.lastName,
          }}
        />
      </div>
      {review.type === "testimonial" && (
        <div className={css(ss.sweetenProject)}>
          <SweetenProjectLabel />
        </div>
      )}
      {review.rating && (
        <div style={{ marginBottom: 8 }}>
          <Stars
            stars={review.rating}
            filledStarOptions={{ color: styles.colors.orange2 }}
          />
        </div>
      )}
      {project && project.title && (
        <div style={{ marginBottom: 8 }}>
          {formatUserName(reviewer, "abbrLast")}
        </div>
      )}
      {project && project.location.county && (
        <div style={{ marginBottom: 8 }}>
          {locationString(project.location)}
        </div>
      )}
      <div style={{ marginBottom: 32 }} />
      <Questions feedback={review.feedback} />
      <div style={{ marginTop: 24 }}>
        Review received on: {format(new Date(review.submittedAt), "MMMM YYYY")}
      </div>
    </>
  );
};

AnswersModal.propTypes = {
  review: PropTypes.object,
};

export default ModalWrapper(AnswersModal);
