import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Body, Col, FormLabel, Header, Input, Row } from "@sweeten/oreo";
import { withApollo } from "react-apollo";
import { useForm, useField } from "react-final-form-hooks";
import {
  required,
  validateEmail as emailValidator,
  name as nameValidator,
  validate,
} from "../../../validators";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";
import { loginWithToken } from "../../../utils";

const ContactDetails = ({
  client,
  isActive,
  setHandleSubmit,
  subject: project,
  update,
}) => {
  const { form, handleSubmit } = useForm({
    initialValues: { defaultProjectId: project.id },
    onSubmit: values => {
      update(values, {
        customCallback: async ({ subject }) => {
          // "subject" here is the project after a user gets created and attached to it
          await loginWithToken(subject.user.loginToken);
          if (project.useType === "commercial") {
            return {
              redirect: `/commercial/${project.id}/edit?onboarding=true`,
            };
          }
          return {};
        },
      });
    },
  });

  const firstName = useField("firstName", form, val => {
    const validators = [required, nameValidator];
    return validate(validators, val);
  });
  const firstNameError = firstName.meta.touched && firstName.meta.error;
  const lastName = useField("lastName", form, val => {
    const validators = [required, nameValidator];
    return validate(validators, val);
  });
  const lastNameError = lastName.meta.touched && lastName.meta.error;
  const email = useField("email", form, val => emailValidator(val, client));
  const emailError = email.meta.touched && email.meta.error;

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Contact Info");
  }, []);

  useEffect(() => {
    if (firstNameError) {
      trackViewError("first name", firstName, firstNameError);
    } else if (lastNameError) {
      trackViewError("last name", lastName, lastNameError);
    } else if (emailError) {
      trackViewError("email", email, emailError);
    }
  }, [firstNameError || lastNameError || emailError]);

  return (
    <>
      <Header tag="h5">Add contact details</Header>
      <Body tag="p" variant="large">
        We need your contact details to get started.
      </Body>
      <Row>
        <Col
          width={{ desktopStandard: 6, tabletStandard: 12, phoneStandard: 12 }}
        >
          <FormLabel>First Name</FormLabel>
          <Input
            {...firstName.input}
            data-test="first_name"
            error={firstName.meta.touched && firstName.meta.error}
            style={{ lineHeight: "inherit" }}
          />
        </Col>
        <Col
          width={{ desktopStandard: 6, tabletStandard: 12, phoneStandard: 12 }}
        >
          <FormLabel>Last Name</FormLabel>
          <Input
            {...lastName.input}
            data-test="last_name"
            error={lastName.meta.touched && lastName.meta.error}
            style={{ lineHeight: "inherit" }}
          />
        </Col>
        <Col width={12}>
          <FormLabel>Email Address</FormLabel>
          <Input
            {...email.input}
            data-test="email_address"
            error={email.meta.touched && email.meta.error}
            style={{ lineHeight: "inherit" }}
          />
        </Col>
      </Row>
    </>
  );
};

ContactDetails.propTypes = {
  client: PropTypes.object,
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default withApollo(ContactDetails);
