import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormLabel, Header, RadioGroup } from "@sweeten/oreo";
import { useField, useForm } from "react-final-form-hooks";
import { required } from "../../../validators";
import StepInfoValue from "../step_info_value";
import { trackViewStepPrimeForm, trackViewError } from "../tracking_events";

const UmbrellaInsurance = ({
  isActive,
  subject: project,
  setHandleSubmit,
  update,
}) => {
  const { umbrellaInsurance } = project;
  const { form, handleSubmit } = useForm({
    initialValues: { umbrellaInsurance },
    onSubmit: update,
  });
  const field = useField("umbrellaInsurance", form, required);
  const error = field.meta.touched && field.meta.error;
  const onChange = val => {
    field.input.onChange(val);
    handleSubmit();
  };

  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Umbrella Insurance");
  }, []);

  useEffect(() => {
    if (error) {
      trackViewError("umbrella insurance", field, error);
    }
  }, [error]);

  return (
    <>
      <Header tag="h5">
        What&apos;s your building&apos;s umbrella insurance requirement?
      </Header>
      <StepInfoValue
        stepValue="Protect yourself by working with
         a contractor who has adequate coverage."
      />
      <RadioGroup error={error} {...field.input} onChange={onChange}>
        <RadioGroup.Radio data-test="option_one" value="$1 Million" />
        <FormLabel style={{ marginBottom: 0 }}>$1 Million</FormLabel>
        <RadioGroup.Radio data-test="option_two" value="$2 - $4 Million" />
        <FormLabel style={{ marginBottom: 0 }}>$2 - $4 Million</FormLabel>
        <RadioGroup.Radio data-test="option_three" value="$5 Million" />
        <FormLabel style={{ marginBottom: 0 }}>$5 Million</FormLabel>
        <RadioGroup.Radio data-test="option_four" value="$6 Million+" />
        <FormLabel style={{ marginBottom: 0 }}>$6 Million+</FormLabel>
        <RadioGroup.Radio
          data-test="option_five"
          value="No insurance required"
        />
        <FormLabel style={{ marginBottom: 0 }}>No insurance required</FormLabel>
        <RadioGroup.Radio data-test="option_six" value="I'll find out later" />
        <FormLabel style={{ marginBottom: 0 }}>
          I&apos;ll find out later
        </FormLabel>
      </RadioGroup>
    </>
  );
};

UmbrellaInsurance.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
  subject: PropTypes.object,
  update: PropTypes.func,
};

export default UmbrellaInsurance;
