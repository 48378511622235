import React, { useState } from "react";
import PropTypes from "prop-types";
import { Body, styles, Card, Avatar, Header } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite/no-important";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { goToPage } from "../utils";
import ItemCarousel from "../components/item_carousel";
import GcStarRating from "../components/gc_star_rating";

const INTROS_QUERY = gql`
  query getProjectIntrosFromProject($projectId: ID!) {
    project(id: $projectId) {
      id
      finishLevel
      location {
        address
      }
      additionalInfo
      scheduledIntroCall
      rooms {
        id
        roomTypeConstant
      }
      useType
      intros {
        id
        state
        company {
          id
          currentCompanyLogoUrl
          nameSignature
          averageRating
          reviews {
            id
          }
          portfolioProjectImages {
            id
            url {
              large
            }
          }
        }
        interest {
          ren {
            isInterested
            responseAt
          }
          gc {
            isInterested
          }
        }
      }
    }
  }
`;

const ss = StyleSheet.create({
  header: {
    fontSize: 22,
    marginBottom: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        fontSize: 18,
      },
    }),
  },
  name: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: 100,
        whiteSpace: "initial",
      },
    }),
  },
  card: {
    width: 360,
    cursor: "pointer",
    display: "inline-block",
    marginBottom: 32,
    padding: "0px 0px 16px",
    position: "relative",
    borderRadius: 8,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginRight: 16,
        width: 200,
      },
    }),
  },
  rating: {
    ...styles.center("vertical"),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        flexWrap: "wrap",
      },
    }),
  },
  companyInfoContainer: {
    display: "flex",
    marginLeft: 16,
  },
  cardsContainer: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
  },
  flexCentered: {
    ...styles.center("vertical"),
    justifyContent: "space-between",
  },
  photo: {
    width: 359,
    height: 240,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    objectFit: "cover",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: 199,
        height: 200,
      },
    }),
  },
  desktop: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  mobile: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "flex",
      },
    }),
  },
});

const OtherMatch = ({ intro }) => {
  const [projIdx, setProjIdx] = useState(0);

  const onError = () => {
    if (projIdx !== null) {
      if (
        projIdx + 1 < intro.company.portfolioProjectImages.length &&
        projIdx < 5
      ) {
        setProjIdx(projIdx + 1);
      } else {
        setProjIdx(null);
      }
    }
  };

  return (
    <Card aphStyle={ss.card} onClick={() => goToPage(intro.company.id)}>
      {projIdx !== null ? (
        <img
          src={intro.company.portfolioProjectImages[projIdx].url.large}
          alt={`${intro.company.nameSignature}_photo`}
          className={css(ss.photo)}
          onError={onError}
        />
      ) : (
        <div className={css(ss.photo)} style={{ marginTop: 8 }} />
      )}
      <div className={css(ss.companyInfoContainer)}>
        <Avatar image={intro.company.currentCompanyLogoUrl} size={32} />
        <div style={{ marginLeft: 8 }}>
          <Body tag="div" variant="bold" aphStyle={ss.name}>
            {intro.company.nameSignature}
          </Body>
          <div className={css(ss.rating)}>
            <div
              style={{ marginRight: 8, marginTop: 5, whiteSpace: "pre-wrap" }}
            >
              <GcStarRating
                companyId={intro.company.id}
                starColor={styles.colors.orange2}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

OtherMatch.propTypes = {
  intro: PropTypes.object,
};

const OtherMatches = ({ projectId, matchId }) => {
  const { data, loading } = useQuery(INTROS_QUERY, {
    variables: { projectId },
  });

  if (loading) {
    return null;
  }
  const { project } = data;
  const { intros } = project;

  const potentialMatches = intros
    .filter(
      introInArray =>
        introInArray.interest.ren.isInterested === null &&
        introInArray.interest.gc.isInterested !== false &&
        introInArray.id !== matchId
    )
    .slice(0, 3);

  return potentialMatches.length ? (
    <div>
      <Header tag="h5" aphStyle={ss.header}>
        Other recommended general contractors
      </Header>
      <Body tag="div" style={{ color: styles.colors.grey40, marginBottom: 24 }}>
        Explore these other top-rated general contractors we’ve matched to your
        renovation
      </Body>
      <div className={css(ss.desktop)}>
        {potentialMatches.map(intro => (
          <OtherMatch intro={intro} />
        ))}
      </div>
      <div className={css(ss.mobile, ss.cardsContainer)}>
        <ItemCarousel numShown={3}>
          {potentialMatches.map(intro => (
            <OtherMatch intro={intro} />
          ))}
        </ItemCarousel>
      </div>
    </div>
  ) : null;
};

OtherMatches.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  matchId: PropTypes.string,
};

export default OtherMatches;
