import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Body,
  Button,
  Loader,
  Card,
  Header,
  Icon,
  styles,
} from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import Review from "./review";

const REVIEWS_QUERY = gql`
  query getReviewsCompanyShow($companyId: ID!) {
    company(id: $companyId) {
      id
      averageRating
      reviews(status: ["received"]) {
        id
        rating
        submittedAt
        type
        project {
          id
          roomScope
          title
          location {
            county
            state
          }
        }
        reviewer {
          avatar {
            url {
              thumb
            }
          }
          firstName
          lastName
        }
        feedback {
          public
          positive
          challenge
        }
      }
    }
  }
`;

const ss = StyleSheet.create({
  header: {
    fontSize: 22,
    marginBottom: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 18,
      },
    }),
  },
  verticalAlign: {
    ...styles.center("vertical"),
  },
  viewMoreButton: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        height: 28,
        fontSize: 12,
      },
    }),
  },
  reviewsCard: {
    display: "flex",
    backgroundColor: "#FFFAF6",
    borderRadius: 8,
    border: "none",
    marginBottom: 32,
    padding: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        backgroundColor: styles.colors.white,
        border: `1px solid ${styles.colors.grey20}`,
        flexFlow: "column nowrap",
        padding: 24,
      },
    }),
  },
  flexCentered: {
    ...styles.center("vertical"),
  },
  flexColumn: {
    display: "flex",
    flexFlow: "column nowrap",
  },
  separator: {
    borderLeft: `1px solid ${styles.colors.grey20}`,
    margin: "0px 32px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        display: "none",
      },
    }),
  },
  flexSpaceBetween: {
    ...styles.center("vertical"),
    justifyContent: "space-between",
  },
  ratingPercentage: {
    width: "100%",
    padding: "0px 24px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: { padding: 0 },
    }),
  },
  progressBarBackground: {
    backgroundColor: "#F7F4F2",
    position: "relative",
    width: 177,
    minHeight: 6,
    borderRadius: 3,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        width: 175,
      },
    }),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneSmall,
      style: {
        width: 140,
      },
    }),
  },
  progressBar: {
    backgroundColor: styles.colors.blueInfo,
    position: "absolute",
    minHeight: 6,
    borderRadius: 3,
  },
  percentage: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: 14,
    fontWeight: 700,
    color: styles.colors.black,
    width: 30,
  },
  container: {
    marginBottom: 64,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginBottom: 40,
      },
    }),
  },
});

const RatingPercentageItem = ({ title, percentage }) => {
  return (
    <div className={css(ss.flexSpaceBetween)}>
      <Body tag="div" variant="bold" style={{ width: 48 }}>
        {title}
      </Body>
      <div className={css(ss.progressBarBackground)}>
        <div
          className={css(ss.progressBar)}
          style={{ width: `calc(${percentage}%)` }}
        />
      </div>
      <Header tag="h6" aphStyle={ss.percentage}>
        {percentage}%
      </Header>
    </div>
  );
};

RatingPercentageItem.propTypes = {
  title: PropTypes.string,
  percentage: PropTypes.number,
};

const Reviews = ({ companyId }) => {
  const { data, loading } = useQuery(REVIEWS_QUERY, {
    variables: { companyId },
  });
  const [numReviewsShown, toggleNumReviewsShown] = useState(3);

  if (loading) {
    return <Loader.Body style={{ height: 600, marginBottom: 24 }} />;
  }

  const { company } = data;
  const { averageRating, reviews } = company;

  if (!reviews.length) {
    return null;
  }

  const reviewCopy = reviews.length === 1 ? "review" : "reviews";
  const sortedReviews = reviews.sort((a, b) => {
    if (a.type === "testimonial" && b.type !== "testimonial") {
      return -1;
    }
    if (a.type !== "testimonial" && b.type === "testimonial") {
      return 1;
    }
    return 0;
  });

  const numReviews = {
    one: 0,
    two: 0,
    three: 0,
    four: 0,
    five: 0,
  };

  reviews.forEach(review => {
    if (review.rating === 5) {
      numReviews.five += 1;
    } else if (review.rating === 4) {
      numReviews.four += 1;
    } else if (review.rating === 3) {
      numReviews.three += 1;
    } else if (review.rating === 2) {
      numReviews.two += 1;
    } else {
      numReviews.one += 1;
    }
  });

  return (
    <div className={css(ss.container)}>
      <div>
        <Header aphStyle={ss.header} tag="h5">
          Reviews ({reviews.length})
        </Header>
      </div>
      <Card aphStyle={ss.reviewsCard}>
        <div className={css(ss.flexColumn)} style={{ marginBottom: 24 }}>
          <div className={css(ss.flexCentered)}>
            <Icon
              name="star-filled"
              size={32}
              color={styles.colors.orange2}
              style={{ marginRight: 8 }}
            />
            <Header tag="h4" style={{ fontWeight: 700 }}>
              {averageRating.toFixed(2)}
            </Header>
          </div>
          <Body tag="p" style={{ margin: 0 }}>
            Based on {reviews.length} {reviewCopy}
          </Body>
        </div>
        <div className={css(ss.separator)} />
        <div className={css([ss.flexColumn, ss.ratingPercentage])}>
          <RatingPercentageItem
            title="5 stars"
            percentage={Math.floor((numReviews.five * 100) / reviews.length)}
          />
          <RatingPercentageItem
            title="4 stars"
            percentage={Math.floor((numReviews.four * 100) / reviews.length)}
            style={{ marginTop: 16 }}
          />
          <RatingPercentageItem
            title="3 stars"
            percentage={Math.floor((numReviews.three * 100) / reviews.length)}
            style={{ marginTop: 16 }}
          />
          <RatingPercentageItem
            title="2 stars"
            percentage={Math.floor((numReviews.two * 100) / reviews.length)}
            style={{ marginTop: 16 }}
          />
          <RatingPercentageItem
            title="1 star"
            percentage={Math.floor((numReviews.one * 100) / reviews.length)}
            style={{ marginTop: 16 }}
          />
        </div>
      </Card>
      {sortedReviews.slice(0, numReviewsShown).map((review, idx) => {
        return <Review key={review.id} review={review} idx={idx} />;
      })}
      {numReviewsShown < reviews.length && (
        <Button
          aphStyle={ss.viewMoreButton}
          onClick={() => toggleNumReviewsShown(numReviewsShown + 3)}
          variant="outlineDark"
        >
          View more
        </Button>
      )}
    </div>
  );
};

Reviews.propTypes = {
  companyId: PropTypes.number,
};

export default Reviews;
