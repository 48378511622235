import React from "react";
import PropTypes from "prop-types";
import { Body, styles } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";

const ss = StyleSheet.create({
  container: {
    display: "inline-flex",
    alignItems: "center",
    backgroundColor: "#F5F6F9",
    borderRadius: 4,
    padding: "4px 10px",
    marginRight: 8,
    marginTop: 16,
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "18px",
    color: styles.colors.black,
  },
});

const Badges = ({ awardedProjects, isMinorityOwned, isWomanOwned }) => {
  return (
    <>
      {!!awardedProjects.length && (
        <div className={css(ss.container)}>
          <Body tag="span" aphStyle={ss.label}>
            {awardedProjects.length} Sweeten renovation
            {awardedProjects.length === 1 ? "" : "s"}
          </Body>
        </div>
      )}
      {isWomanOwned && (
        <div className={css(ss.container)}>
          <Body tag="span" aphStyle={ss.label}>
            Woman-owned and operated
          </Body>
        </div>
      )}
      {isMinorityOwned && (
        <div className={css(ss.container)}>
          <Body tag="span" aphStyle={ss.label}>
            Minority-owned and operated
          </Body>
        </div>
      )}
    </>
  );
};

Badges.propTypes = {
  awardedProjects: PropTypes.array,
  isMinorityOwned: PropTypes.bool,
  isWomanOwned: PropTypes.bool,
};

export default Badges;
