import {
  Body,
  Checkbox,
  FormLabel,
  Input,
  RadioGroup,
  styles,
} from "@sweeten/oreo";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, css } from "aphrodite";

const ss = StyleSheet.create({
  checkboxContainer: {
    display: "flex",
    ...styles.center("vertical"),
  },
});

const BusyFeedback = ({
  pausedField,
  pausedFieldOnChange,
  pauseReasonField,
  unpauseAtField,
}) => {
  return (
    <>
      <Body>
        Would you like us to pause your account? You will stop receiving leads.
      </Body>
      <div className={css(ss.checkboxContainer)}>
        <Checkbox {...pausedField.input} onClick={pausedFieldOnChange} />
        <FormLabel onClick={pausedFieldOnChange} style={{ marginBottom: 0 }}>
          Pause
        </FormLabel>
      </div>
      {pausedField.input.value && (
        <>
          <div style={{ marginTop: 8, marginBottom: 16 }}>
            <FormLabel>Enter a date to unpause</FormLabel>
            <Input
              placeholder="mm/dd/yyyy"
              {...unpauseAtField.input}
              error={unpauseAtField.meta.touched && unpauseAtField.meta.error}
            />
          </div>
          <RadioGroup numCols={{ desktopLarge: 1 }} {...pauseReasonField.input}>
            <RadioGroup.Radio value="Busy" />
            <FormLabel style={{ marginBottom: 0 }}>Busy</FormLabel>
            <RadioGroup.Radio value="Busy but open to 3+month project" />
            <FormLabel style={{ marginBottom: 0 }}>
              Busy but open to 3+month project
            </FormLabel>
            <RadioGroup.Radio value="Vacation" />
            <FormLabel style={{ marginBottom: 0 }}>Vacation</FormLabel>
            <RadioGroup.Radio value="Other" />
            <FormLabel style={{ marginBottom: 0 }}>Other</FormLabel>
          </RadioGroup>
        </>
      )}
    </>
  );
};

BusyFeedback.propTypes = {
  pausedField: PropTypes.object.isRequired,
  pausedFieldOnChange: PropTypes.func.isRequired,
  pauseReasonField: PropTypes.object.isRequired,
  unpauseAtField: PropTypes.object.isRequired,
};

export default BusyFeedback;
