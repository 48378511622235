import React, { useEffect, useContext } from "react";
import { includes } from "lodash";
import { useMutation } from "@apollo/react-hooks";
import { StyleSheet, css } from "aphrodite/no-important";
import { gql } from "apollo-boost";
import { useForm, useField } from "react-final-form-hooks";
import { Button, Body, Input, Header, Row, Col } from "@sweeten/oreo";
import { withApollo } from "react-apollo";
import GalleryImage from "../gallery_image";
import { AppDispatch } from "../../components/app_provider";
import { validateEmail } from "../../validators";
import { goToPage } from "../../utils";

const ss = StyleSheet.create({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    flex: "1 1",
  },
  authFields: {
    margin: "16px 0 0 0",
  },
  submit: {
    margin: "32px 0 24px 0",
    justifyContent: "flex-end",
    display: "flex",
    flexFlow: "row nowrap",
  },
});

const USER_RESET_PASSWORD = gql`
  mutation userResetPassword($email: String!) {
    userResetPassword(email: $email) {
      error
    }
  }
`;

const ForgotPassword = withApollo(({ client }) => {
  const dispatch = useContext(AppDispatch);
  const [passwordReset, { loading: mutationLoading }] = useMutation(
    USER_RESET_PASSWORD
  );

  const { form, handleSubmit } = useForm({
    onSubmit: values => {
      passwordReset({
        variables: {
          email: values.email,
        },
      }).then(({ data }) => {
        const { userResetPassword } = data || {};
        const { error } = userResetPassword || {};
        if (error) {
          dispatch({
            type: "alert:show",
            payload: {
              variant: "error",
              text: error,
            },
          });
        } else {
          goToPage("/password_recovery?check_email=true");
        }
      });
    },
  });

  const email = useField("email", form, value =>
    validateEmail(value, client, false)
  );
  const emailError = email.meta.touched && email.meta.error;

  useEffect(() => {
    const queryString = window.location.search;
    if (includes(queryString, "expired")) {
      dispatch({
        type: "alert:show",
        payload: {
          variant: "error",
          text:
            "This link has expired. Request a new password reset email below.",
        },
      });
    }
  }, []);

  return (
    <div style={{ display: "flex", flexFlow: "row nowrap" }}>
      <GalleryImage />
      <div className={css(ss.container)}>
        <Row style={{ justifyContent: "center" }}>
          <Col width={5} aphStyle={ss.authFields}>
            <Header tag="h3" style={{ textAlign: "center", margin: "32px 0" }}>
              Forgot password
            </Header>
            <Body tag="p">
              Enter your email below and instructions on how to reset your
              password will be sent to you.
            </Body>
            <Input
              placeholder="Email"
              style={{ marginTop: 24 }}
              {...email.input}
              error={emailError}
            />
            <div className={css(ss.submit)}>
              <Button
                label="Next"
                onClick={handleSubmit}
                type="submit"
                loading={mutationLoading}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default ForgotPassword;
