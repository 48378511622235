import React, { useContext } from "react";
import PropTypes from "prop-types";
import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
/* eslint-disable import/no-extraneous-dependencies */
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { AppDispatch } from "../app_provider";

const authLink = authToken =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : "",
      },
    };
  });

const GraphqlClient = ({ authToken, linkOptions, children }) => {
  const dispatch = useContext(AppDispatch);

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors || networkError) {
          // TODO: https://swee10.atlassian.net/browse/SWTN-952
          dispatch({
            type: "alert:show",
            payload: {
              variant: "error",
              text:
                "There was an error processing your request. Please try again later.",
            },
          });
        }

        if (typeof Sentry !== "undefined") {
          if (graphQLErrors) {
            const messages = graphQLErrors
              .map(error => {
                const operationName = error.path[0];
                return `(${operationName}) ${error.message}`;
              })
              .join(", ");

            Sentry.withScope(scope => {
              scope.setExtra("graphQLErrors", graphQLErrors);
              Sentry.captureMessage(`graphQLErrors: ${messages}`);
            });
          }
          if (networkError) {
            Sentry.captureMessage(`networkError: ${networkError}`);
          }
        }
      }),
      authLink(authToken).concat(createUploadLink(linkOptions)),
    ]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

GraphqlClient.propTypes = {
  authToken: PropTypes.string,
  linkOptions: PropTypes.object,
  children: PropTypes.node,
};

export default GraphqlClient;
