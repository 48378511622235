/* eslint-disable react/prop-types */
import React from "react";
import ReactDOM from "react-dom";
import { StyleSheet } from "aphrodite";
import { styles } from "@sweeten/oreo";
import { extractReactData } from "../utils";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "../_shared_css.scss";

// Page Containers

import ProjectShow from "../project_show/index";
import CompanyShow from "../company_show/index";
import ContactInfo from "../ren/contact_info";
import ForgotPassword from "../guest/forgot_password";
import ForgotPasswordSuccess from "../guest/forgot_password/success";
import GCContactInfo from "../guest/hubspot_applicant_form/contact_info";
import HubspotEmbeddedForm from "../components/hubspot_embedded_form";
import PasswordReset from "../guest/forgot_password/password_reset";
import Login from "../guest/login";
import ReviewForm from "../components/reviews/form";
import ProjectPostingWizard from "../ren/project_posting/wizard";
import ScheduleInterview from "../guest/hubspot_applicant_form/schedule_interview";
import SuccessConfirmation from "../guest/hubspot_applicant_form/success_confirmation";
import Portfolio from "../guest/hubspot_applicant_form/portfolio";

import GuestLayout from "../guest/guest_layout/index";

const ss = StyleSheet.create({
  wideContentArea: { maxWidth: "none", padding: "16px 0 0 0" },
  noPadding: { padding: "0" },
  noMaxWidth: { maxWidth: "none" },
  companyShowPadding: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: { padding: 0 },
    }),
  },
});

const PageContent = ({ layoutData, pageData, pageName }) => {
  switch (pageName) {
    case "CompanyShow":
      return (
        <GuestLayout
          {...layoutData}
          contentAphStyle={[ss.noPadding, ss.companyShowPadding, ss.noMaxWidth]}
        >
          <CompanyShow {...pageData} />
        </GuestLayout>
      );
    case "ContactInfo":
      return (
        <GuestLayout hideTopNavContent {...layoutData}>
          <ContactInfo {...pageData} />
        </GuestLayout>
      );
    case "GCContactInfo":
      return (
        <GuestLayout hideTopNavContent stepNumber={1} {...layoutData}>
          <GCContactInfo {...pageData} />
        </GuestLayout>
      );
    case "HubspotEmbeddedForm":
      return (
        <GuestLayout hideTopNavContent {...layoutData}>
          <HubspotEmbeddedForm {...pageData} />
        </GuestLayout>
      );
    case "ProjectPosting":
      return (
        <GuestLayout hideTopNavContent {...layoutData}>
          <ProjectPostingWizard {...pageData} />
        </GuestLayout>
      );
    case "ProjectShow":
      return (
        <GuestLayout {...layoutData}>
          <ProjectShow {...pageData} />
        </GuestLayout>
      );
    case "Login":
      return (
        <GuestLayout
          contentAphStyle={ss.wideContentArea}
          hideTopNavContent
          {...layoutData}
        >
          <Login {...pageData} />
        </GuestLayout>
      );
    case "ForgotPassword":
      return (
        <GuestLayout
          contentAphStyle={ss.wideContentArea}
          hideTopNavContent
          {...layoutData}
        >
          <ForgotPassword {...pageData} />
        </GuestLayout>
      );
    case "ForgotPasswordSuccess":
      return (
        <GuestLayout
          contentAphStyle={ss.wideContentArea}
          hideTopNavContent
          {...layoutData}
        >
          <ForgotPasswordSuccess {...pageData} />
        </GuestLayout>
      );
    case "PasswordReset":
      return (
        <GuestLayout
          contentAphStyle={ss.wideContentArea}
          hideSweetenStandardBadge
          hideTopNavContent
          {...layoutData}
        >
          <PasswordReset {...pageData} />
        </GuestLayout>
      );
    case "ReviewForm":
      return (
        <GuestLayout {...layoutData}>
          <ReviewForm {...pageData} />
        </GuestLayout>
      );
    case "ScheduleInterview":
      return (
        <GuestLayout hideTopNavContent stepNumber={3} {...layoutData}>
          <ScheduleInterview {...pageData} />
        </GuestLayout>
      );
    case "SuccessConfirmation":
      return (
        <GuestLayout hideTopNavContent {...layoutData}>
          <SuccessConfirmation {...pageData} />
        </GuestLayout>
      );
    case "Portfolio":
      return (
        <GuestLayout hideTopNavContent stepNumber={2} {...layoutData}>
          <Portfolio {...pageData} />
        </GuestLayout>
      );
    default:
      return null;
  }
};

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <PageContent {...extractReactData()} />,
    document.getElementById("main")
  );
});
