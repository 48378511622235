import React from "react";
import PropTypes from "prop-types";
import { Body, Icon, styles } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";

/* ======= Styles ======= */

const ss = StyleSheet.create({
  passwordRequirementGroup: {
    display: "flex",
    ...styles.center("vertical"),
    marginBottom: 8,
  },
});

export const Requirement = ({ regex, label, field, style }) => {
  const isMeetingRequirements = regex.test(field.input.value);
  let iconColor = styles.colors.grey20;
  let iconName = "close";
  if (isMeetingRequirements) {
    iconColor = styles.colors.greenSuccess;
    iconName = "checkmark";
  } else if (field.meta.dirty && field.meta.touched) {
    iconColor = styles.colors.redError;
  }

  return (
    <div className={css(ss.passwordRequirementGroup)} style={style}>
      <Icon color={iconColor} name={iconName} />
      <Body style={{ marginLeft: 8 }} tag="span">
        {label}
      </Body>
    </div>
  );
};

Requirement.propTypes = {
  regex: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export const shouldShowPasswordReqs = passwordField =>
  passwordField.meta.dirty || passwordField.meta.active;

const PasswordRequirements = ({ passwordField }) => (
  <>
    <Requirement
      regex={/.{12,}/}
      label="Use 12 or more characters"
      field={passwordField}
    />
    <Requirement
      regex={/^(?=.*[a-z])(?=.*[A-Z])/}
      label="Use upper and lower case letters (e.g. Aa)"
      field={passwordField}
    />
    <Requirement
      regex={/.*\d/}
      label="Use a number (e.g. 1234)"
      field={passwordField}
    />
    <Requirement
      regex={/.*[^a-zA-Z0-9]/}
      label="Use a symbol (e.g. !@#$)"
      field={passwordField}
      style={{ marginBottom: 0 }}
    />
  </>
);

PasswordRequirements.propTypes = {
  passwordField: PropTypes.object.isRequired,
};

export default PasswordRequirements;
