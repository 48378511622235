import React, { useState } from "react";
import PropTypes from "prop-types";
import { Loader, styles } from "@sweeten/oreo";
import { StyleSheet } from "aphrodite";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { isEmpty } from "lodash";
import ImageDisplay from "../components/image_display";

const GET_PROJECT_IMAGES = gql`
  query getCompanyAndCarouselExp($id: ID!) {
    company(id: $id) {
      id
      albums(category: ["featured"]) {
        id
        category
        isHidden
        images {
          id
          roomTypeConstant
          sortIndex
          url {
            large
          }
          type
          isHidden
          project {
            id
            title
          }
        }
      }
    }
  }
`;

const ss = StyleSheet.create({
  carouselMain: {
    height: 330,
  },
  carouselNav: {
    height: 115,
    marginTop: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
});

const CompanyCarouselLoader = () => (
  <div>
    <Loader.Quadrangle aphStyle={ss.carouselMain} />
    <Loader.Quadrangle aphStyle={ss.carouselNav} />
  </div>
);

const CompanyCarouselComp = ({ data }) => {
  const { company } = data || {};
  const { id, albums } = company || {};

  const [noImages, setNoImages] = useState(false);

  if (albums.length && !isEmpty(data)) {
    const filteredPhotos = albums[0].images.filter(photo => !photo.isHidden);
    return (
      <>
        {!noImages && (
          <div style={{ position: "relative", margin: "0px -16px" }}>
            <ImageDisplay
              photos={filteredPhotos}
              setNoImages={setNoImages}
              location="gc_profile"
              companyId={id}
            />
          </div>
        )}
      </>
    );
  }

  return null;
};

CompanyCarouselComp.propTypes = {
  data: PropTypes.object,
};

const CompanyCarousel = ({ companyId }) => (
  <Query query={GET_PROJECT_IMAGES} variables={{ id: companyId }}>
    {({ data, loading }) =>
      loading ? <CompanyCarouselLoader /> : <CompanyCarouselComp data={data} />
    }
  </Query>
);

CompanyCarousel.propTypes = {
  companyId: PropTypes.number,
};

export default CompanyCarousel;
