import { get } from "lodash";

export const fieldError = field => {
  return (
    (!field.meta.dirtySinceLastSubmit && field.meta.submitError) ||
    (field.meta.touched && field.meta.error)
  );
};

export const parseDwollaBackendErrors = (resp, dispatch, form, errorPath) => {
  const { data } = resp;
  const errors = get(data, errorPath, []);
  const registeredFields = form.getRegisteredFields();
  const fieldErrors = errors.reduce((mappedErrors, currentError) => {
    if (registeredFields.indexOf(currentError.attribute) > -1) {
      // eslint-disable-next-line no-param-reassign
      mappedErrors[currentError.attribute] = currentError.message;
    }

    return mappedErrors;
  }, {});
  const hasOtherValidationErrors =
    Object.keys(fieldErrors).length !== errors.length;

  // If there is a non field validation error
  // there is not much we can do but have them contact Sweeten.
  if (hasOtherValidationErrors) {
    dispatch({
      type: "alert:show",
      payload: {
        variant: "error",
        text:
          "There was an error creating your account. Please contact Sweeten.",
      },
    });

    return {};
  }

  if (Object.keys(errors).length) {
    dispatch({
      type: "alert:show",
      payload: {
        variant: "error",
        text: errors[0].message,
        shouldStayVisible: true,
      },
    });
  }

  return null;
};
