import React, { forwardRef, useContext } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, styles, Header, Body, Button } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import Description from "./description";
import Info from "./info";
import Licenses from "./licenses";
import Insurance from "./insurance";
import Contact from "./contact";
import Reviews from "./reviews";
import MatchBanner from "../components/match_banner";
import AboutContractor from "./about_contractor";
import { goToPage, trackMixpanelWithCallback } from "../utils";
import Vetting from "./vetting";
import OtherMatches from "./other_matches";
import { GcProfileProjects } from "../components/photo_overlay";
import Badges from "./badges";
import RespondToMatchCTAs from "../ren/my_contractors/ctas/respond_to_match";
import { AppDispatch } from "../components/app_provider";

const COMPANY_INFO = gql`
  query getCompanyProfileData($companyId: ID!) {
    company(id: $companyId) {
      id
      registeredBusiness
      verifiedBusiness
      validLicenses
      requiresLicense
      insuranceCoverage
      referencesConfirmed
      portfolioReviewed
      onlineReputationChecked
      status
      priority
      paused
      awardedProjects {
        id
      }
      umbrellaInsuranceAmount
      liabilityInsuranceAmount
      averageRating
      avgAskResponseTimeMinutes
      contact {
        location {
          address
          lat
          lng
          city
          state
          neighborhood
        }
        number
        email
        facebookUrl
        instagramUrl
        pinterestUrl
        webUrl
      }
      description
      doesBudgetFinish
      doesMidGradeFinish
      doesHighEndFinish
      doesLuxuryFinish
      epaCertified
      hasCompletedRehabMortgageProject
      isMinorityOwned
      isWomanOwned
      licenses {
        id
        licenseType
        licenseNumber
        licenseState
        expiresOn
      }
      matchWithCurrentUser {
        id
        canShowInterest
        introducedAt
        projectId
        proxyPhoneNumberForRen
        interest {
          gc {
            isInterested
            responseAt
          }
          ren {
            isInterested
          }
        }
        successfulContactMade
      }
      nameSignature
      permissions {
        restrictedView
      }
      referencesAreConfirmed
      renovationStyle
      services
      serviceAreas
      supportDuringPermitProcess
      typicalProjectTypes {
        residential
        commercial
      }
      worksWithIkeaProducts
    }
    currentUser {
      id
      companyId
      enabledForTwilioProxy
      isRenovator
      phone
    }
    roomTypes {
      id
      constant
      displayName
    }
  }
`;

const ss = StyleSheet.create({
  declineContainer: {
    backgroundColor: styles.colors.grey10,
    borderRadius: 8,
    padding: "8px 24px",
    boxShadow: "none",
  },
  declineLink: {
    fontWeight: 700,
    color: styles.colors.red5,
    textDecoration: "underline",
  },
  desktopOnly: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  horizontalLine: {
    margin: "32px 0",
    borderTop: `1px solid ${styles.colors.grey20}`,
  },
  mobileOnly: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
      },
    }),
  },
  marginTop: {
    marginTop: 16,
  },
  subtext: {
    color: styles.colors.grey40,
    margin: "12px 0px 24px",
  },
  leftCol: {
    marginBottom: 0,
    marginRight: "auto",
  },
  matchBanner: {
    border: "1px solid #EDE8E9",
    borderRadius: 8,
    boxShadow: "0 0 16px 5px rgba(0,0,0,0.06)",
  },
  loggedOut: {
    width: "100%",
    marginBottom: 64,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginBottom: 0,
      },
    }),
  },
});

const Overview = forwardRef(({ companyId, showLoggedOutCTA }, ref) => {
  const { data, loading } = useQuery(COMPANY_INFO, {
    variables: { companyId },
  });

  if (loading) {
    return;
  }
  const { company, currentUser } = data || {};
  const {
    companyId: currentUserCompanyId,
    enabledForTwilioProxy,
    id: currentUserId,
    isRenovator,
  } = currentUser || {};
  const {
    awardedProjects,
    avgAskResponseTimeMinutes,
    contact,
    description,
    doesBudgetFinish,
    doesMidGradeFinish,
    doesHighEndFinish,
    doesLuxuryFinish,
    epaCertified,
    hasCompletedRehabMortgageProject,
    isMinorityOwned,
    isWomanOwned,
    licenses,
    matchWithCurrentUser,
    permissions,
    services,
    serviceAreas,
    supportDuringPermitProcess,
    typicalProjectTypes,
    worksWithIkeaProducts,
    umbrellaInsuranceAmount,
    liabilityInsuranceAmount,
  } = company || {};
  const {
    canShowInterest,
    interest,
    introducedAt,
    projectId,
    id: matchId,
    successfulContactMade,
    proxyPhoneNumberForRen,
  } = matchWithCurrentUser || {};
  const { gc, ren } = interest || {};
  const { isInterested: gcIsInterested } = gc || {};
  const { isInterested: renIsInterested } = ren || {};
  const { restrictedView } = permissions || {};

  const shouldShowMatchCta = !!matchWithCurrentUser && canShowInterest;
  const shouldShowBadges =
    !!awardedProjects.length || isWomanOwned || isMinorityOwned;
  const shouldShowExactLocation = gcIsInterested && renIsInterested;

  const loggedOutCTAClick = () => {
    trackMixpanelWithCallback("Clicked Get Started CTA", {}, () =>
      goToPage("/journey")
    );
  };

  const dispatch = useContext(AppDispatch);

  const canEdit = currentUserCompanyId === currentUserId;

  return (
    <div style={{ padding: "40px 0" }}>
      <Row verticalGap={0}>
        <Col
          width={{ desktopStandard: 7, tabletStandard: 12 }}
          aphStyle={ss.leftCol}
        >
          {description && <Description description={description} />}
          {shouldShowBadges && (
            <Badges
              awardedProjects={awardedProjects}
              isMinorityOwned={isMinorityOwned}
              isWomanOwned={isWomanOwned}
            />
          )}
          {(description || shouldShowBadges) && (
            <div className={css([ss.horizontalLine, ss.desktopOnly])} />
          )}
          <div className={css(ss.mobileOnly)}>
            {showLoggedOutCTA && (
              <div
                ref={ref}
                id="gc_profile_ctas"
                style={{ margin: "48px 0px 24px" }}
              >
                <Button
                  label="Start your renovation"
                  aphStyle={ss.loggedOut}
                  onClick={loggedOutCTAClick}
                />
              </div>
            )}
            {shouldShowMatchCta && (
              <Card aphStyle={ss.matchBanner} style={{ margin: "40px 0px" }}>
                <MatchBanner introId={matchWithCurrentUser.id} />
              </Card>
            )}
          </div>
          <div className={css(ss.mobileOnly)} />
          <Info
            doesBudgetFinish={doesBudgetFinish}
            doesMidGradeFinish={doesMidGradeFinish}
            doesHighEndFinish={doesHighEndFinish}
            doesLuxuryFinish={doesLuxuryFinish}
            epaCertified={epaCertified}
            hasCompletedRehabMortgageProject={hasCompletedRehabMortgageProject}
            services={services}
            serviceAreas={serviceAreas}
            supportDuringPermitProcess={supportDuringPermitProcess}
            typicalProjectTypes={typicalProjectTypes}
            worksWithIkeaProducts={worksWithIkeaProducts}
          />
          <div className={css(ss.mobileOnly)} />
          <GcProfileProjects
            companyId={companyId}
            canEdit={canEdit}
            profileClick={clickedProject =>
              dispatch({
                type: "overlay:show",
                payload: {
                  variant: "thumbnail",
                  companyId,
                  project: clickedProject,
                },
              })
            }
          />
          <div className={css(ss.mobileOnly)}>
            <Vetting company={company} />
          </div>
          <Reviews companyId={companyId} />
        </Col>
        <Col width={{ desktopStandard: 4, tabletStandard: 12 }}>
          {showLoggedOutCTA && (
            <div ref={ref}>
              <Button
                label="Start your renovation"
                aphStyle={[ss.desktopOnly, ss.loggedOut]}
                onClick={loggedOutCTAClick}
              />
            </div>
          )}
          {shouldShowMatchCta && (
            <Card
              aphStyle={[ss.desktopOnly, ss.matchBanner]}
              style={{ marginBottom: 64 }}
            >
              <MatchBanner
                introId={matchWithCurrentUser.id}
                location="gc_profile"
                ref={ref}
              />
            </Card>
          )}
          <div className={css(ss.desktopOnly)}>
            <Vetting company={company} />
          </div>
          {currentUser && !shouldShowMatchCta && !restrictedView && (
            <Contact
              contact={contact}
              showExactLocation={shouldShowExactLocation}
              gcFirstContactedAt={successfulContactMade}
              enabledForTwilioProxy={enabledForTwilioProxy}
              proxyPhoneNumberForRen={proxyPhoneNumberForRen}
            />
          )}
          {licenses && !!licenses.length && (
            <Licenses
              companyId={company.id}
              currentUser={currentUser}
              introducedAt={introducedAt}
              licenses={licenses}
            />
          )}
          {(umbrellaInsuranceAmount || liabilityInsuranceAmount) && (
            <Insurance
              umbrellaInsuranceAmount={umbrellaInsuranceAmount}
              liabilityInsuranceAmount={liabilityInsuranceAmount}
            />
          )}
          {!!matchId && (
            <div className={css(ss.desktopOnly)}>
              <OtherMatches projectId={projectId} matchId={matchId} />
            </div>
          )}
          {!!matchId && enabledForTwilioProxy && (
            <>
              <div className={css(ss.horizontalLine)} />
              <Card style={{ marginBottom: 32 }}>
                <Header tag="h6">Decline this contractor</Header>
                <Body tag="div" aphStyle={ss.subtext}>
                  Remove from your list. You can undo this if you change your
                  mind.
                </Body>
                <RespondToMatchCTAs
                  enabledForTwilioProxy={enabledForTwilioProxy}
                  avgAskResponseTimeMinutes={avgAskResponseTimeMinutes}
                  introId={matchId}
                  location="gc_profile"
                  variant="gcProfile"
                  isDecline
                />
              </Card>
            </>
          )}
        </Col>
        <Col width={12} style={{ margin: 0 }}>
          <AboutContractor
            companyId={companyId}
            currentUserIsRen={isRenovator}
          />
        </Col>
      </Row>
      <Row verticalGap={0}>
        <Col width={12}>
          {!!matchId && (
            <div className={css(ss.mobileOnly)}>
              <OtherMatches projectId={projectId} matchId={matchId} />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
});

Overview.propTypes = {
  companyId: PropTypes.number,
  showLoggedOutCTA: PropTypes.bool,
};

export default forwardRef((props, ref) => {
  return <Overview {...props} ref={ref} />;
});
