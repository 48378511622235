// = withFlash =
/*
  This higher order component adds flash to the top of your page.
  It provides methods to dismiss and set flash to the wrapped component.
  It consumes the flash prop passed to the wrapped component.
  The flash will disappear automatically after 10 seconds.
*/

// TODO: Move this flash logic into layout? No need to pass flash to each component.
// Can toggle this with a flag? <Layout hasFlash> so CoreP1 can opt out.

import React from "react";
import PropTypes from "prop-types";
import Domo, { Alert, LeftNav, TopNav, styles } from "@sweeten/domo";
import { StyleSheet, css } from "aphrodite";

const ss = StyleSheet.create({
  flash: {
    position: "fixed",
    zIndex: -1,
    maxWidth: 1440,
    margin: "0 auto",
    top: TopNav.style.desktop.height + 16,
    left: 40,
    right: 40,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: { top: TopNav.style.mobile.height + 16, left: 24, right: 24 },
    }),
  },
  flashVisible: {
    zIndex: styles.zIndexes.flash,
  },
  leftNavOffset: {
    left: LeftNav.style.desktop.width + 40,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: { left: 24 },
    }),
  },
  aboveBackdropTablet: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.desktopStandard,
      minWidth: styles.breakpoints.tabletSmall,
      style: { zIndex: 10000 },
    }),
  },
});

export default function(WrappedComponent) {
  const ALERT_DISMISS_TIME = 10000; // milliseconds
  const ALERT_TO_CSS_CLASS = {
    notice: "info",
    danger: "error",
    success: "success",
    error: "error",
  };

  class withFlash extends React.Component {
    constructor(props) {
      super(props);
      const { flash } = this.props;

      this.state = {
        currentFlash: flash,
        showFlash: !!flash.length,
      };
      this.dismissFlash = this.dismissFlash.bind(this);
      this.setFlashTimeout = this.setFlashTimeout.bind(this);
      this.setFlash = this.setFlash.bind(this);
    }

    componentDidMount() {
      this.setFlashTimeout();
    }

    setFlashTimeout() {
      const {
        props: { autoDismissFlash },
        state: { currentFlash, showFlash },
      } = this;

      if (autoDismissFlash && currentFlash.length && showFlash) {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.dismissFlash, ALERT_DISMISS_TIME);
      }
    }

    setFlash(flash) {
      this.setState(
        { currentFlash: flash, showFlash: true },
        this.setFlashTimeout
      );
    }

    dismissFlash() {
      clearTimeout(this.timer);
      this.setState({ showFlash: false });
    }

    render() {
      let type;
      let message = "";
      const {
        props: { flash, hasLeftNav, ...componentProps },
        state: { currentFlash, showFlash },
      } = this;
      const shouldBeVisible = showFlash && ss.flashVisible;
      const shouldBeLeftNavOffset = hasLeftNav && ss.leftNavOffset;
      const shouldBeAboveBackdrop =
        document.body.classList.contains("action-panel-open") &&
        ss.aboveBackdropTablet;

      if (currentFlash.length) {
        [type, message] = currentFlash[0]; // eslint-disable-line prefer-destructuring
        type = ALERT_TO_CSS_CLASS[type];
      }

      return (
        <>
          <div
            className={css(
              ss.flash,
              shouldBeLeftNavOffset,
              shouldBeVisible,
              shouldBeAboveBackdrop
            )}
          >
            <Alert
              body={message}
              dismissedFlash={!showFlash}
              handleClose={this.dismissFlash}
              isFlash
              type={type}
            />
          </div>
          <WrappedComponent
            dismissFlash={this.dismissFlash}
            setFlash={this.setFlash}
            {...componentProps}
          />
        </>
      );
    }
  }

  withFlash.propTypes = {
    autoDismissFlash: PropTypes.bool,
    flash: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    hasLeftNav: PropTypes.bool,
  };

  withFlash.defaultProps = {
    autoDismissFlash: true,
    flash: [],
    hasLeftNav: false,
  };

  withFlash.displayName = `withFlash(${Domo.Core.Utils.ReactUtils.getComponentDisplayName(
    WrappedComponent
  )})`;

  return withFlash;
}
