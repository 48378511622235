import React from "react";
import PropTypes from "prop-types";
import { StyleSheet } from "aphrodite";
import { Card, Icon, Body, styles } from "@sweeten/oreo";

const ss = StyleSheet.create({
  card: {
    backgroundColor: styles.colors.blueLight,
    display: "flex",
    padding: 12,
  },
});

const PhoneAddressInfo = ({ aphStyle, bodyText, style }) => {
  return (
    <Card hideBoxShadow aphStyle={[ss.card, aphStyle]} style={style}>
      <div style={{ marginRight: 8 }}>
        <Icon name="info" size={16} color={styles.colors.blueInfo} />
      </div>
      <Body tag="div" variant="caption">
        {bodyText}
      </Body>
    </Card>
  );
};

PhoneAddressInfo.propTypes = {
  aphStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  bodyText: PropTypes.string,
  style: PropTypes.object,
};

PhoneAddressInfo.defaultProps = {
  bodyText: `We will only share your contact information with Sweeten general
    contractors you've asked to be introduced to.`,
};

export default PhoneAddressInfo;
