import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Body,
  Checkbox,
  Col,
  Header,
  FormLabel,
  Input,
  SingleSelectDropdown,
  Row,
  TextLink,
  styles,
} from "@sweeten/oreo";
import { Textarea } from "@sweeten/domo";
import { StyleSheet, css } from "aphrodite";
import fetch from "unfetch";
import { useForm, useField } from "react-final-form-hooks";
import Cookies from "js-cookie";
import { withApollo } from "react-apollo";
import { AppDispatch } from "../../components/app_provider";
import {
  required,
  phoneNumber as phoneNumberValidator,
  validateEmail,
  validate,
} from "../../validators";
import { goToPage, sameLabelValueOptions } from "../../utils";
import FormButton from "./form_button";

const { breakpoints, center, colors } = styles;

/* =================== Styles =================== */

const ss = StyleSheet.create({
  checkbox: { minWidth: 16 },
  textLink: {
    fontSize: 12,
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: { fontSize: 14 },
    }),
  },
  formContainer: { ...center() },
  formElement: { marginBottom: 16 },
  textContainer: {
    color: colors.grey50,
    ...center("vertical"),
    ...styles.mediaQuery({
      maxWidth: breakpoints.phoneStandard,
      style: { marginBottom: 32 },
    }),
  },
});

const AREAS = [
  "AZ - Phoenix Metro Area",
  "CA - Los Angeles Metro Area",
  "CA - Orange County",
  "CA - San Francisco Bay Area",
  "CA - Ventura County",
  "CT - Fairfield County",
  "CO - Denver Metro Area",
  "DC - Washington D.C Metro Area",
  "FL - Miami Metro Area",
  "GA - Atlanta Metro Area",
  "IL - Chicago Metro Area",
  "MA - Boston Metro Area",
  "MN - Minneapolis - St. Paul Metro Area",
  "NC - Charlotte Metro Area",
  "NC - Raleigh-Durham",
  "NV - Las Vegas Metro Area",
  "NY - New York City Metro Area",
  "NY - Long Island",
  "NY - Lower Hudson",
  "NJ - New Jersey",
  "PA - Philadelphia Metro Area",
  "TN - Nashville Metro Area",
  "TX - Austin Metro Area",
  "TX - Dallas - Ft. Worth Metro Area",
  "TX - Houston Metro Area",
  "WA - Seattle Metro Area",
  "Other",
];

const formatValues = values => {
  const {
    area,
    firstName,
    lastName,
    company,
    phoneNumber,
    email,
    licenseNo,
    url,
    questions,
    acceptMemberAgreementTerms,
  } = values;

  const gcContactValues = [];
  const referralCode = Cookies.get("referral_code");

  if (area) {
    gcContactValues.push({ name: "gc_location_area", value: area });
  }
  if (firstName) {
    gcContactValues.push({ name: "firstname", value: firstName });
  }
  if (lastName) {
    gcContactValues.push({ name: "lastname", value: lastName });
  }
  if (company) {
    gcContactValues.push({ name: "company", value: company });
  }
  if (phoneNumber) {
    gcContactValues.push({ name: "phone", value: phoneNumber });
    gcContactValues.push({ name: "gc_cell_phone", value: phoneNumber });
  }
  if (email) {
    gcContactValues.push({ name: "email", value: email });
  }
  if (licenseNo) {
    gcContactValues.push({ name: "gc_licenses", value: licenseNo });
  }
  if (url) {
    gcContactValues.push({ name: "gc_business_website_urls", value: url });
  }
  if (questions) {
    gcContactValues.push({
      name: "gc_additional_information",
      value: questions,
    });
  }
  if (acceptMemberAgreementTerms) {
    gcContactValues.push(
      {
        name: "gc_accept_member_agreement",
        value: acceptMemberAgreementTerms,
      },
      { name: "gc_accept_terms", value: acceptMemberAgreementTerms }
    );
  }
  if (referralCode) {
    gcContactValues.push({
      name: "gc_referral_code",
      value: referralCode,
    });
  }

  return gcContactValues;
};

const GCContactInfo = ({ client }) => {
  const dispatch = useContext(AppDispatch);
  const showErrorAlert = () => {
    dispatch({
      type: "alert:show",
      payload: {
        variant: "error",
        text:
          "There was an error processing your request. Please try again later.",
      },
    });
  };

  const [isLoading, toggleLoading] = useState(false);
  const { form, handleSubmit } = useForm({
    onSubmit: values => {
      toggleLoading(true);

      fetch(
        // eslint-disable-next-line max-len
        `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.HUBSPOT_PORTAL_ID}/${process.env.HUBSPOT_CONTACT_FORM_ID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fields: formatValues(values) }),
        }
      )
        .then(res => res.json())
        .then(resJson => {
          if (resJson.status === "error") {
            toggleLoading(false);
            showErrorAlert();

            if (typeof Sentry !== "undefined") {
              Sentry.captureMessage(resJson.errors[0].message);
            }
          } else {
            const { firstName, lastName, company, email, area } = values;

            const isSmallerServiceArea =
              area === "MA - Boston Metro Area" ||
              area === "TX - Dallas - Ft. Worth Metro Area" ||
              area === "PA - Philadelphia Metro Area";

            Cookies.set(
              "hs_applicant",
              JSON.stringify({
                firstName,
                lastName,
                company,
                email,
                isSmallerServiceArea,
              })
            );

            const referralCode = Cookies.get("referral_code");
            if (referralCode) {
              fetch("/api/v1/referral_rock/referrals/create_gc_referral", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email,
                  firstName,
                  referralCode,
                }),
              });
            }

            window.dataLayer.push({
              event: "hubspot-form-success",
              "hs-form-guid": "gc-initial-contact-form",
              eventCallback: () => goToPage("/portfolio"),
              eventTimeout: 5000,
            });
          }
        });
    },
  });

  const area = useField("area", form, required);
  const firstName = useField("firstName", form, required);
  const lastName = useField("lastName", form, required);
  const company = useField("company", form, required);
  const phoneNumber = useField("phoneNumber", form, val =>
    validate([phoneNumberValidator, required], val)
  );
  const email = useField("email", form, val => validateEmail(val, client));
  const licenseNo = useField("licenseNo", form, required);
  const url = useField("url", form);
  const questions = useField("questions", form);
  const acceptMemberAgreementTerms = useField(
    "acceptMemberAgreementTerms",
    form
  );

  return (
    <>
      <Row aphStyle={ss.formContainer}>
        <Col
          width={{
            desktopStandard: 6,
            tabletStandard: 12,
          }}
        >
          <Header tag="h5">Win Bigger & Better Renovation Projects</Header>
          <Body tag="p" style={{ color: colors.grey40 }}>
            We vet our leads and deliver high-quality projects. Average project
            size: $125,000. Signing up is free and takes a few minutes.
          </Body>
          <div className={css(ss.formElement)}>
            <FormLabel>Area</FormLabel>
            <SingleSelectDropdown
              {...area.input}
              items={sameLabelValueOptions(AREAS)}
              error={area.meta.touched && area.meta.error}
              placeholder="Select area"
              style={{ marginBottom: 32 }}
            />
          </div>
          <div className={css(ss.formElement)}>
            <FormLabel>Name</FormLabel>
            <Input
              {...firstName.input}
              error={firstName.meta.touched && firstName.meta.error}
              placeholder="First"
              style={{ marginBottom: 32 }}
            />
            <Input
              {...lastName.input}
              error={lastName.meta.touched && lastName.meta.error}
              placeholder="Last"
              style={{ marginBottom: 32 }}
            />
          </div>
          <div className={css(ss.formElement)}>
            <FormLabel>Company Name</FormLabel>
            <Input
              {...company.input}
              error={company.meta.touched && company.meta.error}
              style={{ marginBottom: 32 }}
            />
          </div>
          <div className={css(ss.formElement)}>
            <FormLabel>Phone Number</FormLabel>
            <Input
              {...phoneNumber.input}
              error={phoneNumber.meta.touched && phoneNumber.meta.error}
              style={{ marginBottom: 32 }}
              pattern="[0-9]*"
            />
          </div>
          <div className={css(ss.formElement)}>
            <FormLabel>Email</FormLabel>
            <Input
              {...email.input}
              error={email.meta.touched && email.meta.error}
              style={{ marginBottom: 32 }}
            />
          </div>
          <div className={css(ss.formElement)}>
            <FormLabel>License Number</FormLabel>
            <Input
              {...licenseNo.input}
              error={licenseNo.meta.touched && licenseNo.meta.error}
            />
            <Body
              tag="p"
              variant="caption"
              style={{ color: colors.blueInfo, marginTop: 24 }}
            >
              If you have multiple licenses, you can separate them using a
              comma. Eg: 4FFG367357, 23GJEK243, 34FR34.
            </Body>
          </div>
          <div className={css(ss.formElement)}>
            <FormLabel>URL (Website, Houzz, Instagram, Facebook)</FormLabel>
            <Input {...url.input} placeholder="Optional" />
            <Body tag="p" variant="caption" style={{ color: colors.blueInfo }}>
              If you have multiple URLs, please separate them with a comma.
            </Body>
          </div>
          <div className={css(ss.formElement)}>
            <FormLabel>Do you have any questions for us?</FormLabel>
            <Textarea {...questions.input} heightVariant="small" />
          </div>
          <div className={css(ss.textContainer)} style={{ marginBottom: 32 }}>
            <Checkbox
              aphStyle={ss.checkbox}
              value={acceptMemberAgreementTerms.input.value}
              onClick={() =>
                acceptMemberAgreementTerms.input.onChange(
                  !acceptMemberAgreementTerms.input.value
                )
              }
            />
            <Body tag="span" variant="caption">
              I am an authorized representative or the owner of this company,
              and I accept Sweeten&apos;s{" "}
              <TextLink
                href="/member-agreement"
                target="_blank"
                aphStyle={ss.textLink}
              >
                Membership Agreement and Fees,{" "}
              </TextLink>
              <TextLink
                href="/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
                aphStyle={ss.textLink}
              >
                Terms of Use,
              </TextLink>{" "}
              and{" "}
              <TextLink
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                aphStyle={ss.textLink}
              >
                Privacy Policy.
              </TextLink>
            </Body>
          </div>
          <FormButton
            disabled={!acceptMemberAgreementTerms.input.value}
            loading={isLoading}
            handleSubmit={handleSubmit}
          />
        </Col>
      </Row>
    </>
  );
};

GCContactInfo.propTypes = {
  client: PropTypes.object,
};

export default withApollo(GCContactInfo);
