import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Body, Header } from "@sweeten/oreo";
import { goToPage } from "../../../utils";
import { trackViewStepPrimeForm } from "../tracking_events";

const handleSubmit = () => {
  goToPage("/overview");
};

const OutOfService = ({ isActive, setHandleSubmit }) => {
  useEffect(() => {
    if (isActive) {
      setHandleSubmit(() => handleSubmit);
    }
  }, [isActive]);

  useEffect(() => {
    trackViewStepPrimeForm("Out of Service");
  }, []);

  return (
    <>
      <Header tag="h5">
        Sorry, we couldn&apos;t find any general contractors near your area.
      </Header>
      <Body tag="p">
        We don&apos;t have any contractors in your area right now, but can still
        help you define the detailed scope of work for your project and create a
        document for you to share with any contractors you&apos;re considering.
      </Body>
      <Body tag="p">
        We&apos;ll keep you posted on our launch in your area!
      </Body>
    </>
  );
};

OutOfService.propTypes = {
  isActive: PropTypes.bool,
  setHandleSubmit: PropTypes.func,
};

export default OutOfService;
