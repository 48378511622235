export const trackClickEntireHome = () =>
  window.dataLayer.push({ event: "clicked-entire-home" });

export const trackClickOneOrMoreRooms = () =>
  window.dataLayer.push({ event: "clicked-one-or-more-rooms" });

export const trackViewSqFtError = () =>
  window.dataLayer.push({ event: "viewed-sq-ft-error" });

export const trackClickAddRoom = () =>
  window.dataLayer.push({ event: "clicked-add-room" });

export const trackClickSaveAndAddAnother = () =>
  window.dataLayer.push({ event: "clicked-save-and-add-another" });

export const trackAddRoomError = () =>
  window.dataLayer.push({ event: "add-room-error" });

export const trackAddRoomScopeError = () =>
  window.dataLayer.push({ event: "add-room-scope-error" });

export const trackViewRooms = () =>
  window.dataLayer.push({ event: "viewed-rooms" });
