import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite";
import { concat, isString, throttle } from "lodash";
import { Body, Header, TopNav, styles } from "@sweeten/oreo";

export const stickyBannerHeightDesktop = 121;

const ContentSS = StyleSheet.create({
  container: {
    marginRight: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        marginRight: 0,
        marginBottom: 16,
        textAlign: "left",
      },
    }),
  },
  mobileHeader: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        fontSize: 14,
        lineHeight: "18px",
      },
    }),
  },
  mobileBody: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        fontSize: 14,
      },
    }),
  },
});

export const Content = ({ aphStyle, children, header, style }) => (
  <div className={css(ContentSS.container, aphStyle)}>
    {isString(header) ? (
      <Header aphStyle={ContentSS.mobileHeader} tag="h6">
        {header}
      </Header>
    ) : (
      header
    )}
    <Body
      tag="div"
      variant="large"
      aphStyle={ContentSS.mobileBody}
      style={style}
    >
      {children}
    </Body>
  </div>
);

Content.propTypes = {
  aphStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]),
  header: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]),
  style: PropTypes.object,
};

export const StickyBannerSS = StyleSheet.create({
  bannerSpacing: {
    transition: "padding .2s",
  },
  bannerContainer: {
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    top: TopNav.style.desktop.height,
    left: 0,
    right: 0,
    padding: "0px 24px",
    background: styles.colors.grey10,
    height: stickyBannerHeightDesktop,
    zIndex: styles.zIndexes.secondaryNav,
    ...styles.boxShadow({ vOffset: 2, blur: 4 }),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        top: TopNav.style.mobile.height,
        padding: "8px 24px",
      },
    }),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        ...styles.boxShadow({ vOffset: -2, blur: 4 }),
        top: "auto",
        bottom: 0,
        height: "auto",
        padding: 24,
      },
    }),
  },
  bannerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        flexFlow: "column",
        ...styles.center("horizontal"),
      },
    }),
  },
  bottomBuffer: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        display: "block",
        paddingTop: 200,
      },
    }),
  },
});

const BottomBuffer = () => <div className={css(StickyBannerSS.bottomBuffer)} />;

const StickyBanner = ({ aphStyle, children }) => {
  const bannerRef = useRef(null);
  const [dynamicBannerPadding, updatePadding] = useState(0);
  const adjustSpacingonResize = throttle(() => {
    const banner = bannerRef.current;
    if (banner && window.innerWidth > styles.breakpoints.tabletSmall) {
      updatePadding(banner.clientHeight);
    } else {
      updatePadding(0);
    }
  }, 60);

  useEffect(() => {
    window.addEventListener("resize", adjustSpacingonResize, false);
    adjustSpacingonResize();

    return () =>
      window.removeEventListener("resize", adjustSpacingonResize, false);
  }, []);
  return (
    <div>
      <div
        className={css(StickyBannerSS.bannerSpacing)}
        style={{ paddingBottom: dynamicBannerPadding }}
      />
      <div className={css(StickyBannerSS.bannerContainer)} ref={bannerRef}>
        <div className={css(concat(StickyBannerSS.bannerContent, aphStyle))}>
          {children}
        </div>
      </div>
    </div>
  );
};

StickyBanner.propTypes = {
  aphStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

StickyBanner.Content = Content;
StickyBanner.BottomBuffer = BottomBuffer;

export default StickyBanner;
