import React from "react";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite";
import { styles } from "@sweeten/oreo";
import Checkmark from "../components/checkmark";

const { mediaQuery } = styles;
const { desktopStandard } = styles.breakpoints;

const ss = StyleSheet.create({
  container: {
    display: "none",
    ...mediaQuery({
      minWidth: desktopStandard,
      style: {
        marginTop: -16,
        width: "50%",
        display: "block",
      },
    }),
  },
  leftSubContainer: {
    height: "100%",
    padding: "60px 110px 320px 164px",
    ...mediaQuery({
      minWidth: desktopStandard,
      style: {
        background: "linear-gradient(135deg, #FFF8F4 0%, #ECF7FE 100%)",
      },
    }),
  },
  header: {
    opacity: "0.66",
    color: "#FF7240",
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "24px",
    marginBottom: 8,
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    lineHeight: "36px",
    marginBottom: 24,
  },
  subtitle: {
    color: "#777E90",
    fontSize: 16,
    lineHeight: "24px",
    marginBottom: 16,
  },
  flexCentered: {
    ...styles.center("vertical"),
    marginBottom: 16,
  },
  checklistText: {
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 500,
  },
  gcImage: {
    marginTop: 44,
    float: "right",
    width: 287,
  },
});

const ChecklistItem = ({ text }) => (
  <div className={css(ss.flexCentered)}>
    <div>
      <Checkmark />
    </div>
    <div className={css(ss.checklistText)}>{text}</div>
  </div>
);

ChecklistItem.propTypes = {
  text: PropTypes.string,
};

const NewFeatureSidebar = ({
  checklistItems,
  imgPath,
  subtitle,
  title,
  type,
}) => {
  return (
    <div className={css(ss.container)}>
      <div className={css(ss.leftSubContainer)}>
        <div className={css(ss.header)}>NEW FEATURE</div>
        <div className={css(ss.title)}>{title}</div>
        {type === "bid leveling" && (
          <div style={{ marginBottom: 16 }}>
            Collecting quotes from general contractors for your renovation
            project is an important step to identifying the right person for the
            job. But reviewing this information and figuring out what follow up
            questions to ask can be overwhelming. Sweeten can help!
          </div>
        )}
        <div className={css(ss.subtitle)}>{subtitle}</div>
        {checklistItems.map(itemText => (
          <ChecklistItem text={itemText} />
        ))}
        <img src={imgPath} alt="login" className={css(ss.gcImage)} />
      </div>
    </div>
  );
};

NewFeatureSidebar.propTypes = {
  checklistItems: PropTypes.array,
  imgPath: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default NewFeatureSidebar;
