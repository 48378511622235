import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { Accordion, Icon, CTA, Body, styles } from "@sweeten/oreo";
import PhotoUploader from "./photo_uploader";
import { loadUppy } from "../utils";

const ss = StyleSheet.create({
  beforeLabel: {
    fontSize: 12,
    marginTop: 32,
  },
  attach: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
});

const focusPhotoUploader = photoCTA => {
  photoCTA.current.scrollIntoView({ alignToTop: true, behavior: "smooth" });
};

const AttachPhotos = ({ projectId, aphStyle, style }) => {
  const [photoUploaderViewable, setPhotoUploaderViewable] = useState(false);
  const [uppyLoaded, setUppyLoaded] = useState(false);
  const photoCTA = useRef(null);

  useEffect(() => {
    if (photoUploaderViewable) {
      focusPhotoUploader(photoCTA);
    }
  }, [photoUploaderViewable]);

  useEffect(() => {
    loadUppy("photo-uploader").then(() => setUppyLoaded(true));
  }, []);

  return (
    <div className={css(ss.beforeLabel, aphStyle)} style={style}>
      <Accordion shouldHideContent>
        <CTA onClick={() => setPhotoUploaderViewable(!photoUploaderViewable)}>
          <div className={css(ss.attach)} ref={photoCTA}>
            <Icon
              name="attachment"
              size={16}
              color={styles.colors.brandPrimary}
              style={{ margin: "4px 8px 0px 0px" }}
            />
            <Body tag="div" style={{ color: styles.colors.brandPrimary }}>
              Attach Plans and Photos
            </Body>
          </div>
        </CTA>
        <div id="photo-uploader">
          {uppyLoaded ? (
            <PhotoUploader projectId={projectId} category="before" />
          ) : null}
        </div>
      </Accordion>
    </div>
  );
};

AttachPhotos.propTypes = {
  projectId: PropTypes.string,
  aphStyle: PropTypes.object,
  style: PropTypes.object,
};

export default AttachPhotos;
