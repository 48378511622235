/* eslint-disable consistent-return */
import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormLabel,
  Modal,
  SingleSelectDropdown,
  TabMenu,
  TextLink,
  styles,
} from "@sweeten/oreo";
import { StyleSheet } from "aphrodite";
import { useForm, useField } from "react-final-form-hooks";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import {
  finishLevelOptions,
  ownershipTypeOptions,
  ownerRelationOptions,
  umbrellaInsuranceOptions,
  propertyTypeOptions,
} from "../shared";
import { servicesMap } from "../../ren/project_posting/steps/additional_services";
import { budgetFlexibilityOptions } from "../../ren/project_posting/steps/budget_flexibility";
import { inFiveNYBoroughs } from "../../ren/project_posting/wizard";
import { sameLabelValueOptions } from "../../utils";
import { fieldError } from "../../components/payments/form_utils";
import { required } from "../../validators";

const ss = StyleSheet.create({
  tabMenu: {
    marginBottom: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        top: "80px",
      },
    }),
  },
  tab: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        fontSize: 14,
      },
    }),
  },
  cancelLink: {
    marginRight: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        marginRight: 0,
        marginTop: 16,
      },
    }),
  },
  ctaContainer: {
    paddingRight: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        flexFlow: "column-reverse nowrap",
        marginTop: 0,
      },
    }),
  },
});

const UPDATE_PROJECT = gql`
  mutation projectUpdate($id: ID!, $attributes: ProjectUpdateInput!) {
    projectUpdate(id: $id, attributes: $attributes) {
      project {
        id
        additionalService
        budgetEstimate {
          min
          max
        }
        budgetFlexibility
        finishLevel
        ownershipType
        ownerRelation
        propertyType
        title
        umbrellaInsurance
        workingWithArchitectDesigner
      }
    }
  }
`;

const ownerRelationValidation = (val, allValues) => {
  if (allValues.ownershipType === "Representing owner") {
    return required(val);
  }
};

const umbrellaInsuranceValidation = (val, allValues) => {
  if (allValues.propertyType === "apartment") {
    return required(val);
  }
};

const workingWithArchitectDesignerValidation = (val, allValues) => {
  if (allValues.additionalService === "just_a_general_contractor") {
    return required(val);
  }
};

const ProjectDetailsModal = ({
  closeModal,
  project,
  showSuccessAlert,
  currentUser,
}) => {
  const { user, listedAt, state } = project;
  const { additionalServicesCopyExperimentVariant: copyVariant } = user || {};
  const [update, { loading: updatingProject }] = useMutation(UPDATE_PROJECT);

  const { form, handleSubmit } = useForm({
    initialValues: {
      title: project.title,
      ownershipType: project.ownershipType,
      ownerRelation: project.ownerRelation,
      propertyType: project.propertyType,
      umbrellaInsurance: project.umbrellaInsurance,
      additionalService: project.additionalService,
      workingWithArchitectDesigner: project.workingWithArchitectDesigner,
      finishLevel: project.finishLevel,
      budgetFlexibility: project.budgetFlexibility,
    },
    onSubmit: values =>
      update({
        variables: {
          id: project.id,
          attributes: values,
        },
      }).then(() => {
        closeModal();
        showSuccessAlert();
      }),
  });

  const ownershipType = useField("ownershipType", form, required);
  const ownerRelation = useField("ownerRelation", form, (val, allValues) =>
    ownerRelationValidation(val, allValues)
  );
  const propertyType = useField("propertyType", form, required);
  const umbrellaInsurance = useField(
    "umbrellaInsurance",
    form,
    (val, allValues) => umbrellaInsuranceValidation(val, allValues)
  );
  const additionalService = useField("additionalService", form, required);
  const architectDesigner = useField(
    "workingWithArchitectDesigner",
    form,
    (val, allValues) => workingWithArchitectDesignerValidation(val, allValues)
  );
  const finishLevel = useField("finishLevel", form, required);
  const budgetFlexibility = useField("budgetFlexibility", form, required);

  return (
    <Modal
      onClose={closeModal}
      style={{ width: 613, padding: "48px 24px 48px 48px" }}
    >
      <Modal.Header>Project Details</Modal.Header>
      <Modal.Body style={{ paddingBottom: 24, paddingRight: 24 }}>
        <TabMenu defaultTabId="ownership" wrapperAphStyle={ss.tabMenu}>
          <TabMenu.Tab id="ownership" label="Ownership" aphStyle={ss.tab}>
            <FormLabel>Ownership Status</FormLabel>
            <SingleSelectDropdown
              {...ownershipType.input}
              disabled={state === "posted" && !currentUser.isAdmin}
              error={fieldError(ownershipType)}
              items={Object.keys(ownershipTypeOptions).map(option => {
                return {
                  label: ownershipTypeOptions[option],
                  value: option,
                };
              })}
              style={{ marginBottom: 24 }}
            />
            {ownershipType.input.value === "Representing owner" && (
              <>
                <FormLabel>Owner Relation</FormLabel>
                <SingleSelectDropdown
                  {...ownerRelation.input}
                  disabled={!!listedAt && !currentUser.isAdmin}
                  error={fieldError(ownerRelation)}
                  items={sameLabelValueOptions(ownerRelationOptions)}
                  style={{ marginBottom: 24 }}
                />
              </>
            )}
            <FormLabel>Property Type</FormLabel>
            <SingleSelectDropdown
              {...propertyType.input}
              disabled={!!listedAt && !currentUser.isAdmin}
              error={fieldError(propertyType)}
              items={Object.keys(propertyTypeOptions).map(option => {
                return {
                  label: propertyTypeOptions[option],
                  value: option,
                };
              })}
              style={{ marginBottom: 24 }}
            />
            {propertyType.input.value === "apartment" &&
              inFiveNYBoroughs(project.location.county) && (
                <>
                  <FormLabel>Umbrella Insurance</FormLabel>
                  <SingleSelectDropdown
                    {...umbrellaInsurance.input}
                    disabled={!!listedAt && !currentUser.isAdmin}
                    error={fieldError(umbrellaInsurance)}
                    items={sameLabelValueOptions(umbrellaInsuranceOptions)}
                    style={{ marginBottom: 24 }}
                  />
                </>
              )}
          </TabMenu.Tab>
          <TabMenu.Tab
            id="design_and_finish_level"
            label="Design and Finish Level"
            aphStyle={ss.tab}
          >
            <FormLabel>Design Advice or Services</FormLabel>
            <SingleSelectDropdown
              {...additionalService.input}
              disabled={!!listedAt && !currentUser.isAdmin}
              error={fieldError(additionalService)}
              items={Object.keys(
                servicesMap(copyVariant === "alternative")
              ).map(option => {
                return {
                  label: servicesMap(copyVariant === "alternative")[option],
                  value: option,
                };
              })}
              style={{ marginBottom: 24 }}
            />
            {additionalService.input.value === "just_a_general_contractor" && (
              <>
                <FormLabel>
                  Are you currently working with an architect or designer?
                </FormLabel>
                <SingleSelectDropdown
                  {...architectDesigner.input}
                  disabled={!!listedAt && !currentUser.isAdmin}
                  error={fieldError(architectDesigner)}
                  items={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  style={{ marginBottom: 24 }}
                />
              </>
            )}
            <FormLabel>Finish Level</FormLabel>
            <SingleSelectDropdown
              {...finishLevel.input}
              error={fieldError(finishLevel)}
              disabled={!!listedAt && !currentUser.isAdmin}
              items={Object.keys(finishLevelOptions).map(option => {
                return {
                  label: finishLevelOptions[option],
                  value: option,
                };
              })}
              style={{ marginBottom: 24 }}
            />
            <FormLabel>Budget Flexibility</FormLabel>
            <SingleSelectDropdown
              {...budgetFlexibility.input}
              error={fieldError(budgetFlexibility)}
              items={Object.keys(budgetFlexibilityOptions).map(option => {
                return {
                  label: budgetFlexibilityOptions[option],
                  value: option,
                };
              })}
              style={{ marginBottom: 24 }}
            />
          </TabMenu.Tab>
        </TabMenu>
      </Modal.Body>
      <Modal.Footer aphStyle={ss.ctaContainer}>
        <TextLink onClick={closeModal} aphStyle={ss.cancelLink}>
          Cancel
        </TextLink>
        <Button
          loading={updatingProject}
          onClick={handleSubmit}
          style={{ cursor: "pointer" }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ProjectDetailsModal.propTypes = {
  closeModal: PropTypes.func,
  project: PropTypes.object,
  showSuccessAlert: PropTypes.func,
  currentUser: PropTypes.object,
};

export default ProjectDetailsModal;
