import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { Row, Col } from "@sweeten/oreo";
import { Button } from "@sweeten/domo";

import { goToPage } from "../utils";

const ss = StyleSheet.create({
  container: {
    display: "flex",
    flexFlow: "row wrap",
  },
  adminButton: {
    margin: "8px 8px 0 0",
  },
});

const AdminButtons = ({
  authToken,
  editCompanyPath,
  impersonatePath,
  hubspotCompanyUrl,
}) => (
  <Row verticalGap={0}>
    <Col width={12}>
      <div className={css(ss.container)}>
        <form
          action={impersonatePath}
          method="post"
          className={css(ss.adminButton)}
        >
          <Button label="Impersonate" type="submit" />
          <input name="authenticity_token" type="hidden" value={authToken} />
        </form>
        <form
          action={editCompanyPath}
          method="get"
          className={css(ss.adminButton)}
        >
          <Button label="Edit Company" type="submit" />
        </form>
        {!!hubspotCompanyUrl && (
          <Button
            label="HubSpot"
            aphStyle={ss.adminButton}
            onClick={() =>
              goToPage(hubspotCompanyUrl, { shouldOpenNewTab: true })
            }
          />
        )}
      </div>
    </Col>
  </Row>
);

AdminButtons.propTypes = {
  authToken: PropTypes.string,
  editCompanyPath: PropTypes.string,
  impersonatePath: PropTypes.string,
  hubspotCompanyUrl: PropTypes.string,
};

export default AdminButtons;
