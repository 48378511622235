import React from "react";
import PropTypes from "prop-types";
import { styles, Body, Icon } from "@sweeten/oreo";
import { css, StyleSheet } from "aphrodite";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

const REVIEWS_QUERY = gql`
  query getReviewsCompanyShow($companyId: ID!) {
    company(id: $companyId) {
      id
      averageRating
      reviews(status: ["received"]) {
        id
      }
    }
  }
`;

const ss = StyleSheet.create({
  container: styles.center("vertical"),
  body: {
    marginLeft: 4,
    whiteSpace: "nowrap",
  },
});

const GcStarRating = ({ companyId, starColor, iconSize, bodyAphStyle }) => {
  const { data, loading } = useQuery(REVIEWS_QUERY, {
    variables: { companyId },
  });
  if (loading) {
    return null;
  }
  const { company } = data;
  const { averageRating: rating, reviews } = company;
  const reviewCount = reviews.length;
  const reviewCopy = reviewCount === 1 ? "review" : "reviews";

  if (reviewCount > 0) {
    return (
      <div className={css(ss.container)}>
        <Icon color={starColor} name="star-filled" size={iconSize} />
        <Body aphStyle={[ss.body, bodyAphStyle]} tag="div">
          <b>{rating.toFixed(2)} </b>
          <span style={{ color: styles.colors.grey40, fontSize: 14 }}>
            ({reviewCount} {reviewCopy})
          </span>
        </Body>
      </div>
    );
  }
  return <i style={{ color: styles.colors.grey30 }}>No reviews yet</i>;
};

GcStarRating.defaultProps = {
  iconSize: 18,
};

GcStarRating.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  starColor: PropTypes.string,
  iconSize: PropTypes.number,
  bodyAphStyle: PropTypes.object,
};

export default GcStarRating;
